import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';

import {AuthService} from '../auth.service';
import {MustMatch} from '../../_helpers/matchpassword';

import {environment} from './../../../environments/environment';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {AppConfig} from './../../../app/app.config';
import {ConstantHelper} from './../../_helpers/constant.helper';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class AdminLoginComponent implements OnInit {
  @ViewChild('recaptcha', {static: false}) recaptchaElement: ElementRef;
  @HostListener('window:resize', ['$event'])
  loginForm: FormGroup;
  loginFromSubmitted = false;
  formErrorMessage = false;
  showLoginForm = true;
  verifyLoginOtpForm: FormGroup;
  verifyLoginOtpFormSubmitted = false;
  verifyLoginFormErrorMessage = false;
  showVerifyLoginOtpForm = false;
  userOtp: number;
  userId: number;
  userEmail: string;
  userMobile: string;
  userRoleId: number;
  resultJsonObj: any = {};
  formRecaptchaErrorMessage = false;
  showLoader = false;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private http: HttpClient
  ) {
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      recaptchaValue: ['', Validators.required],
    });

    this.verifyLoginOtpForm = this.formBuilder.group({
      otp: ['', Validators.required],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  get v() {
    return this.verifyLoginOtpForm.controls;
  }

  onSubmit() {
    this.loginFromSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_AdminloginApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          // console.log(apiResponse);
          this.resultJsonObj = apiResponse;

          if (this.resultJsonObj.commandResult.status === 1) {
            this.userId = this.resultJsonObj.commandResult.data.userInfo.Id;
            this.userOtp = this.resultJsonObj.commandResult.data.userInfo.Otp;
            this.showLoginForm = false;
            this.showVerifyLoginOtpForm = true;
          } else {
            this.formErrorMessage = true;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  verifyOtp() {
    this.verifyLoginOtpFormSubmitted = true;

    // stop here if form is invalid
    if (this.verifyLoginOtpForm.invalid) {
      return;
    }

    if (
      this.verifyLoginOtpForm.value.otp.toString().trim() !=
      this.userOtp.toString().trim()
    ) {
      this.verifyLoginFormErrorMessage = true;
    } else {
      const httpHeaders = new HttpHeaders({
        // "Content-Type": "application/x-www-form-urlencoded"
        'Content-Type': 'application/json',
      });
      const options = {
        headers: httpHeaders,
      };
      const finalData = {
        otp: this.verifyLoginOtpForm.value.otp,
        id: this.userId,
      };
      this.showLoader = true;

      return this.http
        .post(
          this.appConfig.getLambdaUrl01() +
          ApiHelper.name_verifyAdminLoginOtpApi,
          finalData,
          options
        )
        .subscribe(
          (apiResponse) => {
            // console.log(apiResponse);
            this.resultJsonObj = apiResponse;
            if (this.resultJsonObj.commandResult.status == 1) {
              this.userId =
                this.resultJsonObj.commandResult.data.userInfo.RecordId;
              // console.log(this.resultJsonObj.commandResult.data.userInfo);
              // if(this.resultJsonObj.commandResult.data.userInfo.UserRoleId==2){
              //   this.router.navigate(["/demographicsform/" + this.userId]);
              // }
              // if(this.resultJsonObj.commandResult.data.userInfo.UserRoleId==1){
              //   this.router.navigate(["/admin/users/" + this.userId]);
              // }

              this.router.navigate(['/admin/demographics/' + this.userId]);
            } else {
              this.verifyLoginFormErrorMessage = true;
            }
            this.showLoader = false;
          },
          (err) => {
            // console.log(err);
          }
        );
    }

    // this.authService.createRegistration(this.registrationForm.value);
    // this.router.navigate(["/demographicsform"]);
  }

  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      sitekey: ConstantHelper.RECAPTCHA_SITE_KEY_CLIENT,
      callback: (response) => {
        // console.log(response);
        this.loginForm.get('recaptchaValue').setValue(response);

        /////////////////// recaptcha validation /////////////////
        const httpHeaders = new HttpHeaders({
          'Content-Type': 'application/json',
        });
        const options = {
          headers: httpHeaders,
        };
        const finalData = {
          recaptchaResponse: response,
          remoteIP: '127.0.0.1',
        };
        this.showLoader = true;

        return this.http
          .post(
            this.appConfig.getLambdaUrl01() +
            ApiHelper.name_verifyRecaptachaApi,
            finalData,
            options
          )
          .subscribe(
            (apiResponse) => {
              // console.log(apiResponse);
              this.resultJsonObj = apiResponse;
              // console.log(this.resultJsonObj);

              if (this.resultJsonObj.commandResult.status === 1) {
                this.formRecaptchaErrorMessage = false;
              } else {
                this.formRecaptchaErrorMessage = true;
              }
              this.showLoader = false;
            },
            (err) => {
              // console.log(err);
            }
          );
        /////////////////////////////////////////////////////////
      },
    });
  }

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    };

    (function(d, s, id, obj) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        obj.renderReCaptch();
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src =
        'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'recaptcha-jssdk', this);
  }

  ngAfterViewInit() {
    this.addRecaptchaScript();
    this.captchScaleFunc();
  }

  onResize(event) {
    this.captchScaleFunc();
  }

  captchScaleFunc() {
    const captchacontainWidth = this.recaptchaElement.nativeElement.clientWidth;
    const scaleValue = captchacontainWidth / 304;
    if (scaleValue < 1) {
      this.recaptchaElement.nativeElement.style.transform =
        'scale(' + scaleValue + ')';
      this.recaptchaElement.nativeElement.style.transformOrigin = '0';
    }
    // console.log(captchacontainWidth);
  }
}
