import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { SignaturePad } from "angular2-signaturepad";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";
@Component({
  selector: "app-signature-waiver",
  templateUrl: "./signature-waiver.component.html",
  styleUrls: ["./signature-waiver.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class SignatureWaiverComponent implements OnInit {
  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  clean = "0";
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  @ViewChild("canvasArea", { static: true }) canvasArea: ElementRef;
  canvasElm: any;
  public signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    minWidth: 1,
    canvasWidth:
      window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
    canvasHeight: 450,
  };

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  showLoader = false;
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,

    private http: HttpClient
  ) {}

  ngOnInit() {
    this.clean = this.activatedRoute.snapshot.paramMap.get("clean");
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.demographicId = localStorage.getItem("MinorDemographicId")
      ? localStorage.getItem("MinorDemographicId")
      : this.activatedRoute.snapshot.paramMap.get("demographicId");

    if (this.clean === "1") {
      this.cleanTempDocs();
    }
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }

  async saveCanvas() {
    if(this.signaturePad.isEmpty() === true){
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.signatureWaiverComp.saveCanvas
      );
    }else{
    const alertStatus = await this.sweetAlertHelper.alertPopUp(
      this.message.confirmMessages.signatureWaiverComp.saveCanvas
    );
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:  By clicking ‘OK’:  Updated Demographics will be added to your database.  Any future Demographic changes require resigning of AHS Documents since there may have been a name spelling or listing change.  You have been ‘fast tracked’ being able to group select all documents, reuse the previously displayed or update your signature on file."
    //   )
    // )
    if (alertStatus) {
      const finalData1 = {
        loggedInUserId: this.loggedInUserId,
      };
      this.showLoader = true;

      return this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_deactivateUserDocsApi,
          finalData1,
          this.httpOptions
        )
        .subscribe(
          (apiResponseDoc) => {
            const finalData = {
              loggedInUserId: this.loggedInUserId,
              demographicId: this.demographicId,
              signatureBase64: this.signaturePad.toDataURL(),
              company_slug: null,
            };
            this.http
              .post(
                this.appConfig.getLambdaUrl05() +
                  ApiHelper.name_generateSignatureImageApi,
                finalData,
                this.httpOptions
              )
              .subscribe(
                (apiResponse) => {
                  this.resultJsonObj = apiResponse;
                  // console.log(this.resultJsonObj);
                  this.http
                    .post(
                      this.appConfig.getPhpUrl() +
                        ApiHelper.php_generateDemographicPdfApi,
                      finalData,
                      this.httpOptions
                    )
                    .subscribe(
                      (apiDemoResponse: any) => {
                        // console.log(apiDemoResponse);
                        this.http
                          .post(
                            this.appConfig.getPhpUrl() +
                              ApiHelper.php_generateSignatureFilesPdfApi,
                            finalData,
                            this.httpOptions
                          )
                          .subscribe(
                            async (apiSignResponse: any) => {
                              this.showLoader = false;

                              // console.log(apiSignResponse);
                              if (
                                this.resultJsonObj.commandResult.status === 1
                              ) {
                                this.router.navigate([
                                  "/documentthoumbs/" +
                                    this.activatedRoute.snapshot.paramMap.get(
                                      "loggedInUserId"
                                    ) +
                                    "/" +
                                    this.activatedRoute.snapshot.paramMap.get(
                                      "demographicId"
                                    ),
                                ]);
                              } else {
                                // alert("oops! error occured, please try later");
                                const alertStatus =
                                  await this.sweetAlertHelper.alertPopUp(
                                    this.message.alertMessages.demographics
                                      .onSubmit
                                  );
                              }
                            },
                            (err) => {
                              // console.log(err);
                            }
                          );
                      },
                      (err) => {
                        // console.log(err);
                      }
                    );
                },
                (err) => {
                  // console.log(err);
                }
              );
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }
  }

  async clearCanvas() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.clearCanvas
    );
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:   By clicking ‘OK’: You will have an opportunity to modify and replace your signature."
    //   )
    // )
    if (alertStatus) {
      this.signaturePad.clear();
    }
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log("begin drawing");
  }

  editDemographicForm4() {
    this.router.navigate([
      "/edit-demographic-4/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/" +
        this.activatedRoute.snapshot.paramMap.get("demographicId"),
    ]);
  }

  goToWaiverDisclaimerForm() {
    this.router.navigate([
      "/waiverdisclaimerform/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/" +
        this.activatedRoute.snapshot.paramMap.get("demographicId"),
    ]);
  }

  goToDemographicForm() {
    this.router.navigate([
      "/demographicsform/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
    ]);
  }

  async saveCanvasMobile() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: By clicking 'OK', you are signing the document and now will be able to review each document."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.signatureWaiverComp.saveCanvasMobile1
    );
    {
      const httpHeaders = new HttpHeaders({
        "Content-Type": "application/json",
      });
      const options = {
        headers: httpHeaders,
      };
      const finalData1 = {
        loggedInUserId: this.loggedInUserId,
        company_slug: ConstantHelper.COMPANY_SLUG,
      };

      return this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_deactivateUserDocsApi,
          finalData1,
          options
        )
        .subscribe(
          (apiResponseDoc) => {
            const finalData = {
              loggedInUserId: this.loggedInUserId,
              demographicId: this.demographicId,
              signatureBase64: this.signaturePad.toDataURL(),
            };
            this.http
              .post(
                this.appConfig.getLambdaUrl05() +
                  ApiHelper.name_generateSignatureImageApi,
                finalData,
                options
              )
              .subscribe(
                (apiResponse) => {
                  this.resultJsonObj = apiResponse;
                  // console.log(this.resultJsonObj);
                  this.http
                    .post(
                      this.appConfig.getPhpUrl() +
                        ApiHelper.php_generateDemographicPdfApi,
                      finalData,
                      options
                    )
                    .subscribe(
                      (apiDemoResponse: any) => {
                        // console.log(apiDemoResponse);
                        this.http
                          .post(
                            this.appConfig.getPhpUrl() +
                              ApiHelper.php_generateSignatureFilesPdfApi,
                            finalData,
                            options
                          )
                          .subscribe(
                            async (apiSignResponse: any) => {
                              // console.log(apiSignResponse);
                              if (
                                this.resultJsonObj.commandResult.status === 1
                              ) {
                                this.router.navigate([
                                  "/documentthoumbs/" +
                                    this.activatedRoute.snapshot.paramMap.get(
                                      "loggedInUserId"
                                    ) +
                                    "/" +
                                    this.activatedRoute.snapshot.paramMap.get(
                                      "demographicId"
                                    ),
                                ]);
                              } else {
                                const alertStatus =
                                  await this.sweetAlertHelper.alertPopUp(
                                    this.message.alertMessages.demographics.onSubmit
                                  );
                                // alert("oops! error occured, please try later");
                              }
                            },
                            (err) => {
                              // console.log(err);
                            }
                          );
                      },
                      (err) => {
                        // console.log(err);
                      }
                    );
                },
                (err) => {
                  // console.log(err);
                }
              );
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async goToWelcome() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.signatureWaiverComp.goToWelcome
    );

    // if (
    //   confirm(
    //     "PLEASE CONFIRM: You must FIRST accept and sign AHS documents and confirm Demographics to use the platform.  Clicking ‘Cancel’ will return you to the ‘Home Page’ to either repeat this requirement or logout."
    //   )
    // )
    if (alertStatus) {
      this.router.navigate([
        "/dashboard/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/1",
      ]);
    }
  }

  cleanTempDocs() {
    const finalData = {
      id: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_cleanTempDocsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
        },
        (err) => {
          // console.log(err);
        }
      );
  }
}
