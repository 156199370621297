import { from } from "rxjs";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { IDropdownSettings } from "ng-multiselect-dropdown";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { UserService } from "./../../_services/user.service";
import { AppConfig } from "./../../../app/app.config";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";
import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";
import { versions } from "process";
import { compilePipeFromMetadata } from "@angular/compiler";

declare let $: any;
@Component({
  selector: "app-user-video",
  templateUrl: "./user-video.component.html",
  styleUrls: ["./user-video.component.scss"],
})
export class UserVideoComponent implements OnInit {
  loggedInUserId: string;
  showVideo1 = false;
  showVideo2 = false;
  showVideo3 = false;
  videoUrl1: string = "";
  videoUrl2: string = "";
  videoUrl3: string = "";
  message = Message;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper
  ) {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
  }

  ngOnInit() {}

  async goToDashboard() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // )
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }

  goToVideo01() {
    this.showVideo2 = false;
    this.videoUrl2 = "";
    this.showVideo3 = false;
    this.videoUrl3 = "";

    this.showVideo1 = true;
    this.videoUrl1 =
      "https://ahs-uat.s3-us-west-2.amazonaws.com/esign-videos/01.mp4";
  }
  goToVideo02() {
    this.showVideo1 = false;
    this.videoUrl1 = "";
    this.showVideo3 = false;
    this.videoUrl3 = "";
    this.showVideo2 = true;
    this.videoUrl2 =
      "https://ahs-uat.s3-us-west-2.amazonaws.com/esign-videos/02.mp4";
  }
  goToVideo03() {
    this.showVideo2 = false;
    this.videoUrl2 = "";
    this.showVideo1 = false;
    this.videoUrl1 = "";
    this.showVideo3 = true;
    this.videoUrl3 =
      "https://ahs-uat.s3-us-west-2.amazonaws.com/esign-videos/03.mp4";
  }

  clearVideo() {
    this.showVideo1 = false;
    this.showVideo2 = false;
    this.showVideo3 = false;
    this.videoUrl1 = "";
    this.videoUrl2 = "";
    this.videoUrl3 = "";
  }
}
