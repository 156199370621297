import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";

@Component({
  selector: 'app-physicians-list',
  templateUrl: './physicians-list.component.html',
  styleUrls: ['./physicians-list.component.scss']
})
export class PhysiciansListComponent implements OnInit {
  loggedInUserId: string;
  resultJsonObj: any = {};
  companiesLength: boolean = false;
  companiesList: any;
  usersLength: boolean = false;
  noUsersLength: boolean = false;
  loadingUsersLength: boolean = true;
  selectedCompanySlug: string;
  usersList: any;
  userDeactivateSuccess: boolean = false;
  userActivateSuccess: boolean = false;
  userErrorFlashMsg: boolean = false;
  apiURl: string;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });
  httpOptions = {
    headers: this.httpHeaders
  };


  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }
  ngOnInit() {
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get(
      "loggedInUserId"
    );
    const finalData = {
      loggedInUserId: this.loggedInUserId
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getAllPhysiciansApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          this.loadingUsersLength = false;
          if (this.resultJsonObj.commandResult.data.physiciansList.length > 0) {
            this.usersLength = true;
            this.usersList = this.resultJsonObj.commandResult.data.physiciansList;
          } else {
            this.noUsersLength = true;
          }
        },
        err => {
          // console.log(err);
        }
      );
  }



  importPhysicianCsv() {
    this.router.navigate(["/physicians/import/" + this.loggedInUserId]);
  }




}
