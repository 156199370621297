import { BrowserModule } from "@angular/platform-browser";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { NgModule } from "@angular/core";
import { SignaturePad } from "angular2-signaturepad";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { DataTablesModule } from "angular-datatables";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";

import * as $ from "jquery";
import { AngularFontAwesomeModule } from "angular-font-awesome";
import { AngularDualListBoxModule } from "angular-dual-listbox";
import { OrderByPipe } from "./order-by.pipe";

import { LoginComponent } from "./auth/login/login.component";
import { RegisterComponent } from "./auth/register/register.component";
import { DemographicsformComponent } from "./user/demographicsform/demographicsform.component";
import { HippoReleaseNavigationComponent } from "./user/hippo-release-navigation/hippo-release-navigation.component";
import { HippoReleaseFormComponent } from "./user/hippo-release-form/hippo-release-form.component";
import { WaiverDisclaimerNavigationComponent } from "./user/waiver-disclaimer-navigation/waiver-disclaimer-navigation.component";
import { WaiverDisclaimerFormComponent } from "./user/waiver-disclaimer-form/waiver-disclaimer-form.component";
import { SignatureWaiverComponent } from "./user/signature-waiver/signature-waiver.component";
import { DocumentThoubsComponent } from "./user/document-thoubs/document-thoubs.component";

import { CommonHelper } from "./_helpers/common.helper";
import { SweetAlertHelper } from "./_helpers/sweet.alert.helper";
import { AppConfig } from "./app.config";
import { Editform2Component } from "./user/editform2/editform2.component";
import { Editform3Component } from "./user/editform3/editform3.component";
import { Editform4Component } from "./user/editform4/editform4.component";
import { Editform5Component } from "./user/editform5/editform5.component";
import { ThankyouComponent } from "./user/thankyou/thankyou.component";
import { ForgotpasswordComponent } from "./auth/forgotpassword/forgotpassword.component";
import { ChangepasswordComponent } from "./auth/changepassword/changepassword.component";
import { HippaAcceptanceComponent } from "./user/hippa-acceptance/hippa-acceptance.component";
import { ConfirmationformComponent } from "./user/confirmationform/confirmationform.component";
import { UsersListComponent } from "./admin/users-list/users-list.component";
import { ThumbMobileComponent } from "./user/thumb-mobile/thumb-mobile.component";
import { AdminLoginComponent } from "./auth/admin-login/admin-login.component";
import { DemographicsComponent } from "./admin/demographics/demographics.component";
import { HeaderMenuComponent } from "./admin/header-menu/header-menu.component";
import { AddAdminUserComponent } from "./admin/add-admin-user/add-admin-user.component";
import { AdminForgotpasswordComponent } from "./auth/admin-forgotpassword/admin-forgotpassword.component";
import { AdminChangepasswordComponent } from "./auth/admin-changepassword/admin-changepassword.component";
import { FaqListComponent } from "./admin/faq-list/faq-list.component";
import { DocumentListComponent } from "./admin/document-list/document-list.component";
import { AddAdminDocComponent } from "./admin/add-admin-doc/add-admin-doc.component";
import { ModifyAdminDocComponent } from "./admin/modify-admin-doc/modify-admin-doc.component";
import { ActivityLogComponent } from "./admin/activity-log/activity-log.component";
import { WelcomeComponent } from "./auth/welcome/welcome.component";
import { TooltipModule } from "ng2-tooltip-directive";
import { PhysiciansListComponent } from "./admin/physicians-list/physicians-list.component";
import { ImportPhysicianComponent } from "./admin/import-physician/import-physician.component";
import { DashboardComponent } from "./user/dashboard/dashboard.component";
import { DoctorSignupComponent } from "./auth/doctor-signup/doctor-signup.component";
import { RegisteredSitesComponent } from "./common/registered-sites/registered-sites.component";
import { DoctorRegisterComponent } from "./auth/doctor-register/doctor-register.component";
import { ChooseDoctorsComponent } from "./user/choose-doctors/choose-doctors.component";
import { MustMatchDirective } from "./_directives/must-match.directive";
import { SenddocumentComponent } from "./user/senddocument/senddocument.component";
import { EmailLogComponent } from "./user/email-log/email-log.component";
import { ResendDocsComponent } from "./user/resend-docs/resend-docs.component";
import { RegisteredDoctorsComponent } from "./user/registered-doctors/registered-doctors.component";
import { DocReviewComponent } from "./user/doc-review/doc-review.component";
import { PageLoaderComponent } from "./page-loader/page-loader.component";
import { LoaderService } from "./_services/loader.service";
import { LoaderInterceptor } from "./_services/http-inter-cepter.service";
import { DoctorViewDocsComponent } from "./auth/doctor-view-docs/doctor-view-docs.component";
import { ViewDocsAcknowledgementComponent } from "./admin/view-docs-acknowledgement/view-docs-acknowledgement.component";
import { ViewUserDocsComponent } from "./admin/view-user-docs/view-user-docs.component";
import { UploadMultipleDocumentsComponent } from "./user/upload-multiple-documents/upload-multiple-documents.component";
import { ShowDocsComponent } from "./auth/show-docs/show-docs.component";
import { DocHeaderComponent } from "./admin/doc-header/doc-header.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { UshfComponent } from "./user/ushf/ushf.component";

import { NjComponent } from "./hipp/nj/nj.component";
import { NyComponent } from "./hipp/ny/ny.component";
import { FlComponent } from "./hipp/fl/fl.component";
import { PaComponent } from "./hipp/pa/pa.component";
import { CtComponent } from "./hipp/ct/ct.component";
import { OhComponent } from "./hipp/oh/oh.component";
import { CoComponent } from "./hipp/co/co.component";
import { CaComponent } from "./hipp/ca/ca.component";
import { LaComponent } from "./hipp/la/la.component";
import { MdComponent } from "./hipp/md/md.component";
import { MnComponent } from "./hipp/mn/mn.component";
import { MoComponent } from "./hipp/mo/mo.component";
import { MsComponent } from "./hipp/ms/ms.component";
import { NvComponent } from "./hipp/nv/nv.component";
import { OkComponent } from "./hipp/ok/ok.component";
import { WaComponent } from "./hipp/wa/wa.component";
import { NhComponent } from "./hipp/nh/nh.component";
import { KsComponent } from "./hipp/ks/ks.component";
import { IaComponent } from "./hipp/ia/ia.component";
import { AlComponent } from "./hipp/al/al.component";
import { AzComponent } from "./hipp/az/az.component";
import { ArComponent } from "./hipp/ar/ar.component";
import { DeComponent } from "./hipp/de/de.component";
import { GaComponent } from "./hipp/ga/ga.component";
import { HiComponent } from "./hipp/hi/hi.component";
import { IdComponent } from "./hipp/id/id.component";
import { IlComponent } from "./hipp/il/il.component";
import { AkComponent } from "./hipp/ak/ak.component";
import { TxComponent } from "./hipp/tx/tx.component";
import { SdComponent } from "./hipp/sd/sd.component";
import { NdComponent } from "./hipp/nd/nd.component";
import { VaComponent } from "./hipp/va/va.component";
import { VtComponent } from "./hipp/vt/vt.component";
import { KyComponent } from "./hipp/ky/ky.component";
import { WiComponent } from "./hipp/wi/wi.component";
import { WvComponent } from "./hipp/wv/wv.component";
import { TnComponent } from "./hipp/tn/tn.component";
import { OrComponent } from "./hipp/or/or.component";
import { NeComponent } from "./hipp/ne/ne.component";
import { MtComponent } from "./hipp/mt/mt.component";
import { NcComponent } from "./hipp/nc/nc.component";
import { ScComponent } from "./hipp/sc/sc.component";
import { InComponent } from "./hipp/in/in.component";
import { MeComponent } from "./hipp/me/me.component";
import { RiComponent } from "./hipp/ri/ri.component";
import { UtComponent } from "./hipp/ut/ut.component";
import { NmComponent } from "./hipp/nm/nm.component";
import { MaComponent } from "./hipp/ma/ma.component";

import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  OWL_DATE_TIME_LOCALE,
} from "ng-pick-datetime";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DateFilterPipe } from "./date-filter.pipe";
import { CommonModule, DatePipe } from "@angular/common";
import { UploadMultipleDocuments2Component } from "./user/upload-multiple-documents2/upload-multiple-documents2.component";
import { UserViewDocsComponent } from "./user/user-view-docs/user-view-docs.component";
import { UserVideoComponent } from "./user/user-video/user-video.component";
import { NgxTrimDirectiveModule } from "ngx-trim-directive";
import { ExpertPhysicianOpinionComponent } from "./user/expert-physician-opinion/expert-physician-opinion.component";
import { ExpertPhysicianOpinionLogComponent } from "./user/expert-physician-opinion-log/expert-physician-opinion-log.component";
import { AuthShowD2dDocumentsComponent } from "./auth/auth-show-d2d-documents/auth-show-d2d-documents.component";
import { AdminViewD2dDocsComponent } from "./admin/admin-view-d2d-docs/admin-view-d2d-docs.component";
import { ExpertPhysicianOpinion2Component } from "./user/expert-physician-opinion2/expert-physician-opinion2.component";
import { UserViewD2dDocsComponent } from "./user/user-view-d2d-docs/user-view-d2d-docs.component";
import { UserReplyD2dComponent } from "./user/user-reply-d2d/user-reply-d2d.component";
import { LoaderComponent } from "./loader/loader.component";

// import { ImageViewerModule } from "ng2-image-viewer";
import { AngularImageViewerModule } from "angular-x-image-viewer";
import { SendEmailComponent } from "./admin/send-email/send-email.component";
import { PracticesComponent } from "./admin/practices/practices.component";
import { AddAdminPracticeComponent } from "./admin/add-admin-practice/add-admin-practice.component";
import { UserContactUsComponent } from "./user/user-contact-us/user-contact-us.component";
import { UserChangePasswordComponent } from "./user/user-change-password/user-change-password.component";
import { UserSecurityQuestions } from "./user/user-security-questions/user-security-questions.component";
import { ParentMinorRelationComponent } from "./user/parent-minor-relation/parent-minor-relation.component";
import { ParentMinorLoginComponent } from "./auth/parent-minor-login/parent-minor-login.component";
import { UserHeaderMenuComponent } from "./user/user-header-menu/user-header-menu.component";
import { MinorActivityLogComponent } from "./user/minor-activity-log/minor-activity-log.component";
import { ReportsComponent } from "./admin/reports/reports.component";
import { LoginQaComponent } from './auth/login-qa/login-qa.component';
import { IdleTimerService } from './_services/idle-timer.service';
import { PatientDemographicsComponent } from './user/profile/patient-demographics/patient-demographics.component';
import { AcceptHipaaComponent } from './user/profile/accept-hipaa/accept-hipaa.component';
import { SignHipaaComponent } from './user/profile/sign-hipaa/sign-hipaa.component';
import { ReviewHipaaComponent } from './user/profile/review-hipaa/review-hipaa.component';
import { AhsaichatgptComponent } from "./ahsaichatgpt/ahsaichatgpt.component";
import { ChatbotComponent } from "./chatbot/chatbot.component";
import { ChatComponent } from "./chatBot-window/chat.component";
import { ShareDocuments } from "./user/share-document/share-documents.component";
import { PatientHistory } from "./user/patient-history/patient-history.component";
@NgModule({
  declarations: [
    AppComponent,
    SignaturePad,
    OrderByPipe,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    DemographicsformComponent,
    HippoReleaseNavigationComponent,
    HippoReleaseFormComponent,
    WaiverDisclaimerNavigationComponent,
    WaiverDisclaimerFormComponent,
    SignatureWaiverComponent,
    DocumentThoubsComponent,
    Editform2Component,
    Editform3Component,
    Editform4Component,
    ThankyouComponent,
    Editform5Component,
    ForgotpasswordComponent,
    ChangepasswordComponent,
    HippaAcceptanceComponent,
    ConfirmationformComponent,
    UsersListComponent,
    ThumbMobileComponent,
    AdminLoginComponent,
    DemographicsComponent,
    HeaderMenuComponent,
    AddAdminUserComponent,
    AdminForgotpasswordComponent,
    AdminChangepasswordComponent,
    FaqListComponent,
    DocumentListComponent,
    AddAdminDocComponent,
    ModifyAdminDocComponent,
    ActivityLogComponent,
    WelcomeComponent,
    PhysiciansListComponent,
    ImportPhysicianComponent,
    DashboardComponent,
    DoctorSignupComponent,
    RegisteredSitesComponent,
    DoctorRegisterComponent,
    ChooseDoctorsComponent,
    MustMatchDirective,
    SenddocumentComponent,
    EmailLogComponent,
    ResendDocsComponent,
    RegisteredDoctorsComponent,
    DocReviewComponent,
    PageLoaderComponent,
    DoctorViewDocsComponent,
    ViewDocsAcknowledgementComponent,
    ViewUserDocsComponent,
    UploadMultipleDocumentsComponent,
    ShowDocsComponent,
    DocHeaderComponent,
    UshfComponent,
    DateFilterPipe,
    UploadMultipleDocuments2Component,
    UserViewDocsComponent,
    UserVideoComponent,
    ExpertPhysicianOpinionComponent,
    ExpertPhysicianOpinionLogComponent,
    AuthShowD2dDocumentsComponent,
    AdminViewD2dDocsComponent,
    ExpertPhysicianOpinion2Component,
    UserViewD2dDocsComponent,
    UserReplyD2dComponent,
    LoaderComponent,
    NjComponent,
    NyComponent,
    FlComponent,
    PaComponent,
    CtComponent,
    OhComponent,
    CoComponent,
    CaComponent,
    AlComponent,
    AzComponent,
    ArComponent,
    DeComponent,
    GaComponent,
    HiComponent,
    IdComponent,
    IlComponent,
    AkComponent,
    TxComponent,
    SdComponent,
    NdComponent,
    VaComponent,
    VtComponent,
    KyComponent,
    WiComponent,
    WvComponent,
    TnComponent,
    MtComponent,
    NeComponent,
    OrComponent,
    LaComponent,
    MdComponent,
    MnComponent,
    MoComponent,
    MsComponent,
    NvComponent,
    OkComponent,
    WaComponent,
    NhComponent,
    KsComponent,
    IaComponent,
    NcComponent,
    ScComponent,
    InComponent,
    MeComponent,
    RiComponent,
    UtComponent,
    NmComponent,
    MaComponent,

    SendEmailComponent,
    PracticesComponent,
    AddAdminPracticeComponent,
    UserContactUsComponent,
    UserChangePasswordComponent,
    UserSecurityQuestions,
    ParentMinorRelationComponent,
    ParentMinorLoginComponent,
    UserHeaderMenuComponent,
    MinorActivityLogComponent,
    ReportsComponent,
    LoginQaComponent,
    PatientDemographicsComponent,
    AcceptHipaaComponent,
    SignHipaaComponent,
    ReviewHipaaComponent,
    AhsaichatgptComponent,
    ChatbotComponent,
    ChatComponent,
    ShareDocuments,
    PatientHistory
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxDatatableModule,
    DataTablesModule,
    TooltipModule,
    AngularFontAwesomeModule,
    AngularDualListBoxModule,
    NgMultiSelectDropDownModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxTrimDirectiveModule,
    // ImageViewerModule,
    AngularImageViewerModule,
  ],

  providers: [
    AppConfig,
    CommonHelper,
    SweetAlertHelper,
    LoaderService,
    DatePipe,
    IdleTimerService,
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    { provide: OWL_DATE_TIME_LOCALE, useValue: "en" },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
