import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  setArray(key: string, value: any[]): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getArray(key: string): any[] | null {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : [];
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
