import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ChatbotService } from "../chatbot/chatbot.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit, AfterViewInit {
  currentYear: any;
  isChatbotOpen: boolean;
  chatMessages: string[];
  shouldShowChatbotIcon: boolean;
 
  constructor(
    private chatbotService: ChatbotService,    
  )
   {
    this.chatbotService.getChatbotState().subscribe((isOpen) => {
      this.isChatbotOpen = isOpen;
    });
    this.chatMessages = this.chatbotService.getChatMessages();
  }

  

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.chatMessages = this.chatbotService.getChatMessages();
    this.checkCurrentUrl();
  }
  ngAfterViewInit(): void {
    window.onscroll = function() {
      if (window.scrollY >= 300) {
        // document.getElementById("myBtn").style.visibility = "visible";
      } else {
        // document.getElementById("myBtn").style.visibility = "hidden";
      }
    };
  }
  goToTopPage() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }

  openChatbot(): void {
    this.chatbotService.toggleChatbotState();
  }

  checkCurrentUrl() {
    const currentUrl = localStorage.getItem('currentURL');    
    if (currentUrl !== null){
      this.shouldShowChatbotIcon = !currentUrl.includes('ahsgpt');
    }  
  }
}
