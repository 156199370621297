import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UserService } from "./../../_services/user.service";
import Swal from "sweetalert2";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";
import { ChatbotService } from "src/app/chatbot/chatbot.service";
import * as md5 from 'md5';

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class DashboardComponent implements OnInit {
  loggedInUserId: string;
  loggedInUserName: any;
  clean = "0";
  demographicId: any;
  companiesList: any = [];
  resultJsonObj: any = {};
  profileObj: any = {};
  isDisbleLink = true;
  showModificationAlert = false;
  showContent = false;
  showLoader = false;
  isTemperoryPassword: any;
  isMinor = 0;
  isDependant = 0;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  requestsArr: any = [];
  hasHippaRequest = false;
  message = Message;
  environmentName = environment.environmentName;
  isChatbotOpen: boolean;
  secondQues: any;
  firstQues: any;
  passwordMismatch: any;
  newPassword: any;
  QueId_01: any;
  ans01: any;
  Ans_02: any;
  tempPassword: any;
  QueId_02: any;
  cnFieldTextType: boolean;
  tempfieldTextType: boolean;
  fieldTextType : boolean;
  ans_01: any;
  confirmNewPassword: any;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public userService: UserService,
    public sweetAlertHelper: SweetAlertHelper,
    private chatbotService: ChatbotService
  ) {
    localStorage.setItem("isUpdate", "0");
    this.clean = this.activatedRoute.snapshot.paramMap.get("clean");
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.updateDocGroupId(this.loggedInUserId);
    this.findDemographicDetails();
    this.findHipaaShareRequests();
    // this.findUserDetails();
    // this.loggedInUserName = localStorage.getItem('loggedInUserName')
    // ? localStorage.getItem('loggedInUserName')
    // : localStorage.getItem('loggedInUserEmail');
    this.loggedInUserName = sessionStorage.getItem('loggedInUserName');
    if (this.clean == "1") {
      this.cleanTempDocs();
    }
    this.chatbotService.getChatbotState().subscribe((isOpen) => {
      this.isChatbotOpen = isOpen;
    });
  }
  hashPassword(password: string): string {
    return md5(password);
  }
  openChatbot(): void {
    this.chatbotService.toggleChatbotState();
    this.chatbotService.getChatMessages();
  }
  

  ngOnInit() {
    sessionStorage.setItem("currentLinkGpt", "Dashboard");
    this.findUserDetails().then(() => {
      this.loggedInUserName = sessionStorage.getItem('loggedInUserName');
    });
    this.findUserDetail(this.loggedInUserId);
    this.findFirstSecurityQues();
    this.findSecondSecurityQues();  
  }

  fnFindBlogs() {
    // this.userService.findNPI()
    //   .subscribe(
    //     (res: any) => {
    //       console.log('resNPI=>', res);
    //     },
    //     error => {
    //     }
    //   );
  }

  findHipaaShareRequests() {
    const finalData = {
      patientId: this.loggedInUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() +
          ApiHelper.php_findHippaShareRequestByPatientIdApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.showLoader = false;
          this.requestsArr = res.commandResult.data.requests;
          // console.log(res.commandResult.data.requests.length)
          if (res.commandResult.data.requests.length > 0) {
            this.hasHippaRequest = true;
          }

          // console.log("this.requestsArr=>", this.hasHippaRequest);
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  // findUserDetails() {
  //   const finalData = {
  //     id: this.loggedInUserId,
  //   };
  //   this.showLoader = true;
  //   this.http
  //     .post(
  //       this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
  //       finalData,
  //       this.httpOptions
  //     )
  //     .subscribe(
  //       (apiResponse) => {
  //         this.resultJsonObj = apiResponse;
  //         console.log(this.resultJsonObj);

  //         this.isMinor = this.resultJsonObj.commandResult.data.userInfo.isMinor;
  //         localStorage.setItem(
  //           "isMinor",
  //           this.resultJsonObj.commandResult.data.userInfo.isMinor
  //         );
  //         this.isDependant =
  //           this.resultJsonObj.commandResult.data.userInfo.IsDependant;
  //         localStorage.setItem("loggedInUserName",this.resultJsonObj.commandResult.data.userInfo.FullName);
  //         sessionStorage.setItem("loggedInUserName",this.resultJsonObj.commandResult.data.userInfo.FullName);
  //         localStorage.setItem(
  //           "loggedInUserEmail",
  //           this.resultJsonObj.commandResult.data.userInfo.Email
  //         );
  //         localStorage.setItem(
  //           "loggedInUserRoleId",
  //           this.resultJsonObj.commandResult.data.userInfo.UserRoleId
  //         );
  //         localStorage.setItem(
  //           "loggedInUserMobile",
  //           this.resultJsonObj.commandResult.data.userInfo.Mobile
  //         );
  //         localStorage.setItem("loggedInUserComapnyId", "1");
  //         this.showContent = true;
  //         this.showLoader = false;
  //         this.isTemperoryPassword = this.resultJsonObj.commandResult.data.userInfo.IsTemperoryPassword

  //       },
  //       (err) => {
  //         // console.log(err);
  //       }
  //     );
  // }
  findUserDetails(): Promise<void> {
    return new Promise((resolve, reject) => {
      const finalData = { id: this.loggedInUserId };
      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse) => {
            this.resultJsonObj = apiResponse;
            console.log(this.resultJsonObj);
  
            this.isMinor = this.resultJsonObj.commandResult.data.userInfo.isMinor;
            localStorage.setItem(
              "isMinor",
              this.resultJsonObj.commandResult.data.userInfo.isMinor
            );
            this.isDependant =
              this.resultJsonObj.commandResult.data.userInfo.IsDependant;
            localStorage.setItem("loggedInUserName", this.resultJsonObj.commandResult.data.userInfo.FullName);
            sessionStorage.setItem("loggedInUserName", this.resultJsonObj.commandResult.data.userInfo.FullName);
            localStorage.setItem(
              "loggedInUserEmail",
              this.resultJsonObj.commandResult.data.userInfo.Email
            );
            localStorage.setItem(
              "loggedInUserRoleId",
              this.resultJsonObj.commandResult.data.userInfo.UserRoleId
            );
            localStorage.setItem(
              "loggedInUserMobile",
              this.resultJsonObj.commandResult.data.userInfo.Mobile
            );
            localStorage.setItem("loggedInUserComapnyId", "1");
            this.showContent = true;
            this.showLoader = false;
            this.isTemperoryPassword = this.resultJsonObj.commandResult.data.userInfo.IsTemperoryPassword;
  
            resolve(); // Resolve the promise when done
          },
          (err) => {
            console.error(err);
            reject(err); // Reject the promise if there is an error
          }
        );
    });
  }
  

  findUserDetail(userId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      id: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status === 1) {
            this.profileObj = apiResponse.commandResult.data.userInfo;
            this.showLoader = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }
  findFirstSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 1,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.firstQues = apiResponse.commandResult.data.questionsList;
          console.log(this.firstQues);
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findSecondSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 2,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.secondQues = apiResponse.commandResult.data.questionsList;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goToRegisteredSites() {
    sessionStorage.setItem("currentLinkGpt", "Your Doctor List");
    sessionStorage.setItem("pageNumberGpt", "10");
    this.router.navigate(["/registered-doctors/" + this.loggedInUserId]);
  }

  goToChooseDoctors() {
    sessionStorage.setItem("currentLinkGpt", "Add New Doctor to your list");
    sessionStorage.setItem("pageNumberGpt", "12");
    this.router.navigate(["/choose-doctors/" + this.loggedInUserId]);
  }

  goToUploadDocs() {
    sessionStorage.setItem("currentLinkGpt", "Upload / Share Your Documents");
    sessionStorage.setItem("pageNumberGpt", "7");
    this.router.navigate(["/upload-doc/" + this.loggedInUserId]);
  }

  goToShareDocs() {
    this.router.navigate(["/share-doc/" + this.loggedInUserId]);
  }

  async logout() {
    // if (
    //   confirm("PLEASE CONFIRM: Are you sure, you want to close your session?")
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.docHeaderComp.logout
    );
    if (alertStatus) {
      localStorage.clear();
      this.router.navigate(["/"]);
    }
  }

  async goToDemographicForm(isUpdate: any) {
    sessionStorage.setItem("currentLinkGpt", "Review Demographics");
    sessionStorage.setItem("pageNumberGpt", "3");
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.dashboard.gotoReviewDemographic
    // );
    // if (alertStatus)
    {
      localStorage.setItem("isUpdate", isUpdate);
      this.router.navigate(["/demographicsform/" + this.loggedInUserId]);
    }
    // if (confirm('PLEASE CONFIRM: Profile changing requires resigning of AHS’ HIPAA, Waiver and Disclaimer Forms1111.')) {
    //   this.router.navigate(['/demographicsform/' + this.loggedInUserId]);
    // }
  }

  async goToPatientDemographic(isUpdate: any) {
    console.log("goToPatientDemographic", this.demographicId);
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.dashboard.gotoReviewDemographic
    // );
    // if (alertStatus) {
      localStorage.setItem("isUpdate", isUpdate);
      await this.router.navigate([
        "/profile/demographics/" +
          this.loggedInUserId +
          "/" +
          this.demographicId,
      ]);
    // }
  }

  goToUSHFForm() {
    sessionStorage.setItem("currentLinkGpt", "Universal State HIPAA Forms");
    sessionStorage.setItem("pageNumberGpt", "5");
    this.router.navigate(["/ushf/" + this.loggedInUserId]);
  }

  findDemographicDetails() {
    console.log("called findDemographicDetails");
    const finalData = {
      id: this.loggedInUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.showLoader = false;
          this.resultJsonObj = apiResponse;
          console.log("findDemographicDetails=>", this.resultJsonObj);
          this.demographicId =
            this.resultJsonObj.commandResult.data.userInfo.DemographicId;
          if (
            this.resultJsonObj.commandResult.data.userInfo.ProfileCompleted ==
              1 &&
            this.resultJsonObj.commandResult.data.userInfo.Form1Modified == 1
          ) {
            this.showModificationAlert = true;
          } else {
            this.showModificationAlert = false;
          }
          localStorage.setItem(
            "loggedInUserName",
            this.resultJsonObj.commandResult.data.userInfo.from1FirstName
          );
          localStorage.setItem(
            "loggedInUserEmail",
            this.resultJsonObj.commandResult.data.userInfo.Email
          );
          localStorage.setItem(
            "loggedInUserRoleId",
            this.resultJsonObj.commandResult.data.userInfo.UserRoleId
          );
          localStorage.setItem("loggedInUserComapnyId", "1");
          if (
            this.resultJsonObj.commandResult.data.userInfo.ProfileCompleted == 1
          ) {
            this.demographicId =
              this.resultJsonObj.commandResult.data.userInfo.DemographicId;
            localStorage.setItem("demographicId", this.demographicId);
            localStorage.setItem("profileCompleted", "1");
            this.isDisbleLink = false;
          } else {
            localStorage.setItem("profileCompleted", "0");
            this.isDisbleLink = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async showAlertMessage() {
    // alert('Please Review & Sign to in order to Review Demographic.');
    const alertStatus = await this.sweetAlertHelper.alertPopUp(
      this.message.alertMessages.dashboardComp.showALertMessages
    );
  }

  async editDemographicForm4() {
    if (this.demographicId > "0") {
      // if (confirm('PLEASE CONFIRM: Click OK to review and update your demographic data.'))
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.dashboardCompo.editDemographicForm
      );
      if (alertStatus) {
        this.router.navigate([
          "/edit-demographic-4/" +
            this.loggedInUserId +
            "/" +
            this.demographicId,
        ]);
      }
    } else {
      // alert("Do you want to review/update your demographic information?");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.dashboardComp.editDemographicsForm4
      );
    }
  }

  goToEmailDoc() {
    sessionStorage.setItem("currentLinkGpt", "Email Doctors");
    sessionStorage.setItem("pageNumberGpt", "14");
    this.router.navigate(["/send-doc/" + this.loggedInUserId]);
  }

  gotoExpertPhysicianOpinion() {
    sessionStorage.setItem("currentLinkGpt", "Expert Physician Opinion");
    sessionStorage.setItem("pageNumberGpt", "18");
    this.router.navigate([
      "expert-physician-opinion/" + this.loggedInUserId + "/" + 6,
    ]);
  }

  gotoMinorActivityLog() {
    sessionStorage.setItem("currentLinkGpt", "Minor/Dependant Activity Log");
    sessionStorage.setItem("pageNumberGpt", "22");
    this.router.navigate(["minor-activity-log/" + this.loggedInUserId]);
  }

  gotoExpertPhysicianOpinionLog() {
    sessionStorage.setItem("currentLinkGpt", "Expert Physician Opinion Log");
    sessionStorage.setItem("pageNumberGpt", "20");
    this.router.navigate([
      "expert-physician-opinion-log/" + this.loggedInUserId,
    ]);
  }

  goToEmailLog() {
    sessionStorage.setItem("currentLinkGpt", "Email Log");
    sessionStorage.setItem("pageNumberGpt", "16");
    this.router.navigate(["/email-log/" + this.loggedInUserId]);
  }

  goToVideos() {
    this.router.navigate(["/videos/" + this.loggedInUserId]);
  }

  goToDocumentReview() {
    sessionStorage.setItem("currentLinkGpt", "Review Documents");
    sessionStorage.setItem("pageNumberGpt", "9");
    this.router.navigate([
      "/doc-review/" + this.loggedInUserId + "/" + this.demographicId,
    ]);
  }

  cleanTempDocs() {
    const finalData = {
      id: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_cleanTempDocsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  updateDocGroupId(userId) {
    const finalData = {
      userId,
      company_slug: null,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_setUserDocGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.findUserDetails();
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goToParentMinorRelation() {
    sessionStorage.setItem("currentLinkGpt", "Parent Minor/Dependant Relation");
    sessionStorage.setItem("pageNumberGpt", "21");
    this.router.navigate(["/parent-minor-relation/" + this.loggedInUserId]);
  }

  openPatientHelpDocument() {
    window.open(
      "https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/help_docs/AHS_Patient_Site_Instructions.pdf",
      "_blank"
    );
  }

  openMinorHelpDocument() {
    window.open(
      "https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/help_docs/AHS_Minor_Site_Instructions.pdf",
      "_blank"
    );
  }

  openAhsAiGpt() {
    sessionStorage.setItem("currentLinkGpt", "ahsgpt");
    this.router.navigate(["/ahsgpt/" + this.loggedInUserId]);
  }

  goToPatientHistory(){
    this.router.navigate(["/patient-history/" + this.loggedInUserId]);
  }

  checkPasswords(): void {
    // This will be triggered on every change in either the new password or confirm new password
    this.passwordMismatch = this.newPassword !== this.confirmNewPassword;
  }

  async updatePassword() {

    this.checkPasswords();

    if (this.profileObj.OldPassword !== this.hashPassword(this.tempPassword)) {
      // alert("Password must match.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        "Temporary Password Doesn't Match"
      );
      return;

    }else{
      const apiResponse = await this.checkPasswordHistory(this.loggedInUserId, this.hashPassword(this.newPassword));
      if (apiResponse.commandResult.status == 1) {
        console.log("Is Password Old: Ays", apiResponse.commandResult.status)
        await this.sweetAlertHelper.alertPopUp("Previous Password cannot be used. Please choose a different password.");  

        // Clear the new password and confirm password fields
        this.newPassword = '';
        this.confirmNewPassword = '';

      return;
      }

    if (!this.passwordMismatch){
      const finalData = {
        userId : this.loggedInUserId,
        newPassword: this.newPassword,
        secQue01: this.QueId_01,
        secQue02: this.QueId_02,
        ans_01: this.ans_01,
        ans_02: this.Ans_02

      };
      this.http.post(
          this.appConfig.getLambdaUrl05() + ApiHelper.name_updateNewpassword,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse) => {
            await this.sweetAlertHelper.alertPopUp('Password Updated Successfully! Please Login with New Password');
            if (this.sweetAlertHelper.alertPopUp) {
              localStorage.clear();
              this.router.navigate(['/login']); }
          },
          (err) => {
            console.error(err);
          }
        );
    } else {
    }
  }
}

  async checkPasswordHistory(userId, password) {
    try {
        const response = await this.http.post(
            this.appConfig.getLambdaUrl05() + ApiHelper.checkPasswordHistory,
            { userId, password },
            this.httpOptions
        ).toPromise();
        return response as any;
    } catch (error) {
        console.error('Error checking password history:', error);
        throw error;
    }
}

  toggleCnFieldTextType() {
    this.cnFieldTextType = !this.cnFieldTextType;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleTempFieldTextType() {
    this.tempfieldTextType = !this.tempfieldTextType;
  }
}
