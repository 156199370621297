import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {IdleTimerService} from './_services/idle-timer.service';
import Swal from 'sweetalert2';
import {Subscription} from 'rxjs';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private statusSubscription: Subscription;

  constructor(
    private router: Router,
    private idleTimerService: IdleTimerService,
  ) {
  }

  ngOnInit() {
    if (localStorage.getItem('loggedInUserId')) {
      this.idleTimerService.startTimer(environment.idleWarningDuration, environment.idleTimeoutDuration);
    }

    this.statusSubscription = this.idleTimerService.status.subscribe(status => {
      if (status === 'WARNING') {
        this.showPopup();
      } else if (status === 'TIMEOUT') {
        this.logout();
      }
    });
  }


  ngOnDestroy() {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
    this.idleTimerService.stopTimer();
  }

  @HostListener('window:mousemove')
  @HostListener('window:keypress')
  resetIdleTimer() {
    this.idleTimerService.resetTimer();
  }

  showPopup() {
    Swal.fire({
      title: 'Session Expiration',
      html: 'There is 1 minute left before being logged off. Click anywhere on the screen to continue.',
      timer: 60000,
      timerProgressBar: true,
      allowOutsideClick: true,
      allowEscapeKey: true,
      allowEnterKey: true,
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      icon: 'warning',
    }).then((result) => {
      if (result.isConfirmed) {
        this.idleTimerService.resetTimer();
      } else if (result.dismiss === Swal.DismissReason.timer) {
        this.logout();
      }
    });
  }

  logout() {
    this.idleTimerService.stopTimer();
    localStorage.clear();
    this.router.navigate(['/']);
  }

}
