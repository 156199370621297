import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { ArrayHelper } from "./../../_helpers/array.helper";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";
import * as md5 from 'md5';
import { UserService } from "../../_services/user.service";


@Component({
  selector: "app-user-change-password",
  templateUrl: "./user-change-password.component.html",
  styleUrls: ["./user-change-password.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class UserChangePasswordComponent implements OnInit {
  loggedInUserId: string;
  demographicsid: string;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  profileObj: any = {};
  firstQues: any = [];
  secondQues: any = [];
  showLoader = false;
  message = Message;
  fieldTextType: boolean;
  cnFieldTextType : boolean;
  oldFieldTextType : boolean;
  showHeader : boolean =false;
  showFooter : boolean =false;
  showBackButton : boolean = false;
  demographicId: string;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.demographicsid = this.activatedRoute.snapshot.paramMap.get("demographicsid")
    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get('demographicId');
    this.findUserDetails(this.loggedInUserId);
    this.findFirstSecurityQues();
    this.findSecondSecurityQues();
    this.checkRoute();
  }
  checkRoute() {
    const currentRoute = this.router.url;
        const checkrout =  '/profile/demographics/' + this.loggedInUserId + "/" + this.demographicId
        if(currentRoute !== checkrout){
          this.showFooter = true;
          this.showHeader = true;
          this.showBackButton = true;
        }
      }



  hashPassword(password: string): string {
    return md5(password);
  }
  
  findFirstSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 1,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.firstQues = apiResponse.commandResult.data.questionsList;
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  findSecondSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 2,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.secondQues = apiResponse.commandResult.data.questionsList;
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  findUserDetails(userId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      id: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status === 1) {
            this.profileObj = apiResponse.commandResult.data.userInfo;
            this.showLoader = false;
          }
        },
        (err) => {}
      );
  }

  async asynconReset() {
    // if (confirm('PLEASE CONFIRM: Are you certain you want to return to the Home Page?'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }

  async checkPasswordHistory(userId, password) {
    try {
        const response = await this.http.post(
            this.appConfig.getLambdaUrl05() + ApiHelper.checkPasswordHistory,
            { userId, password },
            this.httpOptions
        ).toPromise();
        return response as any;
    } catch (error) {
        console.error('Error checking password history:', error);
        throw error;
    }
  }

  async onSubmit() {
    if (this.profileObj.password !== this.profileObj.cnfPassword) {
      // alert("Password must match.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.userChangePassComp.onSubmit
      );
      return;
    }

    // For last 5 password comparisons
    const apiResponse = await this.checkPasswordHistory(this.loggedInUserId, this.hashPassword(this.profileObj.password));
    console.log("Is Password Old: Ays", apiResponse.commandResult.status)
      if (apiResponse.commandResult.status == 1) {
        await this.sweetAlertHelper.alertPopUp("Previous Password cannot be used. Please choose a different password.");

        // Clear the new password and confirm password fields
        this.profileObj.password = '';
        this.profileObj.cnfPassword = '';

      return;
    }

    // if (confirm("PLEASE CONFIRM: Are you sure you want to update password?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.userChangePassComp.onSubmit
    );
    if (alertStatus) {
      
      if (this.hashPassword(this.profileObj.old_password) !== this.profileObj.OldPassword) {
        // alert("Incorrect old password");
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.userChangePassComp.onSubmit4
        );
        return; 
      }else {
        const finalData = {
          userId: this.loggedInUserId,
          password: this.profileObj.password,
        };
        this.showLoader = true;
        return this.http
          .post(
            this.appConfig.getLambdaUrl05() + ApiHelper.updateUserPassword,
            finalData,
            this.httpOptions
          )
          .subscribe(
            async (apiResponse: any) => {
              // alert("Password updated successfully.");
              this.showLoader = false;
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.userChangePassComp.onSubmit3
              );
              if (alertStatus) {
                localStorage.clear();
                this.router.navigate(["/"]);
              }
            },
            (err) => {}
          );
      }
    }
  }

  async goToDashboad() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        "/dashboard/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    }
}
async goBack() {
  this.router.navigate([ "/"+"profile/demographics/" +this.loggedInUserId +"/" + this.demographicId]);
}

toggleFieldTextType() {
  this.fieldTextType = !this.fieldTextType;
}

toggleCnFieldTextType() {
  this.cnFieldTextType = !this.cnFieldTextType;
}

toggleoldFieldTextType() {
  this.oldFieldTextType = !this.oldFieldTextType;
}

}