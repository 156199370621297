import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "./../../_services/user.service";

@Component({
  selector: "app-minor-activity-log",
  templateUrl: "./minor-activity-log.component.html",
  styleUrls: ["./minor-activity-log.component.scss"],
})
export class MinorActivityLogComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  loggedInUserId: any;
  minorId: any;
  showLoader = false;

  logs: any = [];
  minors: any = [];
  message = Message;
  dtOptions: DataTables.Settings = {};
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    public userService: UserService
  ) {}

  ngOnInit() {
    this.minorId = "0";
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.findParentMinorRelation();
    window.scroll(0,0)
    this.dtOptions = {
      order: [[4, 'desc']]
    };

  }

  findParentMinorRelation() {
    const finalData = {
      parentId: this.loggedInUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getMinorsByParent,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.minors = res.commandResult.data.minors
            ? res.commandResult.data.minors
            : [];
          this.findMinorActivityLog(this.loggedInUserId, this.minorId);
          this.showLoader = false;
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
  }

  findMinorActivityLog(parentId, minorId) {
    this.logs = [];
    const finalData = {
      parentId,
      minorId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findMinorActivityLogApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.logs.length > 0) {
            this.logs = res.commandResult.data.logs;
          }
          this.showLoader = false;
        },
        (err) => {
          this.showLoader = false;
          // console.log(err);
        }
      );
  }

  async goToDashboard() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:   By clicking ‘OK’: You will be returned to the ‘Home Page’."
    //   )
    // )
  //   const alertStatus = await this.sweetAlertHelper.confirmPopUp(
  //     this.message.confirmTitle,
  //     this.message.confirmMessages.ushfComp.onReset
  //   );
  //  if(alertStatus) {
      this.router.navigate([
        "/dashboard/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    // }
  }
}
