import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";

declare let $: any;

@Component({
  selector: "app-user-view-d2d-docs",
  templateUrl: "./user-view-d2d-docs.component.html",
  styleUrls: ["./user-view-d2d-docs.component.scss"],
})
export class UserViewD2dDocsComponent implements OnInit {
  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  hidePdfPopUp = false;
  demographicUrl: SafeResourceUrl;
  hippaUrl: SafeResourceUrl;
  disclaimerUrl: SafeResourceUrl;
  ahsDisclaimerPdf: SafeResourceUrl;
  ahsHippaPdf: SafeResourceUrl;
  showDocThumbnails = true;
  ahsPdfUrl: SafeResourceUrl = "";
  documentsLength = false;
  documentsList: any;
  docGroupId: any;
  docGroupTitle: any;
  docSet: any;
  docTyep: any;
  companyDefaultGroupTitle: any;
  companyDefaultGroupId: any;
  activeGroupId: any;
  selectedDocId: any;
  signedGroups: any = [];
  signedGroupsCount: any;
  pId: any = "";
  logId: any = "";

  pageExpire = 0;
  loggedInUserCompany: string;

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  profileObj: any;
  showDeleteButton = false;
  showHippaUpdateBtn: string;
  patientObj: any;
  patientName: string;
  userOptionId = "0";
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    public sweetAlertHelper: SweetAlertHelper,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.pId = this.activatedRoute.snapshot.paramMap.get("pId");
    this.showHippaUpdateBtn = localStorage.getItem("showHippaUpdateBtn");
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.userOptionId =
      this.activatedRoute.snapshot.paramMap.get("userOptionId");
    this.logId = this.activatedRoute.snapshot.paramMap.get("logId");

    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");

    this.checkActiveUrl(this.pId, this.logId);
    this.findUserDetails(this.loggedInUserId);

    if (this.userOptionId == "1" || this.userOptionId == "3") {
      this.findPatientUserInfo(this.pId);
    }
  }

  checkActiveUrl(pId, logId) {
    const finalData = {
      pId,
      logId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findD2dDocumentsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.pageExpire = 1;
          if (apiResponse.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList = apiResponse.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }

  showDocument(documentId, documentUrl, docTyep) {
    this.selectedDocId = documentId;
    this.docTyep = docTyep;
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
    this.showDeleteButton = true;
  }

  async clearViewr() {
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to clear preview?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.confirmationComp.clearViewr
    );
    if (alertStatus) {
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
      this.showDeleteButton = false;
    }
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["admin/dashboard/" + this.loggedInUserId]);
    }
  }

  findUserDetails(userId) {
    const finalData = {
      id: userId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.profileObj = apiResponse.commandResult.data.userInfo;
            this.loggedInUserCompany =
              apiResponse.commandResult.data.userInfo.CompanySlug;
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }

  findPatientUserInfo(patientUserHashId) {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      profileId: patientUserHashId,
    };
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findUserDetailsByUserHashApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.patientObj = apiResponse;
          this.patientName = apiResponse.commandResult.data.UserInfo.FullName;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  gotoExpertPhysicianOpinionLog() {
    this.router.navigate([
      "expert-physician-opinion-log/" + this.loggedInUserId,
    ]);
  }

  deleteDoc() {}
}
