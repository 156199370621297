import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";

@Component({
  selector: "app-faq-list",
  templateUrl: "./faq-list.component.html",
  styleUrls: ["./faq-list.component.scss"]
})
export class FaqListComponent implements OnInit {
  isCollapsed = false;
  loggedInUserId: string;
  resultJsonObj: any = {};
  companiesLength: boolean = false;
  companiesList: any;


  faqsLength: boolean = false;
  noFaqsLength: boolean = false;
  loadingFaqsLength: boolean = true;


  faqsList: any;

  apiURl: string;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });
  httpOptions = {
    headers: this.httpHeaders
  };



  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get(
      "loggedInUserId"
    );


    const finalData = {
      loggedInUserId: this.loggedInUserId
    };



    this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_getAllFaqListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.loadingFaqsLength = false;

          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.adminFaqs.length > 0) {
            this.faqsLength = true;
            this.faqsList = this.resultJsonObj.commandResult.data.adminFaqs;
          } else {
            this.noFaqsLength = true;
          }
        },
        err => {
          // console.log(err);
        }
      );





    this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_getCompaniesListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.companyList.length > 0) {
            this.companiesLength = true;
            this.companiesList = this.resultJsonObj.commandResult.data.companyList;

            // console.log(this.companiesList);
          } else {
            this.companiesLength = false;
          }
        },
        err => {
          // console.log(err);
        }
      );

  }

  findFaqsByCompany(company_slug) {
    this.loadingFaqsLength = true;
    this.faqsLength = false;
    this.noFaqsLength = false;
    const finalData = {
      company_slug: company_slug,
      loggedInUserId: this.loggedInUserId
    };
    // console.log(finalData);

    if (company_slug == ConstantHelper.ALL_COMPANIES_VALUE) {
      this.apiURl = this.appConfig.getLambdaUrl02() + ApiHelper.name_getAllFaqListApi;
    } else {
      this.apiURl = this.appConfig.getLambdaUrl02() + ApiHelper.name_getAllFaqListByCompanyApi;
    }



    return this.http
      .post(
        this.apiURl,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.loadingFaqsLength = false;
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.adminFaqs.length > 0) {
            this.faqsLength = true;
            this.faqsList = this.resultJsonObj.commandResult.data.adminFaqs;
          } else {
            this.noFaqsLength = true;
          }
        },
        err => {
          // console.log(err);
        }
      );
  }
}
