import {Injectable} from '@angular/core';
import {environment} from './../environments/environment';

@Injectable()
export class AppConfig {
  constructor() {
  }

  getLambdaUrl01() {
    return environment.lambdaApiUrl_01;
  }

  getLambdaUrl02() {
    return environment.lambdaApiUrl_02;
  }


  getLambdaUrl03() {
    return environment.lambdaApiUrl_03;
  }

  getLambdaUrl04() {
    return environment.lambdaApiUrl_04;
  }

  getLambdaUrl05() {
    return environment.lambdaApiUrl_05;
  }

  getLambdaUrl06() {
    return environment.lambdaApiUrl_06;
  }


  getPhpUrl() {
    return environment.phpApiUrl;
  }

  getPhpAhsUrl() {
    return environment.phpAhsApiUrl;
  }

  getDisclaimerPdf() {
    return 'https://ahs-uat.s3.us-west-2.amazonaws.com/AHS-Waiver-And-Disclaimer-V.4.pdf';
  }

  getHippaPdf() {
    return 'https://ahs-uat.s3.us-west-2.amazonaws.com/AHS-HIPAA-Release-V.4.pdf';
  }
}
