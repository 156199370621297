const message = {
  en: {
    confirmTitle: 'PLEASE CONFIRM',
    validEmail: 'Please enter valid email.',
    validNPI: 'Please enter valid NIP.',
    alertMessages: {
      dashboard: {
        deleteUser: 'Are you sure you want to delete this user!',
      },
      demographics: {
        searchPhysicians:
          'Please enter a first name. You may also search for a physician by their NPI number',
        searchPhysicians1: 'Please select a state',
        ngAfterViewInit: 'This activity is on behalf of',
        findPryPhysiciansByCity:
          'No Physician could be found! Please modify your search and try again',
        onSubmit: 'Oops! An error has occurred; please try again',
        searchPhysician2:
          'Please enter a last name. You may also search for a physician by their NPI number',
        searchGuardianLastName: 'Please enter a Last Name and Email.',
        searchGuardianFirstName: 'Please enter a First Name and Email.',
        searchGuardianDetails: 'Please enter all details.',
        searchGuardianEmail: 'Please enter Email',
        searchGuardianfnamelname: 'Please enter first name and last name',
        SearchGuardianNoRecords: 'No Guardian could be found! Please modify your search and try again',
        searchCounter: 'You have attempted 3 times search, we are redirecting you to home page',
        searchValidGuardian: 'Please search correct Guardian details',
        selfSearchAsGuradian: 'You can not search yourself.',
        notValidNpi: 'No record found for the given details',     //Added for npi not found in registry
        invalidNPI: 'Please enter valid NPI'
      },
      signatureWaiverComp: {
        saveCanvas: 'Please Sign!',
      },

      confirmationComp: {
        goToSignatureForm:
          'To proceed, please select all or check all the boxes',
      },
      importPhysicianComp: {
        uploadListner: 'Please import a valid .csv file',
      },
      adminViewd2dDocComp: {
        reply: 'Please enter a message',
        reply1: 'Response sent successfully!',
      },
      ushfComp: {
        onSubmit: 'Please enter an alternate email address',
        onsubmit1: 'Please confirm the alternate email address',
        onSubmit2: 'Please select a HIPAA form',
        onSubmit3: 'Please select who requested the file ',
        onSubmit4:
          'Please download the HIPAA form and email it to your physician',
        onSubmit5: 'No HIPAA form can be found for this state',
        selectPhysician: 'Kindly search the physician'
      },
      uploadDocComp: {
        onFileSelected:
          'You cannot select more than 5 document files. You can share more by sending another email',
        onFileSelected1:
          'You cannot select more than 5 document files. You can share more by sending another email',
        onFileSelected2: 'A file with this name',
        onFileSelected3:
          'already exists! Please locate this file in the Select File section',
        uploadFile: 'Please select at least one (1) doctor',
        uploadFile1:
          'We\'re sorry; the selected file format is not supported. Please convert it to a .png, .jpg, .jpeg. or .pdf',
        uploadFile2:
          'File cannot be uploaded! Please select a file that is less than 60mb',
        onDocumentItemSelect:
          'You cannot select more than 5 document files. You can share more by sending another email',
          uploadDone: 'Documents are successfully uploaded, would you like to upload another document',
          shareDone: 'Documents are successfully shared, would you like to share another document',

          selectValidFile: 'We\'re sorry; the selected file format is not supported. Please select a .png, .jpg, .jpeg, .pdf, .doc, .docx or .mp4',
          pleaseSelectLessThan20MB: 'Please select the file less than 20MB',
          pleaseSelectPhysicianOrPractice: 'Please select practice or physician'
      },
      docReviewComp: {
        uploadDocument: 'File uploaded successfully!',
        uploadDocument1: 'Upload failed, please try again',
      },
      registeredDocComp: {
        removePractice: 'Doctor removed successfully!',
        reActivePractice:'Doctor Added successfully!',
      },
      chooseDocComp: {
        saveDocGroup: 'Please select at least one (1) physician',
        saveDocGroup1: 'Physician saved successfully!',
        informDoctor: 'Physician\'s patient quota has been exceeded',
        noDoctorAdded: 'You have made no changes.',
        CancelChanges: 'Are you sure you want to cancel the changes.'
      },
      sendDocComp: {
        onSubmit:
          'Before clicking \'OK,\' you must first select the receiving Physician to send an email requesting select file sharing containing a matching, attached HIPAA Form',
        onSubmit1:
          'Before clicking \'OK,\' you must first select the matching State HIPAA Form that lists the doctor’s name and at least one file to be shared',
        onSubmit2:
          'You cannot select more than 5 document files. You can share more by sending another email',
        onSubmit3:
          'We\'re sorry; the selected HIPAA file format is not supported. Please convert it to a .png, .jpg, .jpeg. or .pdf',
        onSubmit4:
          'By clicking ‘OK’: You must first contact the doctor’s office by telephone to obtain an alternate email address when none is displayed from the NPI database.  First check the phone number with the doctor’s website. Enter an ‘Alternative email address’ to send.',
        onSubmit5: 'Email sent successfully!',
        downloadHipp:
          'Please download the HIPAA form, complete it, then upload it',
        searchPhysicians:
          'Please enter last name. You may search physician informantion by NPI number.',
        findAllParticipatingCenters:
          'No Center of Excellence was found! Please modify your search and try again',
      },
      resendDocComp: {
        onSubmit:
          'Before clicking \'OK,\' you must first call the doctor’s office to obtain an alternate email address when none is displayed from the NPI database. Their office phone number can be found on their website. Please enter this alternate email address to send the message',
      },
      expertPhysicianComp: {
        findSpecialty:
          'No specialties were found! Please modify your search and try again',
        onSubmit: 'Please select or upload a HIPAA form',

        findSubSpecialtyBySpeciality:
          'No subspecialties were found! Please modify your search and try again',
        onsubmit2:
          'The HIPAA file is too large! Please select a file that is less than 60mb',
        onsubmit3:
          'Before clicking \'OK,\'\' you must first call the doctor’s office to obtain an alternate email address when none is displayed from the NPI database. Their office phone number can be found on their website. Please enter this alternate email address to send the message',
      },
      parentMinorComp: {
        createParentMinorRelation:
          'Guardian-Minor relationship created successfully!',
        isMinorAlert: 'This Guardian-Minor relationship already exists!',
        selectMinor:
          'A HIPAA form for this minor is not complete; please complete this form so you can proceed',
        searchMinors: 'Please enter text in the search box',
        removeMinor: 'Guardian-Minor relationship removed successfully!',
      },
      reportsComp: {
        onSubmit: 'Report emailed successfully!',
      },
      sendEmailComp: {
        onSubmit: 'Email sent successfully!',
        onSubmit1: 'Please select at least one (1) role',
      },
      viewDocKnowComp: {
        normalSign: 'Acknowledgement successfully emailed to the patient!',
      },
      authShowd2dDocComp: {
        checkExistingDoc: 'Please sign up first!',
      },
      doctorRegisterComp: {
        onEnquirySubmit: 'Email sent successfully to the AHS Elemrex Team!',
        getNpiDetails:
          'NPI details not found! Please fill out the information below to create an NPI record',
        getNpiDetails1:
          'Please verify all NPI data is correct and modify as needed',
        getNpiDetails2:
          'NPI numbers do not match! Please recheck and try again',
        getNpiDetails3: 'Please provide a valid NPI number',
        onSubmit: 'Email addresses do not match! Please recheck and try again',
        onSubmit1:
          'Cell phone numbers do not match! Please recheck and try again',
        onSubmit2:
          'Primary state licenses do not match! Please recheck and try again',
        onSubmit3:
          'Secondary state licenses do not match! Please recheck and try again',
        onSubmit4: 'Primary state license not confirmed',
        onSubmit5: 'Secondary state license not confirmed',
        onSubmit6: 'Email address does not work. Please try again',
        onsubmit7: 'We\'re sorry; this registration link is expired',
      },
      registerComp: {
        onSubmit: 'Please enter a guardian name, as patient is a minor',
        onSubmit1:
          'After the guardian has signed up, a record for the minor will be created under their profile',
        onSubmit2: 'Please select a physician ',
        onsubmit3: 'Email already exist.'
      },
      loginComp: {
        goToDoctorComp:
          'You are now logging in to the physician side of the AHS Elemrex Platform ',
      },
      userChangePassComp: {
        onSubmit: 'Password doesn\'t match!',
        onSubmit1: 'Incorrect security answer 01! — Please try again',
        onSubmit2: 'Incorrect security answer 02! — Please try again',
        onSubmit3: 'Password updated successfully! Please log in with your New Password',
        onSubmit4: 'Invalid old password!',
      },
      userSecurityQuestionComp: {
        onSubmit: 'Security Questions updated successfully!',
      },
      expertPhysicianOPinionComp: {
        findCitiesByStateCode:
          'No cities were found! Please modify your search and try again',
      },
      ediform4Comp: {
        onSubmit: 'Demographic information updated successfully!',
      },
      doctorsignupComp: {
        getNpiDetails: 'Please provide your NPI',
      },
      httpintercepterserviceComp: {
        intercept: 'An error has occurred!',
      },
      dashboardComp: {
        showALertMessages:
          'Please review and sign to review your demographic information',
        editDemographicsForm4:
          'Are you sure you want to review/update your demographic information?',
      },
    },

    confirmMessages: {
      dashboard: {
        gotoReviewDemographic:
          'Click \'OK\' to finalize the demographic entries you have made. Review each detail carefully before finalizing',
        // "Are you sure you want to update your profile? Doing so will require you to resign the AHS HIPAA Form, waiver, and disclaimer",
      },
      demographics: {
        onReset:
          'Are you sure you want to cancel your changes and return to the homepage?',
      },
      confirmationComp: {
        goToSignatureForm:
          'By clicking \'Accept\' you will now be directed to sign all documents.',
        goToWelcome:
          'Are you sure you want to reject signing? Doing so will return you to the homepage. ',
        clearViewr: 'Are you sure you want to clear the preview?',
        checkAllHippaDoc:
          'Are you sure you want to select all? Doing this will skip the requirement to review each document individually before signing. ',
        checkAllHippaDoc1:
          'Are you sure you want to unselect all? Doing this will require you to review each document individually before signing.',
      },
      signatureWaiverComp: {
        saveCanvas:
          'Are you sure you want to update this profile? Your current HIPAA, waiver and disclaimer forms will be updated and re-signed',
        goToWelcome:
          'Are you sure you want to accept and sign the AHS HIPAA Form, waiver, and disclaimer, and confirm your demographics? ',
        saveCanvasMobile1:
          'Are you sure you want to sign this document? You will be able to review it before signing.',
      },
      documentToubsComp: {
        editDemographicsForm5:
          'Are you sure you want to modify your demographic information?',
        signOff:
          'Are you sure you want to update your demographics? A copy of your updated information will be emailed to you.',
        editSignature: 'Are you sure you want to update your signature?',
        goToDocList: 'You Have a Assigned Document Group, Do you want to sign now ?'
      },
      ushfComp: {
        onReset: 'Are you sure you want to return to the homepage?',
      },
      njComp: {
        onSubmit:
          'Are you sure you want to submit this information to generate a State HIPAA Form?',
        goBack:
          'Are you sure you want to return to the Universal State HIPAA Form page?',
        saveCanvas: 'Are you sure you want to replace your existing signature?',
        clearCanvas: 'Are you sure you want to clear your signature?',
      },
      uploadDocumentComp: {
        uploadDone: 'Documents are successfully uploaded',
        uploadFile: 'Are you sure you want to upload this file?',
        uploadFile1:
          'Do you want to upload additional files? You can upload a total of five (5) files at a time.',
        submitUploadFile: 'Are you sure you want to upload these files?',
        submiShareFile: 'Are you sure you want to share these files?',

        listData:
          'Are you sure you want to add this physician to your list of Approved Providers? This decision will be permanent to maintain an accurate log of all document sharing, but this provider can be archived on your profile in the future.  ',
          submitUploadFile1: 'Documents uploaded successfully.'
      },
      docReview: {
        editDemographicForm5:
          'Are you sure you want to modify your demographic information?',
        editSignature: 'Are you sure you want to change your signature?',
        deleteDoc: 'Are you sure you want to delete this file?',
      },
      registeredDoctorComp: {
        goToChildSite:
          'Are you ready to review and complete the forwarded practice documents?',
        removePractice:
          'Click \'OK\' to remove this practice from your Approved Providers list.',
        activateDoctor:'Click \'OK\' to add this practice  to your Doctor List.',
      },
      chooseDocComp: {
        saveDocGroup:
          'Are you sure you want to save? Please only select \'OK\' if you have already signed a HIPAA Form for the selected physician or practice.',
      },
      sendDocComp: {
        removeSelectedFile: 'Are you sure you want to remove this file?',
        checkAllHippaDoc: ' Are you sure, you want to share all documents',
        checkAllHippaDoc1: 'Are you sure you want to deselect all documents? ',
        onReset: 'Are you sure you want to reset this form?',
        onsubmit:
          'Are you sure you want to send this email? Please ensure you have selected the matching completed State HIPAA Form and at least one health information file.',
      },
      emailLogComp: {
        resendDocs: 'Are you sure you want to resend this email?',
        sendDocs: 'Are you sure you want to review and forward this email?',
        onReset: 'Are you sure you want to reset this form?',
        back: 'Are you sure you want to go back?',
      },
      resendDocComp: {
        onSubmit: ' Are you sure you want to send this email?',
      },
      expertPhysicianComp: {
        completeHippa: 'Are you sure you want to upload a HIPAA form? ',
        onsubmit:
          'By clicking \'OK,\' you are selecting at least one file to be shared by email.',
      },
      userReplyComp: {
        back: 'Are you sure you want to return to the Expert Opinion Log?',
      },
      parentMinorComp: {
        selectMinor:
          'Are you sure you want to start managing this minor\'s/dependant\'s information?',
        removeMinor:
          'Are you sure you want to stop managing this minor\'s information? ',
        onLoginSubmit:
          'Are you sure you want to make these changes to your demographic information?',
      },

      docHeaderComp: {
        logout: 'Are you sure you want to close the session and log out?',
        onreset: 'Are you sure you want to go to the homepage?',
      },
      reportComp: {
        goToDemographics:
          'Are you sure you want to return to the User Database? ',
        onSubmit: 'Are you sure you want to send this report?',
      },
      sendEmailComp: {
        onSubmit: 'Are you sure you want to send this email?',
      },
      viewDocsAcknowComp: {
        normalSign: 'Select \'OK\' to sign the document and review it.',
      },
      authShowd2ddocComp: {
        verifyOTP:
          'There is no Patient HIPAA Form attached with this email;Are you sure you would like to review the files?',
      },
      doctorRegisterComp: {
        onEnquirySubmit:
          'Are you sure you want to contact the AHS Elemrex team?',
      },

      headerComp: {
        goToDashboard: 'Are you sure, you want to go to Home page?',
      },

      userChangePassComp: {
        onSubmit: 'Are you sure you want to update your password?',
      },
      uploadMulitipleDocComp: {
        uploadFile: 'Are you sure you want to upload the file?',
      },
      editform4Comp: {
        onSubmit: 'Are you sure you want to continue?',
        onReset: 'Are you sure you want to cancel and return to Home Page.',
      },
      dashboardCompo: {
        editDemographicForm:
          'Are you sure you want to review and update your demographic information?',
        editDemographicForm4_1:
          'Do you want to review/update your demographic information?',
      },
      demographicsForm: {
        onsubmit: 'Your demographic information has been successfully updated',
      },
      shareDocument: {
        AssocDoc:"Do you want to upload any associate files?"
      },
      Superadmin: {
        onReset:"Are you sure you want to reset password?"
      },
      
    },
  },
};
export const Message = message.en;
