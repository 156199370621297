import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {AppConfig} from './../../../app/app.config';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {ArrayHelper} from './../../_helpers/array.helper';
import {SweetAlertHelper} from '../../_helpers/sweet.alert.helper';

import {UploadService} from '../../_services/upload.service';
import {UserService} from '../../_services/user.service';

import {Message} from '../../_locale/messages';

@Component({
  selector: 'app-resend-docs',
  templateUrl: './resend-docs.component.html',
  styleUrls: ['./resend-docs.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class ResendDocsComponent implements OnInit {
  @ViewChild('fileUploaded', {static: false}) fileUploaded: ElementRef;
  loggedInUserId: string;
  logId: string;
  docInfo: any = {};
  companySlug = 'esign';
  httpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
  httpOptions = {headers: this.httpHeaders};
  documentsList: any;
  documentsLength: any;
  statesArre: any = [];
  selectedEmail: any;
  hasPhysicianEmail = 1;
  stateSelectDisabledOption = true;
  citySelectDisabledOption = true;
  physicianSelectDisabledOption = true;
  cityLength = false;
  noCityLength = false;
  citiesList: any = [];
  physicianLength = false;
  noPhysicianLength = false;
  physiciansList: any = [];
  selectedPhysician: any = {};
  resultJsonObj: any = {};
  logDetail: any = {};
  hippaDocumentsLength = false;
  hippaDocumentsList: any = [];
  selectedStateCode: any;
  selectedCityName: any;
  selectedPhysicianId: any;
  emailLogTxt: any;
  fileDesTxt: any;
  altEmailText: any;
  cnfAltEmailText: any;
  docType = ConstantHelper.DOC_TYPE_USER_EMAILED;
  hippaAvailable = false;
  hasHippaFile = false;
  selectedDocUrl: any;
  fileToUpload: File = null;
  fSize: any;
  showNewFile = 0;
  attachmentDoc: any = {};
  selectedDocumentId: any;
  selectedPhysicianNPI: any;
  regDoctorInfo: any;
  showLoader = false;
  attachmentDocment: any = '';
  doctorList: any = [];
  doctorLength = false;
  showComfirmationModal = false;
  showInfoModal = false;
  showInfoModalDelete = false;
  isHippa: any;
  isPracticePhy = '0';
  showSearchPhysicianModal = false;
  physicianSearchObj: any = {};
  doctorTableArr: any = [];
  searchStateList: any = ArrayHelper.STATE_ARRAY;
  doctorTableArrTemp: any = [];
  isSearched = false;
  practiceInfo: any = {};
  message = Message;
  // checkAltEmail = false;
  altEmailMatchesLoggedInEmail: boolean = false;
  pacticeSelected = false;
  physicianCount = 1;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    private uploadService: UploadService,
    public userService: UserService
  ) {
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.logId = this.activatedRoute.snapshot.paramMap.get('logId');
    this.regDoctorInfo = this.activatedRoute.snapshot.paramMap.get('regDoctorInfo') ? this.activatedRoute.snapshot.paramMap.get('regDoctorInfo') : 'esign';
    this.isPracticePhy = this.activatedRoute.snapshot.paramMap.get('isPracticePhy') ? this.activatedRoute.snapshot.paramMap.get('isPracticePhy') : '0';
    this.selectedPhysicianId = this.activatedRoute.snapshot.paramMap.get('physicianId') ? this.activatedRoute.snapshot.paramMap.get('physicianId') : '';
    this.isHippa = this.activatedRoute.snapshot.paramMap.get('isHippa') ? this.activatedRoute.snapshot.paramMap.get('isHippa') : '0';
    this.attachmentDocment = this.activatedRoute.snapshot.paramMap.get('documentId') ? this.activatedRoute.snapshot.paramMap.get('documentId') : '';
  }

  ngOnInit() {
    this.selectedPhysician.PhysicianEmail = '';
    this.findEmailLogDetail();
    this.fnFindHippaDocs();
  }

  fnFindStates() {
    const finalCityData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(this.appConfig.getLambdaUrl03() + ApiHelper.name_findPhysicianCitiesApi, finalCityData, this.httpOptions)
      .subscribe(
        (apiResponse: any) => {
          this.statesArre = apiResponse.commandResult.data.statesList;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findEmailLogDetail() {
    const finalLogData = {
      logId: this.logId,
    };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findLogDetail1Api,
        finalLogData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.logDetail = apiResponse;
          this.selectedPhysicianNPI = apiResponse.commandResult.data.logInfo.npi_no;
          this.documentsList = apiResponse.commandResult.data.documentsList;
          this.emailLogTxt = apiResponse.commandResult.data.logInfo.EmailTxt;
          this.fileDesTxt = apiResponse.commandResult.data.logInfo.fileDesTxt;
          this.docInfo.altEmail = this.commonHelper.cleanEmail(
            apiResponse.commandResult.data.logInfo.PersonAltEmail
          );
          this.docInfo.cnfAltEmail = this.commonHelper.cleanEmail(
            apiResponse.commandResult.data.logInfo.PersonAltEmail
          );
          if (this.isHippa == 0) {
            this.selectedStateCode = apiResponse.commandResult.data.logInfo.PhysicianStateCode;
            this.selectedPhysicianId = apiResponse.commandResult.data.logInfo.PhysicianId;
            this.selectedCityName = apiResponse.commandResult.data.logInfo.PhysicianCity;
            this.docInfo.state = apiResponse.commandResult.data.logInfo.PhysicianStateCode;
            this.docInfo.stateName = apiResponse.commandResult.data.logInfo.PhysicianStateName;
            this.docInfo.physicianName = apiResponse.commandResult.data.logInfo.PhysicianId;
            this.docInfo.city = apiResponse.commandResult.data.logInfo.PhysicianCity;
            this.selectedPhysician = apiResponse.commandResult.data.physician;
            this.selectedPhysician.PhysicianEmail = this.selectedPhysician.PhysicianEmail ? this.selectedPhysician.PhysicianEmail : '';
            this.selectedPhysician.PhysicianEmailMasked = this.selectedPhysician.PhysicianEmail ? this.commonHelper.maskEmail(this.selectedPhysician.PhysicianEmail) : '';
            localStorage.setItem('selectedHippaState', this.selectedPhysician.PhysicianState);
            localStorage.setItem('providerName', this.userService.getRefromatName(this.selectedPhysician.PhysicianName));
            localStorage.setItem('providerOfficeAddress', this.selectedPhysician.PhysicianOfficeAddress);
            localStorage.setItem('providerZipCode', this.selectedPhysician.zip_code ? this.selectedPhysician.zip_code : '');
            localStorage.setItem('providerEmail', this.selectedEmail);
            localStorage.setItem('providerPhysicianName', this.userService.getRefromatName(this.selectedPhysician.PhysicianName));
            localStorage.setItem('providerPhysicianNipNo', this.selectedPhysician.PhysicianNipNo);
            this.onChangeUserState(
              apiResponse.commandResult.data.logInfo.PhysicianStateCode
            );
          }
          if (this.isHippa == 1 && this.isPracticePhy == '1') {
            this.findPhysicianById(this.selectedPhysicianId);
          } else if (this.isHippa == 1 && this.isPracticePhy == '0') {
            this.findUserPhysicianById(this.selectedPhysicianId);
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }

  findCitiesByStateCode(stateCode) {
    this.showInfoModal = true;

    this.stateSelectDisabledOption = false;

    this.selectedStateCode = stateCode;

    this.docInfo.city = '';
    this.docInfo.physicianName = '';
    this.selectedPhysician = {};

    const finalData = {stateCode};
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
        ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          this.citySelectDisabledOption = true;
          if (apiResponse.commandResult.status == 1) {
            if (apiResponse.commandResult.data.cityList.length > 0) {
              this.cityLength = true;
              this.citiesList = apiResponse.commandResult.data.cityList;
            } else {
              this.noCityLength = true;
            }
          } else {
            this.noCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPhysicianByStateCode(cityName) {
    this.selectedCityName = cityName;
    this.docInfo.city = cityName;
    this.docInfo.physicianName = '';
    this.selectedPhysician = {};

    this.citySelectDisabledOption = false;
    const finalDataPhysician = {cityName};
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
        ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          // console.log(apiResponsePhysician);
          if (apiResponsePhysician.commandResult.status == 1) {
            this.physicianSelectDisabledOption = true;
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.physicianLength = true;
              // this.physiciansList = apiResponsePhysician.commandResult.data.physiciansList;
              this.physiciansList = this.fnChangePhysicianNameFormat(
                apiResponsePhysician.commandResult.data.physiciansList
              );
            } else {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.demographics.findPryPhysiciansByCity
              );
              // alert("No Physicians Found");
              this.noPhysicianLength = true;
            }
          } else {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.findPryPhysiciansByCity
            );
            // alert("No Physicians Found");
            this.noPhysicianLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  updatePhysicianInfo1(physicianId) {
    this.physicianSelectDisabledOption = false;
    this.selectedPhysicianId = physicianId;
    this.selectedPhysician = this.physiciansList.find(
      (e) => e.RecordId == physicianId
    );
    this.selectedPhysician.PhysicianEmail =
      this.selectedPhysician.PhysicianEmail;
    this.selectedPhysician.PhysicianEmailMasked = this.selectedPhysician
      .PhysicianEmail
      ? this.commonHelper.maskEmail(this.selectedPhysician.PhysicianEmail)
      : '';
  }

  updatePhysicianInfo(physicianId) {
    this.selectedPhysicianId = physicianId;
    this.physicianSelectDisabledOption = false;
    this.selectedPhysician = this.physiciansList.find(
      (e) => e.RecordId == physicianId
    );
    // console.log('selectedPhysician=>', this.selectedPhysician);
    const finalData = {
      physicianNPI: this.selectedPhysician.PhysicianNipNo,
    };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findPhysicianEmailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.selectedEmail = apiResponse.commandResult.data.physicianEmail;
          this.hasPhysicianEmail =
            apiResponse.commandResult.data.hasPhysicianEmail;
          if (apiResponse.commandResult.data.hasPhysicianEmail == 1) {
            this.selectedPhysician.PhysicianEmail =
              apiResponse.commandResult.data.physicianEmail;
            this.selectedPhysician.PhysicianEmailMasked = this.selectedPhysician
              .PhysicianEmail
              ? this.commonHelper.maskEmail(
                apiResponse.commandResult.data.physicianEmail
              )
              : '';
          } else {
            this.selectedPhysician.PhysicianEmail =
              apiResponse.commandResult.data.physicianEmail;
            this.selectedPhysician.PhysicianEmailMasked = this.selectedPhysician
              .PhysicianEmail
              ? this.commonHelper.maskEmail(
                apiResponse.commandResult.data.physicianEmail
              )
              : '';
            this.showComfirmationModal = true;
          }

          localStorage.setItem(
            'selectedHippaState',
            this.selectedPhysician.PhysicianState
          );
          localStorage.setItem(
            'providerName',
            this.userService.getRefromatName(
              this.selectedPhysician.PhysicianName
            )
          );
          localStorage.setItem(
            'providerOfficeAddress',
            this.selectedPhysician.PhysicianOfficeAddress
          );
          localStorage.setItem(
            'providerZipCode',
            this.selectedPhysician.zip_code
              ? this.selectedPhysician.zip_code
              : ''
          );
          localStorage.setItem('providerEmail', this.selectedEmail);
          localStorage.setItem(
            'providerPhysicianName',
            this.userService.getRefromatName(
              this.selectedPhysician.PhysicianName
            )
          );
          localStorage.setItem(
            'providerPhysicianNipNo',
            this.selectedPhysician.PhysicianNipNo
          );
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onSubmit() {
    let altEmail = '';

    if (this.docInfo.altEmail) {
      altEmail = this.docInfo.altEmail;
    }

    if (
      this.showNewFile == 1 &&
      this.fileUploaded.nativeElement.value != '' &&
      !this.validateFile(this.fileToUpload.name)
    ) {
      // alert("Selected file format is not supported");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.sendDocComp.onSubmit3
      );
      return false;
    } else if (
      this.showNewFile == 1 &&
      this.fileUploaded.nativeElement.value != '' &&
      this.fSize > 61440
    ) {
      // alert("File too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.uploadFile2
      );
      return false;
    } else if (!altEmail) {
      // alert(
      //   "PLEASE CONFIRM:   By clicking ‘OK’: You must first contact the doctor’s office by telephone to obtain an alternate email address when none is displayed from the NPI database.  First check the phone number with the doctor’s website. Enter an ‘Alternative email address’ to send."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.resendDocComp.onSubmit
      );
      return false;
    } else {
      // if (
      //   confirm("PLEASE CONFIRM: Are you sure you want to send this email?")
      // )
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.resendDocComp.onSubmit
      );
      if (alertStatus) {
        // tslint:disable-next-line: max-line-length
        this.uploadService
          .adminResendDoc(
            this.userService.getRefromatName(
              this.selectedPhysician.PhysicianName
            ),
            this.selectedPhysician.PhysicianEmail,
            altEmail,
            localStorage.getItem("isDelegating") ? localStorage.getItem("delegateId") : this.loggedInUserId,
            this.logId,
            this.companySlug,
            localStorage.getItem("isDelegating") ? localStorage.getItem("delegateId") : this.loggedInUserId,
            this.selectedStateCode,
            this.selectedCityName,
            this.selectedPhysicianId,
            this.emailLogTxt,
            this.fileToUpload,
            this.fileDesTxt,
            this.docType,
            localStorage.getItem('loggedInUserComapnyId'),
            this.showNewFile,
            this.attachmentDoc,
            this.selectedPhysicianNPI
          )
          .subscribe(
            async (data: any) => {
              // console.log(data);
              if (data !== undefined) {
                // alert("Email successfully sent. ");
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.sendDocComp.onSubmit5
                );
                if (alertStatus) {
                  this.router.navigate(['/email-log/' + this.loggedInUserId]);
                }
              }
            },
            (error) => {
              // console.log(error);
            }
          );
      }
    }
  }

  async onReset() {
    // if (confirm("PLEASE CONFIRM: Are you sure?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.emailLogComp.back
    );
    if (alertStatus) {
      // this.router.navigate(["/email-log/" + this.loggedInUserId]);
      this.router.navigate([
        '/email-log/' + this.loggedInUserId + '/' + this.regDoctorInfo,
      ]);
    }
  }

  async back() {
    // if (confirm("PLEASE CONFIRM: Are you sure?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.emailLogComp.back
    );
    if (alertStatus) {
      this.router.navigate(['/email-log/' + this.loggedInUserId]);
    }
  }

  async goToDashboard() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:   By clicking ‘OK’: You will be returned to the ‘Home Page’."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(['/dashboard/' + this.loggedInUserId]);
    }
  }

  onFileSelected(event) {
    this.showNewFile = 1;
    this.attachmentDocment = '';
    this.hippaAvailable = false;

    if (this.fileUploaded.nativeElement.value != '') {
      // console.log(this.fileUploaded.nativeElement.value);
      this.fileToUpload = event.target.files.item(0) as File;
      this.fSize = Math.round(this.fileToUpload.size / 1024);
    } else {
    }

    if (
      this.hasHippaFile &&
      this.fileUploaded.nativeElement.value == '' &&
      this.selectedDocumentId == ''
    ) {
      this.hippaAvailable = true;
    }
  }

  validateFile(name: String) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (
      ext.toLowerCase() == 'png' ||
      ext.toLowerCase() == 'jpg' ||
      ext.toLowerCase() == 'jpeg' ||
      ext.toLowerCase() == 'pdf'
    ) {
      return true;
    } else {
      return false;
    }
  }

  onChangeUserState(stateCode) {
    // console.log('State Code=> ', stateCode);
    this.userService.findHippaByStateCode(stateCode).subscribe(
      (apiResponse) => {
        // console.log(apiResponse);
        this.resultJsonObj = apiResponse;
        if (this.resultJsonObj.commandResult.status == 1) {
          this.hippaAvailable = true;
          this.hasHippaFile = true;

          this.selectedDocUrl =
            this.resultJsonObj.commandResult.data.hippaDetails.DocumentUrl;
        } else {
          this.hippaAvailable = false;
        }
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  async downloadHipp1() {
    if (this.selectedDocUrl) {
      // if (confirm('PLEASE CONFIRM: Are you sure?')) {
      window.open(this.selectedDocUrl);
      // }
    } else {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.ushfComp.onSubmit5
      );
      // alert("No HIPAA form found for the state.");
    }
  }

  async downloadHipp() {
    if (this.selectedStateCode == 'NJ') {
      this.router.navigate([
        '/hippanj1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'NY') {
      this.router.navigate([
        '/hippany1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'FL') {
      this.router.navigate([
        '/hippafl1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'PA') {
      this.router.navigate([
        '/hippapa1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'CT') {
      this.router.navigate([
        '/hippact1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'OH') {
      this.router.navigate([
        '/hippaoh1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'CO') {
      this.router.navigate([
        '/hippaco1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'CA') {
      this.router.navigate([
        '/hippaca1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'AL') {
      this.router.navigate([
        '/hippaal1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'AZ') {
      this.router.navigate([
        '/hippaaz1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'AR') {
      this.router.navigate([
        '/hippaar1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'DE') {
      this.router.navigate([
        '/hippade1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'GA') {
      this.router.navigate([
        '/hippaga1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'HI') {
      this.router.navigate([
        '/hippahi1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'ID') {
      this.router.navigate([
        '/hippaid1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'IL') {
      this.router.navigate([
        '/hippail1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'AK') {
      this.router.navigate([
        '/hippaak1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'TX') {
      this.router.navigate([
        '/hippatx1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'SD') {
      this.router.navigate([
        '/hippasd1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'ND') {
      this.router.navigate([
        '/hippand1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'VA') {
      this.router.navigate([
        '/hippava1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'VT') {
      this.router.navigate([
        '/hippavt1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'KY') {
      this.router.navigate([
        '/hippaky1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'WI') {
      this.router.navigate([
        '/hippawi1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'WV') {
      this.router.navigate([
        '/hippawv1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == 'TN') {
      this.router.navigate([
        '/hippatn1/' +
        this.loggedInUserId +
        '/4' +
        '/' +
        this.logId +
        '/' +
        this.isPracticePhy +
        '/' +
        this.selectedPhysicianId,
      ]);
    } else {
      if (this.selectedDocUrl) {
        // alert(
        //   "Please download HIPAA form manually and upload, once you filled it."
        // );
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.sendDocComp.downloadHipp
        );
        window.open(this.selectedDocUrl);
      } else {
        // alert("No HIPAA form found for the state.");
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.ushfComp.onSubmit5
        );
      }
    }
  }

  setAttachment(DocumentId) {
    this.hippaAvailable = false;
    this.fileUploaded.nativeElement.value = '';
    this.selectedDocumentId = DocumentId;
    this.showNewFile = 2;
    const docObj = this.hippaDocumentsList.find(
      (e) => e.RecordId == this.selectedDocumentId
    );
    this.attachmentDoc = JSON.stringify(docObj);
    // console.log(this.fileUploaded.nativeElement.value);
    // console.log(this.attachmentDoc);
    if (
      this.hasHippaFile &&
      this.fileUploaded.nativeElement.value == '' &&
      this.selectedDocumentId == ''
    ) {
      this.hippaAvailable = true;
    }
  }

  findUserDoctors() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findUserDoctorsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.data.companiesList.length > 0) {
            this.doctorLength = true;
            this.doctorList = apiResponse.commandResult.data.companiesList;
          } else {
            this.doctorLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnChangePhysicianNameFormat(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.PhysicianName = this.userService.getUS_FromatName(e.PhysicianName);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(
      this.userService.dynamicSort('PhysicianName')
    );
  }

  fnFindHippaDocs() {
    // console.log('fnFindHippaDocs called')
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: null,
      doc_group_id: 'HD',
      default_group_id: '17',
      email: 'test@yopmail.com',
    };

    // console.log(finalData);

    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findDocsBySubGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;

          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.hippaDocumentsLength = true;
            this.hippaDocumentsList =
              this.resultJsonObj.commandResult.data.documentList;
            if (this.isHippa == 1) {
              this.setAttachment(this.attachmentDocment);
            }
          } else {
            this.hippaDocumentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  closeComfirmationModal() {
    this.showComfirmationModal = false;
  }

  closeInfoModal() {
    this.showInfoModal = false;
  }

  openSearchPhysicianModal() {
    this.showSearchPhysicianModal = true;
    this.physicianSearchObj.physicianSearchLastName = '';

    this.physicianSearchObj.physicianSearchFirstName = '';

    this.physicianSearchObj.physicianSearchState = '';
    this.physicianSearchObj.physicianSearchNPI = '';
    this.doctorTableArr = [];
  }

  resetSearchPhysicianModal() {
    this.physicianSearchObj.physicianSearchLastName = '';
    this.physicianSearchObj.physicianSearchFirstName = '';

    this.physicianSearchObj.physicianSearchState = '';
    this.physicianSearchObj.physicianSearchNPI = '';
    this.doctorTableArr = [];
    this.physicianCount = 1;
  }

  async searchPhysicians() {
    if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchFirstName
    ) {
      // alert(
      //   "Please enter first name. You may search physician informantion by NPI number."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.searchPhysicians
      );
      return;
    } else if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchLastName
    ) {
      // alert(
      //   "Please enter last name. You may search physician informantion by NPI number."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.sendDocComp.searchPhysicians
      );
      return;
    } else if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchState
    ) {
      // alert("Please select state.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.searchPhysicians1
      );
      return;
    } else {
      this.doctorTableArr = [];

      const finalData = {
        loggedInUserId: this.loggedInUserId,
        physicianSearchFirstName:
        this.physicianSearchObj.physicianSearchFirstName,
        physicianSearchLastName:
        this.physicianSearchObj.physicianSearchLastName,
        physicianSearchState: this.physicianSearchObj.physicianSearchState,
        physicianSearchNPI: this.physicianSearchObj.physicianSearchNPI,
      };
      this.showLoader = true;

      this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_searchNPIRegistryApi,
          finalData,
          this.httpOptions
        )
        // tslint:disable-next-line: deprecation
        .subscribe(
          (apiResponse: any) => {
            // this.doctorTableArrTemp =
            //   apiResponse.commandResult.data.physiciansList;
            // this.doctorTableArr = this.fnChangePhysicianNameFormat(
            //   this.doctorTableArrTemp
            // );
            // this.cityLength = true;
            // this.citiesList = apiResponse.commandResult.data.cityList;
            // this.hippaAvailable = true;
            // this.hasHippaFile = true;
            // this.selectedDocUrl = apiResponse.commandResult.data.hippaDetails.DocumentUrl;

            this.doctorTableArr = apiResponse.commandResult.data.physiciansList;
            this.hippaAvailable = true;
            this.hasHippaFile = true;
            this.selectedDocUrl =
              apiResponse.commandResult.data.hippaDetails.DocumentUrl;
            this.physicianCount = apiResponse.commandResult.data.physiciansCout;
            this.showLoader = false;
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  setStateCode(stateCode) {
    this.selectedStateCode = stateCode;
  }

  selectDoctor(physicianId) {
    this.selectedPhysicianId = physicianId;
    this.physicianSelectDisabledOption = false;
    this.isSearched = true;
    this.selectedPhysician = this.doctorTableArr.find(
      (e) => e.RecordId == physicianId
    );
    // console.log(this.selectedPhysician);
    this.showSearchPhysicianModal = false;
    this.docInfo.state = this.selectedPhysician.PhysicianState;
    this.docInfo.stateName = this.selectedPhysician.PhysicianStateName;
    this.docInfo.city = this.selectedPhysician.PhysicianCity;
    this.docInfo.physicianName = this.selectedPhysician.RecordId;
    this.docInfo.physician_Name = this.selectedPhysician.PhysicianName;
    this.selectedCityName = this.selectedPhysician.PhysicianCity;
    this.docInfo.physicianName = this.selectedPhysician.RecordId;
    this.docInfo.physician_Name = this.selectedPhysician.PhysicianName;
    this.selectedStateCode = this.selectedPhysician.PhysicianStateCode;
    this.selectedPhysicianNPI = this.selectedPhysician.PhysicianNipNo;
    const finalData = {physicianNPI: this.selectedPhysician.PhysicianNipNo};

    localStorage.setItem(
      'selectedHippaState',
      this.selectedPhysician.PhysicianStateCode
    );
    localStorage.setItem(
      'providerName',
      this.userService.getRefromatName(this.selectedPhysician.PhysicianName)
    );
    localStorage.setItem(
      'providerOfficeAddress',
      this.selectedPhysician.PhysicianOfficeAddress
    );
    localStorage.setItem('providerZipCode', this.selectedPhysician.zip_code);
    localStorage.setItem(
      'providerEmail',
      this.selectedPhysician.PhysicianEmail
    );
    localStorage.setItem(
      'providerPhysicianName',
      this.userService.getRefromatName(this.selectedPhysician.PhysicianName)
    );
    localStorage.setItem(
      'providerPhysicianNipNo',
      this.selectedPhysician.PhysicianNipNo
    );

    // this.http
    //   .post(
    //     this.appConfig.getPhpUrl() + ApiHelper.php_findPhysicianEmailApi,
    //     finalData,
    //     this.httpOptions
    //   )
    //   .subscribe(
    //     (apiResponse: any) => {
    //       this.selectedEmail = apiResponse.commandResult.data.physicianEmail;
    //       this.hasPhysicianEmail = apiResponse.commandResult.data.hasPhysicianEmail;
    //       if (apiResponse.commandResult.data.hasPhysicianEmail == 1) {
    //         this.selectedPhysician.PhysicianEmail = apiResponse.commandResult.data.physicianEmail;
    //         this.selectedPhysician.PhysicianEmailMasked = this.selectedPhysician.PhysicianEmail ? this.commonHelper.maskEmail(apiResponse.commandResult.data.physicianEmail) : '';

    //       } else {
    //         this.selectedPhysician.PhysicianEmail = apiResponse.commandResult.data.physicianEmail;
    //         this.selectedPhysician.PhysicianEmailMasked = this.selectedPhysician.PhysicianEmail ? this.commonHelper.maskEmail(apiResponse.commandResult.data.physicianEmail) : '';
    //         this.showComfirmationModal = true;
    //       }

    //       // console.log(this.selectedPhysician);
    //       localStorage.setItem('selectedHippaState', this.selectedPhysician.PhysicianStateCode);
    //       localStorage.setItem('providerName', this.userService.getRefromatName(this.selectedPhysician.PhysicianName));
    //       localStorage.setItem('providerOfficeAddress', this.selectedPhysician.PhysicianOfficeAddress);
    //       localStorage.setItem('providerZipCode', this.selectedPhysician.zip_code);
    //       localStorage.setItem('providerEmail', this.selectedPhysician.PhysicianEmail);
    //       localStorage.setItem('providerPhysicianName', this.userService.getRefromatName(this.selectedPhysician.PhysicianName));
    //       localStorage.setItem('providerPhysicianNipNo', this.selectedPhysician.PhysicianNipNo);

    //     },
    //     (err) => {
    //       // console.log(err);
    //     }
    //   );
  }

  closeSearchPhysicianModal() {
    this.showSearchPhysicianModal = false;
    this.physicianCount = 1;
  }

  findPhysicianById(physicianId) {
    // console.log(physicianId);

    const finalDataPhysician = {physicianId};
    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findPhysicianByIdApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        (apiResponsePhysician: any) => {
          // console.log('apiResponsePhysician=>', apiResponsePhysician);
          this.isPracticePhy = '1';
          this.pacticeSelected = true;
          this.practiceInfo = apiResponsePhysician.commandResult.data.userInfo;
          this.docInfo.state =
            apiResponsePhysician.commandResult.data.userInfo.PhysicianStateName;
          this.selectedStateCode =
            apiResponsePhysician.commandResult.data.userInfo.PhysicianState;
          this.selectedCityName =
            apiResponsePhysician.commandResult.data.userInfo.PhysicianCity;
          this.docInfo.city =
            apiResponsePhysician.commandResult.data.userInfo.PhysicianCity;
          this.docInfo.stateName =
            apiResponsePhysician.commandResult.data.logInfo.PhysicianStateName;

          this.selectedPhysicianId = physicianId;
          this.selectedPhysician =
            apiResponsePhysician.commandResult.data.userInfo;
          this.selectedEmail = this.selectedPhysician.PhysicianEmail;
          this.selectedPhysician.PhysicianEmail =
            this.selectedPhysician.PhysicianEmail;
          this.selectedPhysician.PhysicianEmailMasked = this.selectedPhysician
            .PhysicianEmail
            ? this.commonHelper.maskEmail(this.selectedPhysician.PhysicianEmail)
            : '';

          localStorage.setItem(
            'selectedHippaState',
            this.selectedPhysician.PhysicianState
          );
          localStorage.setItem(
            'providerName',
            this.userService.getRefromatName(
              this.selectedPhysician.PhysicianName
            )
          );
          localStorage.setItem(
            'providerOfficeAddress',
            this.selectedPhysician.PhysicianOfficeAddress
          );
          localStorage.setItem(
            'providerZipCode',
            this.selectedPhysician.zip_code
              ? this.selectedPhysician.zip_code
              : ''
          );
          localStorage.setItem('providerEmail', this.selectedEmail);
          localStorage.setItem(
            'providerPhysicianName',
            this.userService.getRefromatName(
              this.selectedPhysician.PhysicianName
            )
          );
          localStorage.setItem(
            'providerPhysicianNipNo',
            this.selectedPhysician.PhysicianNipNo
          );
          this.onChangeUserState(this.selectedStateCode);
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserPhysicianById(physicianId) {
    this.selectedPhysicianId = physicianId;
    this.isSearched = true;
    const finalData = {physicianId};
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findUserPhysicianByIdApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          this.selectedPhysician = apiResponse.commandResult.data.physician;

          this.docInfo.state = this.selectedPhysician.PhysicianState;
          this.docInfo.city = this.selectedPhysician.PhysicianCity;
          this.docInfo.physicianName = this.selectedPhysician.RecordId;
          this.docInfo.physician_Name = this.selectedPhysician.PhysicianName;
          this.selectedStateCode = this.selectedPhysician.PhysicianStateCode;
          this.selectedCityName = this.selectedPhysician.PhysicianCity;
          this.docInfo.stateName = this.selectedPhysician.PhysicianStateName;

          const finalData = {
            physicianNPI: this.selectedPhysician.PhysicianNipNo,
          };
          this.http
            .post(
              this.appConfig.getPhpUrl() + ApiHelper.php_findPhysicianEmailApi,
              finalData,
              this.httpOptions
            )
            .subscribe(
              (apiResponse: any) => {
                this.selectedEmail =
                  apiResponse.commandResult.data.physicianEmail;
                this.hasPhysicianEmail =
                  apiResponse.commandResult.data.hasPhysicianEmail;
                if (apiResponse.commandResult.data.hasPhysicianEmail == 1) {
                  this.selectedPhysician.PhysicianEmail =
                    apiResponse.commandResult.data.physicianEmail;
                  this.selectedPhysician.PhysicianEmailMasked = this
                    .selectedPhysician.PhysicianEmail
                    ? this.commonHelper.maskEmail(
                      apiResponse.commandResult.data.physicianEmail
                    )
                    : '';
                } else {
                  this.selectedPhysician.PhysicianEmail =
                    apiResponse.commandResult.data.physicianEmail;
                  this.selectedPhysician.PhysicianEmailMasked = this
                    .selectedPhysician.PhysicianEmail
                    ? this.commonHelper.maskEmail(
                      apiResponse.commandResult.data.physicianEmail
                    )
                    : '';
                  this.showComfirmationModal = true;
                }
                // console.log(this.selectedPhysician.PhysicianName);

                localStorage.setItem(
                  'selectedHippaState',
                  this.selectedPhysician.PhysicianState
                );
                localStorage.setItem(
                  'providerName',
                  this.userService.getRefromatName(
                    this.selectedPhysician.PhysicianName
                  )
                );
                localStorage.setItem(
                  'providerOfficeAddress',
                  this.selectedPhysician.PhysicianOfficeAddress
                );
                localStorage.setItem(
                  'providerZipCode',
                  this.selectedPhysician.zip_code
                    ? this.selectedPhysician.zip_code
                    : ''
                );
                localStorage.setItem('providerEmail', this.selectedEmail);
                localStorage.setItem(
                  'providerPhysicianName',
                  this.userService.getRefromatName(
                    this.selectedPhysician.PhysicianName
                  )
                );
                localStorage.setItem(
                  'providerPhysicianNipNo',
                  this.selectedPhysician.PhysicianNipNo
                );
                this.onChangeUserState(this.selectedStateCode);
              },
              (err) => {
                // console.log(err);
              }
            );
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goToLink(url: string) {
    const extUrl = 'http://' + url.toLowerCase();
    window.open(extUrl, '_blank');
  }

  checkEmail() {
    if (this.docInfo.altEmail.toLowerCase() === localStorage.getItem('loggedInUserEmail').toLowerCase()) {
      this.altEmailMatchesLoggedInEmail = true;
    } else {
      this.altEmailMatchesLoggedInEmail = false;
    }
  }
  // checkEmail() {
  //   this.altEmailMatchesLoggedInEmail = (this.docInfo.cnfAltEmail === localStorage.getItem('loggedInUserEmail'));
  // }


  resetFormControl() {
    this.hippaAvailable = true;
    this.attachmentDocment = '';
    this.fileUploaded.nativeElement.value = '';
  }

  clearSearchPhysicianInputs(flag) {
    if (flag) {
      this.physicianSearchObj.physicianSearchFirstName = '';
      this.physicianSearchObj.physicianSearchLastName = '';
      this.physicianSearchObj.physicianSearchState = '';
    } else {
      this.physicianSearchObj.physicianSearchNPI = '';
    }
  }
}
