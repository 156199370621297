export function navigateToRoute(
  isEmail: number,
  loggedInUserId: string,
  isPracticePhy: number,
  physicianId: number,
  userOptionId: number,
  tempId: number,
  emailLogId: number
): string {
  let route: string;

  if (isEmail == 1) {
    route =
      "/send-doc/" +
      loggedInUserId +
      "/1/" +
      isPracticePhy.toString() +
      "/" +
      physicianId.toString() +
      "/0";
    console.log("route1 ", route);
  } else if (isEmail == 3) {
    route =
      "/expert-physician-opinion/" +
      loggedInUserId +
      "/" +
      userOptionId.toString() +
      "/1/" +
      tempId.toString() +
      "/1";
    console.log("route2 ", route);
  } else if (isEmail == 4) {
    route =
      "/resend-docs/" +
      loggedInUserId +
      "/" +
      emailLogId.toString() +
      "/1/0/" +
      isPracticePhy.toString() +
      "/" +
      physicianId.toString();
    console.log("route3 ", route);
  } else {
    // Handle other cases or provide a default route if needed
    route = "/default-route";
  }

  return route;
}
