import { Component, OnInit } from "@angular/core";
// import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
// import { AddAdminUser } from "./add-admin-user";

@Component({
  selector: "app-add-admin-user",
  templateUrl: "./add-admin-user.component.html",
  styleUrls: ["./add-admin-user.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class AddAdminUserComponent implements OnInit {
  loggedInUserId: string;
  companySlug: string;
  resultJsonObj: any = {};
  addAdminObj: any = {};

  existingEmail = false;
  existingMobile = false;
  userAdded = false;
  isPrimary: string = "0";

  apiURl: string;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    this.companySlug = this.activatedRoute.snapshot.paramMap.get("companySlug");
  }

  onSubmit() {
    const finalData = {
      company_slug: this.companySlug,
      fullName: this.addAdminObj.fullName,
      mobile: this.addAdminObj.mobile,
      email: this.addAdminObj.email,
      isPrimary: this.isPrimary,
      loggedInUserId: this.loggedInUserId,
      loggedInUserName: localStorage.getItem("loggedInUserName"),
    };

    return this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_saveAdminUserApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.status == -1) {
            this.existingMobile = true;
            this.existingEmail = false;
          } else if (this.resultJsonObj.commandResult.status == -2) {
            this.existingEmail = true;
            this.existingMobile = false;
          } else {
            this.existingMobile = false;
            this.existingEmail = false;
            this.userAdded = true;

            setTimeout(() => {
              this.router.navigate(["/admins/" + this.loggedInUserId]);
            }, 2000);
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }

  goToAdmins() {
    this.router.navigate(["/admins/" + this.loggedInUserId]);
  }
}
