import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
// import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

declare let $: any;

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";

@Component({
  selector: "app-confirmationform",
  templateUrl: "./confirmationform.component.html",
  styleUrls: ["./confirmationform.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class ConfirmationformComponent implements OnInit {
  isChecked = false;
  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  documentsLength = false;
  documentsList: any[] = [];
  ahsPdfUrl: SafeResourceUrl = "";
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  showDeleteButton = false;
  showLoader = false;
  message = Message;
  selectAllText: string = "Select All";

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.demographicId = localStorage.getItem("MinorDemographicId")
      ? localStorage.getItem("MinorDemographicId")
      : this.activatedRoute.snapshot.paramMap.get("demographicId");
    // this.demographicId = this.activatedRoute.snapshot.paramMap.get('demographicId');
    // alert(this.loggedInUserId);
    // alert(this.demographicId);

    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_getSignatureDocsByCompanyGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.showLoader = false;

          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );

    $(document).ready(function () {
      $(window).resize(function () {
        setHeightResizeFunc();
      });
      setHeightResizeFunc();
    });

    function setHeightResizeFunc() {
      const rightHeight =
        $(".right-box .ibox-title").height() +
        $(".right-box .ibox-content").height() -
        45;
      $(".docLists").height(rightHeight);
    }
  }

  acceptConfirmationFunc() {
    // if (this.selectedBtn == 'disclaimer') {
    //
    // } else if (this.selectedBtn == 'hippa') {
    //
    // } else {
    //   alert('Please read document first.')
    //
    // }
  }

  goToDemographicForm() {
    this.router.navigate([
      "/demographicsform/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
    ]);
  }

  async goToSignatureForm() {
    if ($(".com").not(":checked").length > 0) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.confirmationComp.goToSignatureForm
      );
      // alert('Please check all checkboxes or click on select all to proceed to next signature page.');
    } else {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.confirmationComp.goToSignatureForm
      );
      // if (
      //   //   const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      //   //     this.message.confirmTitle,
      //   //     this.message.confirmMessages.confirmationComp
      //   //   );
      //   confirm(
      //     "PLEASE CONFIRM:   By clicking ‘Accept’ and ‘OK’: You will now be directed to electronically sign all documents."
      //   )
      // )
      if (alertStatus) {
        this.router.navigate([
          "/signaturewaiver/" +
            this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
            "/" +
            this.activatedRoute.snapshot.paramMap.get("demographicId"),
        ]);
      }
    }
  }

  loadHippaDoc(event, documentUrl: any, i) {
    // console.log(event.target.checked);
    if (event.target.checked) {
      document.getElementById("hipaaAccepted" + i).removeAttribute("disabled");
      document
        .getElementById("hipaaAccepted" + i)
        .setAttribute("checked", "checked");

      this.ahsPdfUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
      this.showDeleteButton = true;
    } else {
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
      this.isChecked = false;
    }
  }

  

  // async checkAllHippaDoc(isChecked) {
  //   if (this.isChecked) {
  //     const alertStatus = await this.sweetAlertHelper.confirmPopUp(
  //       this.message.confirmTitle,
  //       this.message.confirmMessages.confirmationComp.checkAllHippaDoc
  //     );

  //     if (alertStatus) {
  //       this.documentsList.forEach((doc) => {
  //         doc.isChecked = true;
  //       });
  //       this.selectAllText = "Unselect All";
  //     } else {
  //       this.isChecked = false;
  //       this.selectAllText = "Select All";
  //     }
  //   } else {
  //     const alertStatus = await this.sweetAlertHelper.confirmPopUp(
  //       this.message.confirmTitle,
  //       this.message.confirmMessages.confirmationComp.checkAllHippaDoc1
  //     );

  //     if (alertStatus) {
  //       this.documentsList.forEach((doc) => {
  //         doc.isChecked = false;
  //       });
  //       this.selectAllText = "Select All";
  //     }
  //   }
  // }
    async checkAllHippaDoc(event: any) {  
      const alertStatus = await this.showConfirmationPopup();
      if (alertStatus) {
        this.documentsList.forEach((doc) => {
          doc.isChecked = event.target.checked;
        });
      } 
      else {
        // User canceled the action, so prevent changing the checkboxes
        this.isChecked = !event.target.checked; // Revert the 'Select All' checkbox state
      }
    }

    async showConfirmationPopup(): Promise<boolean> {
      const confirmationResult = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.confirmationComp.checkAllHippaDoc
      );

      return confirmationResult; // Returns true if user confirms, false if canceled
    }

    isAllChecked(): boolean {
      return this.documentsList.every((doc) => doc.isChecked);
    }

    updateSelectAllStatus() {
      this.isChecked = this.isAllChecked();
    }

    toggleSelectAll() {
      this.isChecked = !this.isChecked;
      this.documentsList.forEach((doc) => {
        doc.isChecked = this.isChecked;
      });
    }
    

  // if (confirm("PLEASE CONFIRM: By clicking ‘Select All’ you are waiving your need to review each document individually.")) {
  //   if (isChecked == 1) {
  //     $('.docChecks').each(function () {
  //       $(this).prop("checked", true);
  //     });

  //   } else {
  //     $('.docChecks').each(function () {
  //       $(this).prop("checked", false);
  //     });
  //   }

  // } else {
  //   if (isChecked == 1) {
  //     $('.selectAll').prop("checked", false);

  //   } else {
  //     $('.selectAll').prop("checked", true);
  //   }

  // }

  async goToWelcome() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.confirmationComp.goToWelcome
    );
    // confirm(
    //   "PLEASE CONFIRM:  By clicking ‘Reject’ then ‘OK’:You are not planning to sign the required documents and will be returned to the ‘Home Page’."
    // )
    if (alertStatus) {
      this.router.navigate([
        "/dashboard/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/1",
      ]);
    }
  }

  async clearViewr() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.confirmationComp.clearViewr
    );
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to clear preview?"))
    if (alertStatus) {
      $(".selectAll").prop("checked", false);
      $(".docChecks").each(function () {
        $(this).prop("checked", false);
      });
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
      this.showDeleteButton = false;
    }
  }
}
