import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import {
  HttpClient,

  HttpHeaders,
} from '@angular/common/http';
import { ApiHelper } from './../../_helpers/api.helper';


import { CommonHelper } from './../../_helpers/common.helper';
import { ConstantHelper } from './../../_helpers/constant.helper';


import { AppConfig } from './../../../app/app.config';
@Component({
  selector: 'app-user-header-menu',
  templateUrl: './user-header-menu.component.html',
  styleUrls: ['./user-header-menu.component.scss']
})
export class UserHeaderMenuComponent implements OnInit {

  loggedInUserId: string;
  loggedInUserRoleId: string;
  delegateId: any;
  resultJsonObj: any = {};
  selectedLlink = 'demographics';
  childRoute: string;
  coeStatus: any;
  delegationArr: any = [];
  usersList: any = [];
  tempLog: any = [];
  showLoader = false
  isDelegating: any = 0;
  hipaaPendingCount: any;
  totalPendingCount1: 1;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  selectedDelegate: any = {};
  showDelegationOption: any = 0;
  conmapySlug = ConstantHelper.COMPANY_SLUG;
  minors: any = [];
  FullName = '';
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');

    this.loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');

    this.delegateId = localStorage.getItem('delegateId') ? localStorage.getItem('delegateId') : this.loggedInUserId;
    this.isDelegating = localStorage.getItem('isDelegating') ? localStorage.getItem('isDelegating') : 0;
    this.FullName = localStorage.getItem('FullName') ? localStorage.getItem('FullName') : '';
    this.findParentMinorRelation();
  }

  ngOnInit() {

  }

  findParentMinorRelation() {
    const finalData = {
      parentId: this.loggedInUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getMinorsByParent,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.minors = res.commandResult.data.minors ? res.commandResult.data.minors : [];
          const isMinorIdAvailable = this.minors.some(obj => obj.MinorId == localStorage.getItem('delegateId'));
          if (!isMinorIdAvailable) {
            localStorage.removeItem('delegateId');
        };
          this.showLoader = false;
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
  }





  setdelegateId(userId) {
    if (userId != this.loggedInUserId) {
      this.selectedDelegate = this.minors.find((e) => e.MinorId == userId);
      localStorage.setItem('parentId', this.loggedInUserId);
      localStorage.setItem('delegateId', this.selectedDelegate.MinorId);
      localStorage.setItem('MinorDemographicId', this.selectedDelegate.MinorDemographicId);
      localStorage.setItem('isDelegating', '1');
      localStorage.setItem('FullName', this.selectedDelegate.FullName);
      this.isDelegating = 1;
      window.location.reload();
    } else {
      localStorage.removeItem('parentId');
      localStorage.removeItem('delegateId');
      localStorage.setItem('isDelegating', '0');
      localStorage.removeItem('FullName');
      localStorage.removeItem('MinorDemographicId');
      window.location.reload();
    }

    localStorage.removeItem('onBehalfOfAlertUshf');
    localStorage.removeItem('onBehalfOfAlertUpload');
    localStorage.removeItem('onBehalfOfAlertSendDocument');
    localStorage.removeItem('onBehalfOfAlertRegisteredDoctors');
    localStorage.removeItem('onBehalfOfAlertExpertOpinion');
    localStorage.removeItem('onBehalfOfAlertExpertOpinionLog');
    localStorage.removeItem('onBehalfOfAlertSendDocumentLog');
    localStorage.removeItem('onBehalfOfAlertDocumentReview');
    localStorage.removeItem('onBehalfOfAlertDemographic');
    localStorage.removeItem('onBehalfOfAlertChooseDoctors');
  }

  openPatientHelpDocument() {
    window.open(
      'https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/help_docs/AHS_Patient_Site_Instructions.pdf', '_blank');
  }
}
