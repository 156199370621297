import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { UserService } from "../../_services/user.service";
import { LocalStorageService } from "../../_services/local-storage.service";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";

import { ArrayHelper } from "./../../_helpers/array.helper";

@Component({
  selector: "app-senddocument",
  templateUrl: "./senddocument.component.html",
  styleUrls: ["./senddocument.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class SenddocumentComponent implements OnInit {
  @ViewChild("fileUploaded", { static: false }) fileUploaded: ElementRef;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  maxChars = 300;
  loggedInUserId: string;
  resultJsonObj: any = {};
  physicianSearchObj: any = {};
  loggedInUserEmail: string;
  showSearchPhysicianModal = false;
  docInfo: any = {};
  companySlug = "esign";
  isChecked = false;
  fileToUpload: File = null;
  fSize: any;
  documentsLength = false;
  documentsList: any = [];
  hippaDocumentsLength = false;
  hippaDocumentsList: any = [];
  emailLogTxt: string = "";
  attachmentDocment: any = "";
  fileDesTxt: string = "";
  showDocumentModal = false;
  choosedFileName: any = "";
  doctorList: any = [];
  doctorLength = false;
  showLoader = false;
  selectedDocsId: any = [];
  selectedHippaUrls: any = [];
  statesArre: any = [];
  hippaAvailable = false;
  selectedDocUrl: any;
  stateSelectDisabledOption = true;
  citySelectDisabledOption = true;
  physicianSelectDisabledOption = true;
  cityLength = false;
  noCityLength = false;
  citiesList: any = [];
  physicianLength = false;
  noPhysicianLength = false;
  physiciansList: any = [];
  physiciansListTemp: any = [];
  selectedPhysician: any = {};
  selectedStateCode: any;
  selectedStateName: any;
  selectedCityName: any;
  selectedPhysicianId: any = "";
  selectedPhysicianNPI: any;
  showNewFile = 0;
  attachmentDoc: any = {};
  selectedDocumentId: any;
  hasHippaFile = false;
  selectedEmail: any;
  hasPhysicianEmail = 1;
  practiceInfo: any = {};
  pacticeSelected = false;
  signedGroups: any = [];
  signedGroupsCount: any;
  profileObj: any;
  docGroupId: any;
  docGroupTitle: any;
  documentSetType: any = "E";
  docSet: any;
  docTyep: any;
  activeGroupId: any;
  companyDefaultGroupTitle: any;
  companyDefaultGroupId: any;
  isPracticePhy = "0";
  showComfirmationModal = false;
  showInfoModal = false;
  showInfoModalDelete = false;
  searchStateList: any = ArrayHelper.STATE_ARRAY;
  isProgrammed = false;
  doctorTableArr: any = [];
  doctorTableArrTemp: any = [];
  isSearched = false;
  practices: any = [];
  selectedFiles: any = [];
  isHippa: any = "0";
  searchType: any = "1";
  actualLoggedInUserId = "";
  onBehalfOfAlertSendDocument = "Y";
  message = Message;
  checkAltEmail = false;
  physicianCount = 1;
  selectedStateByRecordId: { [key: string]: boolean } = {};
  selectedIndexes: boolean[] = [];
  isFileSelected = false;
  tempObj: any = {};
  minorId: any;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private uploadService: UploadService,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService,
    public localStorageService: LocalStorageService
  ) {

    this.actualLoggedInUserId = localStorage.getItem("loggedInUserId");
    this.minorId=localStorage.getItem("delegateId")  || "0";
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.isPracticePhy = this.activatedRoute.snapshot.paramMap.get(
      "isPracticePhy"
    )
      ? this.activatedRoute.snapshot.paramMap.get("isPracticePhy")
      : "0";
    this.isHippa = this.activatedRoute.snapshot.paramMap.get("isHippa")
      ? this.activatedRoute.snapshot.paramMap.get("isHippa")
      : "0";
    this.selectedPhysicianId = this.activatedRoute.snapshot.paramMap.get(
      "physicianId"
    )
      ? this.activatedRoute.snapshot.paramMap.get("physicianId")
      : "";
    this.attachmentDocment = this.activatedRoute.snapshot.paramMap.get(
      "documentId"
    )
      ? this.activatedRoute.snapshot.paramMap.get("documentId")
      : "";
    if (this.attachmentDocment) {
      this.isFileSelected = true;
    }
    this.docInfo.state = "";
    this.docInfo.city = "";
    this.docInfo.physicianName = "";
    this.docInfo.practice = "";
    this.docInfo.searchState = "";
    this.physicianSearchObj.physicianSearchState = "";
    this.docInfo.practiceGrp = "";
  }

  async ngAfterViewInit() {
    var item = localStorage.getItem("FullName");
    if (
      this.onBehalfOfAlertSendDocument === "Y" &&
      localStorage.getItem("isDelegating") === "1"
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.ngAfterViewInit + " " + item
      );

      localStorage.setItem("onBehalfOfAlertSendDocument", "N");
    }
  }

  ngOnInit() {
    if(this.attachmentDocment == 0 || ''){
      this.attachmentDocment = "";
      this.isFileSelected = false;
    }
    this.findAllParticipatingCenters();
    this.findSignedGroups(this.loggedInUserId);
    this.findUserDetails(this.loggedInUserId);
    if (this.isHippa == 1 && this.isPracticePhy == "1") {
      this.findPhysicianById(this.selectedPhysicianId);
      this.searchType = "1";
    } else if (this.isHippa == 1 && this.isPracticePhy == "0") {
      this.findUserPhysicianById(this.selectedPhysicianId);
      this.searchType = "2";
    }
    this.onBehalfOfAlertSendDocument = localStorage.getItem(
      "onBehalfOfAlertSendDocument"
    )
      ? localStorage.getItem("onBehalfOfAlertSendDocument")
      : "Y";
    if (this.isHippa == 0) {
      this.localStorageService.removeItem("selectedFiles");
      this.localStorageService.removeItem("selectedDocsId");
      localStorage.removeItem("emailLogTxt");
    } else {
      this.selectedFiles = this.localStorageService.getArray("selectedFiles");
      this.selectedDocsId = this.localStorageService.getArray("selectedDocsId");
      this.emailLogTxt = localStorage.getItem("emailLogTxt")
        ? localStorage.getItem("emailLogTxt")
        : "";
    }
    window.scroll(0, 0);
    this.selectedPhysician.PhysicianName = localStorage.getItem("providerName");
    this.selectedPhysician.PhysicianOfficeAddress = localStorage.getItem("providerOfficeAddress");

  }

  setSearchTypeVal(e) {
    this.searchType = e.target.value;
    this.selectedPhysician = {};
    this.isPracticePhy = "0";
    this.docInfo.state = "";
    this.docInfo.city = "";
  }

  clearSelectedDocs(event, documentInfo) {
    if (event.target.checked) {
      this.selectedFiles.push(documentInfo);
      this.selectedDocsId.push(documentInfo.RecordId);
      this.selectedStateByRecordId[documentInfo.RecordId] = true;
      this.localStorageService.setArray("selectedFiles", this.selectedFiles);
      this.localStorageService.setArray("selectedDocsId", this.selectedDocsId);
      // if (this.emailLogTxt.length > 0) {
      //   localStorage.setItem('emailLogTxt', this.emailLogTxt);
      // }
    } else {
      this.removeSelectedFile(documentInfo.RecordId, false);
      this.selectedStateByRecordId[documentInfo.RecordId] = false;
    }
  }

  async removeSelectedFile(id, confirmFlag) {
    if (confirmFlag) {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.sendDocComp.removeSelectedFile
      );
      if (alertStatus) {
        this.removeItems(id);
      }
    } else {
      this.removeItems(id);
    }
  }

  removeItems(id) {
    this.selectedDocsId = this.selectedDocsId.filter((obj) => obj !== id);
    const removeIndex = this.selectedFiles
      // tslint:disable-next-line:only-arrow-functions
      .map(function (item) {
        return item.RecordId;
      })
      .indexOf(id);
    this.selectedFiles.splice(removeIndex, 1);
    const documentIndex = this.documentsList.findIndex(
      (doc) => doc.RecordId === id
    );
    if (documentIndex > -1) {
      this.selectedIndexes[documentIndex] = false;
    }
  }

  async checkAllHippaDoc(isChecked) {
    if (isChecked == 1) {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.sendDocComp.checkAllHippaDoc
      );
      if (alertStatus) {
        $(".docChecks").each(function () {
          $(this).prop("checked", true);
        });
        $(".hippaChecks").each(function () {
          $(this).prop("checked", true);
        });
      } else {
        this.selectedDocsId = [];
        $(".selectAll").prop("checked", false);
        $(".docChecks").each(function () {
          $(this).prop("checked", false);
        });
        $(".hippaChecks").each(function () {
          $(this).prop("checked", false);
        });
      }
    } else {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.sendDocComp.checkAllHippaDoc1
      );
      if (alertStatus) {
        this.selectedDocsId = [];
        $(".selectAll").prop("checked", false);
        $(".docChecks").each(function () {
          $(this).prop("checked", false);
        });
        $(".hippaChecks").each(function () {
          $(this).prop("checked", false);
        });
      } else {
        $(".selectAll").prop("checked", true);
      }
    }
  }

  getCheckedBoxValue() {
    const allCheck: any = document.getElementsByClassName("docChecks");
    for (let index = 0; index < allCheck.length; index++) {
      const element = allCheck[index];
      if (element.checked) {
        this.selectedDocsId.push(element.value);
      }
    }
  }

  async onReset() {
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.sendDocComp.onReset
    // );
    // if (alertStatus) {
      this.localStorageService.removeItem("selectedFiles");
      this.localStorageService.removeItem("selectedDocsId");
      localStorage.removeItem("emailLogTxt");
      if (this.isHippa == 0) {
        window.location.reload();
      } else {
        this.router.navigate([
          "/send-doc/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
        ]);
      }
    // }
  }

  async goToDashboard() {
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.ushfComp.onReset
    // );
    // if (alertStatus) {
      this.localStorageService.removeItem("selectedFiles");
      this.localStorageService.removeItem("selectedDocsId");
      localStorage.removeItem("emailLogTxt");
      this.router.navigate([
        "/dashboard/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    // }
  }

  onFileSelected(event) {
    this.showNewFile = 1;
    this.attachmentDocment = "";
    this.hippaAvailable = false;
    if (this.fileUploaded.nativeElement.value != "") {
      this.fileToUpload = event.target.files.item(0) as File;
      this.fSize = Math.round(this.fileToUpload.size / 1024);
    } else {
    }
    if (
      this.hasHippaFile &&
      this.fileUploaded.nativeElement.value == "" &&
      this.selectedDocumentId == ""
    ) {
      this.hippaAvailable = true;
    }
    this.isFileSelected = true;
  }

  async onSubmit() {
    let altEmail = "";
    if (this.docInfo.altEmail) {
      altEmail = this.docInfo.altEmail;
    }
    if (!this.selectedPhysicianId) {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.sendDocComp.onSubmit
      );
      return false;
    } else if (this.selectedDocsId.length <= 0) {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.sendDocComp.onSubmit1
      );
      return false;
    } else if (this.selectedDocsId.length > 5) {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.sendDocComp.onSubmit2
      );
      return false;
    
    } else if (!this.isFileSelected) {
      await this.sweetAlertHelper.alertPopUp(
        "ALERT: You must please select a HIPAA form for this doctor or select generate a HIPAA form to attach to the email before the email can be sent."
      );
      return false;
    } else if (
      this.showNewFile == 1 &&
      this.fileUploaded.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload.name)
    ) {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.sendDocComp.onSubmit3
      );
      return false;
    } else if (
      this.showNewFile == 1 &&
      this.fileUploaded.nativeElement.value != "" &&
      this.fSize > 61440
    ) {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.uploadFile2
      );
      return false;
    } else if (this.searchType == 2 && !altEmail) {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.sendDocComp.onSubmit4
      );
      return false;
    } else if (altEmail && altEmail !== this.docInfo.cnfAltEmail) {
      await this.sweetAlertHelper.alertPopUp(
        "Please confirm alternate email address."
      );
      return false;
    } else {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.sendDocComp.onsubmit
      );
      if (alertStatus) {
        if (this.searchType == 2) {
          this.selectedEmail = altEmail;
        }

        if (this.emailLogTxt.length > 0) {
          localStorage.setItem("emailLogTxt", this.emailLogTxt);
        }
        this.showLoader = false;
        this.uploadService
          .sendDoc(
            this.userService.getRefromatName(
              this.selectedPhysician.PhysicianName
            ),
            this.selectedEmail,
            this.selectedDocsId,
            this.fileToUpload,
            this.loggedInUserId,
            this.hippaDocumentsList.RecordId,
            this.companySlug,
            altEmail,
            this.selectedStateCode,
            this.selectedCityName,
            this.selectedPhysicianId,
            this.emailLogTxt,
            this.fileDesTxt,
            ConstantHelper.DOC_TYPE_USER_HIPPA,
            localStorage.getItem("loggedInUserComapnyId"),
            this.showNewFile,
            this.attachmentDoc,
            this.selectedPhysician.PhysicianNipNo,
            this.searchType,
            this.actualLoggedInUserId, 
            this.minorId, 
            
          )
          .subscribe(
            async (data: any) => {
              this.showLoader = false;
              if (data !== undefined) {
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.sendDocComp.onSubmit5
                );
                this.router.navigate([
                  "/dashboard/" +
                  this.activatedRoute.snapshot.paramMap.get(
                    "loggedInUserId"
                  ) +
                  "/1",
                ]);
                if (data.commandResult.success == 1) {
                }
              }
            },
            (error) => {
              // console.log(error);
            }
          );
      }
    }
  }

  validateFile(name: String) {
    const ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "pdf"
    ) {
      return true;
    } else {
      return false;
    }
  }

  onChangeUserState(stateCode) {
    this.userService.findHippaByStateCode(stateCode).subscribe(
      (apiResponse) => {
        this.resultJsonObj = apiResponse;
        if (this.resultJsonObj.commandResult.status == 1) {
          this.hippaAvailable = true;
          this.hasHippaFile = true;
          this.selectedDocUrl =
            this.resultJsonObj.commandResult.data.hippaDetails.DocumentUrl;
          if (
            stateCode == "NJ" ||
            stateCode == "NY" ||
            stateCode == "FL" ||
            stateCode == "PA" ||
            stateCode == "CT" ||
            stateCode == "OH" ||
            stateCode == "CO" ||
            stateCode == "CA" ||
            stateCode == "AL" ||
            stateCode == "AZ" ||
            stateCode == "AR" ||
            stateCode == "DE" ||
            stateCode == "GA" ||
            stateCode == "HI" ||
            stateCode == "ID" ||
            stateCode == "IL" ||
            stateCode == "AK" ||
            stateCode == "TX" ||
            stateCode == "SD" ||
            stateCode == "ND" ||
            stateCode == "VA" ||
            stateCode == "VT" ||
            stateCode == "KY" ||
            stateCode == "WI" ||
            stateCode == "WV" ||
            stateCode == "TN" ||
            stateCode == "IA" ||
            stateCode == "IN" ||
            stateCode == "KS" ||
            stateCode == "LA" ||
            stateCode == "MA" ||
            stateCode == "MD" ||
            stateCode == "ME" ||
            stateCode == "MN" ||
            stateCode == "MO" ||
            stateCode == "MS" ||
            stateCode == "MT" ||
            stateCode == "NC" ||
            stateCode == "ND" ||
            stateCode == "NE" ||
            stateCode == "NH" ||
            stateCode == "NM" ||
            stateCode == "NV" ||
            stateCode == "OK" ||
            stateCode == "OR" ||
            stateCode == "RI" ||
            stateCode == "SC" ||
            stateCode == "UT" ||
            stateCode == "WA"
          ) {
            this.isProgrammed = true;
          } else {
            this.isProgrammed = false;
          }
        } else {
          this.hippaAvailable = false;
        }
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  setStateCode(stateCode) {
    this.selectedStateCode = stateCode;
  }

  async downloadHipaa() {
    sessionStorage.setItem("sendDocRoute", "send-doc/" + this.loggedInUserId);
    if (this.selectedStateCode == "NJ") {
      this.router.navigate([
        "/hippa-nj/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "NY") {
      this.router.navigate([
        "/hippa-ny/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "FL") {
      this.router.navigate([
        "/hippa-fl/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "PA") {
      this.router.navigate([
        "/hippa-pa/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "CT") {
      this.router.navigate([
        "/hippa-ct/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "OH") {
      this.router.navigate([
        "/hippa-oh/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "CO") {
      this.router.navigate([
        "/hippa-co/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "CA") {
      this.router.navigate([
        "/hippa-ca/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "AL") {
      this.router.navigate([
        "/hippa-al/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "AZ") {
      this.router.navigate([
        "/hippa-az/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "AR") {
      this.router.navigate([
        "/hippa-ar/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "DE") {
      this.router.navigate([
        "/hippa-de/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "GA") {
      this.router.navigate([
        "/hippa-ga/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "HI") {
      this.router.navigate([
        "/hippa-hi/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "ID") {
      this.router.navigate([
        "/hippa-id/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "IL") {
      this.router.navigate([
        "/hippa-il/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "AK") {
      this.router.navigate([
        "/hippa-ak/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "TX") {
      this.router.navigate([
        "/hippa-tx/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "SD") {
      this.router.navigate([
        "/hippa-sd/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "ND") {
      this.router.navigate([
        "/hippa-nd/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "VA") {
      this.router.navigate([
        "/hippa-va/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "VT") {
      this.router.navigate([
        "/hippa-vt/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "KY") {
      this.router.navigate([
        "/hippa-ky/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "WI") {
      this.router.navigate([
        "/hippa-wi/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "WV") {
      this.router.navigate([
        "/hippa-wv/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "TN") {
      this.router.navigate([
        "/hippa-tn/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "OK") {
      this.router.navigate([
        "/hippa-ok/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "OR") {
      this.router.navigate([
        "/hippa-ri/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "RI") {
      this.router.navigate([
        "/hippa-ri/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "SC") {
      this.router.navigate([
        "/hippa-sc/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "UT") {
      this.router.navigate([
        "/hippa-ut/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "WA") {
      this.router.navigate([
        "/hippa-wa/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "ND") {
      this.router.navigate([
        "/hippa-nd/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "NE") {
      this.router.navigate([
        "/hippa-ne/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "NH") {
      this.router.navigate([
        "/hippa-nh/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "NM") {
      this.router.navigate([
        "/hippa-nm/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "NV") {
      this.router.navigate([
        "/hippa-nv/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "ME") {
      this.router.navigate([
        "/hippa-me/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "MN") {
      this.router.navigate([
        "/hippa-mn/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "MO") {
      this.router.navigate([
        "/hippa-mo/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "MS") {
      this.router.navigate([
        "/hippa-ms/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "MT") {
      this.router.navigate([
        "/hippa-mt/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "NC") {
      this.router.navigate([
        "/hippa-nc/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "IA") {
      this.router.navigate([
        "/hippa-ia/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "IN") {
      this.router.navigate([
        "/hippa-in/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "KS") {
      this.router.navigate([
        "/hippa-ks/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "LA") {
      this.router.navigate([
        "/hippa-la/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "MA") {
      this.router.navigate([
        "/hippa-ma/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else if (this.selectedStateCode == "MD") {
      this.router.navigate([
        "/hippa-md/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/1" +
        "/" +
        this.isPracticePhy +
        "/" +
        this.selectedPhysicianId,
      ]);
    } else {
      if (this.selectedDocUrl) {
        // alert(
        //   "Please download HIPAA form manually and upload, once you filled it."
        // );
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.sendDocComp.downloadHipp
        );
        if (alertStatus) {
          window.open(this.selectedDocUrl);
        }
      } else {
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.ushfComp.onSubmit5
        );
        // alert("No HIPAA form found for the state.");
      }
    }
    if (this.emailLogTxt.length > 0) {
      localStorage.setItem("emailLogTxt", this.emailLogTxt);
    }
  }

  updateFileStatus() {
    this.isFileSelected = true;
  }

  setAttachment(DocumentId) {
    this.fileToUpload = null;
    this.hippaAvailable = false;
    this.fileUploaded.nativeElement.value = "";
    this.selectedDocumentId = DocumentId;
    this.showNewFile = 2;
    const docObj = this.hippaDocumentsList.find(
      (e) => e.RecordId == this.selectedDocumentId
    );
    this.attachmentDoc = JSON.stringify(docObj);

    if (
      this.hasHippaFile &&
      this.fileUploaded.nativeElement.value == "" &&
      this.selectedDocumentId == ""
    ) {
      this.hippaAvailable = true;
    }
  }

  getMaskedString(email) {
    return email.substring(0, 4);
  }

  findUserDoctors() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findPracticePhysiciansApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.data.Physicians.length > 0) {
            this.doctorLength = true;
            this.doctorList = this.fnChangePhysicianNameFormat1(
              apiResponse.commandResult.data.Physicians
            );
          } else {
            this.doctorLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  // find registered Physician
  findPhysicianById(physicianId) {
    this.showLoader = true;
    const finalDataPhysician = { physicianId };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findPhysicianByIdApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        (apiResponsePhysician: any) => {
          this.isPracticePhy = "1";
          this.pacticeSelected = true;
          this.tempObj = apiResponsePhysician.commandResult.data;
          this.practiceInfo = this.tempObj.userInfo;
          this.docInfo.state = this.tempObj.userInfo.PhysicianStateName;
          this.selectedStateCode = this.tempObj.userInfo.PhysicianState;
          this.selectedCityName = this.tempObj.userInfo.PhysicianCity;
          this.docInfo.city = this.tempObj.userInfo.PhysicianCity;
          this.selectedPhysicianId = physicianId;
          this.selectedPhysicianNPI = this.tempObj.userInfo.PhysicianNipNo;

          this.selectedPhysician = this.tempObj.userInfo;
          this.selectedEmail = this.selectedPhysician.PhysicianEmail;
          this.selectedPhysician.PhysicianEmail =
            this.selectedPhysician.PhysicianEmail;
          this.selectedPhysician.PhysicianEmailMasked =
            this.selectedPhysician.PhysicianEmail.substring(0, 4) +
            "****@***.***";
          localStorage.setItem(
            "selectedHippaState",
            this.selectedPhysician.PhysicianState
          );
          localStorage.setItem(
            "providerName",
            this.userService.getRefromatName(
              this.selectedPhysician.PhysicianName
            )
          );
          localStorage.setItem(
            "providerOfficeAddress",
            this.selectedPhysician.PhysicianOfficeAddress
          );
          localStorage.setItem(
            "providerPhone",
            this.selectedPhysician.PhysicianOfficePhone
              ? this.selectedPhysician.PhysicianOfficePhone
              : ""
          );
          localStorage.setItem("providerEmail", this.selectedEmail);
          localStorage.setItem(
            "providerPhysicianName",
            this.userService.getRefromatName(
              this.selectedPhysician.PhysicianName
            )
          );
          localStorage.setItem(
            "providerPhysicianNipNo",
            this.selectedPhysician.PhysicianNipNo
          );

          localStorage.setItem(
            "providerCity",
            this.tempObj.userInfo.PhysicianCity
              ? this.tempObj.userInfo.PhysicianCity
              : ""
          );
          localStorage.setItem(
            "providerZipCode",
            this.tempObj.userInfo.PhysicianZipCode
              ? this.tempObj.userInfo.PhysicianZipCode
              : ""
          );
          localStorage.setItem(
            "PhysicianStateName",
            this.tempObj.userInfo.PhysicianStateName
              ? this.tempObj.userInfo.PhysicianStateName
              : ""
          );

          this.onChangeUserState(this.selectedStateCode);
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDetails(userId) {
    const finalData = {
      company_slug: null,
      id: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.docGroupId =
              apiResponse.commandResult.data.userInfo.DocGroupId;
            this.docGroupTitle =
              apiResponse.commandResult.data.userInfo.GroupTitle;
            this.profileObj = apiResponse.commandResult.data.userInfo;

            this.activeGroupId = this.profileObj.DocGroupId;
            this.docInfo.docSet = this.profileObj.DocGroupId;
            this.findCompanyDefaultGroup(this.loggedInUserId);
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findCompanyDefaultGroup(userId) {
    const finalData = {
      company_slug: null,
      loggedInUserId: userId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() +
        ApiHelper.name_getCompanyDefaultGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.companyDefaultGroupTitle =
            apiResponse.commandResult.data.companyDefaultGroup.GroupTitle;
          this.companyDefaultGroupId =
            apiResponse.commandResult.data.companyDefaultGroup.Id;
          this.fnFindGroupDocs();
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findSignedGroups(userId) {
    const finalData = {
      company_slug: null,
      loggedInUserId: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findUserDocumentGroups,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.signedGroups = apiResponse.commandResult.data.DocGroups;
          this.signedGroupsCount =
            apiResponse.commandResult.data.DocGroups.length;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindGroupDocs() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: null,
      doc_group_id: this.activeGroupId,
      default_group_id: this.companyDefaultGroupId,
      email: "",
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findDocsBySubGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
            this.documentsList.forEach((doc) => {
              doc.selected = !this.selectedStateByRecordId[doc.RecordId];
            });
            this.fnFindHippaDocs();
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  selectDocGroup(docSet) {
    this.documentsList = [];
    this.documentsLength = false;
    this.docSet = docSet;
    if (this.docSet == "A") {
      this.fnFindAllDocuments();
    } else if (this.docSet == "U") {
      this.fnFindUploadedDocs();
    } else if (this.docSet == "DS") {
      this.fnFindDoctorAcknowledgments();
    } else if (this.docSet == "HD") {
      this.fnFindHippaDocuments();
    } else if (this.docSet == "ID") {
      this.fnFindImageDocuments();
    } else if (this.docSet == "VD") {
      this.fnFindVideoDocuments();
    } else {
      this.activeGroupId = this.docSet;
      this.fnFindGroupDocs();
    }
  }

  fnFindPhysicainHippa(docType) {
    const finalData = {
      userId: this.loggedInUserId,
      docType,
    };

    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findPhysicainHippaApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.documentsLength = true;
          this.documentsList = apiResponse.commandResult.data.documentList;
          this.documentsList.forEach((doc) => {
            doc.selected = !!this.selectedStateByRecordId[doc.RecordId];
          });
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindHippaDocuments() {
    const finalData = {
      userId: this.loggedInUserId,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
        ApiHelper.getPatientHippaDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
            this.documentsList.forEach((doc) => {
              doc.selected = !!this.selectedStateByRecordId[doc.RecordId];
            });
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindImageDocuments() {
    const finalData = {
      userId: this.loggedInUserId,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
        ApiHelper.getPatientImageDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
            this.documentsList.forEach((doc) => {
              doc.selected = !!this.selectedStateByRecordId[doc.RecordId];
            });
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindVideoDocuments() {
    const finalData = {
      userId: this.loggedInUserId,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
        ApiHelper.getPatientVideoDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
            this.documentsList.forEach((doc) => {
              doc.selected = !!this.selectedStateByRecordId[doc.RecordId];
            });
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindAllDocuments() {
    const finalData = {
      userId: this.loggedInUserId,
      company_slug: null,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getPatientAllDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log('All Documents', this.resultJsonObj);
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
            this.documentsList.forEach(
              (doc) =>
                (doc.selected = !!this.selectedStateByRecordId[doc.RecordId])
            );
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindUploadedDocs() {
    const finalData = {
      userId: this.loggedInUserId,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    // console.log(finalData);
    // return;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
        ApiHelper.getPatientUploadedDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
            this.documentsList.forEach((doc) => {
              doc.selected = !!this.selectedStateByRecordId[doc.RecordId];
            });
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindDoctorAcknowledgments() {
    const finalData = {
      userId: this.loggedInUserId,
      company_slug: null,
      docType: 7,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
        ApiHelper.getPatientAcknowledgmentDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
            this.documentsList.forEach((doc) => {
              doc.selected = !!this.selectedStateByRecordId[doc.RecordId];
            });
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindHippaDocs() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: null,
      doc_group_id: "HD",
      default_group_id: this.companyDefaultGroupId,
      email: this.profileObj.Email,
    };

    // console.log(finalData);
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findDocsBySubGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;

          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.hippaDocumentsLength = true;
            this.hippaDocumentsList =
              this.resultJsonObj.commandResult.data.documentList;
            this.setAttachment(this.attachmentDocment);
            this.documentsList.forEach((doc) => {
              doc.selected = !!this.selectedStateByRecordId[doc.RecordId];
            });
          } else {
            this.hippaDocumentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnChangePhysicianNameFormat1(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.physician_name = this.userService.getUS_FromatName(e.physician_name);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(
      this.userService.dynamicSort("physician_name")
    );
  }

  fnChangePhysicianNameFormat(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.PhysicianName = this.userService.getUS_FromatName(e.PhysicianName);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(
      this.userService.dynamicSort("PhysicianName")
    );
  }

  fnChangePhysicianNameFormat2(physicians) {
    const formattedNameArray2: any = [];
    physicians.forEach((e) => {
      e.PhysicianName = this.userService.getUS_FromatName(e.PhysicianName);
      formattedNameArray2.push(e);
    });
    return formattedNameArray2.sort(
      this.userService.dynamicSort("PhysicianName")
    );
  }

  closeComfirmationModal() {
    this.showComfirmationModal = false;
  }

  closeInfoModal() {
    this.showInfoModal = false;
  }

  openSearchPhysicianModal() {
    this.showSearchPhysicianModal = true;
    this.physicianSearchObj.physicianSearchLastName = "";
    this.physicianSearchObj.physicianSearchFirstName = "";
    this.physicianSearchObj.physicianSearchState = "";
    this.physicianSearchObj.physicianSearchNPI = "";
    this.doctorTableArr = [];
  }

  resetSearchPhysicianModal() {
    this.physicianSearchObj.physicianSearchLastName = "";
    this.physicianSearchObj.physicianSearchFirstName = "";
    this.physicianSearchObj.physicianSearchState = "";
    this.physicianSearchObj.physicianSearchNPI = "";
    this.doctorTableArr = [];
    this.physicianCount = 1;
  }

  closeSearchPhysicianModal() {
    this.showSearchPhysicianModal = false;
    this.physicianCount = 1;
  }

  async searchPhysicians() {
    if (this.physicianSearchObj.physicianSearchNPI) {
      if (
        this.physicianSearchObj.physicianSearchNPI.length !== 10 ||
        !/^\d+$/.test(this.physicianSearchObj.physicianSearchNPI)
      ) {
        // Display an error message for invalid NPI length
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.demographics.invalidNPI
        );
        return;
      }
    }
    if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchFirstName
    ) {
      // alert(
      //   "Please enter first name. You may search physician informantion by NPI number."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.searchPhysicians
      );

      return;
    } else if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchLastName
    ) {
      // alert(
      //   "Please enter last name. You may search physician informantion by NPI number."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.sendDocComp.searchPhysicians
      );

      return;
    } else if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchState
    ) {
      // alert("Please select state.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.searchPhysicians1
      );

      return;
    } else {
      this.doctorTableArr = [];
      const finalData = {
        loggedInUserId: this.loggedInUserId,
        physicianSearchFirstName:
          this.physicianSearchObj.physicianSearchFirstName,
        physicianSearchLastName:
          this.physicianSearchObj.physicianSearchLastName,
        physicianSearchState: this.physicianSearchObj.physicianSearchState,
        physicianSearchNPI: this.physicianSearchObj.physicianSearchNPI,
      };
      // this.showLoader = true;
      this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_searchNPIRegistryApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            this.doctorTableArr = apiResponse.commandResult.data.physiciansList;
            this.hippaAvailable = true;
            this.hasHippaFile = true;
            this.selectedDocUrl =
              apiResponse.commandResult.data.hippaDetails.DocumentUrl;
            this.physicianCount = apiResponse.commandResult.data.physiciansCout;
            this.showLoader = false;
            if (this.doctorTableArr.length === 0) {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                "No record found for the given details"
              );
            }
          },
          (err) => {
            this.showLoader = false;
            console.error(err);
          }
        );
    }
  }

  selectDoctor(physicianId) {
    this.physicianSelectDisabledOption = false;
    this.isSearched = true;
    this.selectedPhysician = this.doctorTableArr.find(
      (e) => e.RecordId == physicianId
    );
    this.showSearchPhysicianModal = false;
    this.docInfo.state = this.selectedPhysician.PhysicianState;
    this.docInfo.city = this.selectedPhysician.PhysicianCity;
    this.selectedCityName = this.selectedPhysician.PhysicianCity;
    this.docInfo.physicianName = this.selectedPhysician.RecordId;
    this.docInfo.physician_Name = this.selectedPhysician.PhysicianName;
    this.selectedStateCode = this.selectedPhysician.PhysicianStateCode;
    this.selectedPhysicianNPI = this.selectedPhysician.PhysicianNipNo;
    const finalData = {
      physicianNPI: this.selectedPhysician.PhysicianNipNo,
      physicianCity: this.selectedPhysician.PhysicianCity,
      physicianName: this.userService.getRefromatName(
        this.selectedPhysician.PhysicianName
      ),
      physicianOfficeAddress: this.selectedPhysician.PhysicianOfficeAddress,
      physicianZIP: this.selectedPhysician.zip_code,
      physicianStateName: this.selectedPhysician.PhysicianStateName,
    };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_checkNPIDoctorApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.selectedPhysicianId = apiResponse.commandResult.data.physicianId;
          this.selectedPhysicianNPI = this.selectedPhysician.PhysicianNipNo;
          localStorage.setItem(
            "selectedHippaState",
            this.selectedPhysician.PhysicianStateCode
          );
          localStorage.setItem(
            "providerPhone",
            this.selectedPhysician.PhysicianOfficePhone
              ? this.selectedPhysician.PhysicianOfficePhone
              : ""
          );
          localStorage.setItem(
            "providerName",
            this.userService.getRefromatName(
              this.selectedPhysician.PhysicianName
            )
          );
          localStorage.setItem(
            "PhysicianSpecialty",
            this.selectedPhysician.PhysicianSpecialty
          );
          localStorage.setItem(
            "providerOfficeAddress",
            this.selectedPhysician.PhysicianOfficeAddress
          );
          localStorage.setItem(
            "providerEmail",
            this.selectedPhysician.PhysicianEmail
          );
          localStorage.setItem(
            "providerPhysicianName",
            this.userService.getRefromatName(
              this.selectedPhysician.PhysicianName
            )
          );
          localStorage.setItem(
            "providerPhysicianNipNo",
            this.selectedPhysician.PhysicianNipNo
          );

          localStorage.setItem(
            "PhysicianStateName",
            this.selectedPhysician.PhysicianStateName
              ? this.selectedPhysician.PhysicianStateName
              : ""
          );
          localStorage.setItem(
            "providerZipCode",
            this.selectedPhysician.zip_code
              ? this.selectedPhysician.zip_code
              : ""
          );
          localStorage.setItem(
            "providerCity",
            this.selectedPhysician.PhysicianCity
              ? this.selectedPhysician.PhysicianCity
              : ""
          );

          this.showInfoModal = true;
        },
        (err) => {
          // console.log(err);
        }
      );

    // localStorage.setItem('selectedHippaState', this.selectedPhysician.PhysicianState);
    // localStorage.setItem('providerName', this.userService.getRefromatName(this.selectedPhysician.PhysicianName));
    // localStorage.setItem('providerOfficeAddress', this.selectedPhysician.PhysicianOfficeAddress);
    // localStorage.setItem('providerZipCode', this.selectedPhysician.zip_code ? this.selectedPhysician.zip_code : '');
    // localStorage.setItem('providerPhone', this.selectedPhysician.PhysicianOfficePhone ? this.selectedPhysician.PhysicianOfficePhone : '');
    // localStorage.setItem('providerEmail', this.selectedEmail);
    // localStorage.setItem('providerPhysicianName', this.userService.getRefromatName(this.selectedPhysician.PhysicianName));
    // localStorage.setItem('providerPhysicianNipNo', this.selectedPhysician.PhysicianNipNo);
    this.showLoader = false;
  }

  selectDoctor_02(physicianId) {
    this.physicianSelectDisabledOption = false;
    this.isSearched = true;
    this.selectedPhysician = this.doctorTableArr.find(
      (e) => e.RecordId == physicianId
    );
    this.showSearchPhysicianModal = false;
    this.docInfo.state = this.selectedPhysician.PhysicianState;
    this.docInfo.city = this.selectedPhysician.PhysicianCity;
    this.selectedCityName = this.selectedPhysician.PhysicianCity;
    this.docInfo.physicianName = this.selectedPhysician.RecordId;
    this.docInfo.physician_Name = this.selectedPhysician.PhysicianName;
    this.selectedStateCode = this.selectedPhysician.PhysicianStateCode;
    this.selectedPhysicianNPI = this.selectedPhysician.PhysicianNipNo;
    // localStorage.setItem('selectedHippaState', this.selectedPhysician.PhysicianState);
    // localStorage.setItem('providerName', this.userService.getRefromatName(this.selectedPhysician.PhysicianName));
    // localStorage.setItem('providerOfficeAddress', this.selectedPhysician.PhysicianOfficeAddress);
    // localStorage.setItem('providerZipCode', this.selectedPhysician.zip_code ? this.selectedPhysician.zip_code : '');
    // localStorage.setItem('providerPhone', this.selectedPhysician.PhysicianOfficePhone ? this.selectedPhysician.PhysicianOfficePhone : '');
    // localStorage.setItem('providerEmail', this.selectedEmail);
    // localStorage.setItem('providerPhysicianName', this.userService.getRefromatName(this.selectedPhysician.PhysicianName));
    // localStorage.setItem('providerPhysicianNipNo', this.selectedPhysician.PhysicianNipNo);

    localStorage.setItem(
      "selectedHippaState",
      this.selectedPhysician.PhysicianStateCode
    );
    localStorage.setItem(
      "providerPhone",
      this.selectedPhysician.PhysicianOfficePhone
        ? this.selectedPhysician.PhysicianOfficePhone
        : ""
    );
    localStorage.setItem(
      "providerName",
      this.userService.getRefromatName(this.selectedPhysician.PhysicianName)
    );
    localStorage.setItem(
      "PhysicianSpecialty",
      this.selectedPhysician.PhysicianSpecialty
    );
    localStorage.setItem(
      "providerOfficeAddress",
      this.selectedPhysician.PhysicianOfficeAddress
    );
    localStorage.setItem(
      "providerEmail",
      this.selectedPhysician.PhysicianEmail
    );
    localStorage.setItem(
      "providerPhysicianName",
      this.userService.getRefromatName(this.selectedPhysician.PhysicianName)
    );
    localStorage.setItem(
      "providerPhysicianNipNo",
      this.selectedPhysician.PhysicianNipNo
    );
    localStorage.setItem(
      "PhysicianStateName",
      this.selectedPhysician.PhysicianStateName
        ? this.selectedPhysician.PhysicianStateName
        : ""
    );
    localStorage.setItem(
      "providerZipCode",
      this.selectedPhysician.zip_code ? this.selectedPhysician.zip_code : ""
    );
    localStorage.setItem(
      "providerCity",
      this.selectedPhysician.PhysicianCity
        ? this.selectedPhysician.PhysicianCity
        : ""
    );
    this.showInfoModal = true;
    this.showLoader = false;
  }

  findAllParticipatingCenters() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findPractices,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.data.companiesList.length > 0) {
            this.practices = apiResponse.commandResult.data.companiesList;
          } else {
            // alert("No Center of Excellence found!");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.sendDocComp.findAllParticipatingCenters
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPhysicianByCompanySlug(company_slug) {
    this.companySlug = company_slug;
    const finalData = {
      company_slug,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl04() +
        ApiHelper.name_findPhysiciansByCompanySlugApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.data.companiesList.length > 0) {
            this.doctorList = this.fnChangePhysicianNameFormat1(
              apiResponse.commandResult.data.companiesList
            );
            // console.log('Physicians', this.doctorList);
          } else {
            // alert("No Physicians found!");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.findPryPhysiciansByCity
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserPhysicianById(physicianId) {
    this.selectedPhysicianId = physicianId ? physicianId : "";
    this.isSearched = true;
    const finalData = { physicianId };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findUserPhysicianByIdApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          this.selectedPhysician = apiResponse.commandResult.data.physician;
          this.docInfo.state = this.selectedPhysician.PhysicianState;
          this.docInfo.city = this.selectedPhysician.PhysicianCity;
          this.docInfo.physicianName = this.selectedPhysician.RecordId;
          this.docInfo.physician_Name = this.selectedPhysician.PhysicianName;
          this.selectedStateCode = this.selectedPhysician.PhysicianStateCode;
          this.selectedCityName = this.selectedPhysician.PhysicianCity;
          const finalData = {
            physicianNPI: this.selectedPhysician.PhysicianNipNo,
          };
          this.http
            .post(
              this.appConfig.getPhpUrl() + ApiHelper.php_findPhysicianEmailApi,
              finalData,
              this.httpOptions
            )
            .subscribe(
              (apiResponse: any) => {
                this.selectedEmail =
                  apiResponse.commandResult.data.physicianEmail;
                this.hasPhysicianEmail =
                  apiResponse.commandResult.data.hasPhysicianEmail;
                if (apiResponse.commandResult.data.hasPhysicianEmail == 1) {
                  this.selectedPhysician.PhysicianEmail =
                    apiResponse.commandResult.data.physicianEmail;
                  this.selectedPhysician.PhysicianEmailMasked = apiResponse
                    .commandResult.data.physicianEmail
                    ? apiResponse.commandResult.data.physicianEmail.substring(
                      0,
                      4
                    ) + "****@***.***"
                    : "";
                } else {
                  this.selectedPhysician.PhysicianEmail =
                    apiResponse.commandResult.data.physicianEmail;
                  this.selectedPhysician.PhysicianEmailMasked = apiResponse
                    .commandResult.data.physicianEmail
                    ? apiResponse.commandResult.data.physicianEmail.substring(
                      0,
                      4
                    ) + "****@***.***"
                    : "";

                  this.showComfirmationModal = true;
                }
                localStorage.setItem(
                  "selectedHippaState",
                  this.selectedPhysician.PhysicianState
                );
                localStorage.setItem(
                  "providerName",
                  this.userService.getRefromatName(
                    this.selectedPhysician.PhysicianName
                  )
                );
                localStorage.setItem(
                  "providerOfficeAddress",
                  this.selectedPhysician.PhysicianOfficeAddress
                );
                localStorage.setItem(
                  "providerZipCode",
                  this.selectedPhysician.zip_code
                    ? this.selectedPhysician.zip_code
                    : ""
                );
                localStorage.setItem(
                  "providerPhone",
                  this.selectedPhysician.PhysicianOfficePhone
                    ? this.selectedPhysician.PhysicianOfficePhone
                    : ""
                );
                localStorage.setItem("providerEmail", this.selectedEmail);
                localStorage.setItem(
                  "providerPhysicianName",
                  this.userService.getRefromatName(
                    this.selectedPhysician.PhysicianName
                  )
                );
                localStorage.setItem(
                  "providerPhysicianNipNo",
                  this.selectedPhysician.PhysicianNipNo
                );

                this.showLoader = false;
              },
              (err) => {
                // console.log(err);
              }
            );
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goToLink(url: string) {
    let extUrl = "http://" + url.toLowerCase();
    window.open(extUrl, "_blank");
  }

  // checkEmail() {
  //   if (
  //     this.docInfo.altEmail.toLowerCase() ===
  //     localStorage.getItem("loggedInUserEmail").toLowerCase()
  //   ) {
  //     this.checkAltEmail = true;
  //   } else {
  //     this.checkAltEmail = false;
  //   }
  // }

  checkEmail() {
    // Check if the alternate email matches the logged-in user's email
    if (this.docInfo.altEmail && localStorage.getItem("loggedInUserEmail")) {
      if (this.docInfo.altEmail.toLowerCase() === localStorage.getItem("loggedInUserEmail").toLowerCase()) {
        this.checkAltEmail = true;
      } else {
        this.checkAltEmail = false;
      }
    } else {
      this.checkAltEmail = false; // Reset to false if either email is not available
    }
  }

  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }

  toggleSelectedDoc(index: number, documentInfo: any) {
    this.selectedIndexes[index] = !this.selectedIndexes[index];
    if (this.selectedIndexes[index]) {
      const existsInSelectedFiles = this.selectedFiles.some(
        (doc) => doc.RecordId === documentInfo.RecordId
      );
      if (!existsInSelectedFiles) {
        this.selectedFiles.push(documentInfo);
      }
    } else {
      this.removeSelectedFile(documentInfo.RecordId, false);
    }
  }

  clearSearchPhysicianInputs(flag) {
    if (flag) {
      this.physicianSearchObj.physicianSearchFirstName = "";
      this.physicianSearchObj.physicianSearchLastName = "";
      this.physicianSearchObj.physicianSearchState = "";
    } else {
      this.physicianSearchObj.physicianSearchNPI = "";
    }
  }

  resetFormControl() {
    this.attachmentDocment = "";
    this.fileUploaded.nativeElement.value = "";
    this.isFileSelected = !this.isFileSelected;
    this.hippaAvailable = true;
  }

  isButtonDisabled(): boolean {
    this.checkEmail();
    return this.checkAltEmail;
  }
  // resetPhySelection() {
  //   this.docInfo.practice = "";
  //   this.docInfo.state = "";
  //   this.docInfo.city = "";
  //   this.docInfo.physicianName = "";
  //   this.selectedPhysician.PhysicianNipNo = "";
  //   this.selectedPhysician.PhysicianOfficeAddress = "";
  //   this.selectedPhysician.PhysicianOfficePhone = "";
  //   this.selectedPhysician.PhysicianSpecialty = "";
  //   this.selectedEmail = "";
  //   this.selectedPhysician.PhysicianEmailMasked = "";
  // }
}
