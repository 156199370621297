import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IdleTimerService {

  private _count: number = 0;
  private _timerSubscription: Subscription;
  private _status: BehaviorSubject<string> = new BehaviorSubject('ACTIVE');

  get status(): Observable<string> {
    return this._status.asObservable();
  }

  startTimer(warningDuration: number, timeoutDuration: number) {
    console.log('Timer Started');
    const warningMinutes = warningDuration;
    console.log("sdflmk",warningMinutes)
    const timeoutMinutes = timeoutDuration / 60000;
    this._timerSubscription = this.status.pipe(
      startWith('ACTIVE'),
      switchMap(status => {
        if (status === 'ACTIVE') {
          return new Observable<number>(obs => {
            const id = setInterval(() => obs.next(++this._count), warningMinutes); // 1 minute
            return () => clearInterval(id);
          });
        } else {
          return [];
        }
      })
    ).subscribe(minutes => {
      if (minutes === timeoutMinutes) {
        console.log("Here", warningMinutes)
        this._status.next('WARNING');
      } else if (minutes >= warningMinutes) {
        this._status.next('TIMEOUT');
      }
    });
  }

  resetTimer() {
    this._count = 0;
    this._status.next('ACTIVE');
  }

  stopTimer() {
    if (this._timerSubscription) {
      this._timerSubscription.unsubscribe();
    }
    this._status.next('STOPPED');
  }

}
