import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';

import { environment } from './../../../environments/environment';
import { ApiHelper } from './../../_helpers/api.helper';
import { CommonHelper } from './../../_helpers/common.helper';
import { AppConfig } from './../../../app/app.config';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
  providers: [AppConfig, CommonHelper]
})
export class HeaderMenuComponent implements OnInit {
  loggedInUserId: string;
  resultJsonObj: any = {};
  selectedLlink = 'demographics';
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }
  ngOnInit() {
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get(
      'loggedInUserId'
    );
    // tslint:disable-next-line: prefer-const
    let currentUrl = this.router.url;
    // console.log(this.router.url);

    if (currentUrl.includes('demographics')) {
      this.selectedLlink = 'demographics';
    }
    if (currentUrl.includes('admins')) {
      this.selectedLlink = 'admins';
    }
    if (currentUrl.includes('physicians')) {
      this.selectedLlink = 'physicians';
    }
    if (currentUrl.includes('documents')) {
      this.selectedLlink = 'documents';
    }
    if (currentUrl.includes('faqs')) {
      this.selectedLlink = 'faqs';
    }
    if (currentUrl.includes('logs')) {
      this.selectedLlink = 'logs';
    }
    if (currentUrl.includes('send-email')) {
      this.selectedLlink = 'sendemail';
    }

    if (currentUrl.includes('practices')) {
      this.selectedLlink = 'practices';
    }

    if (currentUrl.includes('reports')) {
      this.selectedLlink = 'reports';
    }





  }
  goToPhysicians() {
    this.router.navigate(['/physicians/' + this.loggedInUserId]);
  }
  goToAdmins() {
    this.router.navigate(['/admins/' + this.loggedInUserId]);
  }
  goToDemographicForms() {
    this.router.navigate(['/demographics/' + this.loggedInUserId]);
  }
  goToFaqs() {
    this.router.navigate(['/faqs/' + this.loggedInUserId]);
  }
  goToDocuments() {
    this.router.navigate(['/documents/' + this.loggedInUserId]);
  }
  goToLogs() {
    this.router.navigate(['/logs/' + this.loggedInUserId]);
  }

  goToSendMail() {
    this.router.navigate(['/send-email/' + this.loggedInUserId]);
  }
  goToPractices() {
    this.router.navigate(['/practices/' + this.loggedInUserId]);
  }
  goToGenerateReport() {
    this.router.navigate(['/reports/' + this.loggedInUserId]);
  }

  openSuperAdminHelpDocument() {
    window.open(
      "https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/help_docs/ahs_admin_site_instructions_1678985434.pdf", "_blank");
  }
}
