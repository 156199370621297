import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import { ApiHelper } from './../../_helpers/api.helper';
import { CommonHelper } from './../../_helpers/common.helper';
import { ConstantHelper } from './../../_helpers/constant.helper';
import { AppConfig } from './../../../app/app.config';
import {SweetAlertHelper} from 'src/app/_helpers/sweet.alert.helper';
import {Message} from 'src/app/_locale/messages';


@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  loggedInUserId: string;
  resultJsonObj: any = {};
  companiesLength = false;
  companiesList: any;
  usersLength = false;
  noUsersLength = false;
  loadingUsersLength = true;
  selectedCompanySlug: string;
  usersList: any;

  userDeactivateSuccess = false;
  userActivateSuccess = false;
  userErrorFlashMsg = false;
  message = Message;
  apiURl: string;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  httpOptions = {
    headers: this.httpHeaders
  };


  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer
  ) { }
  ngOnInit() {
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get(
      'loggedInUserId'
    );
    const finalData = {
      loggedInUserId: this.loggedInUserId
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_getAllAdminUsersListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          this.loadingUsersLength = false;
          if (this.resultJsonObj.commandResult.data.adminUsers.length > 0) {
            this.usersLength = true;
            this.usersList = this.resultJsonObj.commandResult.data.adminUsers;
          } else {
            this.noUsersLength = true;
          }
        },
        err => {
          // console.log(err);
        }
      );
    this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_getCompaniesListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.companyList.length > 0) {
            this.companiesLength = true;
            this.companiesList = this.resultJsonObj.commandResult.data.companyList;

          } else {
            this.companiesLength = false;
          }
        },
        err => {
          // console.log(err);
        }
      );
  }

  findDemographicFormByCompany(company_slug) {
    this.loadingUsersLength = true;
    this.noUsersLength = false;
    this.usersLength = false;

    const finalData = {
      company_slug,
      loggedInUserId: this.loggedInUserId
    };


    if (company_slug == ConstantHelper.ALL_COMPANIES_VALUE) {
      this.selectedCompanySlug = '';
      this.apiURl = this.appConfig.getLambdaUrl02() + ApiHelper.name_getAllAdminUsersListApi;
    } else {
      this.selectedCompanySlug = company_slug;
      this.apiURl = this.appConfig.getLambdaUrl02() + ApiHelper.name_getAllAdminUsersListByCompanyApi;
    }
    return this.http
      .post(
        this.apiURl,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          this.loadingUsersLength = false;
          if (this.resultJsonObj.commandResult.data.adminUsers.length > 0) {
            this.usersLength = true;
            this.usersList = this.resultJsonObj.commandResult.data.adminUsers;
            // console.log(this.usersList);
          } else {
            this.noUsersLength = true;
          }
        },
        err => {
          // console.log(err);
        }
      );
  }

  openAddUserPage() {
    this.router.navigate(['/admins/add/' + this.loggedInUserId + '/' + this.selectedCompanySlug]);
  }



  disableUser(userID) {
    const finalData = {
      user_id: userID,
      loggedInUserId: this.loggedInUserId,
      company_slug: this.selectedCompanySlug
    };

    this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_deactivateUserApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.status == 1) {
            if (this.resultJsonObj.commandResult.data.adminUsers.length > 0) {
              this.usersLength = true;
              this.usersList = this.resultJsonObj.commandResult.data.adminUsers;
              // console.log(this.usersList);
            } else {
              this.usersLength = false;
            }
            this.userDeactivateSuccess = true;
            setTimeout(() => {
              this.userDeactivateSuccess = false;
            }, 2000);
          } else {
            this.userErrorFlashMsg = true;
            setTimeout(() => {
              this.userErrorFlashMsg = false;
            }, 3000);
          }
        },
        err => {
          // console.log(err);
        }
      );
  }

  enableUser(userID) {
    const finalData = {
      user_id: userID,
      loggedInUserId: this.loggedInUserId,
      company_slug: this.selectedCompanySlug
    };

    this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_activateUserApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.status == 1) {
            if (this.resultJsonObj.commandResult.data.adminUsers.length > 0) {
              this.usersLength = true;
              this.usersList = this.resultJsonObj.commandResult.data.adminUsers;
              // console.log(this.usersList);
            } else {
              this.usersLength = false;
            }
            this.userActivateSuccess = true;
            setTimeout(() => {
              this.userActivateSuccess = false;
            }, 2000);
          } else {
            this.userErrorFlashMsg = true;
            setTimeout(() => {
              this.userErrorFlashMsg = false;
            }, 3000);
          }
        },
        err => {
          // console.log(err);
        }
      );
  }
  resetPassword(userId: any) {
    const finalData = {
      userId: userId,
      company_slug: this.selectedCompanySlug
    };

    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.resetBlockedAccount,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse) => {
          const alertStatus =await this.sweetAlertHelper.confirmPopUp(
            this.message.confirmTitle,
            this.message.confirmMessages.Superadmin.onReset);
          if (alertStatus) {
            location.reload();
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }
  async confirmReset(userId: any, isActive: number) {
    if (isActive === 1) {
      const firstConfirmation = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        'Do you want to reset the password?'
      );
  
        if (firstConfirmation) {
          this.resetPassword(userId);
        }
      
    }
  }

}
