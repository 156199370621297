export const ApiHelper = {
  ///////////////////////////////////// Set 01 Starts////////////////////////////////////////////////////////////////////
  name_loginApi: 'login',
  name_verifyLoginOtpApi: 'verifyLoginOtp',
  name_registrationApi: 'registration',
  name_verifyRegistrationOtpApi: 'verifyRegistrationOtp',
  name_checkExistingEmailApi: 'checkExistingEmail',
  name_sendPasswordLinkApi: 'sendPasswordLink',
  name_modifyPasswordApi: 'modifyPassword',
  name_AdminloginApi: 'adminLogin',
  name_verifyAdminLoginOtpApi: 'verifyAdminLoginOtp',
  name_checkExistingAdminEmailApi: 'checkExistingAdminEmail',
  name_saveDemographicFormApi: 'saveDemographicForm',
  name_updateDemographicDetailsApi: 'updateDemographicDetails',
  name_findDemographicFormApi: 'findDemographicForm',
  name_findUserDetailsApi: 'findUserDetails',
  name_findUserDemographicApi: 'findUserDemographic',
  name_generateDemographicPdfApi: 'generateDemographicPdf',
  name_generateHippaRealeasPdfApi: 'generateHippaRealeasPdf',
  name_generateWaiverDisclaimerPdfApi: 'generateWaiverDisclaimerPdf',
  name_generateSignatureImageApi: 'generateSignatureImage',
  name_getUsersListApi: 'getUsersList',
  name_verifyRecaptachaApi: 'verifyRecaptacha',
  name_getAdminUsersListApi: 'getAdminUsersList',
  name_getSignatureDocsByCompanyApi: 'getSignatureDocsByCompany',
  name_getCitiesByStateCodeApi: 'getCitiesByStateCode',
  name_getAllQuestionsListApi: 'getAllQuestionsList',
  name_updateNewpassword : 'updateNewpassword',

  ///////////////////////////////////// Set 01 Ends//////////////////////////////////////////////////////////////////////
  ///////////////////////////////////// Set 02 Starts////////////////////////////////////////////////////////////////////
  name_saveAdminUserApi: 'saveAdminUser',
  name_verifyAddressApi: 'verifyAddress',
  name_superAdminLoginApi: 'superAdminLogin',
  name_verifySuperAdminLoginOtpApi: 'verifySuperAdminLoginOtp',
  name_getCompaniesListApi: 'getCompaniesList',
  name_checkExistingSuperAdminEmailApi: 'checkExistingSuperAdminEmail',
  name_getAdminFaqListApi: 'getAdminFaqList',
  name_getAllUsersListApi: 'getAllUsersList',
  name_getAllUsersListByCompanyApi: 'getAllUsersListByCompany',
  name_getAllFaqListApi: 'getAllFaqList',
  name_getAllFaqListByCompanyApi: 'getAllFaqListByCompany',
  name_getAllAdminUsersListApi: 'getAllAdminUsersList',
  name_getAllAdminUsersListByCompanyApi: 'getAllAdminUsersListByCompany',
  name_deactivateUserApi: 'deactivateUser',
  name_activateUserApi: 'activateUser',
  name_getAdminDocumentsListApi: 'getAdminDocumentsList',
  name_deactivateDocApi: 'deactivateDoc',
  name_activateDocApi: 'activateDoc',
  name_saveAdminDocApi: 'saveAdminDoc',
  name_modifyAdminDocApi: 'modifyAdminDoc',
  name_getAdminLogsListApi: 'getAdminLogsList',
  name_changeShowOnConfirmationStatusApi: 'changeShowOnConfirmationStatus',
  name_getActiveAdminDocumentsListApi: 'getActiveAdminDocumentsList',
  fetchAccountStatus: 'fetchAccountStatus',
  ///////////////////////////////////// Set 02 Ends//////////////////////////////////////////////////////////////////////
  ///////////////////////////////////// Set 03 Starts////////////////////////////////////////////////////////////////////
  name_addDocGroupApi: 'addDocGroup',
  name_findDocGroupsByCompanyApi: 'findDocGroupsByCompany',
  name_getPhysiciansByStateCodeApi: 'getPhysiciansByStateCode',
  name_getStateHippaApi: 'getStateHippa',
  name_getEmailLogsApi: 'getEmailLogs',
  name_getEmailLogsByCompanySlugApi: 'getEmailLogsByCompanySlug',
  name_findDocsByGroupIdApi: 'findDocsByGroupId',
  name_getDocsByEmailLogIdApi: 'getDocsByEmailLogId',
  name_getPhysiciansByCityCodeApi: 'getPhysiciansByCityCode',
  name_getUserDetailApi: 'getUserDetail',
  name_findPhysicianCitiesApi: 'findPhysicianCities',
  name_findLogDetailApi: 'findLogDetail',
  name_getDocumentByIdApi: 'getDocumentById',
  name_findAllDocumentsApi: 'findAllDocuments',
  name_findDefaultDocumentsApi: 'findDefaultDocuments',
  name_findDocsBySubGroupApi: 'findDocsBySubGroup',
  name_findTempDocsBySubGroupApi: 'findTempDocsBySubGroup',
  name_getUploadedDocumentsList: 'getUploadedDocumentsList',
  name_deactivateUserDocsApi: 'deactivateUserDocs',
  name_getCompanyDefaultGroupApi: 'getCompanyDefaultGroup',
  name_getSignatureGroupsApi: 'getSignatureGroups',
  name_getNIPDetailsApi: 'getNIPDetails',
  name_doctorRregistrationApi: 'doctorRregistration',
  name_verifyDoctorRegistrationOtpApi: 'verifyDoctorRegistrationOtp',
  name_checkDocActiveUrlApi: 'checkDocActiveUrl',
  name_findExistingDocApi: 'findExistingDoc',
  name_findDoctorByUserIdApi: 'findDoctorByUserId',
  name_getAllPhysiciansApi: 'getAllPhysicians',
  ///////////////////////////////////// Set 03 Ends//////////////////////////////////////////////////////////////////////
  ///////////////////////////////////// Set 04 Starts//////////////////////////////////////////////////////////////////////
  name_generateDocSignatureImageApi: 'generateDocSignatureImage',
  name_getAcknowledgmentDocumentsList: 'getAcknowledgmentDocumentsList',
  name_authenticateDoctorApi: 'authenticateDoctor',

  name_registrationNormalApi: 'registrationNormal',
  name_verifyRegistrationOtpNormalApi: 'verifyRegistrationOtpNormal',
  name_invitePhysicainApi: 'invitePhysicain',
  name_doctorRregistrationNormalApi: 'doctorRregistrationNormal',
  name_doctorRregistrationNormalNoInvitationApi:
    'doctorRregistrationNormalNoInvitation',
  name_verifyDoctorRegistrationOtpNormalApi:
    'verifyDoctorRegistrationOtpNormal',
  name_updateDemographicDetailsGeneralApi: 'updateDemographicDetailsGeneral',
  name_saveDemographicFormApiGeneral: 'saveDemographicFormGeneral',
  name_getSignatureDocsByCompanyGeneralApi: 'getSignatureDocsByCompanyGeneral',
  name_findUserDoctorsApi: 'findUserDoctors',
  name_getAllAdminDocumentsListApi: 'getAllAdminDocumentsList',
  name_getAllAdminDocumentsListByCompanyApi:
    'getAllAdminDocumentsListByCompany',
  name_saveBulkPhysiciansApi: 'saveBulkPhysicians',
  name_findCompaniesListApi: 'findCompaniesList',
  name_findUserDemographicGeneralApi: 'findUserDemographicGeneral',
  name_esignEmailLogApi: 'esignEmailLog',
  name_findPhysicianByIdApi: 'findPhysicianById',
  name_findAllRegisteredPhysiciansApi: 'findAllRegisteredPhysicians',
  name_findUserRegisteredPhysiciansApi: 'findUserRegisteredPhysicians',
  name_findPracticePhysiciansApi: 'findPracticePhysicians',
  name_findPhysiciansByCompanySlugApi: 'findPhysiciansByCompanySlug',
  name_findPatientHippaDocsApi: 'findPatientHippaDocs',
  name_saveParentSignatureImageApi: 'saveParentSignatureImage',
  name_findD2dDocumentsApi: 'findD2dDocuments',
  name_findPractices: 'findPractices',
  get_SpecialtyByPractices: 'findSpecialitiesByCompany',
  get_SubspecialtyBySpecialtyAndPractice: 'findSubSpecialitiesByCompany',
  name_findParticipatingDoctorsApi: 'findParticipatingDoctors',
  name_findPhysicainHippaApi: 'findPhysicainHippa',
  name_findAllAllowedDocumentsApi: 'findAllAllowedDocuments',
  name_findAllAllowedUploadedDocumentsApi: 'findAllAllowedUploadedDocuments',
  name_findAllAllowedDoctorAcknowledgmentsApi:
    'findAllAllowedDoctorAcknowledgments',
  name_findAllAllowedGroupDocsApi: 'findAllAllowedGroupDocs',
  name_getPhyAcknowledgmentDocumentsList: 'getPhyAcknowledgmentDocuments',
  name_findPhysicianDetailsByUserIdApi: 'findPhysicianDetailsByUserId',
  name_expertOpinionApi: 'expertOpinion',
  name_findVideoDocsByUserApi: 'findVideoDocsByUser',
  name_findDelegationsByAdminApi: 'findDelegationsByAdmin',
  ///////////////////////////////////// Set 04 Ends//////////////////////////////////////////////////////////////////////
  ///////////////////////////////////// PHP Starts///////////////////////////////////////////////////////////////////////
  php_generateDemographicPdfApi: 'generateDemographicPdf.php',
  php_generateHippaRealeasPdfApi: 'generateHippaRealeasPdf.php',
  php_generateWaiverDisclaimerPdfApi: 'generateWaiverDisclaimerPdf.php',
  php_generateSignatureImageApi: 'generateSignatureImage.php',
  php_sendSignOffMailApi: 'sendSignOffMail.php',
  php_sendSignOffMail_updateApi: 'sendSignOffMail_update.php',
  php_sendOtpApi: 'sendOtp.php',
  ahs_uploadFile2ElemrexApi: 'uploadFile2Elemrex.php',
  php_showHippaDocApi: 'showHippaDoc.php',
  php_showDisclaimerDocApi: 'showDisclaimerDoc.php',
  php_uploadFileApi: 'uploadFile.php',
  php_uploadEsignFileApi: 'uploadEsignFile.php',
  php_findMailingDocsApi: 'findMailingDocs.php',
  php_sendDocsApi: 'sendDocs.php',
  php_emailDoctorApi: 'emailDoctor.php',
  php_adminSendDocsApi: 'adminSendDocs.php',
  php_resendDocsApi: 'resendDocs.php',
  php_adminResendDocsApi: 'adminResendDocs.php',
  php_saveDocGroupApi: 'saveDocGroup.php',
  php_updateProfileApi: 'updateProfile.php',
  php_updateUserDocGroupApi: 'updateUserDocGroup.php',
  php_saveAdminDocNewApi: 'saveAdminDocNew.php',
  php_modifyAdminDocNewApi: 'modifyAdminDocNew.php',
  php_setDefaultGroupApi: 'setDefaultGroup.php',
  php_generateSignatureFilesPdfApi: 'generateSignatureFilesPdf.php',
  php_deactivateUserDocsApi: 'deactivateUserDocs.php',
  php_sendDoctorSignUpMainApi: 'sendDoctorSignUpMain.php',
  php_cleanTempDocsApi: 'cleanTempDocs.php',
  php_deleteDocApi: 'deleteDoc.php',
  php_allotDocGroupApi: 'allotDocGroup.php',
  php_sendInvitationApi: 'sendInvitation.php',
  php_checkInvitationApi: 'checkInvitation.php',
  php_findUserSignDocsApi: 'findUserSignDocs.php',
  php_updateDoctorProfileApi: 'updateDoctorProfile.php',
  php_sendUSHFApi: 'sendUSHF.php',
  php_findSecondarySpeByNPI: 'findSecondarySpeByNPI.php',
  php_findUserDetailsApi: 'findUserDetails.php',
  php_updateAdminProfileApi: 'updateAdminProfile.php',
  php_findUserDetailsByUserHashApi: 'findUserDetailsByUserHash.php',
  php_generateDocSignatureFilePdfApi: 'generateDocSignatureFilePdf.php',
  php_generateViewDocSignatureFilePdfApi: 'generateViewDocSignatureFilePdf.php',
  php_invitePhysicainApi: 'invitePhysicain.php',
  php_saveUserDoctorsApi: 'saveUserDoctors.php',
  php_setUserDocGroupApi: 'setUserDocGroup.php',
  php_uploadEsignMultipleFilesApi: 'uploadEsignMultipleFiles.php',
  php_findAcknowledgementStatusApi: 'findAcknowledgementStatus.php',
  php_sendPractieInvitationMailApi: 'sendPractieInvitationMail.php',
  php_findPracticePhysiciansApi: 'findPracticePhysicians.php',
  php_findNPIInfoByEnEmailApi: 'findNPIInfoByEnEmail.php',
  php_searchPhysiciansApi: 'searchPhysicians.php',
  php_getUploadedDocumentsListApi: 'getUploadedDocumentsList.php',
  php_findUserSignatureApi: 'findUserSignature.php',
  php_sendHippaNoMatchMailApi: 'sendHippaNoMatchMail.php',
  php_findPhysicianEmailApi: 'findPhysicianEmail.php',
  php_findUserByHashedEmailApi: 'findUserByHashedEmail.php',
  php_contactElemrixApi: 'contactElemrix.php',
  php_removeUserDoctorApi: 'removeUserDoctor.php',
  php_findUserPhysicianByCompanyApi: 'findUserPhysicianByCompany.php',
  php_searchPhysicianByNameStateApi: 'searchPhysicianByNameState.php',
  php_findUserPhysicianByIdApi: 'findUserPhysicianById.php',
  php_sendEmail2AllApi: 'sendEmail2All.php',
  php_sendEmail2UsersApi: 'sendEmail2Users.php',


  php_generateHippaNJApi: 'generateHippaNJ.php',
  php_generateHippaPAApi: 'generateHippaPA.php',
  php_generateHippaNYApi: 'generateHippaNY.php',
  php_generateHippaFLApi: 'generateHippaFL.php',
  php_generateHippaCTApi: 'generateHippaCT.php',
  php_generateHippaOHApi: 'generateHippaOH.php',
  php_generateHippaCOApi: 'generateHippaCO.php',
  php_generateHippaCAApi: 'generateHippaCA.php',
  php_generateHippaALApi: 'generateHippaAL.php',
  php_generateHippaAZApi: 'generateHippaAZ.php',
  php_generateHippaARApi: 'generateHippaAR.php',
  php_generateHippaDEApi: 'generateHippaDE.php',
  php_generateHippaGAApi: 'generateHippaGA.php',
  php_generateHippaHIApi: 'generateHippaHI.php',
  php_generateHippaIDApi: 'generateHippaID.php',
  php_generateHippaILApi: 'generateHippaIL.php',
  php_generateHippaAKApi: 'generateHippaAK.php',
  php_generateHippaTXApi: 'generateHippaTX.php',
  php_generateHippaSDApi: 'generateHippaSD.php',
  php_generateHippaNDApi: 'generateHippaND.php',
  php_generateHippaVAApi: 'generateHippaVA.php',
  php_generateHippaVTApi: 'generateHippaVT.php',
  php_generateHippaKYApi: 'generateHippaKY.php',
  php_generateHippaWIApi: 'generateHippaWI.php',
  php_generateHippaWAApi: 'generateHippaWA.php',
  php_generateHippaTNApi: 'generateHippaTN.php',
  php_generateHippaLAApi: 'generateHippaLA.php',
  php_generateHippaMDApi: 'generateHippaMD.php',
  php_generateHippaMNApi: 'generateHippaMN.php',
  php_generateHippaMOApi: 'generateHippaMO.php',
  php_generateHippaMSApi: 'generateHippaMS.php',
  php_generateHippaNVApi: 'generateHippaNV.php',
  php_generateHippaOKApi: 'generateHippaOK.php',
  php_generateHippaMAApi: 'generateHippaMA.php',
  php_generateHippaNHApi: 'generateHippaNH.php',
  php_generateHippaKSApi: 'generateHippaKS.php',
  php_generateHippaIAApi: 'generateHippaIA.php',
  php_generateHippaMTApi: 'generateHippaMT.php',
  php_generateHippaNEApi: 'generateHippaNE.php',
  php_generateHippaORApi: 'generateHippaOR.php',
  php_generateHippaNCApi: 'generateHippaNC.php',
  php_generateHippaSCApi: 'generateHippaSC.php',
  php_generateHippaMEApi: 'generateHippaME.php',
  php_generateHippaRIApi: 'generateHippaRI.php',
  php_generateHippaUTApi: 'generateHippaUT.php',
  php_generateHippaNMApi: 'generateHippaNM.php',
  php_generateHippaINApi: 'generateHippaIN.php',
  php_generateHippaWVApi: 'generateHippaWV.php',

  php_findUserDetailsByD2DIdApi: 'findUserDetailsByD2DId.php',
  php_generateD2DDocSignatureFilePdfApi: 'generateD2DDocSignatureFilePdf.php',
  php_docotr2doctorEmail06Api: 'docotr2doctorEmail06.php',
  php_docotr2doctorEmail_PatientApi: 'docotr2doctorEmail_Patient.php',
  php_findPatientOpinionLogApi: 'findPatientOpinionLog.php',
  php_findMinorActivityLogApi: 'findMinorActivityLog.php',
  php_findD2DLogDetailApi: 'findD2DLogDetail.php',
  php_findParticipatingDoctorsBySubSpecialityApi: 'findParticipatingDoctorsBySubSpeciality.php',
  php_findHippaShareRequestByPatientIdApi: 'findHippaShareRequestByPatientId.php',
  php_findHippaShareRequestLogApi: 'findHippaShareRequestLog.php',
  php_changeHippaRequestStatusApi: 'changeHippaRequestStatus.php',
  php_saveTempPatientExpertOpinionApi: 'saveTempPatientExpertOpinion.php',
  php_getTempPatientExpertOpinionApi: 'getTempPatientExpertOpinion.php',
  php_findLogDetail1Api: 'findLogDetail1.php',
  php_getPhysiciansByCityCodeApi: 'getPhysiciansByCityCode.php',
  php_sendD2dResponseApi: 'sendD2dResponse.php',
  php_findD2DUserDetailsByUserHashApi: 'findD2DUserDetailsByUserHash.php',
  php_removeUserPracticeApi: 'removeUserPractice.php',
  php_findUserPracticeApi: 'findUserPractice.php',
  php_findUserDoctorsByPracticeApi: 'findUserDoctorsByPractice.php',
  php_findUserDoctorsApi: 'findUserDoctors.php',
  php_findUserDocumentGroupsApi: 'findUserDocumentGroups.php',
  php_getAdminEmailLogApi: 'getAdminEmailLog.php',
  php_searchNPIRegistryApi: 'searchNPIRegistry.php',
  php_searchNPIDetailsApi: 'searchNPIDetails.php',
  php_checkNPIDoctorApi: 'checkNPIDoctor.php',
  php_getAppSettingApi: 'getAppSetting.php',
  php_uploadReviewFileApi: 'uploadReviewFile.php',
  php_generateReportApi: 'generateReport.php',
  php_fileUploadServiceApi: 'fileUploadService.php',
  php_updateDemographicDetailsGeneralApi: 'updateDemographicDetailsGeneral.php',
  php_saveDemographicFormApiGeneralApi: 'saveDemographicFormGeneral.php',




  php_createParentMinorRelationshipApi: 'createParentMinorRelationship.php',
  php_removeMinorApi: 'removeMinor.php',
  php_searchPracticePhysiciansApi: 'searchPracticePhysicians.php',
  php_resendEmailLogApi: 'resendEmailLog.php',



  php_updatePatientDemographicTempApi: 'update_patient_demographic_temp.php',

  php_generate_demographic_temp_Api: 'generate_demographic_temp.php',
  php_generate_signature_files_pdf_temp_Api: 'generate_signature_files_pdf_temp.php',


  php_get_demographic_temp_Api: 'get_demographic_temp.php',
  php_get_user_docs_temp_Api: 'get_user_docs_temp.php',

  php_emailDoctor_Chat: 'emailDoctor_Chat.php',
  php_uploadAzureFile: 'uploadAzureFile.php',
  php_docotr2doctorEmail_Chat: 'docotr2doctorEmail_Chat.php',
  php_schedule_appointment: 'schedule_appointment.php',



  php_updateLowDemographicDataApi: 'updateLowDemographicDataApi.php',
  php_getUserDetail_SLS05Api: 'getUserDetail_SLS05.php',

  php_shareDocuments : 'shareDocuments.php',
  php_fetchAllDocumentsForPatientHistory : 'fetchAllDocumentsForPatientHistory.php',


  ///////////////////////////////////// PHP Ends/////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////// Set 05 Starts//////////////////////////////////////////////////////////////////////

  name_saveSignatureImageApi: 'saveSignatureImage',
  name_generateViewDocSignatureImageApi: 'generateViewDocSignatureImage',
  getPatientUploadedDocumentsList: 'getUploadedDocumentsList',
  getPatientHippaDocumentsList: 'getHippaDocumentsList',
  getRecordingDocumentsList: 'getRecordingDocumentsList',
  getPhysicianUploadedDocumentsList: 'getPhysicianUploadedDocumentsList',


  getPatientImageDocumentsList: 'getImageDocumentsList',
  getPatientVideoDocumentsList: 'getVideoDocumentsList',
  getPatientAllDocumentsList: 'getAllDocumentsList',
  getPatientAcknowledgmentDocumentsList: 'getAcknowledgmentDocumentsList',
  getMinorsByParent: 'getMinorsByParent',
  searchMinors: 'searchMinors',
  removeMinor: 'removeMinor',
  getAppDetails: 'getAppDetails',

  patientRegistration: 'patientRegistration',
  verifyPatientRegistrationOTP: 'verifyPatientRegistrationOTP',
  createParentMinorRelationship: 'createParentMinorRelationship',
  getAdminEmailLogs: 'getAdminEmailLogs',
  getPatientEmailLogs: 'getPatientEmailLogs',
  getPractices: 'getPractices',
  getAllPractices: 'getAllPractices',
  getPhysicianEmail: 'getPhysicianEmail',
  getPhysicianEmailLogs: 'getPhysicianEmailLogs',
  getPhysicianUploadDocuments: 'getPhysicianUploadDocuments',
  getPhysicianByCompany: 'getPhysiciansByCompanySlug',
  getSpecialties: 'getSpecialties',
  getSubSpecialties: 'getSubSpecialties',
  getCoeBySubSpeciality: 'getCoeBySubSpeciality',
  getCoePhysicians: 'getCoePhysicians',
  createPractice: 'createPractice',
  informPhysician: 'informPhysician',
  sendUserQuery: 'sendUserQuery',
  updateUserPassword: 'updateUserPassword',
  searchParents: 'searchParents',
  generateTempSignatureImage: 'generateTempSignatureImage',
  getAllDocumentsList: 'getAllDocumentsList',
  getTopDocumentsList: 'getTopDocumentsList',
  FindUserRegisteredDoctorDetails: 'FindUserRegisteredDoctorDetails',
  findUserDoctorDetails: 'findUserDoctorDetails',
  getDocumentConfirmObjectionPatientHistory: 'getDocumentConfirmObjectionPatientHistory',
  documentConfirmObjectionPatientHistory: 'documentConfirmObjectionPatientHistory',
  getPatientsNonTreatingDoctorsList: 'getPatientsNonTreatingDoctorsList',
  getPatientsTreatingDoctorsList: 'getPatientsTreatingDoctorsList',
  updatePatientDetailsByPhysician: 'updatePatientDetailsByPhysician',
  getPatientDetailsById: 'getPatientDetailsById',
  generateAiSummaryByClick: 'generateAiSummaryByClick',
  getDocumentCategories: 'getDocumentCategories',
  findPracticeDocuments: 'findPracticeDocuments',
  findDictationFiles: 'findDictationFiles',
  findPatientDictationFiles : 'findPatientDictationFiles',
  updateSecurityQuestions : 'updateSecurityQuestions',
  forgotPassword : 'forgotPassword',
  getLoginCount : 'getLoginCount',
  resetBlockedAccount : 'resetBlockedAccount',
  incrementForgotCounter : 'incrementForgotCounter',
  checkPasswordHistory : 'checkPasswordHistory',
  searchPracticePhysicians : 'searchPracticePhysicians',
  ///////////////////////////////////// Set 05 Ends//////////////////////////////////////////////////////////////////////


  ///////////////////////////////////// Set 06 Starts//////////////////////////////////////////////////////////////////////
  generateHipaaNJ: 'generateHipaaNJ',
  generateHipaaAL: 'generateHipaaAL',
  generateHipaaAK: 'generateHipaaAK',
  generateHipaaWV: 'generateHipaaWV',
  generateHipaaWI: 'generateHipaaWI',
  generateHipaaWA: 'generateHipaaWA',
  generateHipaaTX: 'generateHipaaTX',
  generateHipaaUT: 'generateHipaaUT',
  generateHipaaNV: 'generateHipaaNV',
  generateHipaaOR: 'generateHipaaOR',
  generateHipaaPA: 'generateHipaaPA',
  generateHipaaKY: 'generateHipaaKY',
  generateHipaaMS: 'generateHipaaMS',
  generateHipaaMA: 'generateHipaaMA',
  generateHipaaNY: 'generateHipaaNY',
  generateHipaaOK: 'generateHipaaOK',
  generateHipaaME: 'generateHipaaME',
  generateHipaaLA: 'generateHipaaLA',
  generateHipaaAR: 'generateHipaaAR',
  generateHipaaAZ: 'generateHipaaAZ',
  generateHipaaCA: 'generateHipaaCA',
  generateHipaaCO: 'generateHipaaCO',
  generateHipaaCT: 'generateHipaaCT',
  generateHipaaDE: 'generateHipaaDE',
  generateHipaaFL: 'generateHipaaFL',
  generateHipaaGA: 'generateHipaaGA',
  generateHipaaHI: 'generateHipaaHI',
  generateHipaaIA: 'generateHipaaIA',
  generateHipaaID: 'generateHipaaID',
  generateHipaaIL: 'generateHipaaIL',
  generateHipaaSC: 'generateHipaaSC',
  generateHipaaNC: 'generateHipaaNC',
  generateHipaaMD: 'generateHipaaMD',
  generateHipaaMN: 'generateHipaaMN',
  generateHipaaMO: 'generateHipaaMO',
  generateHipaaMT: 'generateHipaaMT',
  generateHipaaND: 'generateHipaaND',
  generateHipaaNE: 'generateHipaaNE',
  generateHipaaNH: 'generateHipaaNH',
  generateHipaaNM: 'generateHipaaNM',
  generateHipaaOH: 'generateHipaaOH',
  generateHipaaRI: 'generateHipaaRI',
  generateHipaaSD: 'generateHipaaSD',
  generateHipaaTN: 'generateHipaaTN',
  generateHipaaVA: 'generateHipaaVA',
  generateHipaaVT: 'generateHipaaVT',
  generateHipaaIN: 'generateHipaaIN',
  generateHipaaKS: 'generateHipaaKS',
  findUserDoctors: 'findUserDoctors',
  getUserDocsWhoReactivatedPatient:'getUserDocsWhoReactivatedPatient',
  reActiveDoctor:'reActiveDoctor',
  removeUserPractice: 'removeUserPractice',
  contactElemrix: 'contactElemrix',
  findUserDocumentGroups: 'findUserDocumentGroups',
  ///////////////////////////////////// Set 06 Ends//////////////////////////////////////////////////////////////////////
};
