import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {environment} from './../../environments/environment';
import {ApiHelper} from './../_helpers/api.helper';
import {CommonHelper} from './../_helpers/common.helper';
import {ConstantHelper} from './../_helpers/constant.helper';
import {AppConfig} from './../../app/app.config';

import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(
    private http: HttpClient,
    public commonHelper: CommonHelper,
    private appConfig: AppConfig
  ) { }

  uploadFile(
    fileToUpload,
    userId,
    docType,
    companySlug,
    comapnyId,
    docName,
    isHippByAdmin
  ) {
    const formData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    formData.append('loggedInUserId', userId);
    formData.append('docType', docType);
    formData.append('comapny_slug', companySlug);
    formData.append('comapnyId', comapnyId);
    formData.append('docName', docName);
    formData.append('isHippByAdmin', isHippByAdmin);

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_uploadFileApi,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      )
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return {status: 'progress', message: progress};
            case HttpEventType.Response:
              return event.body;
            default:
              // return `Unhandled event: ${event.type}`;
              return 1;

            // case HttpEventType.Response:
            //   return event.body;
          }
        })
      );
  }

  sendDoc(
    personName,
    personEmail,
    selectedDocsId,
    fileToUpload,
    userId,
    selectedHippaUrls,
    companySlug,
    altEmail,
    selectedStateCode,
    selectedCityName,
    selectedPhysicianId,
    emailLogTxt,
    fileDesTxt,
    docType,
    comapnyId,
    showNewFile,
    attachmentDocObj,
    selectedPhysicianNPI,
    searchType,
    actualLoggedInUserId,
    minorId,
  ) {
    const formData = new FormData();
    if (fileToUpload) {
      formData.append('fileKey', fileToUpload, fileToUpload.name);
    }
    formData.append('loggedInUserId', userId);
    formData.append('personName', personName);
    formData.append('personEmail', personEmail);
    formData.append('selectedDocsId', selectedDocsId);
    formData.append('selectedHippaUrls', selectedHippaUrls);
    formData.append('companySlug', companySlug);
    formData.append('altEmail', altEmail);
    formData.append('selectedStateCode', selectedStateCode);
    formData.append('selectedCityName', selectedCityName);
    formData.append('selectedPhysicianId', selectedPhysicianId);
    formData.append('emailLogTxt', emailLogTxt);
    formData.append('fileDesTxt', fileDesTxt);
    formData.append('docType', docType);
    formData.append('comapnyId', comapnyId);
    formData.append('showNewFile', showNewFile);
    formData.append('attachmentDocObj', attachmentDocObj);
    formData.append('selectedPhysicianNPI', selectedPhysicianNPI);
    formData.append('searchType', searchType);
    formData.append('parentId',actualLoggedInUserId);
    formData.append('minorId',minorId);
    return this.http
      .post(this.appConfig.getPhpUrl() + ApiHelper.php_emailDoctorApi, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.Response:
              return event.body;
          }
        })
      );
  }

  adminSendDoc(
    personName,
    personEmail,
    selectedDocsId,
    fileToUpload,
    loggedInUserId,
    userId,
    selectedHippaUrls,
    companySlug,
    altEmail,
    selectedStateCode,
    selectedCityName,
    selectedPhysicianId,
    emailLogTxt,
    fileDesTxt,
    docType,
    comapnyId,
    showNewFile,
    attachmentDocObj
  ) {
    const formData = new FormData();
    if (fileToUpload) {
      formData.append('fileKey', fileToUpload, fileToUpload.name);
    }
    formData.append('loggedInUserId', loggedInUserId);
    formData.append('userId', userId);
    formData.append('personName', personName);
    formData.append('personEmail', personEmail);
    formData.append('selectedDocsId', selectedDocsId);
    formData.append('selectedHippaUrls', selectedHippaUrls);
    formData.append('companySlug', companySlug);
    formData.append('altEmail', altEmail);
    formData.append('selectedStateCode', selectedStateCode);
    formData.append('selectedCityName', selectedCityName);
    formData.append('selectedPhysicianId', selectedPhysicianId);
    formData.append('emailLogTxt', emailLogTxt);
    formData.append('fileDesTxt', fileDesTxt);
    formData.append('docType', docType);
    formData.append('comapnyId', comapnyId);

    formData.append('showNewFile', showNewFile);
    formData.append('attachmentDocObj', attachmentDocObj);

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_adminSendDocsApi,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      )
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.Response:
              return event.body;
          }
        })
      );
  }

  resendDoc(personName, personEmail, userId, logId, companySlug) {
    const formData = new FormData();
    formData.append('loggedInUserId', userId);
    formData.append('personName', personName);
    formData.append('personEmail', personEmail);
    formData.append('logId', logId);
    formData.append('companySlug', companySlug);

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_resendDocsApi,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      )
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.Response:
              return event.body;
          }
        })
      );
  }

  adminResendDoc(
    personName,
    personEmail,
    altEmail,
    userId,
    logId,
    companySlug,
    createdById,
    selectedStateCode,
    selectedCityName,
    selectedPhysicianId,
    emailLogTxt,
    fileToUpload,
    fileDesTxt,
    docType,
    comapnyId,
    showNewFile,
    attachmentDocObj,
    selectedPhysicianNPI
  ) {

    const formData = new FormData();
    if (fileToUpload) {
      formData.append('fileKey', fileToUpload, fileToUpload.name);
    }

    formData.append('loggedInUserId', userId);
    formData.append('personName', personName);
    formData.append('personEmail', personEmail);
    formData.append('altEmail', altEmail);
    formData.append('logId', logId);
    formData.append('companySlug', companySlug);
    formData.append('createdById', createdById);
    formData.append('selectedStateCode', selectedStateCode);
    formData.append('selectedCityName', selectedCityName);
    formData.append('selectedPhysicianId', selectedPhysicianId);
    formData.append('emailLogTxt', emailLogTxt);
    formData.append('fileDesTxt', fileDesTxt);
    formData.append('docType', docType);
    formData.append('comapnyId', comapnyId);

    formData.append('showNewFile', showNewFile);
    formData.append('attachmentDocObj', attachmentDocObj);
    formData.append('selectedPhysicianNPI', selectedPhysicianNPI);

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_adminResendDocsApi,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      )
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.Response:
              return event.body;
          }
        })
      );
  }

  saveDocGroup(userId, companySlug, groupId, docsId, newGroup) {
    const formData = new FormData();

    if (groupId <= 0) {
      formData.append('newGroup', newGroup);
    }
    formData.append('loggedInUserId', userId);
    formData.append('companySlug', companySlug);
    formData.append('groupId', groupId);
    formData.append('docsId', docsId);
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_saveDocGroupApi,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      )
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.Response:
              return event.body;
          }
        })
      );
  }

  saveAdminDoc(
    loggedInUserId,
    comapnySlug,
    docName,
    docDescription,
    confirmationStatus,
    selectedFile,
    docType,
    comapnyId
  ) {
    const formData = new FormData();
    formData.append('fileKey', selectedFile, selectedFile.name);
    formData.append('comapnySlug', comapnySlug);
    formData.append('docName', docName);
    formData.append('docDescription', docDescription);
    formData.append('confirmationStatus', confirmationStatus);
    formData.append('loggedInUserId', loggedInUserId);
    formData.append('docType', docType);
    formData.append('comapnyId', comapnyId);
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_saveAdminDocNewApi,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      )
      .pipe(
        map((event) => {
          switch (event.type) {
            // case HttpEventType.UploadProgress:
            //   const progress = Math.round(100 * event.loaded / event.total);
            //   return { status: 'progress', message: progress };
            // case HttpEventType.Response:
            //   return event.body;
            // default:
            //   return `Unhandled event: ${event.type}`;

            case HttpEventType.Response:
              return event.body;
          }
        })
      );
  }

  modifyAdminDoc(
    loggedInUserId,
    comapny_slug,
    docName,
    docDescription,
    confirmationStatus,
    selectedFile,
    docId,
    hasDoc,
    oldDoc,
    docType,
    comapnyId
  ) {
    const formData = new FormData();
    formData.append('fileKey', selectedFile, selectedFile.name);
    formData.append('comapny_slug', comapny_slug);
    formData.append('docName', docName);
    formData.append('docDescription', docDescription);
    formData.append('confirmationStatus', confirmationStatus);
    formData.append('loggedInUserId', loggedInUserId);
    formData.append('docId', docId);
    formData.append('hasDoc', hasDoc);
    formData.append('oldDoc', oldDoc);
    formData.append('docType', docType);
    formData.append('comapnyId', comapnyId);

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_modifyAdminDocNewApi,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      )
      .pipe(
        map((event) => {
          switch (event.type) {
            // case HttpEventType.UploadProgress:
            //   const progress = Math.round(100 * event.loaded / event.total);
            //   return { status: 'progress', message: progress };
            // case HttpEventType.Response:
            //   return event.body;
            // default:
            //   return `Unhandled event: ${event.type}`;

            case HttpEventType.Response:
              return event.body;
          }
        })
      );
  }

  //uploadFileEsign
  uploadFileEsign(
    fileToUpload,
    userId,
    docType,
    selectedDoctorsId,
    docName,
    isHippByAdmin,
    previousDocId
  ) {
    const formData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    formData.append('loggedInUserId', userId);
    formData.append('docType', docType);
    formData.append('selectedDoctorsId', selectedDoctorsId);
    formData.append('docName', docName);
    formData.append('isHippByAdmin', isHippByAdmin);
    formData.append('previousDocId', previousDocId);
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_uploadEsignFileApi,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      )
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return {status: 'progress', message: progress};
            case HttpEventType.Response:
              return event.body;
            default:
              return `Unhandled event: ${event.type}`;
          }
        })
      );
  }

  uploadFileOnS3(file) {
    const contentType = file.type;
    const bucket = new S3({
      accessKeyId: ConstantHelper.AWS_ACCESS_KEY,
      secretAccessKey: ConstantHelper.AWS_SECRET_KEY,
      region: ConstantHelper.AWS_REGION,
    });
    const params = {
      Bucket: ConstantHelper.AWS_BUCKET,
      Key: file.name,
      Body: file,
      ACL: ConstantHelper.AWS_ACL,
      ContentType: contentType,
    };
    bucket.upload(params, function(err, data) {
      if (err) {
        console.error(err);
        return false;
      }
      return true;
    });
  }





  uploadReviewFile(loggedInUserId, demographicId, fileToUpload, docType, docName) {

    const formData = new FormData();
    formData.append('loggedInUserId', loggedInUserId);
    formData.append('demographicId', demographicId);
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    formData.append('docType', docType);
    formData.append('docName', docName);
    return this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_uploadReviewFileApi, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return {status: 'progress', message: progress};
          case HttpEventType.Response:
            return event.body;
          default:
            return `Unhandled event: ${event.type}`;

          // case HttpEventType.Response:
          //   return event.body;
        }


      })
    );
  }


  uploadFileService(
    fileToUpload,
    userId,
  ) {
    // console.log(userId);
    // console.log(fileToUpload.name);
    // console.log(fileToUpload);

    const formData = new FormData();
    formData.append('fileKey1', fileToUpload, fileToUpload.name);
    formData.append('loggedInUserId', userId);
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_fileUploadServiceApi,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      )
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return {status: 'progress', message: progress};
            case HttpEventType.Response:
              return event.body;
            default:
              // return `Unhandled event: ${event.type}`;
              return 1;

            // case HttpEventType.Response:
            //   return event.body;
          }
        })
      );
  }


  // uploadFileService(fileToUpload, userId) {
  //   const formData = new FormData();
  //   const blob = new Blob([fileToUpload], {type: fileToUpload.type});
  //   formData.append('fileKey', blob, fileToUpload.name);
  //   formData.append('loggedInUserId', userId);
  //   return this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_fileUploadServiceApi, formData, {
  //     reportProgress: true,
  //     observe: 'events'
  //   }).pipe(
  //     map((event) => {
  //       switch (event.type) {
  //         case HttpEventType.UploadProgress:
  //           const progress = Math.round((100 * event.loaded) / event.total);
  //           return {status: 'progress', message: progress};
  //         case HttpEventType.Response:
  //           return event.body;
  //         default:
  //           return 1;
  //       }
  //     })
  //   );
  // }


}
