import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UserService } from "./../../_services/user.service";
import { UploadService } from "../../_services/upload.service";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";
import { async } from "@angular/core/testing";
declare let $: any;

@Component({
  selector: "app-doc-review",
  templateUrl: "./doc-review.component.html",
  styleUrls: ["./doc-review.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class DocReviewComponent implements OnInit {
  @ViewChild("fileUploaded", { static: false }) fileUploaded: ElementRef;
  fileToUpload: File = null;
  fileSize = 0;
  fileName = "";
  docType = 4;

  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  hidePdfPopUp = false;
  demographicUrl: SafeResourceUrl;
  hippaUrl: SafeResourceUrl;
  disclaimerUrl: SafeResourceUrl;
  ahsDisclaimerPdf: SafeResourceUrl;
  ahsHippaPdf: SafeResourceUrl;
  showDocThumbnails = true;
  ahsPdfUrl: SafeResourceUrl = "";
  documentsLength = false;
  documentsList: any;
  docGroupId: any;
  docGroupTitle: any;
  documentSetType: any = "E";
  docSet: any = "";
  physicianUserId: any;
  docTyep: any;
  companyDefaultGroupTitle: any;
  companyDefaultGroupId: any;
  activeGroupId: any;
  selectedDocId: any;
  signedGroups: any = [];
  signedGroupsCount: any;
  showLoader = false;
  isUploadAllowed = 0;
  documentMessage = "";
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  profileObj: any = {};
  showDeleteButton = false;
  showHippaUpdateBtn: string;
  doctorLength = false;
  doctorList: any = [];
  seleteDocumetBase64: any = "";
  seleteDocumetUrl: any = "";
  selectedFileExt: any = "";
  filename: any = "file.png";
  onBehalfOfAlertDocumentReview = "Y";
  message = Message;
  videoLink: any;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public userService: UserService,
    public sweetAlertHelper: SweetAlertHelper,
    private uploadService: UploadService
  ) {
    this.showHippaUpdateBtn = localStorage.getItem("showHippaUpdateBtn");
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.demographicId = localStorage.getItem("MinorDemographicId")
      ? localStorage.getItem("MinorDemographicId")
      : this.activatedRoute.snapshot.paramMap.get("demographicId");
  }

  async ngAfterViewInit() {
    var item = localStorage.getItem("FullName");
    if (
      this.onBehalfOfAlertDocumentReview === "Y" &&
      localStorage.getItem("isDelegating") === "1"
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.ngAfterViewInit + " " + item
      );
      // alert(
      //   "Dear User, you are doing this activity on behalf of " +
      //     localStorage.getItem("FullName")
      // );

      localStorage.setItem("onBehalfOfAlertDocumentReview", "N");
    }
  }

  ngOnInit() {
    this.onBehalfOfAlertDocumentReview = localStorage.getItem(
      "onBehalfOfAlertDocumentReview"
    )
      ? localStorage.getItem("onBehalfOfAlertDocumentReview")
      : "Y";

    $(document).ready(function () {
      $(window).resize(function () {
        setHeightResizeFunc();
      });
      setHeightResizeFunc();
    });

    function setHeightResizeFunc() {
      const rightHeight =
        $(".right-box .ibox-title").height() +
        $(".right-box .ibox-content").height() -
        45;
      $(".docLists").height(rightHeight);
    }

    this.findCompanyDefaultGroup(this.loggedInUserId);
    this.findSignedGroups(this.loggedInUserId);
    this.findUserDetails(this.loggedInUserId);
    this.findUserDoctors();
    window.scroll(0,0)
  }

  findUserDetails(userId) {
    const finalData = {
      company_slug: null,
      id: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.docGroupId =
              apiResponse.commandResult.data.userInfo.DocGroupId;
            this.docGroupTitle =
              apiResponse.commandResult.data.userInfo.GroupTitle;
            this.profileObj = apiResponse.commandResult.data.userInfo;
            this.activeGroupId = this.profileObj.DocGroupId;
            this.docSet = this.profileObj.DocGroupId;
            this.fnFindGroupDocs();
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findCompanyDefaultGroup(userId) {
    const finalData = {
      company_slug: null,
      loggedInUserId: userId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getCompanyDefaultGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.companyDefaultGroupTitle =
            apiResponse.commandResult.data.companyDefaultGroup.GroupTitle;
          this.companyDefaultGroupId =
            apiResponse.commandResult.data.companyDefaultGroup.Id;
          this.fnFindGroupDocs();
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  showDemographicForm() {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
    };
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_generateDemographicPdfApi,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.success == 1) {
            // console.log(this.resultJsonObj.commandResult.data);

            // this.demographicUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );
          } else {
            // alert("oops! error occured, please try later");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.onSubmit
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  showHippaForm() {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
    };

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_generateHippaRealeasPdfApi,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.success == 1) {
            this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );
          } else {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.onSubmit
            );
            // alert("oops! error occured, please try later");
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  showDisclaimerForm() {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
    };

    return this.http
      .post(
        this.appConfig.getPhpUrl() +
          ApiHelper.php_generateWaiverDisclaimerPdfApi,
        finalData,
        options
      )
      .subscribe(
      async  (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.success == 1) {
            // console.log(apiResponse);
            // this.disclaimerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );
          } else {
            // alert("oops! error occured, please try later");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.onSubmit
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goToDemographicForm() {
    this.router.navigate(["/demographicsform/" + this.loggedInUserId]);
  }

  async editDemographicForm5() {
    // if (
    //   confirm("Are you sure, you want to modify your demographic information.")
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.docReview.editDemographicForm5
    );
    if (alertStatus) {
      this.router.navigate([
        "/edit-demographic-5/" + this.loggedInUserId + "/" + this.demographicId,
      ]);
    }
  }

  async editSignature() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure, you want to change your signature."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.docReview.editSignature
    );
    if (alertStatus) {
      this.router.navigate([
        "/signaturewaiver/" + this.loggedInUserId + "/" + this.demographicId,
      ]);
    }
  }

  showDocument(
    documentId,
    documentUrl,
    docTyep,
    isUploadAllowed,
    documentMessage
  ) {
    this.selectedFileExt = this.getFileExtension(documentUrl).toLowerCase();
    this.selectedDocId = documentId;
    this.docTyep = docTyep;
    this.isUploadAllowed = isUploadAllowed;
    this.documentMessage = documentMessage ? documentMessage : "some message";

    if (this.selectedFileExt == "doc" || this.selectedFileExt == "docx") {
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        "https://docs.google.com/gview?url=" + documentUrl + "&embedded=true"
      );
    } else {
      this.ahsPdfUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
    }

    // if (this.selectedFileExt == 'jpeg' || this.selectedFileExt == 'jpg' || this.selectedFileExt == 'png') {
    //   this.http.get(documentUrl, {responseType: 'blob'})
    //     .subscribe(response => {
    //       const reader = new FileReader();
    //       reader.onloadend = () => {
    //         const base64Data = reader.result as string;
    //         const base64result = base64Data.split(',')[1];
    //         this.seleteDocumetBase64 = base64result;
    //       };
    //       reader.readAsDataURL(response);
    //     });
    // }

    if (this.selectedFileExt === 'jpeg' || this.selectedFileExt === 'jpg' || this.selectedFileExt === 'png' || this.selectedFileExt === 'mp4') {
      if (this.selectedFileExt === 'mp4') {
        // const video = document.createElement('video');
        this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
        console.log("video link:", this.videoLink)
        // this.videoLink.controls = true;
        const getSafeVideoUrl = () => {
          return this.sanitizer.bypassSecurityTrustResourceUrl(this.videoLink);
        }
      } else {
        this.http.get(documentUrl, { responseType: 'blob' })
          .subscribe(response => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64Data = reader.result as string;
              console.log("reader.result as string" + base64Data)
              const base64result = base64Data.split(',')[1];
             console.log("base64result     " + base64Data)
              this.seleteDocumetBase64 = base64Data;
            };
            reader.readAsDataURL(response);
          });
      }
    }



    this.seleteDocumetUrl = documentUrl;
    this.showDeleteButton = true;
  }

  toDataURL(url, callback) {
    let xhr = new XMLHttpRequest();
    xhr.onload = function () {
      let reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.send();
  }

  async onReset() {
    // if (
    //   // confirm(
    //   //   "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   // )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.ushfComp.onReset
    // );

    // if (alertStatus) {
      this.router.navigate([
        "/dashboard/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    // }
  }

  findDocumntByPhysicain(documentSetType) {
    this.seleteDocumetUrl = "";
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.showDeleteButton = false;
    if (this.docSet == "A") {
      this.fnFindAllDocuments();
    } else if (this.docSet == "U") {
      this.fnFindUploadedDocs();
    } else if (this.docSet == "DS") {
      this.fnFindDoctorAcknowledgments();
    } else if (this.docSet == "HD") {
      this.fnFindHippaDocuments();
    } else if (this.docSet == "ID") {
      this.fnFindImageDocuments();
    } else if (this.docSet == "VD") {
      this.fnFindVideoDocuments();
    } else {
      this.activeGroupId = this.docSet;
      this.fnFindGroupDocs();
    }
  }

  selectDocGroup(docSet) {
    this.docSet = docSet;
    this.seleteDocumetUrl = "";
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.showDeleteButton = false;
    if (this.docSet == "A") {
      this.fnFindAllDocuments();
    } else if (this.docSet == "U") {
      this.fnFindUploadedDocs();
    } else if (this.docSet == "DS") {
      this.fnFindDoctorAcknowledgments();
    } else if (this.docSet == "HD") {
      this.fnFindHippaDocuments();
    } else if (this.docSet == "ID") {
      this.fnFindImageDocuments();
    } else if (this.docSet == "VD") {
      this.fnFindVideoDocuments();
    } else if (this.docSet == "RD") {
      this.fnFindRecordingDocuments();
    } else if (this.docSet == "PU") {
      this.fnFindPhysicianUploadedDocuments();
    } else {
      this.activeGroupId = this.docSet;
      this.fnFindGroupDocs();
    }
  }

  fnFindActiveAdminDocuments() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: null,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl02() +
          ApiHelper.name_getActiveAdminDocumentsListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindAllDocuments() {
    const finalData = {
      userId: this.loggedInUserId,
      company_slug: null,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getPatientAllDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log('All Documents', this.resultJsonObj);
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindDefaultDocuments() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: null,
      email: this.profileObj.Email,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_findDefaultDocumentsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log('Default Documents', this.resultJsonObj);

          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindGroupDocs() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: null,
      doc_group_id: this.activeGroupId,
      default_group_id: this.companyDefaultGroupId,
      email: this.profileObj.Email,
      physicianId: this.documentSetType,
    };

    // console.log(finalData)
    // return;
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findDocsBySubGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  fnFindUploadedDocs() {
    const finalData = {
      userId: this.loggedInUserId,
      docType: ConstantHelper.DOC_TYPE_USER_UPLOADED,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    // console.log(finalData);
    // return;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
          ApiHelper.getPatientUploadedDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindHippaDocuments() {
    const finalData = {
      userId: this.loggedInUserId,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    // console.log(finalData);
    // return;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
          ApiHelper.getPatientHippaDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindImageDocuments() {
    const finalData = {
      userId: this.loggedInUserId,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
          ApiHelper.getPatientImageDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindVideoDocuments() {
    const finalData = {
      userId: this.loggedInUserId,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
          ApiHelper.getPatientVideoDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async deleteDoc() {
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to delete"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.docReview.deleteDoc
    );
    if (alertStatus) {
      const finalData = {
        docId: this.selectedDocId,
      };
      return this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_deleteDocApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
            this.showDeleteButton = false;
            this.fnFindUploadedDocs();
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async clearViewr() {
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to clear preview?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.confirmationComp.clearViewr
    );
    if (alertStatus) {
      // this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
      this.selectedFileExt = "";
      this.showDeleteButton = false;
    }
  }

  findSignedGroups(userId) {
    const finalData = {
      company_slug: null,
      loggedInUserId: userId,
    };
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findUserDocumentGroupsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.signedGroups = apiResponse.commandResult.data.DocGroupd;
          this.signedGroupsCount =
            apiResponse.commandResult.data.DocGroupd.length;

          this.fnFindGroupDocs();
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindDoctorAcknowledgments() {
    const finalData = {
      userId: this.loggedInUserId,
      company_slug: null,
      docType: 7,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
          ApiHelper.getPatientAcknowledgmentDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDoctors() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findUserDoctorsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.data.companiesList.length > 0) {
            this.doctorLength = true;
            this.doctorList = apiResponse.commandResult.data.companiesList;
          } else {
            this.doctorLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  // getFileExtension(filename) {
  //   // get file extension
  //   const extension = filename.split(".").pop();
  //   return extension;
  // }
  getFileExtension(filename) {
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(filename)[1];
    if (!extension) {
      // No extension found
      console.log("No extension found");
      return "";
    }
    console.log("Extension", extension);
    return extension.toLowerCase();
  }

  getFileExtensionFromSignedUrl(filename) {
    const actualUrl = filename.split("?");
    const extension = actualUrl[0].split(".").pop();
    return extension;
  }

  async uploadDocument(event) {
    if (this.fileUploaded.nativeElement.value !== "") {
      this.fileToUpload = event.target.files.item(0) as File;
      this.fileSize = Math.round(this.fileToUpload.size / 1024);
      this.fileName = this.fileToUpload.name;
      if (this.fileSize > 61440) {
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.uploadDocComp.uploadFile2
        );
        // alert("File is too Big, please select a file less than 60mb");
        return;
      } else {
        // if (
        //   confirm("PLEASE CONFIRM: Are you sure you want to upload the file?")
        // )
        const alertStatus = await this.sweetAlertHelper.confirmPopUp(
          this.message.confirmTitle,
          this.message.confirmMessages.uploadDocumentComp.uploadFile
        );
        if (alertStatus) {
          this.showLoader = true;
          this.uploadService
            .uploadReviewFile(
              this.loggedInUserId,
              this.demographicId,
              this.fileToUpload,
              this.docType,
              this.fileName
            )
            .subscribe(
              async (data: any) => {
                if (data !== undefined) {
                  this.showLoader = false;
                  this.fileUploaded.nativeElement.value = "";
                  if (data.commandResult.success == 1) {
                    const alertStatus = await this.sweetAlertHelper.alertPopUp(
                      this.message.alertMessages.docReviewComp.uploadDocument
                    );
                    // alert("File successfully upload.");
                  } else {
                    const alertStatus = await this.sweetAlertHelper.alertPopUp(
                      this.message.alertMessages.docReviewComp.uploadDocument1
                    );
                    // alert("Upload failed, try again");
                  }
                }
              },
              (error) => {
                this.showLoader = false;
                this.fileUploaded.nativeElement.value = "";
                console.log(error);
              }
            );
        }
      }
    }
  }


  fnFindRecordingDocuments() {
    const finalData = {
      userId: this.loggedInUserId,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    // console.log(finalData);
    // return;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
        ApiHelper.getRecordingDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }
  fnFindPhysicianUploadedDocuments() {
    const finalData = {
      userId: this.loggedInUserId,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    // console.log(finalData);
    // return;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
        ApiHelper.getPhysicianUploadedDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

}
