import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  HostListener
} from "@angular/core";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";

import { AuthService } from "../auth.service";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";

@Component({
  selector: "app-admin-forgotpassword",
  templateUrl: "./admin-forgotpassword.component.html",
  styleUrls: ["./admin-forgotpassword.component.scss"],
  providers: [AppConfig, CommonHelper]
})
export class AdminForgotpasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  forgotFromSubmitted: boolean = false;
  formErrorMessage: boolean = false;
  userId: number;
  resultJsonObj: any = {};

  showPasswordForm: boolean = true;
  showPasswordMessage: boolean = false;
  showLoader = false;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      emailOrNumber: ["", [Validators.required]]
    });
  }
  get f() {
    return this.forgotPasswordForm.controls;
  }

  onSubmit() {
    this.forgotFromSubmitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let options = {
      headers: httpHeaders
    };
    const finalData = {
      email: this.forgotPasswordForm.value.emailOrNumber
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
        ApiHelper.name_checkExistingAdminEmailApi,
        finalData,
        options
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.status == 1) {
            this.showPasswordForm = false;
            this.showPasswordMessage = true;
          } else {
            this.formErrorMessage = true;
          }
          this.showLoader = false;
        },
        err => {
          // console.log(err);
        }
      );
  }
}
