import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
// import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
  }
  goToLogin() {
    this.router.navigate(["/login/"]);
  }

}
