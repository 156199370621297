import { Component, OnInit } from '@angular/core';
import { SweetAlertHelper } from '../_helpers/sweet.alert.helper';
import { Message } from '../_locale/messages';
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-ahsaichatgpt",
  templateUrl: "./ahsaichatgpt.component.html",
  styleUrls: ["./ahsaichatgpt.component.scss"],
})
export class AhsaichatgptComponent implements OnInit {
  message = Message;
  loggedInUserId = localStorage.getItem("loggedInUserId");
  constructor(
    public sweetAlertHelper: SweetAlertHelper,
    private router: Router
  ) {}
  ngOnInit(): void {}

  async goToHomePage() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.headerComp.goToDashboard
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }
}
