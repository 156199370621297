import { Component, OnInit } from "@angular/core";
// import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";


@Component({
  selector: 'app-modify-admin-doc',
  templateUrl: './modify-admin-doc.component.html',
  styleUrls: ['./modify-admin-doc.component.scss'],
  providers: [AppConfig, CommonHelper]
})
export class ModifyAdminDocComponent implements OnInit {



  loggedInUserId: string;
  docId: string;
  documentName: string;
  resultJsonObj: any = {};
  addAdminObj: any = {};
  docModified: boolean = false;
  selectedFile: File = null;
  companySlug: string;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });
  httpOptions = {
    headers: this.httpHeaders
  };

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get(
      "loggedInUserId"
    );
    this.docId = this.activatedRoute.snapshot.paramMap.get("docId");
    this.documentName = this.activatedRoute.snapshot.paramMap.get(
      "documentName"
    );
    this.companySlug = this.activatedRoute.snapshot.paramMap.get(
      "companySlug"
    );

    this.addAdminObj.docName = this.documentName;
  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
    // console.log(this.selectedFile);
  }

  onSubmit() {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/pdf"
    });
    const options = {
      headers: httpHeaders
    };

    const finalData = new FormData();

    finalData.append("company_slug", this.companySlug);
    finalData.append("selected_file", this.selectedFile);
    finalData.append("doc_name", this.addAdminObj.docName);
    finalData.append("loggedInUserId", this.loggedInUserId);
    finalData.append("confirmationStatus", this.addAdminObj.confirmationStatus);
    finalData.append("doc_id", this.docId);

    return this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_modifyAdminDocApi,
        finalData
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          this.docModified = true;
          setTimeout(() => {
            this.router.navigate(["/documents/" + this.loggedInUserId]);
          }, 2000);
        },
        err => {
          // console.log(err);
        }
      );
  }

  goToDocuments() {
    this.router.navigate(["/documents/" + this.loggedInUserId]);
  }

}
