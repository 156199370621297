import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";

@Component({
  selector: "app-activity-log",
  templateUrl: "./activity-log.component.html",
  styleUrls: ["./activity-log.component.scss"]
})
export class ActivityLogComponent implements OnInit {
  loggedInUserId: string;
  resultJsonObj: any = {};

  noUsersLength: boolean = false;
  loadingUsersLength: boolean = false;
  showChooseCompany: boolean = true;

  logsLength: boolean = false;
  logsList: any;

  companiesLength: boolean = false;
  companiesList: any;

  selectedDocId: string;
  selectedDocUrl: string;
  selectedDocName: string;
  showPdfModal: boolean = false;
  ahsPdfUrl: SafeResourceUrl = "";

  apiURl: string;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });
  httpOptions = {
    headers: this.httpHeaders
  };

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get(
      "loggedInUserId"
    );

    const finalData = {
      loggedInUserId: this.loggedInUserId
    };

    this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_getCompaniesListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.companyList.length > 0) {
            this.companiesLength = true;
            this.companiesList = this.resultJsonObj.commandResult.data.companyList;

          } else {
            this.companiesLength = false;
          }
        },
        err => {
          console.error(err);
        }
      );
  }

  findActivityByCompany(company_slug) {
    this.showChooseCompany = false;
    this.loadingUsersLength = true;
    this.logsLength = false;
    this.noUsersLength = false;
    const finalData = {
      company_slug: company_slug,
      loggedInUserId: this.loggedInUserId
    };

    return this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_getAdminLogsListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.logsList.length > 0) {
            this.logsLength = true;
            this.logsList = this.resultJsonObj.commandResult.data.logsList;
          } else {
            this.logsLength = false;
          }
        },
        err => {
          console.error(err);
        }
      );
  }

  loadDocunent(docId, docName, docUrl) {
    this.selectedDocId = docId;
    this.selectedDocUrl = docUrl;
    this.selectedDocName = docName;
    this.showPdfModal = true;
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.selectedDocUrl
    );
  }

  closePdfModal() {
    this.showPdfModal = false;
  }
}
