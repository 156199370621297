import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";

@Component({
  selector: "app-doc-header",
  templateUrl: "./doc-header.component.html",
  styleUrls: ["./doc-header.component.scss"],
})
export class DocHeaderComponent implements OnInit {
  loggedInUserName: any;
  message = Message;
  demographicId: string;
  loggedInUserId: any;
  loggedInUserRoleId: any;
  showUserLink: boolean = false;
  resultJsonObj: any = {};
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  company_slug: string;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer
  ) {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.findUserDetails();
    this.loggedInUserName = localStorage.getItem("loggedInUserName")
      ? localStorage.getItem("loggedInUserName")
      : localStorage.getItem("loggedInUserEmail");
    this.loggedInUserRoleId = localStorage.getItem("loggedInUserRoleId");
  }

  ngOnInit() {
    $(".dropmenu-link").hover(function () {
      var isHovered = $(this).is(":hover");
      if (isHovered) {
        $(this).children("ul").stop().slideDown(300);
      } else {
        $(this).children("ul").stop().slideUp(300);
      }
    });
    this.loggedInUserName = localStorage.getItem("loggedInUserName");
  }

  async logout(company_slug) {
    if (company_slug == ConstantHelper.COMPANY_SLUG_OTHER) {
      // if (confirm('PLEASE CONFIRM: If you would like to use this program for your own practice, click here to notify AHS Support Team and we will email your practice URL link directly to you. Once you receive the link your registration will be listed under both your name and practice name with one URL. If you do not wish to sign up for future platform use for your practice, your access will continue to be limited to the particular patient\'s information you were asked to review.'))

      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.docHeaderComp.logout
      );
      if (alertStatus) {
        this.sendPractieInvitationMail();
      } else {
        this.router.navigate(["/"]);
      }
    } else {
      this.router.navigate(["/"]);
    }
  }

  async goToDashboard() {
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to go to Home page?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.docHeaderComp.onreset
    );
    if (alertStatus) {
      // this.router.navigate(["/dashboard/" + this.loggedInUserId]);
      window.open(
        "https://" +
          ConstantHelper.COMPANY_SLUG +
          "." +
          ConstantHelper.MAIN_SITE +
          "/doctor/dashboard/" +
          this.loggedInUserId,
        "_self"
      );
    }
  }
  findUserDetails() {
    const finalData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log('userheader', apiResponse)
          localStorage.setItem(
            "loggedInUserId",
            this.resultJsonObj.commandResult.data.userInfo.RecordId
          );
          localStorage.setItem(
            "loggedInUserName",
            this.resultJsonObj.commandResult.data.userInfo.FullName
          );
          localStorage.setItem(
            "loggedInUserEmail",
            this.resultJsonObj.commandResult.data.userInfo.Email
          );
          localStorage.setItem(
            "loggedInUserRoleId",
            this.resultJsonObj.commandResult.data.userInfo.UserRoleId
          );
          localStorage.setItem(
            "loggedInUserComapnyId",
            this.resultJsonObj.commandResult.data.userInfo.ComapnyId
          );
          this.company_slug =
            this.resultJsonObj.commandResult.data.userInfo.CompanySlug;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  sendPractieInvitationMail() {
    const finalData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_sendPractieInvitationMailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.router.navigate(["/"]);
        },
        (err) => {
          // console.log(err);
        }
      );
  }
}
