import { Component, OnInit } from '@angular/core';
// import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';

import { environment } from './../../../environments/environment';
import { ApiHelper } from './../../_helpers/api.helper';
import { CommonHelper } from './../../_helpers/common.helper';
import { ConstantHelper } from './../../_helpers/constant.helper';
import { AppConfig } from './../../../app/app.config';

@Component({
  selector: 'app-add-admin-doc',
  templateUrl: './add-admin-doc.component.html',
  styleUrls: ['./add-admin-doc.component.scss'],
  providers: [AppConfig, CommonHelper]
})
export class AddAdminDocComponent implements OnInit {
  loggedInUserId: string;
  resultJsonObj: any = {};
  addAdminObj: any = {};
  docAdded = false;
  selectedFile: File = null;
  message: string;
  mediaType: string;
  imagePath: string;
  imgURL: any;
  loading: any;
  uploadService: any;
  mediaUrl: any;
  toastr: any;
  companySlug: string;


  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  httpOptions = {
    headers: this.httpHeaders
  };

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get(
      'loggedInUserId'
    );
    this.companySlug = this.activatedRoute.snapshot.paramMap.get(
      'companySlug'
    );
  }

  onFileSelected(event) {
    this.selectedFile =  event.target.files[0] as File;
  }

  onSubmit() {



    const finalData = new FormData();

    finalData.append('company_slug', this.companySlug);
    finalData.append('selected_file', this.selectedFile);
    finalData.append('doc_name', this.addAdminObj.docName);
    finalData.append('confirmationStatus', this.addAdminObj.confirmationStatus);
    finalData.append('loggedInUserId', this.loggedInUserId);

    return this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_saveAdminDocApi,
        finalData
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;

          this.docAdded = true;
          setTimeout(() => {
            this.router.navigate(['/documents/' + this.loggedInUserId]);
          }, 2000);
        },
        err => {
          console.error(err);
        }
      );
  }




  previewImage(files) {
    this.message = '';
    this.mediaType = '1';
    if (files.length === 0) { return; }
    let mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
    }

    let reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = _event => {
      this.imgURL = reader.result;
    };
    this.upload(files[0]);
  }




  upload(file) {
    this.loading = true;
    this.uploadService.uploadS3File(file).subscribe(
      (data: any) => {
        const imageData = data;
        if (imageData.success && imageData.status_code == 200) {
          //_self.businessModel.docs.push(_self.responseData.result.imageurl);
          this.mediaUrl = imageData.result.imageurl;
          this.loading = false;
        } else {
          this.toastr.error(imageData.message, 'Error');
        }
      },
      error => {
        const imageData = error;
        this.toastr.error(imageData.error, 'Error');
      }
    );
  }


  goToDocuments() {
    this.router.navigate(['/documents/' + this.loggedInUserId]);
  }

}
