import Swal from 'sweetalert2';

const SweetAlertHelper = {
  // ... other functions

  showConfirm: (message, onConfirm) => {
    Swal.fire({
      title: 'PLEASE CONFIRM',
      text: message,
      showCancelButton: true,
      confirmButtonColor: '#7066e0',
      cancelButtonColor: '#6e7881',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'

    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm(); // Call the callback function if the user confirms
      }
    });
    Swal.exist({
        title: 'PLEASE CONFIRM',
        text: message,
        showCancelButton: true,
        confirmButtonColor: '#7066e0',
        cancelButtonColor: '#6e7881',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'

      }).then((result) => {
        if (result.isConfirmed) {
          onConfirm(); // Call the callback function if the user confirms
        }
      });
  },
};

export default SweetAlertHelper;
