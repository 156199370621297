import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { from } from "rxjs";
import { DatePipe } from "@angular/common";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { SignaturePad } from "angular2-signaturepad";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "./../../_services/user.service";
import * as moment from "moment";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";
import { navigateToRoute } from "./../../_helpers/emailDoctorExpertOpinionRouteBack.helper";

@Component({
  selector: "app-co",
  templateUrl: "./co.component.html",
  styleUrls: ["./co.component.scss"],
})
export class CoComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  hippaJson: any = {};
  loggedInUserId: string;
  userSignatures: any = [];
  userSignatureSelected = false;
  userChoosedSignature = "";
  isEmail: any;
  isPracticePhy: any;
  physicianId: any;
  requestId: any;
  tempId: any;
  userOptionId: any;
  emailLogId: any;
  userFullSignature = "";
  userInitialSignature = "";
  userFullSignatureExit = false;
  userInitialSignatureExis = false;
  showPdfModal = false;
  signatureType: any;
  ssnPattern = "^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$";
  resultJsonObj: any = {};
  clean: string = "0";
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  @ViewChild("canvasArea", { static: true }) canvasArea: ElementRef;
  canvasElm: any;
  public signaturePadOptions: Object = {
    minWidth: 1,
    canvasWidth:
      window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
    canvasHeight: 450,
  };

  currentDate = new Date();
  dateFormat = "MM-dd-yyyy";
  firstWitnessSignatureSelected = false;
  firstWitnessChoosedSignature = "";

  secondWitnessSignatureSelected = false;
  secondWitnessChoosedSignature = "";

  showLoader = false;

  signatureFroWhom: any;
  message = Message;
  sendDocRoute = sessionStorage.getItem("sendDocRoute");
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService
  ) {
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.isEmail = this.activatedRoute.snapshot.paramMap.get("isEmail")
      ? this.activatedRoute.snapshot.paramMap.get("isEmail")
      : 0;
    this.isPracticePhy =
      this.activatedRoute.snapshot.paramMap.get("isPracticePhy");
    this.physicianId = this.activatedRoute.snapshot.paramMap.get("physicianId")
      ? this.activatedRoute.snapshot.paramMap.get("physicianId")
      : 0;
    this.requestId = this.activatedRoute.snapshot.paramMap.get("requestId")
      ? this.activatedRoute.snapshot.paramMap.get("requestId")
      : 0;
    this.tempId = this.activatedRoute.snapshot.paramMap.get("tempId")
      ? this.activatedRoute.snapshot.paramMap.get("tempId")
      : 0;
    this.userOptionId = this.activatedRoute.snapshot.paramMap.get(
      "userOptionId"
    )
      ? this.activatedRoute.snapshot.paramMap.get("userOptionId")
      : 0;
    this.emailLogId = this.activatedRoute.snapshot.paramMap.get("emailLogId")
      ? this.activatedRoute.snapshot.paramMap.get("emailLogId")
      : 0;

    this.hippaJson.healthcare_provider = this.userService.getUS_FromatName(
      localStorage.getItem("providerName")
    );
    this.hippaJson.healthcare_provider_email =
      localStorage.getItem("providerEmail");
    this.hippaJson.healthcare_provider_npi = localStorage.getItem(
      "providerPhysicianNipNo"
    );
    this.hippaJson.patient_name = this.userService.getUS_FromatName(
      localStorage.getItem("loggedInUserName")
    );
    this.hippaJson.healthcare_provider_address = localStorage.getItem(
      "providerOfficeAddress"
    );
    this.hippaJson.healthcare_provider_phone =
      localStorage.getItem("providerPhone");

    this.fnFindSignature(this.loggedInUserId);
    this.fnFindDemographic(this.loggedInUserId);

    this.hippaJson.patient_signature_date = this.currentDate;
    this.hippaJson.revocation_dob = this.currentDate;
    this.hippaJson.copy_release_date = this.currentDate;
    this.hippaJson.first_witness_date = this.currentDate;
    this.hippaJson.second_witness_date = this.currentDate;
  }

  ngOnInit() {
    this.hippaJson.patient_signature = "";
    this.hippaJson.first_witness_signature = "";
    this.hippaJson.second_witness_signature = "";
  }

  fnFindSignature(user_id) {
    const reqData = {
      user_id: user_id,
    };
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findUserSignatureApi,
        reqData,
        this.httpOptions
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (response.commandResult.success == 1) {
            this.userSignatures = response.commandResult.data.userSignatures;
            this.userFullSignature =
              response.commandResult.data.userSignatures.full_signature_url;
            this.userInitialSignature =
              response.commandResult.data.userSignatures.initial_signature_url;

            if (response.commandResult.data.userSignatures.full_signature_url) {
              this.userFullSignatureExit = true;
            }

            if (
              response.commandResult.data.userSignatures.initial_signature_url
            ) {
              this.userInitialSignatureExis = true;
            }
          } else {
          }

          // console.log(this.userSignatures);
        },
        (err) => {}
      );
  }

  async onSubmit() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:   By clicking ‘OK’:The information you have entered will be used to electronically generate a completed State HIPAA Form."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.onSubmit
    );
    if (alertStatus) {
      const reqData = {
        loggedInUserId: this.loggedInUserId,
        healthcare_provider: this.hippaJson.healthcare_provider,
        healthcare_provider_email: this.hippaJson.healthcare_provider_email,
        healthcare_provider_npi: this.hippaJson.healthcare_provider_npi,
        healthcare_provider_address: this.hippaJson.healthcare_provider_address,
        healthcare_provider_phone: this.hippaJson.healthcare_provider_phone,
        first_agent_name: this.hippaJson.first_agent_name,
        first_agent_address: this.hippaJson.first_agent_address,
        first_agent_phone: this.hippaJson.first_agent_phone,
        dob: moment(new Date(this.hippaJson.dob.toString())).format(
          "MM-DD-YYYY"
        ),
        second_agent_name: this.hippaJson.second_agent_name,
        second_agent_address: this.hippaJson.second_agent_address,
        second_agent_phone: this.hippaJson.second_agent_phone,
        copy_release_date: moment(
          new Date(this.hippaJson.copy_release_date.toString())
        ).format("MM-DD-YYYY"),
        health_info: this.hippaJson.health_info,
        patient_city: this.hippaJson.patient_city,
        patient_name: this.hippaJson.patient_name,
        patient_phone: this.hippaJson.patient_phone,
        patient_signature: this.userChoosedSignature,
        patient_signature_date: moment(
          new Date(this.hippaJson.patient_signature_date.toString())
        ).format("MM-DD-YYYY"),
        patient_state: this.hippaJson.patient_state,
        patient_zip: this.hippaJson.patient_zip,
        patient_address: this.hippaJson.patient_address,
        patient_medical_id: this.hippaJson.patient_medical_id,
        first_witness_signature: this.firstWitnessChoosedSignature,
        first_witness_name: this.hippaJson.first_witness_name,
        first_witness_address: this.hippaJson.first_witness_address,
        first_witness_date: moment(
          new Date(this.hippaJson.first_witness_date.toString())
        ).format("MM-DD-YYYY"),
        second_witness_signature: this.secondWitnessChoosedSignature,
        second_witness_name: this.hippaJson.first_witness_name,
        second_witness_address: this.hippaJson.first_witness_address,
        second_witness_date: moment(
          new Date(this.hippaJson.second_witness_date.toString())
        ).format("MM-DD-YYYY"),
        isEmail: this.isEmail,
        requestId: this.requestId,
        phyAltEmail: sessionStorage.getItem('phyAltEmail')

      };
      this.showLoader = true;
      // console.log(reqData);
      return this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.generateHipaaCO,
          reqData,
          this.httpOptions
        )
        .subscribe(
          (response: any) => {
            if (this.isEmail == 1) {
              this.router.navigate([
                "/send-doc/" +
                  this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
                  "/1/" +
                  this.isPracticePhy +
                  "/" +
                  this.physicianId +
                  "/" +
                  response.commandResult.message,
              ]);
            } else if (this.isEmail == 3) {
              this.router.navigate([
                "/expert-physician-opinion/" +
                  this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
                  "/" +
                  this.userOptionId +
                  "/1/" +
                  this.tempId +
                  "/" +
                  response.commandResult.message,
              ]);
            } else if (this.isEmail == 4) {
              this.router.navigate([
                "/resend-docs/" +
                  this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
                  "/" +
                  this.emailLogId +
                  "/1/" +
                  response.commandResult.message +
                  "/" +
                  this.isPracticePhy +
                  "/" +
                  this.physicianId,
              ]);
            } else {
              if (window.open(response.commandResult.data, "_blank")) {
                this.router.navigate([
                  "/dashboard/" +
                    this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
                ]);
              }
            }
            this.showLoader = false;
          },
          (err) => {}
        );
    }
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        "/dashboard/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    }
  }

  async goBack() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the USHF Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.goBack
    );
    if (alertStatus) {
      if(this.sendDocRoute){
        const loggedInUserId =
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
        const route = navigateToRoute(
          this.isEmail,
          loggedInUserId,
          this.isPracticePhy,
          this.physicianId,
          this.userOptionId,
          this.tempId,
          this.emailLogId
        );
        console.log("route ", route);
        this.router.navigate([route]);
        sessionStorage.removeItem("sendDocRoute");
      }else{
        this.router.navigate([
          '/ushf/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId')
      ]);
      }
    }
  }

  closePdfModal() {
    this.showPdfModal = false;
    this.hippaJson.patient_signature = "";
  }

  openSignatureCanvas(selectedValue, signatureFroWhom) {
    this.signatureFroWhom = signatureFroWhom;

    // console.log(selectedValue);
    this.signatureType = selectedValue;
    if (
      selectedValue == "AFS" ||
      selectedValue == "AFWS" ||
      selectedValue == "ASWS"
    ) {
      this.showPdfModal = true;
    }
    if (selectedValue == "FS") {
      this.userSignatureSelected = true;
      this.userChoosedSignature = this.userFullSignature;
    }
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }

  async saveCanvas() {
    // if (
    //   confirm(
    //     "This will replace your existing signature. Do you wish to change?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.saveCanvas
    );
    if (alertStatus) {
      const reqData = {
        loggedInUserId: this.loggedInUserId,
        signatureBase64: this.signaturePad.toDataURL(),
        signatureType: this.signatureType,
      };
      this.showLoader = true;
      return this.http
        .post(
          this.appConfig.getLambdaUrl05() +
            ApiHelper.name_saveSignatureImageApi,
          reqData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            if (apiResponse.commandResult.status == 1) {
              this.userSignatureSelected = true;
              this.userChoosedSignature =
                apiResponse.commandResult.data.fileInfo.signatureUrl;
              // console.log(this.userChoosedSignature);
              this.fnFindSignature(this.loggedInUserId);
              this.showPdfModal = false;
              // this.hippaJson.patient_signature = '';
            }
            this.showLoader = false;
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async clearCanvas() {
    // if (
    //   confirm("PLEASE CONFIRM: Are you sure you want to clear you signature.")
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.clearCanvas
    );
    if (alertStatus) {
      this.signaturePad.clear();
    }
  }

  drawStart() {}

  fnFindDemographic(user_id) {
    const finalData = {
      id: user_id,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (response: any) => {
          // console.log('response=>', response);
          this.hippaJson.dob = new Date(
            response.commandResult.data.userInfo.form1DOB
          );
          this.hippaJson.patient_city =
            response.commandResult.data.userInfo.form1City;
          this.hippaJson.patient_zip =
            response.commandResult.data.userInfo.form1ZIP;
          this.hippaJson.patient_state =
            response.commandResult.data.userInfo.form1State;
          this.hippaJson.patient_address =
            response.commandResult.data.userInfo.form1ResidenceNumber +
            ", " +
            response.commandResult.data.userInfo.form1StreetName +
            ", " +
            response.commandResult.data.userInfo.form1PrimaryPhysicianCity +
            ", " +
            response.commandResult.data.userInfo.form1State +
            ", " +
            response.commandResult.data.userInfo.form1ZIP;
          this.hippaJson.patient_phone =
            response.commandResult.data.userInfo.Mobile;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  autoFormatSSN() {
    // alert('Amit');
    var val = this.hippaJson.social_security_no.replace(/\D/g, "");
    val = val.replace(/^(\d{3})/, "$1-");
    val = val.replace(/-(\d{2})/, "-$1-");
    val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    this.hippaJson.social_security_no = val;
  }

  autoFormatSSN1() {
    // alert('Amit');
    var val = this.hippaJson.revocation_social_security_no.replace(/\D/g, "");
    val = val.replace(/^(\d{3})/, "$1-");
    val = val.replace(/-(\d{2})/, "-$1-");
    val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    this.hippaJson.revocation_social_security_no = val;
  }

  saveParentCanvas(signFor) {
    const reqData = {
      loggedInUserId: this.loggedInUserId,
      signatureBase64: this.signaturePad.toDataURL(),
      signatureType: this.signatureType,
      signFor: signFor,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() +
          ApiHelper.name_saveParentSignatureImageApi,
        reqData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            if (signFor == "FWS") {
              this.firstWitnessSignatureSelected = true;
              this.firstWitnessChoosedSignature =
                apiResponse.commandResult.data.fileInfo.signatureUrl;
            }

            if (signFor == "SWS") {
              this.secondWitnessSignatureSelected = true;
              this.secondWitnessChoosedSignature =
                apiResponse.commandResult.data.fileInfo.signatureUrl;
            }
            this.showPdfModal = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }
}
