import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { AuthService } from "../auth.service";
import { MustMatch } from "../../_helpers/matchpassword";

import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class RegisterComponent implements OnInit {
  @ViewChild("recaptcha", { static: false }) recaptchaElement: ElementRef;
  @HostListener("window:resize", ["$event"])
  registrationForm: FormGroup;
  registrationFormSubmitted = false;
  showRegistrationForm = true;
  registrationEmailTaken = false;
  registrationMobileTaken = false;
  verifyRegistrationFormMessage = false;
  verifyRegistrationFormErrorMessage = false;
  verifyRegistrationOtpForm: FormGroup;
  verifyRegistrationOtpFormSubmitted = false;
  showVerifyRegistrationOtpForm = false;
  tempUserId: number;
  // tempUserOtp: string;
  userOtp: number;
  userId: number;
  userEmail: string;
  userMobile: string;
  userRoleId: number;
  resultJsonObj: any = {};
  formRecaptchaErrorMessage = false;
  supportMail = ConstantHelper.SUPPORT_EMAIL;
  firstQuesLength = false;
  selectedCompanySlug: any;
  firstQuesList: any;
  doctorList: any = [];
  secondQuesLength = false;
  secondQuesList: any;
  showMailModal = false;
  enquiryObj: any = {};
  registrationFormMessage = false;
  practices: any = [];
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  fieldTextType: boolean;
  cnFieldTextType: boolean;
  showLoader = false;
  parentNameRequired = false;
  message = Message;
  fureFlag: boolean = false;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private authService: AuthService,
    public sweetAlertHelper: SweetAlertHelper,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.addRecaptchaScript();
    this.registrationForm = this.formBuilder.group(
      {
        firstName: ["", Validators.required],
        middleName: [""],
        lastName: ["", Validators.required],
        mobile: ["", Validators.required],
        email: ["", [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.(com|net|org|in|[a-zA-Z]{2,})$')]],
        dob: ['', [Validators.required, this.preventFutureDateValidator()]],
        // tslint:disable-next-line: max-line-length
        // password: ["", Validators.required],
        password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$#@$!%*?&])[A-Za-z\d$#@$!%*?&].{7,}')]],
        confirmPassword: ["", Validators.required],
        recaptchaValue: ["", Validators.required],
        que1Id: ["", Validators.required],
        que2Id: ["", Validators.required],
        ans1: ["", Validators.required],
        ans2: ["", Validators.required],
        companySlug: [""],
        physicianId: [""],
        parentFirstName: [""],
        parentLastName: [""],
      },
      {
        validator: MustMatch("password", "confirmPassword"),
      }
    );
    this.verifyRegistrationOtpForm = this.formBuilder.group({
      otp: ["", Validators.required],
    });
    this.fnFindQuestionsSet(1);
    this.fnFindQuestionsSet(2);
    this.findAllParticipatingCenters();

    this.enquiryObj.subject = "Having trouble signing up or logging in";
    // tslint:disable-next-line: max-line-length
    this.enquiryObj.content = "I am having difficulty signing up or logging into the ELEMREX platform.  I understand someone from AHS Customer Support will be contacting me by email within the next 24 hours.  I can also be reached at the following cell phone number I am providing here:  <user typed in number>. Feel free to type any additional information for our review. Thank you.  AHS Support Team";

    this.route.queryParams.subscribe(params => {
      const companySlug = params['companySlug'];
      const physicianId = params['physicianId'];

      // Set form values and disable the controls only if URL params are available
      if (companySlug) {
        this.registrationForm.controls['companySlug'].setValue(companySlug);
        // this.registrationForm.controls['companySlug'].disable();  // Disable only if param is present
        this.findPhysicianByCompanySlug({ target: { value: companySlug } });
      }

      if (physicianId) {
        this.registrationForm.controls['physicianId'].setValue(physicianId);
        // this.registrationForm.controls['physicianId'].disable();  // Disable only if param is present
      }
    });
  }

  get f() {
    return this.registrationForm.controls;
  }

  get v() {
    return this.verifyRegistrationOtpForm.controls;
  }

  preventFutureDateValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const inputDate = new Date(control.value);
      const currentDate = new Date();
      console.log("inputDate",inputDate)
      if (inputDate > currentDate) {
        this.fureFlag = true; 
        return { futureDate: true }; // Return an error if the date is in the future
      }
      this.fureFlag = false;
      return null; // No error
    };
  }

  findAllParticipatingCenters() {
    const finalData = {
      // loggedInUserId: this.loggedInUserId,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getPractices,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.data.practices.length > 0) {
            this.practices = apiResponse.commandResult.data.practices;
            this.showLoader = false;
          } else {
            // alert("No Center of Excellence found!");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.sendDocComp.findAllParticipatingCenters
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPhysicianByCompanySlug(event: any) {
    this.selectedCompanySlug = event.target.value;
    this.registrationForm.patchValue({
      physicianId: "", // Reset the physicianId in the form
    });

    if (this.selectedCompanySlug === "none") {
      this.doctorList = [];
      return;
    }
  
    // Prepare the request payload
    const requestData = {
      companySlug: this.selectedCompanySlug,
    };
  
    this.showLoader = true;
    this.http
      .post(this.appConfig.getLambdaUrl05() + ApiHelper.getPhysicianByCompany, requestData, this.httpOptions)
      .subscribe(
        async (apiResponse: any) => {
          const doctorList = apiResponse.commandResult.data.doctorList;
          if (doctorList && doctorList.length > 0) {
            this.doctorList = doctorList.sort((a, b) => {
              if (a.physician_name && b.physician_name) {
                return a.physician_name.localeCompare(b.physician_name);
              } else if (a.physician_name) {
                return -1; // a comes first if b has no name
              } else {
                return 1; // b comes first if a has no name
              }
            });
          } else {
            this.showLoader = false;
            this.doctorList = [];
            await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.demographics.findPryPhysiciansByCity);
          }
          this.showLoader = false; // Stop loader after the API call
        },
        (err) => {
          console.error("Error fetching physicians", err);
          this.showLoader = false; // Stop loader in case of an error
        }
      );
  }

  fnFindQuestionsSet(questionSetId) {
    const finalDataF = {
      setId: questionSetId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataF,
        this.httpOptions
      )
      .subscribe(
        (apiResponseFQ: any) => {
          this.resultJsonObj = apiResponseFQ;
          if (this.resultJsonObj.commandResult.data.questionsList.length > 0) {
            if (questionSetId === 1) {
              this.firstQuesLength = true;
              this.firstQuesList =
                this.resultJsonObj.commandResult.data.questionsList;
            } else if (questionSetId === 2) {
              this.secondQuesLength = true;
              this.secondQuesList =
                this.resultJsonObj.commandResult.data.questionsList;
            } else {
              this.firstQuesLength = false;
              this.secondQuesLength = false;
            }
          } else {
            this.firstQuesLength = false;
            this.secondQuesLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  checkAge(event: any) {
    const dob = new Date(event.value);
    const dobControl = this.registrationForm.get('dob');
    console.log("Age",dob)
    const age = this.calculateAge(dob);
    this.parentNameRequired = age < 18;
  }

  calculateAge(dob: Date): number {
    const diff = Date.now() - dob.getTime();
    const ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  private formatDate(date: Date | string): string {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ('0' + (d.getMonth() + 1)).slice(-2); // Months are zero-indexed
    const day = ('0' + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  async onSubmit() {
    if (this.fureFlag) {
      const fureFlag = await this.sweetAlertHelper.alertPopUp("Cannot select future Date for DOB");
      return false // Prevent form submission if invalid or futureDate error present
    }
    
    this.registrationFormSubmitted = true;
    if (this.registrationForm.invalid) {
      return;
    }

    const age = this.commonHelper.getAge(this.registrationForm.value.dob);
    if (age < 18) {
      if (
        !this.registrationForm.value.parentFirstName &&
        !this.registrationForm.value.parentLastName
      ) {
        // alert("Parent name is required for minors.");
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.registerComp.onSubmit
        );
        return false;
      }

      // alert(
      //   "The parent has to sign in and the record will be created under parent"
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.registerComp.onSubmit1
      );
    }
    if (
      this.registrationForm.value.companySlug !== "none" &&
      this.registrationForm.value.companySlug.length > 0 &&
      !this.registrationForm.value.physicianId
    ) {
      // alert("Please select a Physician.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.registerComp.onSubmit2
      );
      return false;
    }

    const finalData = {
      email: this.registrationForm.value.email.toLowerCase(),
      mobile: this.registrationForm.value.mobile,
      firstName: this.registrationForm.value.firstName,
      middleName: this.registrationForm.value.middleName,
      lastName: this.registrationForm.value.lastName,
      password: this.registrationForm.value.password,
      dob: this.formatDate(this.registrationForm.value.dob),
      age,
      que1Id: this.registrationForm.value.que1Id,
      que2Id: this.registrationForm.value.que2Id,
      ans1: this.registrationForm.value.ans1,
      ans2: this.registrationForm.value.ans2,
      user_role_id: ConstantHelper.USER_ROLE_USER,
      companySlug: this.registrationForm.value.companySlug,
      physicianId: this.registrationForm.value.physicianId
        ? this.registrationForm.value.physicianId
        : null,
      parentFirstName: this.registrationForm.value.parentFirstName
        ? this.registrationForm.value.parentFirstName
        : "",
      parentLastName: this.registrationForm.value.parentLastName
        ? this.registrationForm.value.parentLastName
        : "",
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.patientRegistration,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.status === 1) {
            // this.tempUserOtp = this.resultJsonObj.commandResult.data.tempUserInfo.tempOtp;
            this.tempUserId =
              this.resultJsonObj.commandResult.data.tempUserInfo.tempId;
            this.showRegistrationForm = false;
            this.showVerifyRegistrationOtpForm = true;
          } else if (this.resultJsonObj.commandResult.status === -1) {
            this.registrationEmailTaken = true;
            this.registrationMobileTaken = false;
          } else if (this.resultJsonObj.commandResult.status === -2) {
            this.registrationEmailTaken = false;
            console.log("email is here", this.registrationEmailTaken);
            this.registrationMobileTaken = true;
          }else if (this.resultJsonObj.commandResult.status === 0) {
            this.registrationEmailTaken = true;
          } else {
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  verifyOtp() {
    this.verifyRegistrationOtpFormSubmitted = true;
    if (this.verifyRegistrationOtpForm.invalid) {
      return;
    }

    const finalData = {
      otp: this.verifyRegistrationOtpForm.value.otp,
      id: this.tempUserId,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl05() +
          ApiHelper.verifyPatientRegistrationOTP,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.status === 1) {
            const finalData = {
              email: this.registrationForm.value.email.toLowerCase(),
              id: this.resultJsonObj.commandResult.data.userInfo.RecordId,
            };
            this.http
              .post(
                this.appConfig.getPhpUrl() + ApiHelper.php_checkInvitationApi,
                finalData,
                this.httpOptions
              )
              .subscribe(
                (apiResponse: any) => {
                  localStorage.setItem("isUserIdloggedIn", "Y");
                  localStorage.setItem(
                    "loggedInUserId",
                    this.resultJsonObj.commandResult.data.userInfo.RecordId
                  );
                  localStorage.setItem(
                    "loggedInUserName",
                    this.resultJsonObj.commandResult.data.userInfo.FullName
                  );
                  localStorage.setItem(
                    "loggedInUserEmail",
                    this.resultJsonObj.commandResult.data.userInfo.Email
                  );
                  localStorage.setItem(
                    "loggedInUserRoleId",
                    this.resultJsonObj.commandResult.data.userInfo.UserRoleId
                  );
                  this.userId =
                    this.resultJsonObj.commandResult.data.userInfo.RecordId;
                  if (
                    this.resultJsonObj.commandResult.data.userInfo
                      .UserRoleId === 3
                  ) {
                    this.router.navigate(["/demographics/" + this.userId]);
                  } else {
                    this.router.navigate(["/dashboard/" + this.userId]);
                  }
                  this.showLoader = false;
                },
                (err) => {
                  console.error(err);
                }
              );
          } else {
            this.verifyRegistrationFormErrorMessage = true;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  renderReCaptch() {
    setTimeout(() => {
      window["grecaptcha"].render(this.recaptchaElement.nativeElement, {
        sitekey: ConstantHelper.RECAPTCHA_SITE_KEY_CLIENT,
        callback: (response) => {
          // console.log(response);
          // console.log(
          //   this.appConfig.getLambdaUrl01() + ApiHelper.name_verifyRecaptachaApi
          // );
          // console.log(response);
          this.registrationForm.get("recaptchaValue").setValue(response);

          /////////////////// recaptcha validation /////////////////
          const httpHeaders = new HttpHeaders({
            "Content-Type": "application/json",
          });
          const options = {
            headers: httpHeaders,
          };
          const finalData = {
            recaptchaResponse: response,
            remoteIP: "127.0.0.1",
          };
          this.showLoader = true;

          return this.http
            .post(
              this.appConfig.getLambdaUrl01() +
                ApiHelper.name_verifyRecaptachaApi,
              finalData,
              options
            )
            .subscribe(
              (apiResponse) => {
                // console.log(apiResponse);
                this.resultJsonObj = apiResponse;
                // console.log(this.resultJsonObj);

                if (this.resultJsonObj.commandResult.status === 1) {
                  this.formRecaptchaErrorMessage = false;
                } else {
                  this.formRecaptchaErrorMessage = true;
                }
                this.showLoader = false;
              },
              (err) => {
                // console.log(err);
              }
            );
          /////////////////////////////////////////////////////////
        },
      });
    }, 500);
  }

  addRecaptchaScript() {
    window["grecaptchaCallback"] = () => {
      this.renderReCaptch();
    };

    (function (d, s, id, obj) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        obj.renderReCaptch();
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src =
        "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "recaptcha-jssdk", this);
  }

  ngAfterViewInit() {
    this.addRecaptchaScript();
    this.captchScaleFunc();
  }

  onResize(event) {
    this.captchScaleFunc();
  }

  captchScaleFunc() {
    let captchacontainWidth = this.recaptchaElement.nativeElement.clientWidth;
    let scaleValue = captchacontainWidth / 304;
    if (scaleValue < 1) {
      this.recaptchaElement.nativeElement.style.transform =
        "scale(" + scaleValue + ")";
      this.recaptchaElement.nativeElement.style.transformOrigin = "0";
    }
    // console.log(captchacontainWidth);
  }

  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleCnFieldTextType() {
    this.cnFieldTextType = !this.cnFieldTextType;
  }

  fnShowMailModal() {
    this.showMailModal = true;
  }

  fnCloseMailModal() {
    this.showMailModal = false;
  }

  async onEnquirySubmit() {
    // if (confirm('PLEASE CONFIRM: Click ‘OK’ to contact Elemrex admin.'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.doctorRegisterComp.onEnquirySubmit
    );
    if (alertStatus) {
      const finalData = {
        subject: this.enquiryObj.subject,
        content: this.enquiryObj.content,
      };
      this.showLoader = true;

      return this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_contactElemrixApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (response: any) => {
            this.showLoader = false;

            // console.log(response);
            // alert("Email sent ot Elemrex Admin.");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.doctorRegisterComp.onEnquirySubmit
            );
            this.showMailModal = false;
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  getCurrentDate(): string {
    const today = new Date();
    return today.toISOString().split('T')[0];
  }


}
