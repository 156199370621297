import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { ArrayHelper } from "./../../_helpers/array.helper";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";

@Component({
  selector: "app-user-security-questions",
  templateUrl: "./user-security-questions.component.html",
  styleUrls: ["./user-security-questions.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class UserSecurityQuestions implements OnInit {
  loggedInUserId: string;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  profileObj: any = {};
  firstQues: any = [];
  secondQues: any = [];
  showLoader = false;
  demographicId: string;
  childRoute: string;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient
  ) {
    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get('demographicId');
      localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
      this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.findUserDetails(this.loggedInUserId);
    this.findFirstSecurityQues();
    this.findSecondSecurityQues();
  }

  findFirstSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 1,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.firstQues = apiResponse.commandResult.data.questionsList;
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  findSecondSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 2,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.secondQues = apiResponse.commandResult.data.questionsList;
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  findUserDetails(userId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      id: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status === 1) {
            this.profileObj = apiResponse.commandResult.data.userInfo;
            this.showLoader = false;
          }
        },
        (err) => {}
      );
  }

  async asynconReset() {
    // if (confirm('PLEASE CONFIRM: Are you certain you want to return to the Home Page?'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }

  async onSubmit() {
    // if (this.profileObj.password !== this.profileObj.cnfPassword) {
    //   // alert("Password must match.");
    //   const alertStatus = await this.sweetAlertHelper.alertPopUp(
    //     this.message.alertMessages.userChangePassComp.onSubmit
    //   );
    //   return;
    // }
    // // if (confirm("PLEASE CONFIRM: Are you sure you want to update password?"))
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.userChangePassComp.onSubmit
    // );
    // if (alertStatus) {
    //   if (this.profileObj.Ans01 !== this.profileObj.Ans_01) {
    //     // alert("Incorrect secirity answer 01.");
    //     const alertStatus = await this.sweetAlertHelper.alertPopUp(
    //       this.message.alertMessages.userChangePassComp.onSubmit1
    //     );
    //     return;
    //   } else if (this.profileObj.Ans02 !== this.profileObj.Ans_02) {
    //     // alert("Incorrect secirity answer 02.");
    //     const alertStatus = await this.sweetAlertHelper.alertPopUp(
    //       this.message.alertMessages.userChangePassComp.onSubmit2
    //     );
    //     return;
    
        const finalData = {
          userId: this.loggedInUserId,
          que_id_01 : this.profileObj.QueId_01,
          que_id_02 : this.profileObj.QueId_02,
          ans_01 : this.profileObj.Ans_01,
          ans_02 : this.profileObj.Ans_02,


        };
        this.showLoader = true;
        return this.http
          .post(
            this.appConfig.getLambdaUrl05() + ApiHelper.updateSecurityQuestions,
            finalData,
            this.httpOptions
          )
          .subscribe(
            async (apiResponse: any) => {
              // alert("Password updated successfully.");
              this.showLoader = false;
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.userSecurityQuestionComp.onSubmit
              );
              window.location.reload();
              this.showLoader = false;
            },
            (err) => {}
          );
    }
  

  async goToDashboad() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        "/dashboard/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    }
}

goBack(){
    this.router.navigate(["/" + this.childRoute + "/demographics/" + this.loggedInUserId + "/" + this.demographicId]);

}
}