import { Injectable } from "@angular/core";
@Injectable()
export class CommonHelper {
  constructor() {}

  testHelper() {
    return "Called, Hellper method.";
  }
  calculateAge(selectedYear, selectedMonth, selectedDay) {
    let dob = new Date(selectedYear, selectedMonth - 1, selectedDay);
    let diff_ms = Date.now() - dob.getTime();
    let age_dt = new Date(diff_ms);
    let ageCalculated = Math.abs(age_dt.getUTCFullYear() - 1970);
    if (ageCalculated > 0) {
      return ageCalculated;
    } else {
      return 1;
    }
  }
  cleanEmail(str) {
    return str.replace(/[^a-zA-Z0-9.@_ ]/g, "");
  }

  maskEmail(email) {
    const maskedEmail = "";
    if (email.length > 0) {
      return email.substring(0, 4) + "****@***.***";
    } else {
      return maskedEmail;
    }
  }

  getAge(date) {
    const dob = new Date(date);
    const monthDiff = Date.now() - dob.getTime();
    const ageDt = new Date(monthDiff);
    const year = ageDt.getUTCFullYear();
    return Math.abs(year - 1970);
  }

  isValidEmail(email: string): boolean {
    // Regular expression for email validation
    const emailRegex: RegExp =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  containsOnlyNumbers(value: string): boolean {
    // Regular expression for checking if the value contains only numbers
    const numberRegex: RegExp = /^[0-9]+$/;
    return numberRegex.test(value);
  }
}
