import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiHelper } from './../../_helpers/api.helper';
import { CommonHelper } from './../../_helpers/common.helper';
import { ConstantHelper } from './../../_helpers/constant.helper';
import { AppConfig } from './../../../app/app.config';

@Component({
  selector: 'app-practices',
  templateUrl: './practices.component.html',
  styleUrls: ['./practices.component.scss']
})
export class PracticesComponent implements OnInit {
  loggedInUserId: string;
  practices: any = [];
  apiURl: string;
  showLoader = true;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  httpOptions = {
    headers: this.httpHeaders
  };

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {
  this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
  this.findPractice();
  }
  ngOnInit() { }

  findPractice() {
    const finalData = {
      userId: this.loggedInUserId
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getAllPractices,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.practices = res.commandResult.data.practices;
          this.showLoader = false;
        },
        err => {
          console.error(err);
          this.showLoader = false;
        }
      );
  }



  openAddPracticePage() {
    this.router.navigate(['/practices/add/' + this.loggedInUserId]);
  }
















}

