
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';

import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {IDropdownSettings} from 'ng-multiselect-dropdown';

import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';

import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {UploadService} from '../../_services/upload.service';
import {UserService} from './../../_services/user.service';
import {AppConfig} from './../../../app/app.config';
import {SweetAlertHelper} from '../../_helpers/sweet.alert.helper';
import {Message} from '../../_locale/messages';

import * as S3 from 'aws-sdk/clients/s3';
import { Observable } from 'rxjs';


declare let $: any;

@Component({
  selector: 'app-share-documents',
  templateUrl: './share-documents.component.html',
  styleUrls: ['./share-documents.component.scss'],
})
export class ShareDocuments implements OnInit {
  message = Message;

  private pdfApiUrl = 'https://openaitimesheet.azurewebsites.net/reports';
  private videoApiUrl = 'https://app-videosummarize-eastus.azurewebsites.net/analyze_video'
  private imageApiUrl = 'https://app-videosummarize-eastus.azurewebsites.net/analyze_image'

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private uploadService: UploadService,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService
  ) {
    this.loggedInUserId = localStorage.getItem('delegateId')
      ? localStorage.getItem('delegateId')
      : this.activatedRoute.snapshot.paramMap.get('loggedInUserId');

    this.docInfo.doc = '';
    this.fnFindAppSetting();
  }

  @ViewChild('fileUpoladed', {static: false}) fileUpoladed: ElementRef;

  loggedInUserId: string;
  resultJsonObj: any = {};
  fileToUpload: File = null;
  fileName: string;
  companySlug = '';
  previousDocId = '';
  docType = ConstantHelper.DOC_TYPE_USER_UPLOADED;
  companiesList: any = [];
  companiesListLength = false;

  accessKeyId: any = '';
  secretAccessKey: any = '';

  attachmentDocment: any = '';
  files: any = [];
  practices: any;
  uploadedPracticsSettings: any;
  uploadedPracticesListSelected: any = [];

  physicans: any;

  uploadedPhysicanSettings: any;
  uploadedPhysicanListSelected: any = [];
  uploadedPhysicanListSelected0: any = [];

  uploadedDocumentsList: any = [];
  uploadedDocumentsListSelected: any = [];
  uploadedDropdownSettings: any = {};

  s3Files: any = [];
  isUploadFile = false;

  showLoader = false;

  ppData = [
    {
      practices: [],
      physicans: [],
    },
    {
      practices: [],
      physicans: [],
    },
    {
      practices: [],
      physicans: [],
    },
    {
      practices: [],
      physicans: [],
    },
    {
      practices: [],
      physicans: [],
    },
  ];

  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  docInfo: any = {};

  isFileSelected = false;
  @ViewChild('fileLog', {static: false}) fileLog: ElementRef;
  @ViewChild('fileUploaded', {static: false})
  fileInput: ElementRef<HTMLInputElement>;

  tempPractice01: any = [];

  tempPhysician01: any = [];

  finalPhysician01: any = [];

  show01 = false;
  practiceInfo1: any = '';

  physicianInfo1: any = '';

  physicans1: any;

  physicianLength1 = false;

  disabled1 = false;

  previou_permission1 = [];

  previou_practice_permission1 = [];


  choosedPhysicians: any = [];
  choosedPractices: any = [];
  practiceInfos: any = [];

  isHippa: any;

  isDisableSubmitBtn = true;
  selectedDoctorsSlug: any = [];
  selectedDoctorsId: any = [];
  isHippByAdmin = false;
  isHippByAdmin1 = 0;
  profileObj: any;

  isDisabled = false;
  showNameAndFolderName1 = false;
  loggedInUserName = localStorage.getItem('loggedInUserName');

  finalRequest: any = {isUploadFile: 0};
  onBehalfOfAlertUpload = 'Y';
  associatedFileName: any;
  associatedFileUrl: any;
  associatedFileCreatedDate: any;
  originalFileName: any;
  originalFileUrl: any;
  associatedFileDetails : any = {};
  selectedOption: boolean = false;
  showAssociateDocumentUpload = false;
  aiSummary: string;
  disableSelectable = false;
  documentIdToGenerateAiSummary: any;
  documentUrlToGenerateAiSummary: any;
  summaryAnswer: any;
  // async;

  async ngAfterViewInit() {
    var item = localStorage.getItem('FullName');
    if (
      this.onBehalfOfAlertUpload === 'Y' &&
      localStorage.getItem('isDelegating') === '1'
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.ngAfterViewInit + ' ' + item
      );

      localStorage.setItem('onBehalfOfAlertUpload', 'N');
    }
  }

  ngOnInit() {
    $(function() {
      $('input[type=file]').change(function() {
        $(this).val(null);
      });
    });
    this.findCompanies();
    this.findUserDetails(this.loggedInUserId);
    this.getUserPractices(this.loggedInUserId);

    this.onBehalfOfAlertUpload = localStorage.getItem('onBehalfOfAlertUpload')
      ? localStorage.getItem('onBehalfOfAlertUpload')
      : 'Y';
      window.scroll(0,0)
  }

  getUserPractices(loggedInUserId) {
    const finalData = {
      loggedInUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findUserPracticeApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.practices = apiResponse.commandResult.data.companiesList;
          this.uploadedPracticsSettings = {
            singleSelection: false,
            idField: 'company_url',
            textField: 'company',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true,
          };
          this.showLoader = false;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  fnFindAppSetting() {
    const reqData = {userId: this.loggedInUserId};
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getAppDetails,
        reqData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.accessKeyId = res.commandResult.data.awsAccessKeyId;
          this.secretAccessKey = res.commandResult.data.awsSecretAccessKey;
          this.showLoader = false;
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
  }

  getPhysican(company_url, i) {
    const reqData = {company_slug: company_url};
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
        ApiHelper.name_findPhysiciansByCompanySlugApi,
        reqData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.ppData[i].physicans =
            apiResponse.commandResult.data.companiesList;
          this.uploadedPhysicanSettings = {
            singleSelection: false,
            idField: 'physician_id',
            textField: 'physician',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true,
          };
          this.showLoader = false;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  findCompanies() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
        ApiHelper.name_findUserRegisteredPhysiciansApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.companiesList = apiResponse.commandResult.data.companiesList;
          this.companiesListLength = true;
          this.showLoader = false;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  async onFileSelected(event) {
    this.disableSelectable = true;
    const file: File = event.target.files[0];
    const maxFileSize = 20 * 1024 * 1024;
    if (file) {
      if (file.size <= maxFileSize) {
        if (!this.validateFile(event.target.value)) {
          await this.sweetAlertHelper.alertPopUp(
            this.message.alertMessages.uploadDocComp.selectValidFile
          );
          event.target.value = null;
        } else {

          this.files = [];
            this.isUploadFile = true;
              if (
                this.uploadedDocumentsList.find(
                  (x) => x.DocumentName == event.target.files[0].name
                )
              ) {
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.uploadDocComp.onFileSelected2 + ' ' + event.target.files[0].name + this.message.alertMessages.uploadDocComp.onFileSelected3
                );
                if(alertStatus){
                  this.disableSelectable = false;
                }
              } else {
                const {docUrl, docName} = await this.uploadFileOnAzure(event.target.files[0] as File, this.s3Files);
              this.originalFileUrl = docUrl;
              this.originalFileName = docName;
                this.ppData[0].practices = this.practices;
                this.files.push(event.target.files[0]);
                // const extension = await this.checkDocumentType(this.originalFileUrl);
                // this.generateSummary(this.originalFileUrl, extension);
              }
        }
      } else {
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.uploadDocComp.pleaseSelectLessThan20MB
        );
        if (alertStatus) {
          this.fileInput.nativeElement.value = '';
        }
      }
    }

  }

  async uploadFile() {
    let isHippa = 0;

    this.getCheckedBoxValue();
    if (this.selectedDoctorsId.length <= 0) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.uploadFile
      );
      if (alertStatus) {
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.uploadDocComp.uploadDone
        );
      }
      return false;
    }
    const fSize = Math.round(this.fileToUpload.size / 1024);
    if (!this.validateFile(this.fileToUpload.name)) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.uploadFile1
      );

      return false;
    } else if (fSize > 20000) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.uploadFile2
      );
    } else {
      if (this.isHippByAdmin === true) {
        isHippa = 1;
      } else {
        isHippa = 0;
      }
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.uploadDocumentComp.uploadFile
      );
      if (alertStatus) {
        this.uploadService
          .uploadFileEsign(
            this.fileToUpload,
            this.loggedInUserId,
            this.isHippa,
            this.selectedDoctorsId,
            this.fileToUpload.name,
            isHippa,
            this.previousDocId
          )
          .subscribe(
            async (data: any) => {
              if (data !== undefined) {
                const alertStatus =
                  await this.sweetAlertHelper.confirmPopUpYesOrNo(
                    this.message.confirmTitle,
                    this.message.confirmMessages.uploadDocumentComp.uploadFile1
                  );
                if (alertStatus) {
                  window.location.reload();
                } else {
                  this.router.navigate(['/dashboard/' + this.loggedInUserId]);
                }
              }
            },
            (error) => {
              console.error(error);
            }
          );
      } else {
        this.router.navigate(['/dashboard/' + this.loggedInUserId]);
      }
    }
  }

  async submitUploadFile() {
    const dataArray = [];
    let finalData = [];
    this.s3Files.forEach((element, i) => {
      if (i == 0) {
        this.choosedPhysicians = this.finalPhysician01;
        this.choosedPractices = this.tempPractice01;
        this.isHippa = this.isHippByAdmin1 == 0 ? 4 : this.isHippByAdmin1;
        this.practiceInfos = this.practiceInfo1;
      }

      const obj = {
        RecordId: element.RecordId,
        UploadedPracticesListSelected: this.choosedPractices,
        UploadedPhysicanListSelected: this.choosedPhysicians,
        practiceInfo: this.practiceInfos,
        isHippa: this.isHippa,
        file: element.fileUrl,
        fileName: element.DocumentName,
      };
      dataArray.push(obj);
      
      finalData = [dataArray[0]];
    });
    const reqData = {
      isDelegating: localStorage.getItem('isDelegating'),
      delegateId: localStorage.getItem('delegateId'),
      parentId: localStorage.getItem('parentId'),
      isUploadFile: this.isUploadFile,
      patientId: this.loggedInUserId,
      data: finalData,
      docType: this.isHippa,
      associatedFileName: this.associatedFileName,
      associatedFileUrl: this.associatedFileUrl,
      facilityName: this.associatedFileDetails.facilityName,
      facilityAddress: this.associatedFileDetails.facilityAddress,
      facilityContact: this.associatedFileDetails.facilityContact,
      reportedDoctorName: this.associatedFileDetails.reportedDoctorName,
      orderedDoctorName: this.associatedFileDetails.orderedDoctorName,
      testingDoneDate: this.associatedFileDetails.testingDoneDate,
      associatedFileDescription: this.associatedFileDetails.associatedFileDescription,
      aiSummary : this.aiSummary,
      loggedInUserId: this.loggedInUserId 
    };

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.uploadDocumentComp.submitUploadFile
    );
    if (alertStatus) {
      this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_shareDocuments,
        reqData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          this.showLoader = false;
          this.documentIdToGenerateAiSummary = apiResponsePhysician.commandResult.data.document_id;
          this.documentUrlToGenerateAiSummary = apiResponsePhysician.commandResult.data.document_url;
          if (apiResponsePhysician !== undefined) {
            if (apiResponsePhysician.commandResult.success == 1) {
              const alertStatus = await this.sweetAlertHelper.confirmPopUpYesOrNo(this.message.confirmTitle, this.message.alertMessages.uploadDocComp.uploadDone);
              if (alertStatus) {
                await this.generateAiSummaryOnDocumentUpload()
                window.location.reload();
              } else {
                this.router.navigate(['/dashboard/' + localStorage.getItem('loggedInUserId')]);
                await this.generateAiSummaryOnDocumentUpload()
              }
            }
          }
        },
        (err) => {
          console.error(err);
        }
      );
      // const alertStatus = await this.sweetAlertHelper.confirmPopUpYesOrNo(this.message.confirmTitle, this.message.alertMessages.uploadDocComp.uploadDone);
      // if (alertStatus) {
      //   await this.generateAiSummaryOnDocumentUpload()
      //   window.location.reload()
      // } else {
      //   this.router.navigate(['/dashboard/' + localStorage.getItem('loggedInUserId')]);
      // }
    } else {
      // return false;
      this.router.navigate(['/dashboard/' + localStorage.getItem('loggedInUserId')]);
    }
    
  }

  // async gotToDashboard() {
  //   const alertStatus = await this.sweetAlertHelper.confirmPopUp(
  //     this.message.confirmTitle,
  //     this.message.confirmMessages.ushfComp.onReset
  //   );
  //   if (alertStatus) {
  //     this.router.navigate([
  //       '/dashboard/' +
  //       this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
  //       '/1',
  //     ]);
  //   }
  // }

  validateFile(name: String) {
    const maxFileSize = 20 * 1024 * 1024;
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (
      ext.toLowerCase() == 'png' ||
      ext.toLowerCase() == 'jpg' ||
      ext.toLowerCase() == 'jpeg' ||
      ext.toLowerCase() == 'pdf' ||
      ext.toLowerCase() == 'doc' ||
      ext.toLowerCase() == 'docx' ||
      ext.toLowerCase() == 'mp4'
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkCheckBoxvalue(event) {
  }

  clearSelectedDoctors() {
    this.selectedDoctorsId = [];
  }

  getCheckedBoxValue() {
    const allCheck: any = document.getElementsByClassName('docChecks');
    for (let index = 0; index < allCheck.length; index++) {
      const element = allCheck[index];
      if (element.checked) {
        this.selectedDoctorsId.push(element.value);
      }
    }
  }

  fnFindUploadedDocs(profileObj) {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: null,
      docType: ConstantHelper.DOC_TYPE_USER_UPLOADED,
      email: profileObj.Email,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_getUploadedDocumentsListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            const docList = this.resultJsonObj.commandResult.data.documentList;
            this.uploadedDocumentsList = [];
            docList.forEach((element) => {
              element.uploadedPracticesListSelected = [];
              element.uploadedPhysicanListSelected = [];
              // element.uploadedPhysicanListSelected = [];
              this.uploadedDocumentsList.push(element);
            });
            this.uploadedDropdownSettings = {
              singleSelection: false,
              idField: 'RecordId',
              textField: 'DocumentName',
              selectAllText: 'Select All',
              unSelectAllText: 'UnSelect All',
              itemsShowLimit: 3,
              allowSearchFilter: true,
            };
          }
          this.showLoader = false;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  findUserDetails(userId) {
    const finalData = {
      company_slug: null,
      id: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.profileObj = apiResponse.commandResult.data.userInfo;
            this.fnFindUploadedDocs(this.profileObj);
          }
          this.showLoader = false;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  addPreviousDocument(previousDocId) {
    this.previousDocId = previousDocId;
  }

  async goToDashboard() {
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.ushfComp.onReset
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/dashboard/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
      ]);
    // }
  }

  async onDocumentItemSelect(item: any) {
    if (this.s3Files.length > 1) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.onDocumentItemSelect
      );
      return;
    }
    this.isUploadFile = false;
    this.updateSelectedDocs();
  }

  async onDocumentSelectAll(items: any) {
    if (items.length > 4) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.onDocumentItemSelect
      );
      return;
    }
    this.updateSelectedDocs();
    this.isUploadFile = false;
  }

  onPracticeItemSelect(item: any, i) {
    const testData = this.s3Files[i].uploadedPracticesListSelected;
    this.getPhysican(testData[0].company_url, i);
  }

  onPracticeSelectAll(items: any, i) {
    this.isDisabled = true;
  }

  onPracticeItemDeSelect(item: any, i) {
  }

  onDocumentItemDeSelect(item: any) {
    this.s3Files = [];
    this.updateSelectedDocs();
  }

  updateSelectedDocs() {
    this.uploadedDocumentsListSelected.forEach((element, i) => {
      this.ppData[i].practices = this.practices;
      this.uploadedDocumentsListSelected[i].uploadedPracticesListSelected = [];
      this.uploadedDocumentsListSelected[i].uploadedPhysicanListSelected = [];
    });
  }

  onPhysicanItemSelect(item: any, i) {
    if (i == 0) {
      this.tempPhysician01 = this.s3Files[0].uploadedPhysicanListSelected;
    }
  }

  onPhysicanSelectAll(item: any, i) {
    if (i == 0) {
      item.forEach((element, i) => {
        this.tempPhysician01.push(element);
      });
    }
  }

  onPhysicanItemDeSelect(item: any, i) {
    if (item.length <= 0 && i == 0) {
      this.tempPhysician01 = [];
    }
  }

  async listData(i, file) {
    if (this.physicianInfo1.length <= 0) {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.pleaseSelectPhysicianOrPractice
      );
      return false;
    }
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.uploadDocumentComp.listData
    );
    if (alertStatus) {
      if (i == 0) {
        if (this.physicianInfo1 == 'all') {
          this.tempPractice01.push(
            this.practices.find((x) => x.company_url == this.practiceInfo1)
          );
        } else {
          this.physicianInfo1.forEach((element) => {
            const physicianToAdd = this.physicans1.find((x) => x.physician_id === element);
            if (physicianToAdd && !this.finalPhysician01.some((x) => x.physician_id === physicianToAdd.physician_id)) {
              this.finalPhysician01.push(physicianToAdd);
              console.log("physicans1  ", this.finalPhysician01);
              this.tempPhysician01.push(physicianToAdd);
            }
          });
        }
        this.show01 = true;
        this.practiceInfo1 = '';
        this.physicans1 = [];
        this.physicianLength1 = false;
      }

      this.isDisableSubmitBtn = false;
      this.fileInput.nativeElement.value = null;
    }
  }

  getFileDetails(file, i) {
  }

  uploadFileOnS3(file, arrayData) {
    const contentType = file.type;
    const bucket = new S3({
      accessKeyId: ConstantHelper.AWS_ACCESS_KEY,
      secretAccessKey: ConstantHelper.AWS_SECRET_KEY,
      region: ConstantHelper.AWS_REGION,
    });
    const params = {
      Bucket: ConstantHelper.AWS_BUCKET,
      Key: file.name,
      Body: file,
      ContentType: contentType,
    };
    const finalData = [];
    return bucket.upload(params, function(err, data) {
      if (err) {
        console.error('There was an error uploading your file: ', err);
        return false;
      }
      const obj = {
        uploadedPracticesListSelected: [],
        uploadedPhysicanListSelected: [],
        RecordId: 0,
        fileUrl: data.Location,
        DocumentName: file.name,
      };

      if (!arrayData.find((x) => x.DocumentName == obj.DocumentName)) {
        arrayData.push(obj);
      }
      return obj;
    });
  }

  async uploadFileOnAzure(file: File, arrayData: any[]): Promise<{ docUrl: string, docName: string }> {
    this.fileToUpload = file;
    this.showLoader = true;
    return new Promise<{ docUrl: string, docName: string }>((resolve, reject) => {
        this.uploadService
            .uploadFileService(
                this.fileToUpload,
                this.loggedInUserId,
            )
            .subscribe(
                async (data: any) => {
                    this.showLoader = false;
                    const obj = {
                        uploadedPracticesListSelected: [],
                        uploadedPhysicanListSelected: [],
                        RecordId: 0,
                        fileUrl: data.commandResult.data.fileUrl,
                        DocumentName: file.name,
                    };
                    if (!arrayData.find((x) => x.DocumentName == obj.DocumentName)) {
                        arrayData.push(obj);
                    }
                    const { fileUrl, DocumentName } = obj;
                    resolve({ docUrl: fileUrl, docName: DocumentName });
                },
                (error) => {
                    reject(error);
                }
            );
    });
}


  setClass() {
    this.setStatus();
    if (this.files.length > 0) {
      return 'validField';
    } else {
      return 'invalidField';
    }
  }

  setStatus() {
    // (this.selectedItems.length > 0) ? this.requiredField = true : this.requiredField = false;
  }

  onChangePractice(companyUrl, i) {
    const reqData = {
      company_slug: companyUrl,
      loggedInUserId: this.loggedInUserId,
      i,
    };
    // this.showLoader=true;

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findUserDoctorsByPracticeApi,
        reqData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (i == 0) {
              if(companyUrl == 0){
                this.showNameAndFolderName1 = true;
              }
            this.physicans1 = apiResponse.commandResult.data.companiesList;
            this.disabled1 = false;
            if (Array.isArray(this.physicans1) && this.physicans1.length > 0) {
              this.physicianLength1 = true;
            } else {
              this.physicianLength1 = false;
            }
          }
          this.isDisableSubmitBtn = false;
          // this.showLoader=false;
        },
        (err) => {
        }
      );
  }

  onChangePhysician(value, j) {
    if (value == 'all' && j == 0) {
      // this.disabled1 = true;
    }
    if (value == 'all' && j == 1) {
      // this.disabled2 = true;
    }
    if (value == 'all' && j == 2) {
      // this.disabled3 = true;
    }
    if (value == 'all' && j == 3) {
      // this.disabled4 = true;
    }
    if (value == 'all' && j == 4) {
      // this.disabled5 = true;
    }

    //disable1
  }

  fndPremission(RecordId, i) {
    if (
      i == 0 &&
      this.uploadedDocumentsList.find((x) => x.RecordId == RecordId)
    ) {
      this.previou_permission1 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previou_permissions;
      this.previou_practice_permission1 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previouse_practice_permissions;
      // console.log(this.previou_practice_permission1);
    }
  }

  fnChangeDocumentPhysicianNameFormat(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      let nameArr = e.physician.split('(');
      e.physician =
        this.userService.getUS_FromatName(
          nameArr[0].replace(/^\s+|\s+$/g, '')
        ) +
        ' (' +
        nameArr[1];
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(this.userService.dynamicSort('physician'));
  }

removeDocument(recordId) {
  this.disableSelectable = false;
  const index = this.s3Files.findIndex((x) => x.RecordId === recordId);
  if (index !== -1) {
    this.s3Files.splice(index, 1);
    if (index === 0) {
      this.practiceInfo1 = '';
      this.physicans1 = [];
      this.physicianLength1 = false;
      this.previou_permission1 = null;
      this.previou_practice_permission1 = null;
    } 
}
  }

  async selectAssociatedDoc(event) {
    if(this.s3Files.length > 1){
      this.s3Files.splice(1);
    }
    const file: File = event.target.files[0];
    const maxFileSize = 20 * 1024 * 1024;
    if (file) {
      if (file.size <= maxFileSize) {
        if (!this.validateFile(event.target.value)) {
          await this.sweetAlertHelper.alertPopUp(
            this.message.alertMessages.uploadDocComp.selectValidFile
          );
          event.target.value = null;
        } else {
            if (
              this.uploadedDocumentsList.find(
                (x) => x.DocumentName == event.target.files[0].name
              )
            ) {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.uploadDocComp.onFileSelected2 + ' ' + event.target.files[0].name + this.message.alertMessages.uploadDocComp.onFileSelected3
              );
            } else {
              const {docUrl, docName} = await this.uploadFileOnAzure(event.target.files[0] as File, this.s3Files);
              this.associatedFileUrl = docUrl;
              this.associatedFileName = docName;
            }

        }
      } else {
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.uploadDocComp.pleaseSelectLessThan20MB
        );
      }
    }

  }

  isFileInputDisabled(): boolean {
    return this.selectedOption == false;
}

async checkAssociateDocument(event) {
  const selectedValue = event.target.value;
  if (selectedValue !== "") {
    if (selectedValue > '0') {
      this.showAssociateDocumentUpload = true;
  } else{
      this.showAssociateDocumentUpload = false;
    }
  }
}

summarizeReport(documentUrl: string, extension: string): Observable<any> {
  const Pdfpayload = {
    question: 'Summarize the report',
    pdf_blob_urls: [documentUrl]
  };

  const videoPayload = {
    video_data: documentUrl,
    video_question: "if it is MRI/CT scan or X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points"
  }

  const imagePayload = {
    img_data: documentUrl,
    question: "For the the given image if it is MRI/CT scan or  X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points" 
  }

  if(extension == 'PDF'){
    return this.http.post<any>(this.pdfApiUrl, Pdfpayload);
  } else if (extension == 'Image'){
    return this.http.post<any>(this.imageApiUrl, imagePayload);
  } else if (extension == 'Video'){
    return this.http.post<any>(this.videoApiUrl, videoPayload);
  }
}

// generateSummary(documentUrl: string, extension: string): void {
//   this.showLoader = true;
//   this.summarizeReport(documentUrl, extension).subscribe(
//     (response) => {
//       this.showLoader = false;
//       console.log("AI Summary", response.answer);
//       this.aiSummary = response.answer
//     },
//     (error) => {
//       this.showLoader = false;
//       console.error(error);
//     }
//   );
// }

async generateSummary(documentUrl: string, extension: string): Promise<string> {
  try {      
      if (extension === 'PDF' || extension === 'Image' || extension === 'Video') {
        this.showLoader = true;
          const response = await this.summarizeReport(documentUrl, extension).toPromise();
          this.summaryAnswer = response.answer;
          this.showLoader = false;
      } else {
          console.error('Unknown document type');
      }
      return this.summaryAnswer;
  } catch (error) {
      console.error('Error generating summary:', error);
      throw error;
  }
}

async checkDocumentType(url: string): Promise<string> {
  this.showLoader = true;
  try {
    const response = await this.http.get(url, { responseType: 'blob' }).toPromise();
    const contentType = response.type;
    if (contentType === 'application/pdf') {
      this.showLoader = false;
      return 'PDF';
    } else if (contentType.startsWith('image/')) {
      this.showLoader = false;
      return 'Image';
    } else if (contentType.startsWith('video/')) {
      this.showLoader = false;
      return 'Video';
    } else {
      this.showLoader = false;
      console.log('Unknown document type');
      return 'Unknown';
    }
  } catch (error) {
    console.error('Error fetching document:', error);
    throw error;
  }
}

// async generateAiSummaryOnDocumentUpload() {
//   try {
//       const extension = await this.checkDocumentType(this.documentUrlToGenerateAiSummary);
//       await this.generateSummary(this.documentUrlToGenerateAiSummary, extension);
//       const finalData = {
//           documentId: this.documentIdToGenerateAiSummary,
//           aiSummary: this.aiSummary
//       };
//       this.showLoader = true;
//       await this.http.post(this.appConfig.getLambdaUrl05() + ApiHelper.generateAiSummaryByClick, finalData, this.httpOptions).toPromise();
//       this.showLoader = false;
//       window.location.reload();
//       // Handle the API response here
//   } catch (err) {
//       console.error(err);
//       // Handle error
//   }
// }

async generateAiSummaryOnDocumentUpload() {
  try {
      const extension = await this.checkDocumentType(this.documentUrlToGenerateAiSummary);
      await this.generateSummary(this.documentUrlToGenerateAiSummary, extension);
      const finalData = {
          documentId: this.documentIdToGenerateAiSummary,
          aiSummary: this.summaryAnswer
      };
      this.showLoader = true;
      await this.http.post(this.appConfig.getLambdaUrl05() + ApiHelper.generateAiSummaryByClick, finalData, this.httpOptions).toPromise();
      this.showLoader = false;
      window.location.reload();
      // Handle the API response here
  } catch (err) {
      console.error(err);
      // Handle error
  }
}

getMaxDate(): string {
  const currentDate = new Date();
  // Convert to string in yyyy-MM-dd format
  return currentDate.toISOString().split('T')[0];
}
}
