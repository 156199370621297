import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";

@Component({
  selector: "app-document-list",
  templateUrl: "./document-list.component.html",
  styleUrls: ["./document-list.component.scss"]
})
export class DocumentListComponent implements OnInit {
  loggedInUserId: string;
  resultJsonObj: any = {};
  companiesLength: boolean = false;
  companiesList: any;
  selectedDocId: string;
  selectedDocUrl: string;
  selectedDocName: string;
  showPdfModal: boolean = false;

  documentsLength: boolean = false;
  noDocumentsLength: boolean = false;
  loadingDocumentsLength: boolean = true;

  selectedCompanySlug: string;

  documentsList: any;
  ahsPdfUrl: SafeResourceUrl = "";

  docDeactivateSuccess: boolean = false;
  docActivateSuccess: boolean = false;
  docErrorFlashMsg: boolean = false;

  apiURl: string;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });
  httpOptions = {
    headers: this.httpHeaders
  };

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get(
      "loggedInUserId"
    );
    const finalData = {
      loggedInUserId: this.loggedInUserId
    };

    this.http
      .post(
        this.appConfig.getLambdaUrl02() +
        ApiHelper.name_getAllAdminDocumentsListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          this.loadingDocumentsLength = false;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList = this.resultJsonObj.commandResult.data.documentList;
            // console.log(this.documentsList);
          } else {
            this.noDocumentsLength = true;
          }
        },
        err => {
          // console.log(err);
        }
      );

    this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_getCompaniesListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.companyList.length > 0) {
            this.companiesLength = true;
            this.companiesList = this.resultJsonObj.commandResult.data.companyList;

            // console.log(this.companiesList);
          } else {
            this.companiesLength = false;
          }
        },
        err => {
          // console.log(err);
        }
      );
  }

  findAllDocumentByCompany(company_slug) {
    this.loadingDocumentsLength = true;
    this.documentsLength = false;
    this.noDocumentsLength = false;
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");

    const finalData = {
      company_slug: company_slug,
      loggedInUserId: this.loggedInUserId
    };

    if (company_slug == ConstantHelper.ALL_COMPANIES_VALUE) {
      this.selectedCompanySlug = "";
      this.apiURl =
        this.appConfig.getLambdaUrl02() +
        ApiHelper.name_getAllAdminDocumentsListApi;
    } else {
      this.selectedCompanySlug = company_slug;
      this.apiURl =
        this.appConfig.getLambdaUrl02() +
        ApiHelper.name_getAllAdminDocumentsListByCompanyApi;
    }

    return this.http.post(this.apiURl, finalData, this.httpOptions).subscribe(
      apiResponse => {
        this.loadingDocumentsLength = false;
        this.resultJsonObj = apiResponse;
        // console.log(this.resultJsonObj);
        if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
          this.documentsLength = true;
          this.documentsList = this.resultJsonObj.commandResult.data.documentList;
        } else {
          this.noDocumentsLength = true;
        }
      },
      err => {
        // console.log(err);
      }
    );
  }

  disableDoc(DocID) {
    const finalData = {
      doc_id: DocID,
      loggedInUserId: this.loggedInUserId,
      company_slug: this.selectedCompanySlug
    };

    this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_deactivateDocApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.status == 1) {
            if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
              this.documentsLength = true;
              this.documentsList = this.resultJsonObj.commandResult.data.documentList;
              // console.log(this.documentsList);
            } else {
              this.documentsLength = false;
            }
            this.docDeactivateSuccess = true;
            setTimeout(() => {
              this.docDeactivateSuccess = false;
            }, 2000);
          } else {
            this.docErrorFlashMsg = true;
            setTimeout(() => {
              this.docErrorFlashMsg = false;
            }, 3000);
          }
        },
        err => {
          // console.log(err);
        }
      );
  }

  enableDoc(DocID) {
    const finalData = {
      doc_id: DocID,
      loggedInUserId: this.loggedInUserId,
      company_slug: this.selectedCompanySlug
    };

    this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_activateDocApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.status == 1) {
            if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
              this.documentsLength = true;
              this.documentsList = this.resultJsonObj.commandResult.data.documentList;
              // console.log(this.documentsList);
            } else {
              this.documentsLength = false;
            }
            this.docActivateSuccess = true;
            setTimeout(() => {
              this.docActivateSuccess = false;
            }, 2000);
          } else {
            this.docErrorFlashMsg = true;
            setTimeout(() => {
              this.docErrorFlashMsg = false;
            }, 3000);
          }
        },
        err => {
          // console.log(err);
        }
      );
  }

  changeShowOnConfirmationStatus(DocID, statusId) {
    const finalData = {
      doc_id: DocID,
      status_id: statusId,
      loggedInUserId: this.loggedInUserId,
      company_slug: this.selectedCompanySlug
    };

    this.http
      .post(
        this.appConfig.getLambdaUrl02() +
        ApiHelper.name_changeShowOnConfirmationStatusApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.status == 1) {
            if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
              this.documentsLength = true;
              this.documentsList = this.resultJsonObj.commandResult.data.documentList;
              // console.log(this.documentsList);
            } else {
              this.documentsLength = false;
            }
            this.docActivateSuccess = true;
            setTimeout(() => {
              this.docActivateSuccess = false;
            }, 2000);
          } else {
            this.docErrorFlashMsg = true;
            setTimeout(() => {
              this.docErrorFlashMsg = false;
            }, 3000);
          }
        },
        err => {
          // console.log(err);
        }
      );
  }

  closePdfModal() {
    this.showPdfModal = false;
  }

  loadDocunent(docId, docUrl, docName) {
    this.selectedDocId = docId;
    this.selectedDocUrl = docUrl;
    this.selectedDocName = docName;
    this.showPdfModal = true;
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.selectedDocUrl
    );
  }

  openAddDocPage() {
    this.router.navigate([
      "/documents/add/" + this.loggedInUserId + "/" + this.selectedCompanySlug
    ]);
  }

  openModifyDocPage(doc_id, doc_name) {
    this.router.navigate([
      "/documents/modify/" +
      this.loggedInUserId +
      "/" +
      doc_id +
      "/" +
      doc_name +
      "/" +
      this.selectedCompanySlug
    ]);
  }
}
