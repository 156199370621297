import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";

@Component({
  selector: "app-send-email",
  templateUrl: "./send-email.component.html",
  styleUrls: ["./send-email.component.scss"],
})
export class SendEmailComponent implements OnInit {
  blastMailTypeVal: any = "4";
  maxChars = 700;
  fileDesTxt: any = "";
  emailSubject: any = "";
  loggedInUserId: string;
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  selectedRoles: any = [];

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
  }

  async onReset() {
    // if (confirm('PLEASE CONFIRM: Are you certain you want to return to the Home Page?'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }
  async goToDemographics() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the User Database?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.reportComp.goToDemographics
    );
    if (alertStatus) {
      this.router.navigate(["/demographics/" + this.loggedInUserId]);
    }
  }

  getCheckedBoxValue() {
    const allCheck: any = document.getElementsByClassName("docChecks");
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < allCheck.length; index++) {
      const element = allCheck[index];
      if (element.checked) {
        this.selectedRoles.push(element.value);
      }
    }
  }

  async onSubmit() {
    this.getCheckedBoxValue();
    if (this.selectedRoles.length <= 0) {
      // alert("Please select at least one role");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.sendEmailComp.onSubmit1
      );

      return true;
    }
    // if (confirm("PLEASE CONFIRM: Are you sure you want to send email?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.sendEmailComp.onSubmit
    );
    if (alertStatus) {
      const finalData = {
        emailSubject: this.emailSubject,
        fileDesTxt: this.fileDesTxt,
        loggedInUserId: this.loggedInUserId,
        selectedRoles: this.selectedRoles,
      };
      this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_sendEmail2UsersApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (res: any) => {
            // alert("Email successfully sent to users.");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.sendEmailComp.onSubmit
            );
            window.location.reload();
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  setEmailContent(e) {
    this.blastMailTypeVal = e.target.value;
    if (this.blastMailTypeVal == "1") {
      this.emailSubject = "The AHS Elemrex platform is temporarily unavailable";
      // tslint:disable-next-line: max-line-length
      this.fileDesTxt =
        "The Elemrex Platform is temporarily unavailable for scheduled maintenance.\n\nPlease note that the platform will be unavailable from (beginning date and time) to (ending date and time).\n\n\nThank you for your understanding. We apologize for any inconvenience our routine maintenance may have caused.\n\n\nAHS Support Team.";
    } else if (this.blastMailTypeVal == "2") {
      this.emailSubject =
        "The AHS Elemrex platform services are temporarily unavailable";
      // tslint:disable-next-line: max-line-length
      this.fileDesTxt =
        "AHS's Elemrex Platform services have been temporarily interrupted. services are back online\nPlease forgive this interruption as we actively investigate and resume Platform. \nservices as quickly as possible. You will be notified immediately by email once Platform.\n\nThank you for your understanding. We apologize for any inconvenience this temporary interrupted may have caused.\n\nAHS Support Team.";
    } else if (this.blastMailTypeVal == "3") {
      this.emailSubject = "AHS Elemrex Platform services are restored!";
      // tslint:disable-next-line: max-line-length
      this.fileDesTxt =
        "We appreciate your patience. AHS Elemrex Platform services have been successfully restored!\n\nThank you for your understanding and again, we apologize for any inconvenience we may have caused.\n\nAHS Support Team.";
    } else {
      this.emailSubject = "";
      this.fileDesTxt = "";
    }
  }
}
