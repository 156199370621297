import {Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {SignaturePad} from 'angular2-signaturepad';
import {FormBuilder} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';


import {AppConfig} from './../../../../app/app.config';
import {Message} from './../../../_locale/messages';
import {SweetAlertHelper} from './../../../_helpers/sweet.alert.helper';
import {CommonHelper} from './../../../_helpers/common.helper';
import {ConstantHelper} from './../../../_helpers/constant.helper';
import {ApiHelper} from './../../../_helpers/api.helper';


@Component({
  selector: 'app-sign-hipaa',
  templateUrl: './sign-hipaa.component.html',
  styleUrls: ['./sign-hipaa.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class SignHipaaComponent implements OnInit {

  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  clean = '0';
  @ViewChild(SignaturePad, {static: false}) signaturePad: SignaturePad;
  @ViewChild('canvasArea', {static: true}) canvasArea: ElementRef;
  canvasElm: any;
  public signaturePadOptions: object = {
    minWidth: 1,
    canvasWidth: window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
    canvasHeight: 450,
  };

  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  showLoader = false;
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient
  ) {
  }

  ngOnInit() {
    this.clean = this.activatedRoute.snapshot.paramMap.get('clean');
    this.loggedInUserId = localStorage.getItem('delegateId') ? localStorage.getItem('delegateId') : this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.demographicId = localStorage.getItem('MinorDemographicId') ? localStorage.getItem('MinorDemographicId') : this.activatedRoute.snapshot.paramMap.get('demographicId');

    if (this.clean === '1') {
      this.cleanTempDocs();
    }
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 1); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }

  async saveCanvas1() {
    if (this.signaturePad.isEmpty() === true) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.signatureWaiverComp.saveCanvas);
    } else {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(this.message.confirmMessages.signatureWaiverComp.saveCanvas);
      if (alertStatus) {
        const finalData1 = {
          loggedInUserId: this.loggedInUserId,
        };
        this.showLoader = true;

        return this.http
          .post(
            this.appConfig.getPhpUrl() + ApiHelper.php_deactivateUserDocsApi,
            finalData1,
            this.httpOptions
          )
          .subscribe(
            (apiResponseDoc) => {
              const finalData = {
                loggedInUserId: this.loggedInUserId,
                demographicId: this.demographicId,
                signatureBase64: this.signaturePad.toDataURL(),
                company_slug: null,
              };
              this.http
                .post(
                  this.appConfig.getLambdaUrl05() +
                  ApiHelper.name_generateSignatureImageApi,
                  finalData,
                  this.httpOptions
                )
                .subscribe(
                  (apiResponse) => {
                    this.resultJsonObj = apiResponse;
                    this.http
                      .post(
                        this.appConfig.getPhpUrl() +
                        ApiHelper.php_generateDemographicPdfApi,
                        finalData,
                        this.httpOptions
                      )
                      .subscribe(
                        (apiDemoResponse: any) => {
                          this.http
                            .post(
                              this.appConfig.getPhpUrl() +
                              ApiHelper.php_generateSignatureFilesPdfApi,
                              finalData,
                              this.httpOptions
                            )
                            .subscribe(
                              async (apiSignResponse: any) => {
                                this.showLoader = false;
                                if (
                                  this.resultJsonObj.commandResult.status === 1
                                ) {
                                  this.router.navigate(['/profile/review-doc/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.activatedRoute.snapshot.paramMap.get('demographicId')]);
                                } else {
                                  await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.demographics.onSubmit);
                                }
                              },
                              (err) => {
                                // console.log(err);
                              }
                            );
                        },
                        (err) => {
                          // console.log(err);
                        }
                      );
                  },
                  (err) => {
                    // console.log(err);
                  }
                );
            },
            (err) => {
              // console.log(err);
            }
          );
      }
    }
  }

  async clearCanvas() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.njComp.clearCanvas);
    if (alertStatus) {
      this.signaturePad.clear();
    }
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log("begin drawing");
  }

  editDemographicForm4() {
    this.router.navigate(['/edit-demographic-4/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.activatedRoute.snapshot.paramMap.get('demographicId')]);
  }

  goToWaiverDisclaimerForm() {
    this.router.navigate(['/waiverdisclaimerform/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.activatedRoute.snapshot.paramMap.get('demographicId')]);
  }

  goToDemographicForm() {
    this.router.navigate(['/demographicsform/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId')]);
  }


  async goToWelcome() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.signatureWaiverComp.goToWelcome);
    if (alertStatus) {
      this.router.navigate(['/dashboard/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/1']);
    }
  }

  cleanTempDocs() {
    const finalData = {
      id: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_cleanTempDocsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async saveCanvas() {
    if (this.signaturePad.isEmpty() === true) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.signatureWaiverComp.saveCanvas);
    } else {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(this.message.confirmMessages.signatureWaiverComp.saveCanvas);
      if (alertStatus) {
        this.showLoader = true;
        const finalData = {
          loggedInUserId: this.loggedInUserId,
          demographicId: this.demographicId,
          signatureBase64: this.signaturePad.toDataURL(),
          company_slug: null,
        };
        return this.http
          .post(
            this.appConfig.getLambdaUrl05() + ApiHelper.generateTempSignatureImage,
            finalData,
            this.httpOptions
          )
          .subscribe(
            (apiResponse) => {

              this.http
                .post(
                  this.appConfig.getPhpUrl() + ApiHelper.php_generate_demographic_temp_Api,
                  finalData,
                  this.httpOptions
                )
                .subscribe(
                  (apiDemoResponse: any) => {
                    this.http
                      .post(
                        this.appConfig.getPhpUrl() + ApiHelper.php_generate_signature_files_pdf_temp_Api,
                        finalData,
                        this.httpOptions
                      )
                      .subscribe(
                        async (apiSignResponse: any) => {
                          this.showLoader = false;
                          this.resultJsonObj = apiSignResponse;
                          if ( this.resultJsonObj.commandResult.success === 1
                          ) {
                            this.router.navigate(['/profile/review-doc/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.activatedRoute.snapshot.paramMap.get('demographicId')]);
                          } else {
                            await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.demographics.onSubmit);
                          }
                        },
                        (err) => {
                          // console.log(err);
                        }
                      );
                  },
                  (err) => {
                    // console.log(err);
                  }
                );
            },
            (err) => {
              // console.log(err);
            }
          );


      }
    }
  }


}
