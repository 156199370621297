import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit {
  emails: any = "";
  startDate: any = "";
  endDate: any = "";
  loggedInUserId: string;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
  }

  async goToDemographics() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the User Database?Are you certain you want to return to the User Database?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.reportComp.goToDemographics
    );
    if (alertStatus) {
      this.router.navigate(["/demographics/" + this.loggedInUserId]);
    }
  }

  async onSubmit() {
    // if (confirm("PLEASE CONFIRM: Are you sure you want to send report?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.reportComp.onSubmit
    );
    if (alertStatus) {
      const finalData = {
        emails: this.emails,
        startDate: this.startDate,
        endDate: this.endDate,
        loggedInUserId: this.loggedInUserId,
      };
      this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_generateReportApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (res: any) => {
            // alert("Report successfully sent on Email.");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.reportsComp.onSubmit
            );

            window.location.reload();
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }
}
