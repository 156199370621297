import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


import { environment } from './../../environments/environment';
import { ApiHelper } from './../_helpers/api.helper';
import { CommonHelper } from './../_helpers/common.helper';
import { ConstantHelper } from './../_helpers/constant.helper';
import { AppConfig } from './../../app/app.config';

@Injectable({
  providedIn: 'root'
})
export class DoctorMailService {
  constructor(private http: HttpClient,
    public commonHelper: CommonHelper,
    private appConfig: AppConfig) {
  }



  // tslint:disable-next-line: max-line-length
  doctor2DoctorEmail06_Delete(userId, companySlug, physicianType, stateCode, cityName, physicianId, fileDesTxt, docType, userOptionId, fileToUpload, selectedDocsId, hippaFileDesTxt, showNewFile, attachmentDoc, selectedEmail) {
    const formData = new FormData();
    if (fileToUpload) {
      formData.append('fileKey', fileToUpload, fileToUpload.name);
    }
    formData.append('requestedPhysicianEmail', selectedEmail);
    formData.append('loggedInUserId', userId);
    formData.append('userOptionId', userOptionId);
    formData.append('physicianType', physicianType);
    formData.append('selectedDocsId', selectedDocsId);
    formData.append('companySlug', companySlug);
    formData.append('selectedStateCode', stateCode);
    formData.append('selectedCityName', cityName);
    formData.append('selectedPhysicianId', physicianId);
    formData.append('hippaFileDesTxt', hippaFileDesTxt);
    formData.append('fileDesTxt', fileDesTxt);
    formData.append('docType', docType);
    formData.append('showNewFile', showNewFile);
    formData.append('attachmentDocObj', attachmentDoc);

    return this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_docotr2doctorEmail06Api, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.Response:
          return event.body;
      }
    })
    );
  }

  docotr2doctorEmail_Patient(
    userId,
    companySlug,
    physicianType,
    physicianId,
    specialtyId,
    subSpecialtyId,
    fileDesTxt,
    docType,
    userOptionId,
    fileToUpload,
    selectedDocsId,
    hippaFileDesTxt,
    showNewFile,
    attachmentDoc,
    requestingPhyMobile,
    requestingPhyEmail,
    reuestTime) {
    const formData = new FormData();
    if (fileToUpload) {
      formData.append('fileKey', fileToUpload, fileToUpload.name);
    }
    formData.append('loggedInUserId', userId);
    formData.append('companySlug', companySlug);
    formData.append('physicianType', physicianType);
    formData.append('physicianId', physicianId);
    formData.append('specialtyId', specialtyId);
    formData.append('subSpecialtyId', subSpecialtyId);
    formData.append('fileDesTxt', fileDesTxt);
    formData.append('docType', docType);
    formData.append('userOptionId', userOptionId);
    formData.append('selectedDocsId', selectedDocsId);
    formData.append('hippaFileDesTxt', hippaFileDesTxt);
    formData.append('showNewFile', showNewFile);
    formData.append('attachmentDocObj', attachmentDoc);
    formData.append('requestingPhyMobile', requestingPhyMobile);
    formData.append('requestingPhyEmail', requestingPhyEmail);
    formData.append('reuestTime', reuestTime);

    return this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_docotr2doctorEmail_PatientApi, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.Response:
          return event.body;
      }
    })
    );
  }





}
