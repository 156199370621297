import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';

declare let $: any;
import {AppConfig} from './../../../../app/app.config';
import {Message} from './../../../_locale/messages';
import {SweetAlertHelper} from './../../../_helpers/sweet.alert.helper';
import {CommonHelper} from './../../../_helpers/common.helper';
import {ApiHelper} from './../../../_helpers/api.helper';

@Component({
  selector: 'app-accept-hipaa',
  templateUrl: './accept-hipaa.component.html',
  styleUrls: ['./accept-hipaa.component.scss']
})
export class AcceptHipaaComponent implements OnInit {


  isChecked = false;
  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  documentsLength = false;
  documentsList: any;
  ahsPdfUrl: SafeResourceUrl = '';
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  showDeleteButton = false;
  showLoader = false;
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.loggedInUserId = localStorage.getItem('delegateId') ? localStorage.getItem('delegateId') : this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.demographicId = localStorage.getItem('MinorDemographicId') ? localStorage.getItem('MinorDemographicId') : this.activatedRoute.snapshot.paramMap.get('demographicId');

    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
        ApiHelper.name_getSignatureDocsByCompanyGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.showLoader = false;

          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );

    $(document).ready(function() {
      $(window).resize(function() {
        setHeightResizeFunc();
      });
      setHeightResizeFunc();
    });

    function setHeightResizeFunc() {
      const rightHeight =
        $('.right-box .ibox-title').height() +
        $('.right-box .ibox-content').height() -
        45;
      $('.docLists').height(rightHeight);
    }
  }


  goToDemographicForm() {
    this.router.navigate([
      '/demographicsform/' +
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
    ]);
  }

  async goToSignatureForm() {
    if ($('.com').not(':checked').length > 0) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.confirmationComp.goToSignatureForm
      );
    } else {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.confirmationComp.goToSignatureForm
      );

      if (alertStatus) {
        this.router.navigate([
          '/profile/signature/' +
          this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
          '/' +
          this.activatedRoute.snapshot.paramMap.get('demographicId'),
        ]);
      }
    }
  }

  loadHippaDoc(event, documentUrl: any, i) {
    // console.log(event.target.checked);
    if (event.target.checked) {
      document.getElementById('hipaaAccepted' + i).removeAttribute('disabled');
      document
        .getElementById('hipaaAccepted' + i)
        .setAttribute('checked', 'checked');

      this.ahsPdfUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
      this.showDeleteButton = true;
    } else {
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
      this.isChecked = false;
    }
  }

  // checkAllHippaDoc(isChecked) {
  // console.log(isChecked);

  //   if (confirm("PLEASE CONFIRM: By clicking ‘Select All’ you are waiving your need to review each document individually.")) {
  //     if (isChecked == 1) {
  //       $('.docChecks').each(function () {
  //         $(this).prop("checked", true);
  //       });

  //     } else {
  //       $('.docChecks').each(function () {
  //         $(this).prop("checked", false);
  //       });
  //     }

  //   } else {
  //     if (isChecked == 1) {
  //       $('.selectAll').prop("checked", false);

  //     } else {
  //       $('.selectAll').prop("checked", true);
  //     }

  //   }
  // }

  async checkAllHippaDoc(isChecked) {
    if (isChecked == 1) {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.confirmationComp.checkAllHippaDoc
      );
      // if (
      //   confirm(
      //     "PLEASE CONFIRM: By clicking the ‘Select All’ box: You agree to waive reviewing of each document separately prior to signing them all."
      //   )
      // )
      if (alertStatus) {
        $('.docChecks').each(function() {
          $(this).prop('checked', true);
        });
        document.getElementById('selectAllH5').innerHTML = 'Unselect All';
      } else {
        $('.selectAll').prop('checked', false);
        $('.docChecks').each(function() {
          $(this).prop('checked', false);
        });
      }
    } else {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.confirmationComp.checkAllHippaDoc1
      );

      // if (
      //   confirm(
      //     "PLEASE CONFIRM: By clicking the ‘Unselect All’, then ‘OK’: You are restoring the ability to review each document separately prior to signing."
      //   )
      // )
      if (alertStatus) {
        document.getElementById('selectAllH5').innerHTML = 'Select All';
        $('.selectAll').prop('checked', false);
        $('.docChecks').each(function() {
          $(this).prop('checked', false);
        });
      }
    }

    // if (confirm("PLEASE CONFIRM: By clicking ‘Select All’ you are waiving your need to review each document individually.")) {
    //   if (isChecked == 1) {
    //     $('.docChecks').each(function () {
    //       $(this).prop("checked", true);
    //     });

    //   } else {
    //     $('.docChecks').each(function () {
    //       $(this).prop("checked", false);
    //     });
    //   }

    // } else {
    //   if (isChecked == 1) {
    //     $('.selectAll').prop("checked", false);

    //   } else {
    //     $('.selectAll').prop("checked", true);
    //   }

    // }
  }

  async goToWelcome() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.confirmationComp.goToWelcome
    );
    // confirm(
    //   "PLEASE CONFIRM:  By clicking ‘Reject’ then ‘OK’:You are not planning to sign the required documents and will be returned to the ‘Home Page’."
    // )
    if (alertStatus) {
      this.router.navigate([
        '/dashboard/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/1',
      ]);
    }
  }

  async clearViewr() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.confirmationComp.clearViewr
    );
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to clear preview?"))
    if (alertStatus) {
      $('.selectAll').prop('checked', false);
      $('.docChecks').each(function() {
        $(this).prop('checked', false);
      });
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
      this.showDeleteButton = false;
    }
  }

}
