import { Component, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { CSVRecord } from "./CSVRecord";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/messages";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-import-physician",
  templateUrl: "./import-physician.component.html",
  styleUrls: ["./import-physician.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class ImportPhysicianComponent implements OnInit {
  resultJsonObj: any = {};

  public records: any[] = [];
  @ViewChild("csvReader", { static: false }) csvReader: any;

  loggedInUserId: string;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}
  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
  }
  onSubmit() {
    // console.log("on submit"+JSON.stringify(this.records));

    const finalData = {
      physiciandata: this.records,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_saveBulkPhysiciansApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // this.loadingUsersLength = false;
          //  console.log("----------->"+this.resultJsonObj.commandResult.message);
          this.goToPhysicians();
          if (this.resultJsonObj.commandResult.data.physiciansList.length > 0) {
            //  this.usersLength = true;
            //  this.usersList = this.resultJsonObj.commandResult.data.physiciansList;
          } else {
            //  this.noUsersLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }
  uploadListener($event: any): void {
    let text = [];
    let files = $event.srcElement.files;

    if (this.isValidCSVFile(files[0])) {
      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

        let headersRow = this.getHeaderArray(csvRecordsArray);

        this.records = this.getDataRecordsArrayFromCSVFile(
          csvRecordsArray,
          headersRow.length
        );
        // console.log("---Amrit--->"+JSON.stringify(this.records));
      };

      reader.onerror = function () {
        // console.log('error is occured while reading file!');
      };
    } else {
      alert("Please import valid .csv file.");
      //  const alertStatus = await this.sweetAlertHelper.alertPopUp(
      //     this.message.alertMessages.importPhysicianComp.uploadListner
      //   );

      this.fileReset();
    }
  }
  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];
    // console.log("---->"+csvRecordsArray.length);
    for (let i = 1; i < 500; i++) {
      let curruntRecord = (<string>csvRecordsArray[i]).split(",");
      if (curruntRecord.length == headerLength) {
        let csvRecord: CSVRecord = new CSVRecord();
        //  csvRecord.npi = curruntRecord[0].trim();
        // csvRecord.lastName = curruntRecord[1].trim();
        // csvRecord.firstName = curruntRecord[2].trim();
        //  console.log("++++++++++++>"+curruntRecord[0].trim().replace(/"/g, ''));

        csvRecord.npi = curruntRecord[0].trim().replace(/"/g, "");
        csvRecord.npiProviderEnumDate = curruntRecord[36]
          .trim()
          .replace(/"/g, "");
        csvRecord.npiLastUpdateDate = curruntRecord[37]
          .trim()
          .replace(/"/g, "");
        csvRecord.npiDeactivationDate = curruntRecord[39]
          .trim()
          .replace(/"/g, "");
        csvRecord.npiDeactivationReasonCode = curruntRecord[38]
          .trim()
          .replace(/"/g, "");
        csvRecord.npiReactivationDate = curruntRecord[40]
          .trim()
          .replace(/"/g, "");
        csvRecord.lastName = curruntRecord[5].trim().replace(/"/g, "");
        csvRecord.firstName = curruntRecord[6].trim().replace(/"/g, "");
        csvRecord.middleName = curruntRecord[7].trim().replace(/"/g, "");
        csvRecord.suffixName = curruntRecord[9].trim().replace(/"/g, "");
        csvRecord.gender = curruntRecord[41].trim().replace(/"/g, "");
        csvRecord.nationOfPracticeLocation = curruntRecord[25]
          .trim()
          .replace(/"/g, "");
        csvRecord.primaryOfficeStateLocation = curruntRecord[23]
          .trim()
          .replace(/"/g, "");
        csvRecord.city = curruntRecord[22].trim().replace(/"/g, "");
        csvRecord.zipCode = curruntRecord[24].trim().replace(/"/g, "");
        csvRecord.streetAddress1 = curruntRecord[20].trim().replace(/"/g, "");
        csvRecord.streetAddress2 = curruntRecord[21].trim().replace(/"/g, "");
        csvRecord.officeTelephone = curruntRecord[26].trim().replace(/"/g, "");
        csvRecord.officeFax = curruntRecord[27].trim().replace(/"/g, "");
        csvRecord.degree = curruntRecord[10].trim().replace(/"/g, "");
        csvRecord.specialty = curruntRecord[47].trim().replace(/"/g, "");
        csvRecord.subspecialty = curruntRecord[51].trim().replace(/"/g, "");

        csvRecord.primaryStatePractice = curruntRecord[49]
          .trim()
          .replace(/"/g, "");
        csvRecord.primaryStateLicenseNo = curruntRecord[48]
          .trim()
          .replace(/"/g, "");
        csvRecord.secondaryStatePractice = curruntRecord[52]
          .trim()
          .replace(/"/g, "");
        csvRecord.secondaryStateLicenseNo = curruntRecord[53]
          .trim()
          .replace(/"/g, "");

        csvRecord.email = "";

        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(",");
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileReset() {
    this.csvReader.nativeElement.value = "";
    this.records = [];
  }

  goToPhysicians() {
    this.router.navigate(["/physicians/" + this.loggedInUserId]);
  }
}
