import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { AuthService } from "../auth.service";
import { MustMatch } from "../../_helpers/matchpassword";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";

import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UserService } from "../../_services/user.service";
import { Message } from "src/app/_locale/messages";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";

@Component({
  selector: "app-auth-show-d2d-documents",
  templateUrl: "./auth-show-d2d-documents.component.html",
  styleUrls: ["./auth-show-d2d-documents.component.scss"],
})
export class AuthShowD2dDocumentsComponent implements OnInit {
  @ViewChild("recaptcha", { static: false }) recaptchaElement: ElementRef;
  @HostListener("window:resize", ["$event"])
  loginForm: FormGroup;
  loginFromSubmitted = false;
  formErrorMessage = false;
  inactiveUserErrorMessage = false;
  inactiveDoctorErrorMessage = false;
  showLoginForm = true;
  verifyLoginOtpForm: FormGroup;
  verifyLoginOtpFormSubmitted = false;
  verifyLoginFormErrorMessage = false;
  showVerifyLoginOtpForm = false;
  userOtp: number;
  userId: number;
  userEmail: string;
  userMobile: string;
  userRoleId: number;
  resultJsonObj: any = {};
  formRecaptchaErrorMessage = false;

  pId: any = "";
  docEmail: any = "";
  pUserId: any = "";
  docId: any = "";
  userOptionId: any = "";
  logId: any = "";
  fieldTextType: boolean;
  companyslug: any = "";
  userData: any = {};
  showComfirmationModal = false;
  showMsgAgain = true;
  supportMail = ConstantHelper.SUPPORT_EMAIL;
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public sweetAlertHelper: SweetAlertHelper,
    private authService: AuthService,
    private http: HttpClient,
    private userService: UserService
  ) {
    this.docEmail = this.activatedRoute.snapshot.paramMap.get("docEmail");
    this.pId = this.activatedRoute.snapshot.paramMap.get("pId");
    this.findUserByHashedEmail(this.docEmail);
  }

  ngOnInit() {
    this.pId = this.activatedRoute.snapshot.paramMap.get("pId");
    this.pUserId = this.activatedRoute.snapshot.paramMap.get("pUserId");
    this.docId = this.activatedRoute.snapshot.paramMap.get("docId");
    this.userOptionId =
      this.activatedRoute.snapshot.paramMap.get("userOptionId");
      this.userOptionId = this.userOptionId.replace(/[^0-9]/g, '');
    this.logId = this.activatedRoute.snapshot.paramMap.get("logId");

    localStorage.removeItem("loggedInUserName");
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
      recaptchaValue: ["", Validators.required],
    });

    this.verifyLoginOtpForm = this.formBuilder.group({
      otp: ["", Validators.required],
    });

    if (this.pId.length > 0) {
      this.checkExistingDoc();
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  get v() {
    return this.verifyLoginOtpForm.controls;
  }

  onSubmit() {
    this.loginFromSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    };
    // console.log('doctor login', finalData);

    return this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_superAdminLoginApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          // console.log('login-apiResponse=>', apiResponse);
          this.resultJsonObj = apiResponse;
          // console.log('this.resultJsonObj', this.resultJsonObj);
          if (this.resultJsonObj.commandResult.status === 1) {
            if (this.resultJsonObj.commandResult.data.userInfo.IsActive == 1) {
              this.userId = this.resultJsonObj.commandResult.data.userInfo.Id;
              this.userOtp = this.resultJsonObj.commandResult.data.userInfo.Otp;

              if (this.showMsgAgain) {
                if (this.userData.company_slug == "other") {
                  this.showComfirmationModal = true;
                } else {
                  this.showLoginForm = false;
                  this.showVerifyLoginOtpForm = true;
                }
              } else {
                this.showLoginForm = false;
                this.showVerifyLoginOtpForm = true;
              }
            } else {
              this.inactiveUserErrorMessage = true;
            }
          } else {
            this.formErrorMessage = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  verifyOtp(verifyLogintpFormValue) {
    this.verifyLoginOtpFormSubmitted = true;
    if (this.verifyLoginOtpForm.invalid) {
      return;
    }

    if (
      this.verifyLoginOtpForm.value.otp.toString().trim() !=
      this.userOtp.toString().trim()
    ) {
      this.verifyLoginFormErrorMessage = true;
    } else {
      const httpHeaders = new HttpHeaders({
        "Content-Type": "application/json",
      });
      const options = {
        headers: httpHeaders,
      };
      const finalData = {
        otp: this.verifyLoginOtpForm.value.otp,
        id: this.userId,
      };
      return this.http
        .post(
          this.appConfig.getLambdaUrl02() +
            ApiHelper.name_verifySuperAdminLoginOtpApi,
          finalData,
          options
        )
        .subscribe(
          async (apiResponse) => {
            // console.log(apiResponse);
            this.resultJsonObj = apiResponse;
            if (this.resultJsonObj.commandResult.status == 1) {
              this.userId =
                this.resultJsonObj.commandResult.data.userInfo.RecordId;
              // console.log(this.resultJsonObj.commandResult.data.userInfo);
              localStorage.setItem(
                "loggedInUserName",
                this.resultJsonObj.commandResult.data.userInfo.FullName
              );
              localStorage.setItem(
                "loggedInUserRoleId",
                this.resultJsonObj.commandResult.data.userInfo.UserRoleId
              );
              localStorage.setItem(
                "loggedInUserComapnyId",
                this.resultJsonObj.commandResult.data.userInfo.ComapnyId
              );
              // this.fnCheckAcknowledgementStatus(this.pId, this.userId);

              if (this.userOptionId == 4 || this.userOptionId == 2) {
                // if (confirm('PLEASE CONFIRM: There is no Patient HIPAA Form attached with this email; Are you sure you would like to review the files? '))
                const alertStatus = await this.sweetAlertHelper.confirmPopUp(
                  this.message.confirmTitle,
                  this.message.confirmMessages.authShowd2ddocComp.verifyOTP
                );
                if (alertStatus) {
                  this.router.navigate([
                    "/doctor/user-docs/" +
                      this.pId +
                      "/" +
                      this.logId +
                      "/" +
                      this.userId +
                      "/" +
                      this.userOptionId,
                  ]);
                } else {
                  this.router.navigate(["/"]);
                }
              } else if (
                this.userOptionId == 1 ||
                this.userOptionId == 3 ||
                this.userOptionId == 6
              ) {
                this.router.navigate([
                  "/doctor/view-docs-acknowledgement/" +
                    this.pId +
                    "/" +
                    this.logId +
                    "/" +
                    this.userId +
                    "/" +
                    this.userOptionId,
                ]);
              } else {
                // this.router.navigate(['/doctor/view-docs-acknowledgement/' + this.pId + '/' + this.logId + '/' + this.userId + '/' + this.userOptionId]);
              }
            } else {
              this.verifyLoginFormErrorMessage = true;
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  renderReCaptch() {
    window["grecaptcha"].render(this.recaptchaElement.nativeElement, {
      sitekey: ConstantHelper.RECAPTCHA_SITE_KEY_CLIENT,
      callback: (response) => {
        // console.log(response);
        this.loginForm.get("recaptchaValue").setValue(response);

        ///////////////////recaptcha validation /////////////////
        const httpHeaders = new HttpHeaders({
          "Content-Type": "application/json",
        });
        const options = {
          headers: httpHeaders,
        };
        const finalData = {
          recaptchaResponse: response,
          remoteIP: '127.0.0.1',
        };
        return this.http
          .post(
            this.appConfig.getLambdaUrl01() +
              ApiHelper.name_verifyRecaptachaApi,
            finalData,
            options
          )
          .subscribe(
            (apiResponse) => {
              // console.log(apiResponse);
              this.resultJsonObj = apiResponse;
              // console.log(this.resultJsonObj);

              if (this.resultJsonObj.commandResult.status === 1) {
                this.formRecaptchaErrorMessage = false;
              } else {
                this.formRecaptchaErrorMessage = true;
              }
            },
            (err) => {
              // console.log(err);
            }
          );
        /////////////////////////////////////////////////////////
      },
    });
  }

  addRecaptchaScript() {
    window["grecaptchaCallback"] = () => {
      this.renderReCaptch();
    };

    (function (d, s, id, obj) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        obj.renderReCaptch();
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src =
        "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "recaptcha-jssdk", this);
  }

  ngAfterViewInit() {
    this.addRecaptchaScript();
    this.captchScaleFunc();
  }

  onResize(event) {
    this.captchScaleFunc();
  }

  captchScaleFunc() {
    var captchacontainWidth = this.recaptchaElement.nativeElement.clientWidth;
    var scaleValue = captchacontainWidth / 304;
    if (scaleValue < 1) {
      this.recaptchaElement.nativeElement.style.transform =
        "scale(" + scaleValue + ")";
      this.recaptchaElement.nativeElement.style.transformOrigin = "0";
    }
    // console.log(captchacontainWidth);
  }

  goToDoctorRegister() {
    if (this.pId && this.pId.length > 0) {
      this.router.navigate([
        "/doctor/signup/" +
          this.pId +
          "/" +
          this.logId +
          "/" +
          this.docEmail +
          "/" +
          this.userOptionId,
      ]);
    } else {
      this.router.navigate(["/doctor/register"]);
    }
  }

  checkExistingDoc() {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {
      company_slug: null,
      email: this.docEmail,
      user_role_id: ConstantHelper.USER_ROLE_DOCTOR,
    };
    // console.log('doctor login', finalData);
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findExistingDocApi,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse) => {
          // console.log(apiResponse);
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.status === 1) {
            //do noting, just login
          } else {
            // alert("Please signup first.");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.authShowd2dDocComp.checkExistingDoc
            );
            this.router.navigate([
              "/doctor/signup/" +
                this.pId +
                "/" +
                this.logId +
                "/" +
                this.docEmail +
                "/" +
                this.userOptionId,
            ]);
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  findUserByHashedEmail(docEmail) {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {
      email: docEmail,
    };
    // console.log('doctor login', finalData);

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findUserByHashedEmailApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log('apiResponse', apiResponse);
          this.userData = apiResponse.commandResult.data.physicianInfo;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  closeComfirmationModal() {
    this.showComfirmationModal = false;
    this.showMsgAgain = false;
  }
}
