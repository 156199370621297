import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";

import { FormBuilder, FormGroup, Validators, FormControl, ValidatorFn, AbstractControl} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { AuthService } from "../auth.service";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { MustMatch } from "../../_helpers/matchpassword";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/messages";
import * as md5 from 'md5';

@Component({
  selector: "app-changepassword",
  templateUrl: "./changepassword.component.html",
  styleUrls: ["./changepassword.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class ChangepasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  changePasswordFormSubmitted = false;
  profileObj: any = {};
  formErrorMessage: boolean = false;
  formSuccessMessage: boolean = false;
  showModifyPasswordForm: boolean = true;
  userId: number;
  resultJsonObj: any = {};
  fieldTextType: boolean;
  cnFieldTextType: boolean;
  loggedInUserId: string;
  passwordMismatch: boolean = false;
  showLoader = false;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  secondQues: any;
  firstQues: any;
  message = Message;
  cnfPassword: any;
  password: any;
  counterValue: any;
  accountErrorMessage: boolean = false;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
  ) {}

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
      this.findUserDetails(this.loggedInUserId);
    this.findFirstSecurityQues();
    this.findSecondSecurityQues();
 
  }

  hashPassword(password: string): string {
    return md5(password);
  }
  

  // onSubmit() {
  //   this.changePasswordFormSubmitted = true;
  //   if (this.changePasswordForm.invalid) {
  //     return;
  //   }
  //   let httpHeaders = new HttpHeaders({
  //     "Content-Type": "application/json",
  //   });
  //   let options = {
  //     headers: httpHeaders,
  //   };
  //   const finalData = {
  //     password: this.changePasswordForm.value.password,
  //     id: this.loggedInUserId,
  //   };

  //   return this.http
  //     .post(
  //       this.appConfig.getLambdaUrl01() + ApiHelper.name_modifyPasswordApi,
  //       finalData,
  //       options
  //     )
  //     .subscribe(
  //       (apiResponse) => {
  //         this.resultJsonObj = apiResponse;
  //         // sthis.resultJsonObj);
  //         if (this.resultJsonObj.commandResult.status == 1) {
  //           this.formSuccessMessage = true;
  //           this.showModifyPasswordForm = false;
  //         } else {
  //           this.formErrorMessage = true;
  //         }
  //       },
  //       (err) => {
  //         // console.log(err);
  //       }
  //     );
  // }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleCnFieldTextType() {
    this.cnFieldTextType = !this.cnFieldTextType;
  }

  findUserDetails(userId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      id: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status === 1) {
            this.profileObj = apiResponse.commandResult.data.userInfo;
            this.showLoader = false;
          }
        },
        (err) => {}
      );
  }

  findFirstSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 1,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.firstQues = apiResponse.commandResult.data.questionsList;
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  findSecondSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 2,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.secondQues = apiResponse.commandResult.data.questionsList;
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  checkPasswords(): void {
    // This will be triggered on every change in either the new password or confirm new password
    this.passwordMismatch = this.password !== this.cnfPassword;
  }

     
  // async onSubmit() {
  //   const email = this.profileObj.Email; // Adjust this as needed
  
  //   // Check if passwords match
  //   if (this.profileObj.password !== this.profileObj.cnfPassword) {
  //     await this.sweetAlertHelper.alertPopUp("Password doesn't match!");
  //     return;
  //   }
  
  //   const alertStatus = await this.sweetAlertHelper.confirmPopUp(
  //     this.message.confirmTitle,
  //     'Are you sure you want to change your password?'
  //   );
  
  //   if (alertStatus) {
  //     // Check security answers
  //     if (this.profileObj.Ans01 !== this.profileObj.Ans_01) {
  //       await this.sweetAlertHelper.alertPopUp(
  //         'Incorrect security answer! — Please try again'
  //       );
  //       await this.incrementWrongAnswerCounter();
  //     } else if (this.profileObj.Ans02 !== this.profileObj.Ans_02) {
  //       await this.sweetAlertHelper.alertPopUp(
  //         'Incorrect security answer! — Please try again'
  //       );
  //       await this.incrementWrongAnswerCounter();
  //     } else {
  //       // Security answers are correct, proceed with password reset
  //       const finalData = {
  //         userId: this.loggedInUserId,
  //         password: this.profileObj.password,
  //       };
  //       this.showLoader = true;
  //       return this.http
  //         .post(
  //           this.appConfig.getLambdaUrl05() + ApiHelper.forgotPassword,
  //           finalData,
  //           this.httpOptions
  //         )
  //         .subscribe(
  //           async (apiResponse: any) => {
  //             this.showLoader = false;
  //             const alertStatus = await this.sweetAlertHelper.alertPopUp(
  //               'Password reset successfully! Please log in with your new password'
  //             );
  //             if (alertStatus) {
  //               this.router.navigate(['/login']); // Navigate to login page
  //             }
  //           },
  //           (err) => {
  //             // Handle error here if needed
  //             this.showLoader = false;
  //           }
  //         );
  //     }
  //     // Check the login count after incrementing it
  //     const counterValue = await this.checkLoginCount(email);
  //     if (counterValue == 3) {
  //       await this.sweetAlertHelper.alertPopUp(
  //         "You have failed to successfully login 3 times. Your platform access is now locked. To restore your login capability, please either email support@actualhs.com or, for immediate assistance, you may call Customer Support at (312) 898-4266."
  //       );
  //       return;
  //     } else if (counterValue == 2) {
  //       await this.sweetAlertHelper.alertPopUp(
  //         "You have failed to successfully login 3 times. Your platform access is now locked. To restore your login capability, please either email support@actualhs.com or, for immediate assistance, you may call Customer Support at (312) 898-4266."
  //       );
  //     }
  //   }
  // }

  async onSubmit() {
    const email = this.profileObj.Email; // Adjust this as needed
  
    // Check if passwords match
    if (this.profileObj.password !== this.profileObj.cnfPassword) {
      await this.sweetAlertHelper.alertPopUp("Password doesn't match!");
      return;
    }
  
    // Check security answers
    let incorrectAnswer = false;
    if (this.profileObj.Ans01 !== this.profileObj.Ans_01 || this.profileObj.Ans02 !== this.profileObj.Ans_02) {
      incorrectAnswer = true;
    } 
  
    if (incorrectAnswer) {
      await this.incrementWrongAnswerCounter(); // Increment the counter immediately
      const counterValue = await this.checkLoginCount(email);
      if (counterValue >= 3) {
        this.accountErrorMessage = true;
        await this.sweetAlertHelper.alertPopUp(
          "You have failed to reset your password 3 times.Your platform access is now locked.To restore your login capability, please either email support@actualhs.com or, for immediate assistance, you may call Customer Support at (312) 898-4266."
        );
      }else if (counterValue == 2) {
        await this.sweetAlertHelper.alertPopUp('Incorrect security answer! — Please try again');
        await this.sweetAlertHelper.alertPopUp(
          "You have unsuccessfully attempted to login twice. One more unsuccessful login attempt will lock your platform access for which you must either contact support@actualhs.com or, for immediate assistance, please call AHS Customer Service at (312) 898-4266."
        );
        // Followed by the incorrect security answer pop-up
      }  else {
        await this.sweetAlertHelper.alertPopUp('Incorrect security answer! — Please try again');
      }
  
      return;
    }
    // If security answers are correct, proceed with password change
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      'Are you sure you want to change your password?'
    );
    // For last 5 password comparisons
    const apiResponse = await this.checkPasswordHistory(this.loggedInUserId, this.hashPassword(this.profileObj.password));
    console.log("Is Password Old: Ays", apiResponse.commandResult.status)
      if (apiResponse.commandResult.status == 1) {
        await this.sweetAlertHelper.alertPopUp("Previous Password cannot be used. Please choose a different password.");

        // Clear the new password and confirm password fields
        this.profileObj.password = '';
        this.profileObj.cnfPassword = '';
      return;
    }
    if (alertStatus) {
      const finalData = {
        userId: this.loggedInUserId,
        password: this.profileObj.password,
      };
      this.showLoader = true;
      return this.http
        .post(
          this.appConfig.getLambdaUrl05() + ApiHelper.forgotPassword,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              'Password reset successfully! Please log in with your new password.'
            );
            if (alertStatus) {
              this.router.navigate(['/login']); // Navigate to login page
            }
          },
          (err) => {
            // Handle error here if needed
            this.showLoader = false;
          }
        );
    }
  }
  
  incrementWrongAnswerCounter(): Promise<void> {
    const counterData = {
      userId: this.loggedInUserId,
    };
  
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.appConfig.getLambdaUrl05() + ApiHelper.incrementForgotCounter,
          counterData,
          this.httpOptions
        )
        .subscribe(
          (response: any) => {
            console.log('Counter incremented successfully');
            resolve();
          },
          (err) => {
            console.error('Error incrementing counter', err);
            reject(err);
          }
        );
    });
  }
  
  async checkPasswordHistory(userId, password) {
    try {
        const response = await this.http.post(
            this.appConfig.getLambdaUrl05() + ApiHelper.checkPasswordHistory,
            { userId, password },
            this.httpOptions
        ).toPromise();
        return response as any;
    } catch (error) {
        console.error('Error checking password history:', error);
        throw error;
    }
}
  
  checkLoginCount(email: string): Promise<number> {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const options = {
      headers: httpHeaders,
    };
    const data = {
      email: email,
    };
    return new Promise((resolve, reject) => {
      this.http.post<any>(
        this.appConfig.getLambdaUrl05() + ApiHelper.getLoginCount,
        data,
        options
      ).subscribe(
        (apiResponse: any) => {
          this.counterValue = apiResponse.commandResult.data.userInfo.ForgotPasswordCount;
          resolve(this.counterValue);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  

}
