import { Component, OnInit } from '@angular/core';
// import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import { ApiHelper } from './../../_helpers/api.helper';
import { CommonHelper } from './../../_helpers/common.helper';
import { AppConfig } from './../../../app/app.config';



@Component({
  selector: 'app-add-admin-practice',
  templateUrl: './add-admin-practice.component.html',
  styleUrls: ['./add-admin-practice.component.scss']
})
export class AddAdminPracticeComponent implements OnInit {
  loggedInUserId: string;
  companySlug: string;
  resultJsonObj: any = {};
  addPracticeObj: any = {};
  existingEmail = false;
  existingMobile = false;
  practiceAdded = false;
  showLoader = false;


  apiURl: string;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  httpOptions = {
    headers: this.httpHeaders
  };

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
  }

   onSubmit() {
    const finalData = {
      practiceName: this.addPracticeObj.practiceName,
            url: this.addPracticeObj.url,
participatingCenter: this.addPracticeObj.participatingCenter,
address: this.addPracticeObj.address,


      mobile: this.addPracticeObj.mobile,
      email: this.addPracticeObj.email,
      userId: this.loggedInUserId
    };


    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.createPractice,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.success == -1) {
            this.existingEmail = true;
          } else {
            this.existingEmail = false;
            this.practiceAdded = true;

            setTimeout(() => {
              this.router.navigate(['/practices/' + this.loggedInUserId]);
            }, 2000);
          }
        },
        err => {
          console.error(err);
        }
      );
  }

  goTopractices() {
    this.router.navigate(['/practices/' + this.loggedInUserId]);
  }

}
