import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';

import {AuthService} from '../auth.service';
import {MustMatch} from '../../_helpers/matchpassword';

import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {ConstantHelper} from './../../_helpers/constant.helper';

import {AppConfig} from './../../../app/app.config';
import {UserService} from '../../_services/user.service';
import {Router, ActivatedRoute} from '@angular/router';
import {SweetAlertHelper} from '../../_helpers/sweet.alert.helper';
import {Message} from '../../_locale/messages';
import {async} from '@angular/core/testing';

@Component({
  selector: 'app-login-qa',
  templateUrl: './login-qa.component.html',
  styleUrls: ['./login-qa.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class LoginQaComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  @ViewChild('recaptcha', {static: false}) recaptchaElement: ElementRef;
  @HostListener('window:resize', ['$event'])
  loginForm: FormGroup;
  loginFromSubmitted = false;
  formErrorMessage = false;
  accountErrorMessage = false;
  showLoginForm = true;
  inactiveDoctorErrorMessage = false;
  verifyLoginOtpForm: FormGroup;
  verifyLoginOtpFormSubmitted = false;
  verifyLoginFormErrorMessage = false;
  showVerifyLoginOtpForm = false;
  // userOtp: number;
  userId: number;
  userEmail: string;
  userMobile: string;
  userRoleId: number;
  resultJsonObj: any = {};
  formRecaptchaErrorMessage = false;
  showMailModal = false;
  enquiryObj: any = {};
  pId = '';
  showLoader = false;
  fieldTextType: boolean;

  supportMail = ConstantHelper.SUPPORT_EMAIL;
  message = Message;
  // allowedEmail: any[] = ['code.amitsinha@gmail.com', 'kobe_goodwin@yopmail.com', 'rio_jacobs.com', 'lilah_tyler@yopmail.com', 'arya_raymond@yopmail.com'];

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private http: HttpClient,
    private userService: UserService,
    public sweetAlertHelper: SweetAlertHelper,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.pId = this.activatedRoute.snapshot.paramMap.get('pId');
    localStorage.clear();

    localStorage.removeItem('loggedInUserName');
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      // recaptchaValue: ["", Validators.required],
    });

    this.verifyLoginOtpForm = this.formBuilder.group({
      otp: ['', Validators.required],
    });

    this.enquiryObj.subject = 'Having trouble signing up or logging in';
    this.enquiryObj.content =
      // tslint:disable-next-line: max-line-length
      'I am having difficulty signing up or logging into the ELEMREX platform.  I understand someone from AHS Customer Support will be contacting me by email within the next 24 hours.  I can also be reached at the following cell phone number I am providing here:  <user typed in number>. Feel free to type any additional information for our review. Thank you.  AHS Support Team';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  get v() {
    return this.verifyLoginOtpForm.controls;
  }

  onSubmit(loginFormValue) {
    this.loginFromSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    // const existsInArray = this.allowedEmail.includes(this.loginForm.value.email);
    // if (!existsInArray) {
    //   alert('The email is not valid');
    //   return;
    // }

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_superAdminLoginApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.showLoader = false;
          this.resultJsonObj = apiResponse;

          if (this.resultJsonObj.commandResult.status === 1) {
            if (this.resultJsonObj.commandResult.data.userInfo.IsActive == 1) {
              this.userId = this.resultJsonObj.commandResult.data.userInfo.Id;
              // this.userOtp = this.resultJsonObj.commandResult.data.userInfo.Otp;
              // if (this.resultJsonObj.commandResult.data.userInfo.isMinor === 0) {

              // } else {
              //   alert('Minors are not allowed to login.');
              // }

              this.showLoginForm = false;
              this.showVerifyLoginOtpForm = true;
            } else {
              this.accountErrorMessage = true;
            }

            //
          } else {
            this.formErrorMessage = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  verifyOtp() {
    this.verifyLoginOtpFormSubmitted = true;
    if (this.verifyLoginOtpForm.invalid) {
      return;
    }
    //
    // if (this.verifyLoginOtpForm.value.otp.toString().trim() != this.userOtp.toString().trim()) {
    //   this.verifyLoginFormErrorMessage = true;
    // } else {}

    const httpHeaders = new HttpHeaders({
      // "Content-Type": "application/x-www-form-urlencoded"
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {
      otp: this.verifyLoginOtpForm.value.otp,
      id: this.userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl02() +
        ApiHelper.name_verifySuperAdminLoginOtpApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.status == 1 && !(this.resultJsonObj.commandResult.message == 'invalid otp.')) {
            this.userId =
              this.resultJsonObj.commandResult.data.userInfo.RecordId;
            localStorage.setItem('isUserIdloggedIn', 'Y');
            localStorage.setItem(
              'loggedInUserId',
              this.resultJsonObj.commandResult.data.userInfo.RecordId
            );
            localStorage.setItem(
              'loggedInUserName',
              this.resultJsonObj.commandResult.data.userInfo.FullName
            );
            localStorage.setItem(
              'loggedInUserEmail',
              this.resultJsonObj.commandResult.data.userInfo.Email
            );
            localStorage.setItem(
              'loggedInUserRoleId',
              this.resultJsonObj.commandResult.data.userInfo.UserRoleId
            );
            if (
              this.resultJsonObj.commandResult.data.userInfo.UserRoleId == 3
            ) {
              this.router.navigate(['/demographics/' + this.userId]);
            } else if (
              this.resultJsonObj.commandResult.data.userInfo.UserRoleId == 2
            ) {
              // this.router.navigate(["/dashboard/" + this.userId]);
              if (this.pId && this.pId.length > 0) {
                // console.log('pid');
                this.router.navigate([
                  '/view-docs/' + this.pId + '/' + this.userId,
                ]);
              } else {
                // console.log('normal');
                this.router.navigate(['/dashboard/' + this.userId]);
              }
            } else if (
              this.resultJsonObj.commandResult.data.userInfo.UserRoleId == 1
            ) {
              // console.log(this.userService.childSiteUrl(this.resultJsonObj.commandResult.data.userInfo.CompanySlug) + '/admin/dashboard/' + this.userId);

              window.open(
                this.userService.childSiteUrl(
                  this.resultJsonObj.commandResult.data.userInfo.CompanySlug
                ) +
                '/admin/dashboard/' +
                this.userId,
                '_self'
              );
            } else if (
              this.resultJsonObj.commandResult.data.userInfo.UserRoleId == 4
            ) {
              window.open(
                this.userService.childSiteUrl(
                  this.resultJsonObj.commandResult.data.userInfo.CompanySlug
                ) +
                '/doctor/dashboard/' +
                this.userId,
                '_self'
              );
            }
          } else {
            this.verifyLoginFormErrorMessage = true;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      sitekey: ConstantHelper.RECAPTCHA_SITE_KEY_CLIENT,
      callback: (response) => {
        // console.log(response);
        this.loginForm.get('recaptchaValue').setValue(response);

        ///////////////////recaptcha validation /////////////////
        const httpHeaders = new HttpHeaders({
          'Content-Type': 'application/json',
        });
        const options = {
          headers: httpHeaders,
        };
        const finalData = {
          recaptchaResponse: response,
          remoteIP: '127.0.0.1',
        };
        return this.http
          .post(
            this.appConfig.getLambdaUrl01() +
            ApiHelper.name_verifyRecaptachaApi,
            finalData,
            options
          )
          .subscribe(
            (apiResponse) => {
              // console.log(apiResponse);
              this.resultJsonObj = apiResponse;
              // console.log(this.resultJsonObj);

              if (this.resultJsonObj.commandResult.status === 1) {
                this.formRecaptchaErrorMessage = false;
              } else {
                this.formRecaptchaErrorMessage = true;
              }
            },
            (err) => {
              // console.log(err);
            }
          );
        /////////////////////////////////////////////////////////
      },
    });
  }

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    };

    (function(d, s, id, obj) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        obj.renderReCaptch();
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src =
        'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'recaptcha-jssdk', this);
  }

  ngAfterViewInit() {
    this.addRecaptchaScript();
    this.captchScaleFunc();
  }

  onResize(event) {
    this.captchScaleFunc();
  }

  captchScaleFunc() {
    let captchacontainWidth = this.recaptchaElement.nativeElement.clientWidth;
    let scaleValue = captchacontainWidth / 304;
    if (scaleValue < 1) {
      this.recaptchaElement.nativeElement.style.transform =
        'scale(' + scaleValue + ')';
      this.recaptchaElement.nativeElement.style.transformOrigin = '0';
    }
    // console.log(captchacontainWidth);
  }

  fnShowMailModal() {
    this.showMailModal = true;
  }

  fnCloseMailModal() {
    this.showMailModal = false;
  }

  async onEnquirySubmit() {
    // if (confirm('PLEASE CONFIRM: Click ‘OK’ to contact Elemrex admin.'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.doctorRegisterComp.onEnquirySubmit
    );
    if (alertStatus) {
      const finalData = {
        subject: this.enquiryObj.subject,
        content: this.enquiryObj.content,
      };
      return this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_contactElemrixApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (response: any) => {
            // console.log(response);
            // alert("Email sent ot Elemrex Admin.");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.doctorRegisterComp.onEnquirySubmit
            );

            this.showMailModal = false;
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async goToDoctorLogin() {
    // alert("goToDoctorLogin");
    const alertStatus = await this.sweetAlertHelper.alertPopUp(
      this.message.alertMessages.loginComp.goToDoctorComp
    );
    if (alertStatus) {
      this.router.navigate(['/doctor/signup']);
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
