export class CSVRecord {  
    public npi: any;  
    public npiProviderEnumDate: any;  
    public npiLastUpdateDate: any;  
    public npiDeactivationDate: any;  
    public npiDeactivationReasonCode: any;  
    public npiReactivationDate: any;  
    public lastName: any;  
    public firstName: any; 
    public middleName: any;  
    public suffixName: any;  
    public gender: any;
    
    public nationOfPracticeLocation:any;
    public primaryOfficeStateLocation:any;
    public city: any; 
    public zipCode:any;
    public streetAddress1:any;
    public streetAddress2:any;
    public officeTelephone:any;
    public officeFax:any;
    public degree : any;  
    public specialty: any;  
    public subspecialty:any;

    public primaryStatePractice:any;
    public primaryStateLicenseNo:any;
    public secondaryStatePractice:any;
    public secondaryStateLicenseNo:any;

    public email:any;   
  }  