import { Component, OnInit } from "@angular/core";
// import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/messages";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-editform5",
  templateUrl: "./editform5.component.html",
  styleUrls: ["./editform5.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class Editform5Component implements OnInit {
  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};

  // age: number;
  calculatedAge: number;
  loggedUserEmail: any;
  loggedUserMobile: any;
  newAge: any;
  editDdemographicsformDataObj: any = {};
  ahsDisclaimerPdf: SafeResourceUrl;
  ahsHippaPdf: SafeResourceUrl;
  showAddressSuggestionsModal: boolean = false;
  showGenders: any = ["Male", "Female", "Other"];
  showMOnths: any = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  showDays: any = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];
  showYears: any = [
    "1930",
    "1931",
    "1932",
    "1933",
    "1934",
    "1935",
    "1936",
    "1937",
    "1938",
    "1939",
    "1940",
    "1941",
    "1942",
    "1943",
    "1944",
    "1945",
    "1946",
    "1947",
    "1948",
    "1949",
    "1950",
    "1951",
    "1952",
    "1953",
    "1954",
    "1955",
    "1956",
    "1957",
    "1958",
    "1959",
    "1960",
    "1961",
    "1962",
    "1963",
    "1964",
    "1965",
    "1966",
    "1967",
    "1968",
    "1969",
    "1970",
    "1971",
    "1972",
    "1973",
    "1974",
    "1975",
    "1976",
    "1977",
    "1978",
    "1979",
    "1980",
    "1981",
    "1982",
    "1983",
    "1984",
    "1985",
    "1986",
    "1987",
    "1988",
    "1989",
    "1990",
    "1991",
    "1992",
    "1993",
    "1994",
    "1995",
    "1996",
    "1997",
    "1998",
    "1999",
    "2000",
    "2001",
    "2002",
    "2003",
    "2004",
    "2005",
    "2006",
    "2007",
    "2008",
    "2009",
    "2010",
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
  ];
  statesArre1: any = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  statesArre: any = [
    { id: "AL", name: "Alabama" },
    { id: "AK", name: "Alaska" },
    { id: "AZ", name: "Arizona" },
    { id: "AR", name: "Arkansas" },

    { id: "CA", name: "California" },
    { id: "CO", name: "Colorado" },
    { id: "CT", name: "Connecticut" },
    { id: "DE", name: "Delaware" },
    { id: "FL", name: "Florida" },
    { id: "GA", name: "Georgia" },

    { id: "GU", name: "Guam" },
    { id: "HI", name: "Hawaii" },
    { id: "ID", name: "Idaho" },
    { id: "IL", name: "Illinois" },
    { id: "IN", name: "Indiana" },
    { id: "IA", name: "Iowa" },

    { id: "KS", name: "Kansas" },
    { id: "KY", name: "Kentucky" },
    { id: "LA", name: "Louisiana" },
    { id: "ME", name: "Maine" },
    { id: "MD", name: "Maryland" },
    { id: "MA", name: "Massachusetts" },

    { id: "MI", name: "Michigan" },
    { id: "MN", name: "Minnesota" },
    { id: "MS", name: "Mississippi" },
    { id: "MO", name: "Missouri" },
    { id: "MT", name: "Montana" },
    { id: "NE", name: "Nebraska" },

    { id: "NV", name: "Nevada" },
    { id: "NH", name: "New Hampshire" },
    { id: "NJ", name: "New Jersey" },
    { id: "NM", name: "New Mexico" },
    { id: "NY", name: "New York" },
    { id: "NC", name: "North Carolina" },

    { id: "ND", name: "North Dakota" },
    { id: "OH", name: "Ohio" },
    { id: "OK", name: "Oklahoma" },
    { id: "OR", name: "Oregon" },
    { id: "PA", name: "Pennsylvania" },
    { id: "PR", name: "Puerto Rico" },

    { id: "RI", name: "Rhode Island" },
    { id: "SC", name: "South Carolina" },
    { id: "SD", name: "South Dakota" },
    { id: "TN", name: "Tennessee" },
    { id: "TX", name: "Texas" },
    { id: "UT", name: "Utah" },

    { id: "VT", name: "Vermont" },
    { id: "VA", name: "Virginia" },
    { id: "WA", name: "Washington" },
    { id: "WV", name: "West Virginia" },
    { id: "WI", name: "Wisconsin" },
    { id: "WY", name: "Wyoming" },
  ];
  addressLength: boolean = false;
  noAddressLength: boolean = false;
  addressList: any;
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.ahsDisclaimerPdf = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.appConfig.getDisclaimerPdf()
    );

    this.ahsHippaPdf = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.appConfig.getHippaPdf()
    );

    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get("demographicId");

    this.editDdemographicsformDataObj.fName = localStorage.getItem("fName");
    this.editDdemographicsformDataObj.mName = localStorage.getItem("mName");
    this.editDdemographicsformDataObj.lName = localStorage.getItem("lName");
    this.editDdemographicsformDataObj.dobYear = localStorage.getItem("dobYear");
    this.editDdemographicsformDataObj.dobMonth =
      localStorage.getItem("dobMonth");
    this.editDdemographicsformDataObj.dobDay = localStorage.getItem("dobDay");
    this.editDdemographicsformDataObj.age = localStorage.getItem("age");
    this.editDdemographicsformDataObj.gender = localStorage.getItem("gender");
    this.editDdemographicsformDataObj.residence =
      localStorage.getItem("residence");
    this.editDdemographicsformDataObj.street = localStorage.getItem("street");
    this.editDdemographicsformDataObj.city = localStorage.getItem("city");
    this.editDdemographicsformDataObj.state = localStorage.getItem("state");
    this.editDdemographicsformDataObj.zip = localStorage.getItem("zip");
    this.editDdemographicsformDataObj.email = localStorage.getItem("email");
    this.editDdemographicsformDataObj.cellPhone =
      localStorage.getItem("cellPhone");
    this.editDdemographicsformDataObj.altPhone =
      localStorage.getItem("altPhone");

    this.editDdemographicsformDataObj.primaryPhysician =
      localStorage.getItem("primaryPhysician");
    this.editDdemographicsformDataObj.primaryPhysicianState =
      localStorage.getItem("primaryPhysicianState");
    this.editDdemographicsformDataObj.primaryPhysicianCity =
      localStorage.getItem("primaryPhysicianCity");

    this.editDdemographicsformDataObj.orderingPhysician =
      localStorage.getItem("orderingPhysician");
    this.editDdemographicsformDataObj.orderingPhysicianState =
      localStorage.getItem("orderingPhysicianState");
    this.editDdemographicsformDataObj.orderingPhysicianCity =
      localStorage.getItem("orderingPhysicianCity");

    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const options = {
      headers: httpHeaders,
    };

    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
    };
    // console.log(finalData);

    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_findDemographicFormApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          //
          var dob =
            this.resultJsonObj.commandResult.data.demographicInfo.form1DOB.split(
              "T"
            );

          let finalDob = dob[0].split("-");

          this.editDdemographicsformDataObj.fName =
            this.resultJsonObj.commandResult.data.demographicInfo.from1FirstName;
          this.editDdemographicsformDataObj.mName =
            this.resultJsonObj.commandResult.data.demographicInfo.from1MiddleName;
          this.editDdemographicsformDataObj.lName =
            this.resultJsonObj.commandResult.data.demographicInfo.from1LastName;

          this.editDdemographicsformDataObj.dobYear = finalDob[0];
          this.editDdemographicsformDataObj.dobMonth = finalDob[1];
          this.editDdemographicsformDataObj.dobDay = finalDob[2];

          this.editDdemographicsformDataObj.age =
            this.resultJsonObj.commandResult.data.demographicInfo.form1Age;
          this.editDdemographicsformDataObj.gender =
            this.resultJsonObj.commandResult.data.demographicInfo.form1Gender;
          this.editDdemographicsformDataObj.residence =
            this.resultJsonObj.commandResult.data.demographicInfo.form1ResidenceNumber;

          this.editDdemographicsformDataObj.street =
            this.resultJsonObj.commandResult.data.demographicInfo.form1StreetName;
          this.editDdemographicsformDataObj.city =
            this.resultJsonObj.commandResult.data.demographicInfo.form1City;
          this.editDdemographicsformDataObj.state =
            this.resultJsonObj.commandResult.data.demographicInfo.form1State;
          this.editDdemographicsformDataObj.zip =
            this.resultJsonObj.commandResult.data.demographicInfo.form1ZIP;
          this.editDdemographicsformDataObj.email =
            this.resultJsonObj.commandResult.data.demographicInfo.form1EmailAddress;
          this.editDdemographicsformDataObj.cellPhone =
            this.resultJsonObj.commandResult.data.demographicInfo.form1CellularPhone;
          this.editDdemographicsformDataObj.altPhone =
            this.resultJsonObj.commandResult.data.demographicInfo.form1CellularPhoneAlt;

          this.editDdemographicsformDataObj.primaryPhysician =
            this.resultJsonObj.commandResult.data.demographicInfo.form1PrimaryPhysician;
          this.editDdemographicsformDataObj.primaryPhysicianState =
            this.resultJsonObj.commandResult.data.demographicInfo.form1PrimaryPhysicianState;
          this.editDdemographicsformDataObj.primaryPhysicianCity =
            this.resultJsonObj.commandResult.data.demographicInfo.form1PrimaryPhysicianCity;

          this.editDdemographicsformDataObj.orderingPhysician =
            this.resultJsonObj.commandResult.data.demographicInfo.form1OrderingPhysician;
          this.editDdemographicsformDataObj.orderingPhysicianState =
            this.resultJsonObj.commandResult.data.demographicInfo.form1OrderingPhysicianState;
          this.editDdemographicsformDataObj.orderingPhysicianCity =
            this.resultJsonObj.commandResult.data.demographicInfo.form1OrderingPhysicianCity;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  calculateAge(value) {
    // alert(value.dobDay);
    // alert(value.dobMonth);
    // alert(value.dobYear);

    if (value.dobDay > 0 && value.dobMonth > 0 && value.dobYear > 0) {
      var calculatedAge = this.commonHelper.calculateAge(
        value.dobYear,
        value.dobMonth,
        value.dobDay
      );
      this.editDdemographicsformDataObj.age = calculatedAge;
      this.newAge = calculatedAge;
    } else {
      this.editDdemographicsformDataObj.age =
        this.editDdemographicsformDataObj.age;
    }
    // console.log(value);
  }

  onSubmit() {
    // alert('orderingPhysicianStateorderingPhysicianStateorderingPhysicianState');
    headers: new HttpHeaders();
    let httpHeaders = new HttpHeaders({
      // "Content-Type": "application/x-www-form-urlencoded"
      "Content-Type": "application/json",
    });
    let options = {
      headers: httpHeaders,
    };

    const finalData = {
      fName: this.editDdemographicsformDataObj.fName,
      mName: this.editDdemographicsformDataObj.mName,
      lName: this.editDdemographicsformDataObj.lName,
      dobMonth: this.editDdemographicsformDataObj.dobMonth,
      dobDay: this.editDdemographicsformDataObj.dobDay,
      dobYear: this.editDdemographicsformDataObj.dobYear,
      age: this.newAge ? this.newAge : this.editDdemographicsformDataObj.age,
      gender: this.editDdemographicsformDataObj.gender,
      residence: this.editDdemographicsformDataObj.residence,
      street: this.editDdemographicsformDataObj.street,
      city: this.editDdemographicsformDataObj.city,
      state: this.editDdemographicsformDataObj.state,
      zip: this.editDdemographicsformDataObj.zip,
      email: this.editDdemographicsformDataObj.email,
      cellPhone: this.editDdemographicsformDataObj.cellPhone,
      altPhone: this.editDdemographicsformDataObj.altPhone,

      primaryPhysician: this.editDdemographicsformDataObj.primaryPhysician,
      primaryPhysicianState:
        this.editDdemographicsformDataObj.primaryPhysicianState,
      primaryPhysicianCity:
        this.editDdemographicsformDataObj.primaryPhysicianCity,

      orderingPhysician: this.editDdemographicsformDataObj.orderingPhysician,
      orderingPhysicianState:
        this.editDdemographicsformDataObj.orderingPhysicianState,
      orderingPhysicianCity:
        this.editDdemographicsformDataObj.orderingPhysicianCity,

      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
    };

    // console.log(finalData);
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_updateDemographicDetailsApi,
        finalData,
        options
      )
      .subscribe(
       async (apiResponse) => {
          // console.log(apiResponse);
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.status === 1) {
            localStorage.setItem(
              "fName",
              this.editDdemographicsformDataObj.fName
            );
            localStorage.setItem(
              "mName",
              this.editDdemographicsformDataObj.mName
                ? this.editDdemographicsformDataObj.mName
                : ""
            );
            localStorage.setItem(
              "lName",
              this.editDdemographicsformDataObj.lName
            );
            localStorage.setItem(
              "dobMonth",
              this.editDdemographicsformDataObj.dobMonth
            );
            localStorage.setItem(
              "dobDay",
              this.editDdemographicsformDataObj.dobDay
            );
            localStorage.setItem(
              "dobYear",
              this.editDdemographicsformDataObj.dobYear
            );
            localStorage.setItem(
              "age",
              this.newAge ? this.newAge : this.editDdemographicsformDataObj.age
            );
            localStorage.setItem(
              "gender",
              this.editDdemographicsformDataObj.gender
            );
            localStorage.setItem(
              "residence",
              this.editDdemographicsformDataObj.residence
            );
            localStorage.setItem(
              "street",
              this.editDdemographicsformDataObj.street
            );
            localStorage.setItem(
              "city",
              this.editDdemographicsformDataObj.city
            );
            localStorage.setItem(
              "state",
              this.editDdemographicsformDataObj.state
            );
            localStorage.setItem("zip", this.editDdemographicsformDataObj.zip);
            localStorage.setItem(
              "email",
              this.editDdemographicsformDataObj.email
            );
            localStorage.setItem(
              "cellPhone",
              this.editDdemographicsformDataObj.cellPhone
            );
            localStorage.setItem(
              "altPhone",
              this.editDdemographicsformDataObj.altPhone
            );
            localStorage.setItem(
              "primaryPhysician",
              this.editDdemographicsformDataObj.primaryPhysician
            );
            localStorage.setItem(
              "primaryPhysicianState",
              this.editDdemographicsformDataObj.primaryPhysicianState
            );
            localStorage.setItem(
              "primaryPhysicianCity",
              this.editDdemographicsformDataObj.primaryPhysicianCity
            );
            localStorage.setItem(
              "loggedInUserId",
              this.editDdemographicsformDataObj.loggedInUserId
            );
            localStorage.setItem(
              "demographicId",
              this.editDdemographicsformDataObj.demographicId
            );
            // this.demographicId = this.resultJsonObj.commandResult.data.demographicInfo.Id;
            this.router.navigate([
              "/documentthoumbs/" +
                this.loggedInUserId +
                "/" +
                this.demographicId,
            ]);
            // console.log("done");
          } else {
            // alert("oops! error occured, please try later");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.onSubmit
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goToWaiverDisclaimerForm() {
    this.router.navigate([
      "/waiverdisclaimerform/" + this.loggedInUserId + "/" + this.demographicId,
    ]);
  }

  verifyAddress() {
    this.addressList = {};
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let options = {
      headers: httpHeaders,
    };

    const finalData = {
      streeAddress:
        this.editDdemographicsformDataObj.street +
        ", " +
        this.editDdemographicsformDataObj.city +
        ", " +
        this.editDdemographicsformDataObj.state +
        ", " +
        this.editDdemographicsformDataObj.zip,
    };

    // console.log(finalData);

    return this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_verifyAddressApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.status == 1) {
            if (this.resultJsonObj.commandResult.data.Addresses.length > 0) {
              this.addressLength = true;
              this.addressList =
                this.resultJsonObj.commandResult.data.Addresses;
              // console.log(this.addressList);
            } else {
              // this.noAddressLength = true;
            }
          } else {
            // this.noAddressLength = true;
          }
          this.showAddressSuggestionsModal = true;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  closeAddressSuggestionsModal() {
    this.showAddressSuggestionsModal = false;
  }

  onItemChange(address) {
    if (address != "curentAdd") {
      var splitted = address.split(",");
      this.editDdemographicsformDataObj.street = splitted[0];
      // var splitted1 = splitted[1].trim().split(" ");

      // var n = splitted[1].lastIndexOf(" ");

      var stateName = splitted[1]
        .trim()
        .substring(splitted[1].trim().lastIndexOf(" ") + 1);

      var cityName = splitted[1]
        .trim()
        .substring(0, splitted[1].trim().lastIndexOf(" "));

      // console.log(cityName);
      // console.log(stateName);
      this.editDdemographicsformDataObj.city = cityName;
      this.editDdemographicsformDataObj.state = stateName;
      this.showAddressSuggestionsModal = false;
    }
  }
}
