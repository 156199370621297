import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {AppConfig} from './../../../app/app.config';
import {UserService} from '../../_services/user.service';
import {SweetAlertHelper} from '../../_helpers/sweet.alert.helper';
import {Message} from '../../_locale/messages';

@Component({
  selector: 'app-registered-doctors',
  templateUrl: './registered-doctors.component.html',
  styleUrls: ['./registered-doctors.component.scss'],
})
export class RegisteredDoctorsComponent implements OnInit {
  loggedInUserId: string;
  demographicId: any;
  loggedInUserRoleId: any;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  logsList: any = [];
  logsListReactivated: any = [];
  showPdfModal = false;
  selectedPracticeName: any;
  selectedPracticeId: any;
  physiciansArr: any = [];
  selectedDocsId: any = [];
  showModificationAlert = false;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  showLoader = false;
  onBehalfOfAlertRegisteredDoctors = 'Y';
  message = Message;
  dtOptions: DataTables.Settings = {};
  dtElement: any;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    private userService: UserService
  ) {
    this.dtOptions = {
      order: [[1, "asc"]], // Assuming 'Physician Name' is the second column (index 1)
  
      columnDefs: [
        {
          targets: 1, // Assuming the column with names is the second column (index 1)
          type: "string",
          orderDataType: "dom-text",
          render: function (data, type) {
            if (type === "sort") {
              return data.split(", ")[1]; // Sort by firstname
            }
            return data; // Display the original 'Lastname, Firstname' format
          },
        },
      ],
    };
  }

  async ngAfterViewInit() {
    var item = localStorage.getItem('FullName');
    if (
      this.onBehalfOfAlertRegisteredDoctors === 'Y' &&
      localStorage.getItem('isDelegating') === '1'
    ) {
      // alert(
      //   "Dear User, you are doing this activity on behalf of " +
      //     localStorage.getItem("FullName")
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.ngAfterViewInit +  " " + item
      );

      localStorage.setItem('onBehalfOfAlertRegisteredDoctors', 'N');
    }
  }

  ngOnInit() {
    this.loggedInUserId = localStorage.getItem('delegateId')
      ? localStorage.getItem('delegateId')
      : this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.demographicId = localStorage.getItem('MinorDemographicId')
      ? localStorage.getItem('MinorDemographicId')
      : this.activatedRoute.snapshot.paramMap.get('demographicId');

    this.findDemographicDetails();
    this.onBehalfOfAlertRegisteredDoctors = localStorage.getItem(
      'onBehalfOfAlertRegisteredDoctors'
    )
      ? localStorage.getItem('onBehalfOfAlertRegisteredDoctors')
      : 'Y';
    window.scroll(0,0)
    this.findUserDoctorsAferRequestingPatient();
    sessionStorage.removeItem('practiceDocCount');
  }

  findUserDoctors() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findUserDoctors,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log('apiResponse=>', apiResponse);
          // console.log('companiesList=>', apiResponse.commandResult.data.companiesList.length);

          this.logsList = apiResponse.commandResult.data.companiesList
            ? apiResponse.commandResult.data.companiesList
            : [];
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async goToChildSite(company) {
    // if (
    //   confirm(
    //     // tslint:disable-next-line: max-line-length
    //     'PLEASE CONFIRM:   Please select ‘OK’ to confirm that you are ready to review, complete and/or sign the forwarded practice documents at this time. ‘Cancel’ ‘OK’'
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.registeredDoctorComp.goToChildSite
    );
    if (alertStatus) {
      if (localStorage.getItem('loggedInUserRoleId') == '2') {
        if (localStorage.getItem('isDelegating') == '1') {
          window.open(
            this.userService.childSiteUrl(company.company_url) +
            '/hippa-acceptance/' +
            this.loggedInUserId +
            '/' +
            this.demographicId +
            '/' +
            company.physician_id +
            '/' +
            company.invitation_id +
            '/' +
            this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
            '_self'
          );
        } else {
          window.open(
            this.userService.childSiteUrl(company.company_url) +
            '/hippa-acceptance/' +
            this.loggedInUserId +
            '/' +
            this.demographicId +
            '/' +
            company.physician_id +
            '/' +
            company.invitation_id +
            '/0',
            '_self'
          );
        }
      } else {
        window.open(
          this.userService.childSiteUrl(company.company_url) +
          '/admin/dashboard/' +
          this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
          '_self'
        );
      }
    }
  }

  async removePractice(company) {
    // console.log(company);

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.registeredDoctorComp.removePractice
    );
    if (alertStatus) {
      const finalData = {
        companyId: company.id,
        doctor_id: company.doctor_id,
        userId: this.loggedInUserId,
        physician_id: company.physician_id,
        isDelegating: localStorage.getItem('isDelegating'),
        delegateId: localStorage.getItem('delegateId'),
        parentId: localStorage.getItem('parentId')
      };
      this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.removeUserPractice,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.registeredDocComp.removePractice
            );
            // alert("Practice removed successfully.");
            window.location.reload();
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async goToDashboad() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:   By clicking ‘OK’: You will be returned to the ‘Home Page’."
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.ushfComp.onReset
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/dashboard/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
      ]);
    // }
  }

  closePdfModal() {
    this.showPdfModal = false;
  }

  clearSelectedDocs() {
    this.selectedDocsId = [];
  }

  getCheckedBoxValue() {
    const allCheck: any = document.getElementsByClassName('docChecks');
    for (let index = 0; index < allCheck.length; index++) {
      const element = allCheck[index];
      if (element.checked) {
        // console.log(element.value);
        this.selectedDocsId.push(element.value);
      }
    }
  }

  findDemographicDetails() {
    const finalData = {
      id: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_findUserDemographicApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.demographicId = res.commandResult.data.userInfo.DemographicId;
          if (
            res.commandResult.data.userInfo.ProfileCompleted == 1 &&
            res.commandResult.data.userInfo.Form1Modified == 1
          ) {
            this.showModificationAlert = true;
          } else {
            this.showModificationAlert = false;
          }
          if (res.commandResult.data.userInfo.ProfileCompleted == 1) {
            localStorage.setItem('profileCompleted', '1');
          } else {
            localStorage.setItem('profileCompleted', '0');
          }
          this.findUserDoctors();
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDoctorsAferRequestingPatient(){
    const finalData={
      loggedInUserId: this.loggedInUserId
    }
    this.http.post(
      this.appConfig.getLambdaUrl05() + ApiHelper.getUserDocsWhoReactivatedPatient,
      finalData,
      this.httpOptions
    )
    .subscribe(
      (apiResponse:any) => {
        this.logsListReactivated=apiResponse.commandResult.data.companiesList
        ? apiResponse.commandResult.data.companiesList: [];
        this.dtOptions = {
          order : [[6, 'desc']]
        }
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  async reActivateDoctor(company) {
    // console.log(company);

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.registeredDoctorComp.activateDoctor,
    );
    if (alertStatus) {
      const finalData = {
        patientId: this.loggedInUserId,
        physicianId: company.physician_id,
      };
      this.http
        .post(
          this.appConfig.getLambdaUrl05() + ApiHelper.reActiveDoctor,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.registeredDocComp.reActivePractice
            );
            // alert("Practice removed successfully.");
            window.location.reload();
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

}
