import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { ArrayHelper } from "./../../_helpers/array.helper";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";

@Component({
  selector: "app-editform4",
  templateUrl: "./editform4.component.html",
  styleUrls: ["./editform4.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class Editform4Component implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  resultJsonObjCity: any = {};
  resultJsonObjPryPhyCity: any = {};
  resultJsonObjOrdPhyCity: any = {};
  existingDemographicData = false;
  newDemographicData = false;
  editDdemographicsformDataObj: any = {};
  calculatedAge: number;
  loggedUserEmail: any;
  loggedUserMobile: any;
  newAge: any;

  ahsDisclaimerPdf: SafeResourceUrl;
  ahsHippaPdf: SafeResourceUrl;
  showAddressSuggestionsModal = false;

  arrayGenders: any = ArrayHelper.GENDER_ARRAY;
  arrayMonths: any = ArrayHelper.MONTH_ARRAY;
  arrayDays: any = ArrayHelper.DAY_ARRAY;
  arrayYears: any = ArrayHelper.YEAR_ARRAY;

  arrayStates: any = ArrayHelper.STATE_ARRAY;

  statesArrayDB: any = [];

  addressLength = false;
  noAddressLength = false;
  addressList: any;

  cityLength = false;
  noCityLength = false;
  citiesList: any = [];

  pryPhyCityLength = false;
  noPryPhyCityLength = false;
  pryPhyCitiesList: any = [];

  ordPhyCityLength = false;
  noOrdPhyCityLength = false;
  ordPhyCitiesList: any = [];

  orderStateSelectDisabledOption = true;
  orderCitySelectDisabledOption = true;
  orderSelectDisabledOption = true;

  priStateSelectDisabledOption = true;
  priCitySelectDisabledOption = true;
  priSelectDisabledOption = true;

  stateSelectDisabledOption = true;
  citySelectDisabledOption = true;

  priPhysicianLength = false;
  noPriPhysicianLength = false;
  priPhysiciansList: any = [];

  ordPhysicianLength = false;
  noOrdPhysicianLength = false;
  ordPhysiciansList: any = [];
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer
  ) {
    this.ahsDisclaimerPdf = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.appConfig.getDisclaimerPdf()
    );
    this.ahsHippaPdf = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.appConfig.getHippaPdf()
    );
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    this.editDdemographicsformDataObj.dobMonth = "";
    this.editDdemographicsformDataObj.dobDay = "";
    this.editDdemographicsformDataObj.dobYear = "";
    this.editDdemographicsformDataObj.gender = "";
    this.editDdemographicsformDataObj.state = "";
    this.editDdemographicsformDataObj.city = "";
    this.editDdemographicsformDataObj.primaryPhysicianState = "";
    this.editDdemographicsformDataObj.primaryPhysicianCity = "";
    this.editDdemographicsformDataObj.primaryPhysician = "";
    this.editDdemographicsformDataObj.orderingPhysicianState = "";
    this.editDdemographicsformDataObj.orderingPhysicianCity = "";
    this.editDdemographicsformDataObj.orderingPhysician = "";
  }

  ngOnInit() {
    this.fnFindDemographic();
    this.fnFindPhysicianCities();
  }

  fnFindDemographic() {
    const finalData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj.commandResult.data.userInfo);
          this.editDdemographicsformDataObj.email =
            this.resultJsonObj.commandResult.data.userInfo.Email;
          this.editDdemographicsformDataObj.cellPhone =
            this.resultJsonObj.commandResult.data.userInfo.Mobile;
          if (
            this.resultJsonObj.commandResult.data.userInfo.DemographicId > 0
          ) {
            const form1DOB =
              this.resultJsonObj.commandResult.data.userInfo.form1DOB.split(
                "-"
              );
            this.existingDemographicData = true;
            this.editDdemographicsformDataObj.fName =
              this.resultJsonObj.commandResult.data.userInfo.from1FirstName;
            if (
              this.resultJsonObj.commandResult.data.userInfo.from1MiddleName ==
              "NA"
            ) {
              this.editDdemographicsformDataObj.mName = "";
            } else {
              this.editDdemographicsformDataObj.mName =
                this.resultJsonObj.commandResult.data.userInfo.from1MiddleName;
            }
            this.editDdemographicsformDataObj.lName =
              this.resultJsonObj.commandResult.data.userInfo.from1LastName;
            this.editDdemographicsformDataObj.dobYear = form1DOB[0];
            this.editDdemographicsformDataObj.dobMonth = form1DOB[1];
            this.editDdemographicsformDataObj.dobDay = form1DOB[2];
            this.editDdemographicsformDataObj.age =
              this.resultJsonObj.commandResult.data.userInfo.form1Age;
            this.editDdemographicsformDataObj.gender =
              this.resultJsonObj.commandResult.data.userInfo.form1Gender;
            if (
              this.resultJsonObj.commandResult.data.userInfo
                .form1ResidenceNumber == "NA"
            ) {
              this.editDdemographicsformDataObj.residence = "";
            } else {
              this.editDdemographicsformDataObj.residence =
                this.resultJsonObj.commandResult.data.userInfo.form1ResidenceNumber;
            }
            this.editDdemographicsformDataObj.street =
              this.resultJsonObj.commandResult.data.userInfo.form1StreetName;
            this.editDdemographicsformDataObj.city =
              this.resultJsonObj.commandResult.data.userInfo.form1City;
            this.editDdemographicsformDataObj.state =
              this.resultJsonObj.commandResult.data.userInfo.form1State;
            this.editDdemographicsformDataObj.zip =
              this.resultJsonObj.commandResult.data.userInfo.form1ZIP;
            this.editDdemographicsformDataObj.email =
              this.resultJsonObj.commandResult.data.userInfo.form1EmailAddress;
            this.editDdemographicsformDataObj.cellPhone =
              this.resultJsonObj.commandResult.data.userInfo.form1CellularPhone;
            if (
              this.resultJsonObj.commandResult.data.userInfo
                .form1CellularPhoneAlt == "NA"
            ) {
              this.editDdemographicsformDataObj.altPhone = "";
            } else {
              this.editDdemographicsformDataObj.altPhone =
                this.resultJsonObj.commandResult.data.userInfo.form1CellularPhoneAlt;
            }
            this.editDdemographicsformDataObj.primaryPhysician =
              this.resultJsonObj.commandResult.data.userInfo.form1PrimaryPhysician;
            this.editDdemographicsformDataObj.primaryPhysicianState =
              this.resultJsonObj.commandResult.data.userInfo.form1PrimaryPhysicianState;
            this.editDdemographicsformDataObj.primaryPhysicianCity =
              this.resultJsonObj.commandResult.data.userInfo.form1PrimaryPhysicianCity;
            this.editDdemographicsformDataObj.orderingPhysician =
              this.resultJsonObj.commandResult.data.userInfo.form1OrderingPhysician;
            this.editDdemographicsformDataObj.orderingPhysicianState =
              this.resultJsonObj.commandResult.data.userInfo.form1OrderingPhysicianState;
            this.editDdemographicsformDataObj.orderingPhysicianCity =
              this.resultJsonObj.commandResult.data.userInfo.form1OrderingPhysicianCity;
            this.editDdemographicsformDataObj.demographicId =
              this.resultJsonObj.commandResult.data.userInfo.DemographicId;

            this.fnFindCitiesList(
              this.resultJsonObj.commandResult.data.userInfo.form1State
            );
            this.fnFindPrimaryPhysicianCitiesList(
              this.resultJsonObj.commandResult.data.userInfo
                .form1PrimaryPhysicianState
            );
            this.fnFindOrderingPhysicianCitiesList(
              this.resultJsonObj.commandResult.data.userInfo
                .form1OrderingPhysicianState
            );
            this.fnFindPrimaryPhysicianList(
              this.resultJsonObj.commandResult.data.userInfo
                .form1PrimaryPhysicianCity
            );
            this.fnFindOrderingPhysicianList(
              this.resultJsonObj.commandResult.data.userInfo
                .form1OrderingPhysicianCity
            );
          } else {
            this.newDemographicData = true;
            this.editDdemographicsformDataObj.demographicId = 0;
            this.existingDemographicData = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindCitiesList(stateCode) {
    const finalDataCity = { stateCode: stateCode };
    // console.log(this.appConfig.getLambdaUrl01() + ApiHelper.name_getCitiesByStateCodeApi);
    this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalDataCity,
        this.httpOptions
      )
      .subscribe(
        (apiResponseCity) => {
          this.resultJsonObjCity = apiResponseCity;
          if (this.resultJsonObjCity.commandResult.status == 1) {
            if (this.resultJsonObjCity.commandResult.data.cityList.length > 0) {
              this.cityLength = true;
              this.citiesList =
                this.resultJsonObjCity.commandResult.data.cityList;
              // console.log('citiesList=>', this.citiesList)
            } else {
              this.noCityLength = true;
            }
          } else {
            this.noCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindPrimaryPhysicianCitiesList(stateCode) {
    const finalDataPryPhyCity = { stateCode: stateCode };
    this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalDataPryPhyCity,
        this.httpOptions
      )
      .subscribe(
        (apiResponsePryPhyCity) => {
          this.resultJsonObjPryPhyCity = apiResponsePryPhyCity;
          if (this.resultJsonObjPryPhyCity.commandResult.status == 1) {
            if (
              this.resultJsonObjPryPhyCity.commandResult.data.cityList.length >
              0
            ) {
              this.pryPhyCityLength = true;
              this.pryPhyCitiesList =
                this.resultJsonObjPryPhyCity.commandResult.data.cityList;
            } else {
              this.noPryPhyCityLength = true;
            }
          } else {
            this.noPryPhyCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindOrderingPhysicianCitiesList(stateCode) {
    const finalDataOrdPhyCity = { stateCode: stateCode };
    this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalDataOrdPhyCity,
        this.httpOptions
      )
      .subscribe(
        (apiResponseOrdPhyCity) => {
          this.resultJsonObjOrdPhyCity = apiResponseOrdPhyCity;
          if (this.resultJsonObjOrdPhyCity.commandResult.status == 1) {
            if (
              this.resultJsonObjOrdPhyCity.commandResult.data.cityList.length >
              0
            ) {
              this.ordPhyCityLength = true;
              this.ordPhyCitiesList =
                this.resultJsonObjOrdPhyCity.commandResult.data.cityList;
            } else {
              this.noOrdPhyCityLength = true;
            }
          } else {
            this.noOrdPhyCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindPrimaryPhysicianList(cityName) {
    if (this.existingDemographicData) {
      const finalDataPriPhysician = { cityName: cityName };
      this.http
        .post(
          this.appConfig.getLambdaUrl03() +
            ApiHelper.name_getPhysiciansByCityCodeApi,
          finalDataPriPhysician,
          this.httpOptions
        )
        .subscribe(
          (apiResponsePhysician: any) => {
            if (apiResponsePhysician.commandResult.status == 1) {
              if (
                apiResponsePhysician.commandResult.data.physiciansList.length >
                0
              ) {
                this.priPhysicianLength = true;
                this.priSelectDisabledOption = false;
                this.priPhysiciansList =
                  apiResponsePhysician.commandResult.data.physiciansList;
              } else {
                this.noPriPhysicianLength = true;
              }
            } else {
              this.noPriPhysicianLength = true;
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  fnFindOrderingPhysicianList(cityName) {
    if (this.existingDemographicData) {
      const finalDataPhysician = { cityName: cityName };
      // console.log('ordPhysiciansList=>', finalDataPhysician);
      this.http
        .post(
          this.appConfig.getLambdaUrl03() +
            ApiHelper.name_getPhysiciansByCityCodeApi,
          finalDataPhysician,
          this.httpOptions
        )
        .subscribe(
          (apiResponsePhysician: any) => {
            if (apiResponsePhysician.commandResult.status == 1) {
              if (
                apiResponsePhysician.commandResult.data.physiciansList.length >
                0
              ) {
                this.ordPhysicianLength = true;
                this.orderSelectDisabledOption = false;
                this.ordPhysiciansList =
                  apiResponsePhysician.commandResult.data.physiciansList;
                // console.log('ordPhysiciansList=>', this.ordPhysiciansList);
              } else {
                this.noOrdPhysicianLength = true;
              }
            } else {
              this.noOrdPhysicianLength = true;
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  fnFindPhysicianCities() {
    const finalData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findPhysicianCitiesApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.statesArrayDB = apiResponse.commandResult.data.statesList;
          // console.log('this.statesArrayDB=>', this.statesArrayDB)
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onSubmit() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to continue with the given information?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.editform4Comp.onSubmit
    );
    {
      const finalData = {
        fName: this.editDdemographicsformDataObj.fName,
        mName: this.editDdemographicsformDataObj.mName,
        lName: this.editDdemographicsformDataObj.lName,
        dobMonth: this.editDdemographicsformDataObj.dobMonth,
        dobDay: this.editDdemographicsformDataObj.dobDay,
        dobYear: this.editDdemographicsformDataObj.dobYear,
        age: this.newAge ? this.newAge : this.editDdemographicsformDataObj.age,
        gender: this.editDdemographicsformDataObj.gender,
        residence: this.editDdemographicsformDataObj.residence,
        street: this.editDdemographicsformDataObj.street,
        city: this.editDdemographicsformDataObj.city,
        state: this.editDdemographicsformDataObj.state,
        zip: this.editDdemographicsformDataObj.zip,
        email: this.editDdemographicsformDataObj.email,
        cellPhone: this.editDdemographicsformDataObj.cellPhone,
        altPhone: this.editDdemographicsformDataObj.altPhone,
        primaryPhysician: this.editDdemographicsformDataObj.primaryPhysician,
        primaryPhysicianState:
          this.editDdemographicsformDataObj.primaryPhysicianState,
        primaryPhysicianCity:
          this.editDdemographicsformDataObj.primaryPhysicianCity,

        orderingPhysician: this.editDdemographicsformDataObj.orderingPhysician,
        orderingPhysicianState:
          this.editDdemographicsformDataObj.orderingPhysicianState,
        orderingPhysicianCity:
          this.editDdemographicsformDataObj.orderingPhysicianCity,

        loggedInUserId: this.loggedInUserId,
        demographicId: this.editDdemographicsformDataObj.demographicId,
      };

      // console.log(finalData);
      if (
        this.editDdemographicsformDataObj.demographicId > 0 ||
        this.editDdemographicsformDataObj.demographicId == null
      ) {
        var apiName = ApiHelper.name_updateDemographicDetailsGeneralApi;
      } else {
        apiName = ApiHelper.name_saveDemographicFormApiGeneral;
      }

      return this.http
        .post(
          this.appConfig.getLambdaUrl04() + apiName,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse) => {
            this.resultJsonObj = apiResponse;
            if (this.resultJsonObj.commandResult.status == 1) {
              localStorage.setItem(
                "fName",
                this.editDdemographicsformDataObj.fName
              );
              localStorage.setItem(
                "mName",
                this.editDdemographicsformDataObj.mName
                  ? this.editDdemographicsformDataObj.mName
                  : ""
              );
              localStorage.setItem(
                "lName",
                this.editDdemographicsformDataObj.lName
              );
              localStorage.setItem(
                "dobMonth",
                this.editDdemographicsformDataObj.dobMonth
              );
              localStorage.setItem(
                "dobDay",
                this.editDdemographicsformDataObj.dobDay
              );
              localStorage.setItem(
                "dobYear",
                this.editDdemographicsformDataObj.dobYear
              );
              localStorage.setItem(
                "age",
                this.newAge
                  ? this.newAge
                  : this.editDdemographicsformDataObj.age
              );
              localStorage.setItem(
                "gender",
                this.editDdemographicsformDataObj.gender
              );
              localStorage.setItem(
                "residence",
                this.editDdemographicsformDataObj.residence
              );
              localStorage.setItem(
                "street",
                this.editDdemographicsformDataObj.street
              );
              localStorage.setItem(
                "city",
                this.editDdemographicsformDataObj.city
              );
              localStorage.setItem(
                "state",
                this.editDdemographicsformDataObj.state
              );
              localStorage.setItem(
                "zip",
                this.editDdemographicsformDataObj.zip
              );
              localStorage.setItem(
                "email",
                this.editDdemographicsformDataObj.email
              );
              localStorage.setItem(
                "cellPhone",
                this.editDdemographicsformDataObj.cellPhone
              );
              localStorage.setItem(
                "altPhone",
                this.editDdemographicsformDataObj.altPhone
              );

              localStorage.setItem(
                "primaryPhysician",
                this.editDdemographicsformDataObj.primaryPhysician
              );
              localStorage.setItem(
                "primaryPhysicianState",
                this.editDdemographicsformDataObj.primaryPhysicianState
              );
              localStorage.setItem(
                "primaryPhysicianCity",
                this.editDdemographicsformDataObj.primaryPhysicianCity
              );

              localStorage.setItem(
                "orderingPhysician",
                this.editDdemographicsformDataObj.orderingPhysician
              );
              localStorage.setItem(
                "orderingPhysicianCity",
                this.editDdemographicsformDataObj.orderingPhysicianCity
              );
              localStorage.setItem(
                "orderingPhysicianState",
                this.editDdemographicsformDataObj.orderingPhysicianState
              );

              localStorage.setItem(
                "loggedInUserId",
                this.editDdemographicsformDataObj.loggedInUserId
              );
              localStorage.setItem(
                "demographicId",
                this.editDdemographicsformDataObj.demographicId
              );

              this.demographicId =
                this.resultJsonObj.commandResult.data.demographicInfo.Id;
              // alert("Demographic Details updated.");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.ediform4Comp.onSubmit
              );
              // this.router.navigate(['/dashboard/' +this.loggedInUserId]);
              if (alertStatus)
                this.router.navigate([
                  "/dashboard/" + this.loggedInUserId + "/1",
                ]);
              // console.log("done");
            } else {
              //  alert("oops! error occured, please try later");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.demographics.onSubmit
              );
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  verifyAddress() {
    this.addressList = {};
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const options = {
      headers: httpHeaders,
    };

    const finalData = {
      streeAddress:
        this.editDdemographicsformDataObj.residence +
        ", " +
        this.editDdemographicsformDataObj.street +
        ", " +
        this.editDdemographicsformDataObj.city +
        ", " +
        this.editDdemographicsformDataObj.state +
        ", " +
        this.editDdemographicsformDataObj.zip,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_verifyAddressApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;

          if (this.resultJsonObj.commandResult.status == 1) {
            if (this.resultJsonObj.commandResult.data.Addresses.length > 0) {
              this.addressLength = true;
              this.addressList =
                this.resultJsonObj.commandResult.data.Addresses;
            } else {
            }
          } else {
          }
          this.showAddressSuggestionsModal = true;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  closeAddressSuggestionsModal() {
    this.showAddressSuggestionsModal = false;
  }

  onItemChange(address) {
    if (address != "curentAdd") {
      var splitted = address.split(",");
      this.editDdemographicsformDataObj.street = splitted[0];
      // var splitted1 = splitted[1].trim().split(" ");

      // var n = splitted[1].lastIndexOf(" ");

      var stateName = splitted[1]
        .trim()
        .substring(splitted[1].trim().lastIndexOf(" ") + 1);

      var cityName = splitted[1]
        .trim()
        .substring(0, splitted[1].trim().lastIndexOf(" "));

      // console.log(cityName);
      // console.log(stateName);
      this.editDdemographicsformDataObj.city = cityName;
      this.editDdemographicsformDataObj.state = stateName;
      this.showAddressSuggestionsModal = false;
    }
  }

  findCitiesByStateCode(stateCode) {
    // console.log(stateCode)
    this.stateSelectDisabledOption = false;

    this.citiesList = [];
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = { stateCode: stateCode };
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          this.citySelectDisabledOption = true;
          this.editDdemographicsformDataObj.zip = null;
          if (this.resultJsonObj.commandResult.status == 1) {
            if (this.resultJsonObj.commandResult.data.cityList.length > 0) {
              this.cityLength = true;
              this.citiesList = this.resultJsonObj.commandResult.data.cityList;
              // console.log(this.citiesList);
            } else {
              this.noCityLength = true;
            }
          } else {
            this.noCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPryPhyCitiesByStateCode(stateCode) {
    // alert(stateCode)
    this.priStateSelectDisabledOption = false;

    this.pryPhyCitiesList = [];
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = { stateCode: stateCode };
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;

          this.priCitySelectDisabledOption = false;
          if (this.resultJsonObj.commandResult.status == 1) {
            if (this.resultJsonObj.commandResult.data.cityList.length > 0) {
              this.pryPhyCityLength = true;
              this.pryPhyCitiesList =
                this.resultJsonObj.commandResult.data.cityList;
            } else {
              this.noPryPhyCityLength = true;
            }
          } else {
            this.noPryPhyCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findOrdPhyCitiesByStateCode(stateCode) {
    // alert(stateCode)
    this.orderStateSelectDisabledOption = false;

    this.ordPhyCitiesList = [];
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = { stateCode: stateCode };
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          this.orderCitySelectDisabledOption = false;
          if (this.resultJsonObj.commandResult.status == 1) {
            if (this.resultJsonObj.commandResult.data.cityList.length > 0) {
              // console.log(this.orderStateSelectDisabledOption);
              this.ordPhyCityLength = true;
              this.ordPhyCitiesList =
                this.resultJsonObj.commandResult.data.cityList;
              // console.log(this.ordPhyCitiesList);
            } else {
              this.noOrdPhyCityLength = true;
            }
          } else {
            this.noOrdPhyCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to cancel and return to Home Page."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.demographics.onReset
    );
    if (alertStatus) {
      // this.router.navigate(['/dashboard/' + this.loggedInUserId]);
      this.router.navigate(["/dashboard/" + this.loggedInUserId + "/1"]);
    }
  }

  hideDisabledOrderCity() {
    this.orderCitySelectDisabledOption = false;
  }

  hideDisabledOrder() {
    this.orderSelectDisabledOption = false;
  }

  hideDisabledPriCity() {
    this.priCitySelectDisabledOption = false;
  }

  hideDisabledPri() {
    this.priSelectDisabledOption = false;
  }

  hideDisabledCity() {
    this.citySelectDisabledOption = false;
  }

  findPryPhysiciansByCity(cityName) {
    this.priCitySelectDisabledOption = false;
    const finalDataPhysician = { cityName: cityName };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          if (apiResponsePhysician.commandResult.status == 1) {
            this.priSelectDisabledOption = false;
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.priPhysicianLength = true;
              this.priPhysiciansList =
                apiResponsePhysician.commandResult.data.physiciansList;
              // console.log(this.priPhysiciansList);
              // console.log(this.priPhysicianLength);
            } else {
              // alert("No Physicians Found");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.demographics.findPryPhysiciansByCity
              );

              this.noPriPhysicianLength = true;
            }
          } else {
            // alert("No Physicians Found");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.findPryPhysiciansByCity
            );
            this.noPriPhysicianLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findOdrPhysiciansByCity(cityName) {
    this.orderCitySelectDisabledOption = false;
    const finalDataOrdPhysician = { cityName: cityName };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataOrdPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          // console.log(apiResponsePhysician);
          if (apiResponsePhysician.commandResult.status == 1) {
            this.orderSelectDisabledOption = false;
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.ordPhysicianLength = true;
              this.ordPhysiciansList =
                apiResponsePhysician.commandResult.data.physiciansList;
              // console.log(this.ordPhyCityLength);
              // console.log(this.ordPhysiciansList);
            } else {
              // alert("No Physicians Found");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.demographics.findPryPhysiciansByCity
              );
              this.noOrdPhysicianLength = true;
            }
          } else {
            // alert("No Physicians Found");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.findPryPhysiciansByCity
            );
            this.noOrdPhysicianLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }

  calculateAge(value) {
    if (value.dobDay > 0 && value.dobMonth > 0 && value.dobYear > 0) {
      // tslint:disable-next-line: prefer-const
      var calculatedAge = this.commonHelper.calculateAge(
        value.dobYear,
        value.dobMonth,
        value.dobDay
      );
      this.editDdemographicsformDataObj.age = calculatedAge;
      this.newAge = calculatedAge;
    } else {
      this.editDdemographicsformDataObj.age =
        this.editDdemographicsformDataObj.age;
    }
    // console.log(value);
  }

  async goToDashboad() {
    // if (confirm('PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’.'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }
}
