import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';

import {environment} from './../../../../environments/environment';
import {ApiHelper} from './../../../_helpers/api.helper';
import {CommonHelper} from './../../../_helpers/common.helper';
import {AppConfig} from './../../../../app/app.config';
import {ConstantHelper} from './../../../_helpers/constant.helper';
import {SweetAlertHelper} from '../../../_helpers/sweet.alert.helper';
import {Message} from '../../../_locale/messages';
import {UserService} from './../../../_services/user.service';


declare let $: any;

@Component({
  selector: 'app-review-hipaa',
  templateUrl: './review-hipaa.component.html',
  styleUrls: ['./review-hipaa.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class ReviewHipaaComponent implements OnInit {
  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  hidePdfPopUp = false;
  demographicUrl: SafeResourceUrl;
  hippaUrl: SafeResourceUrl;
  disclaimerUrl: SafeResourceUrl;
  ahsDisclaimerPdf: SafeResourceUrl;
  ahsHippaPdf: SafeResourceUrl;
  showDocThumbnails = true;
  ahsPdfUrl: SafeResourceUrl = '';
  documentsLength = false;
  documentsList: any;
  docGroupId: any;
  docGroupTitle: any;
  docSet: any;
  docTyep: any;
  companyDefaultGroupTitle: any;
  companyDefaultGroupId: any;
  activeGroupId: any;
  selectedDocId: any;
  signedGroups: any = [];
  signedGroupsCount: any;
  showLoader = false;
  isUpdate: any;


  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  profileObj: any;
  tempProfileObj: any;
  showDeleteButton = false;
  showHippaUpdateBtn: string;
  message = Message;
  practiceDocCount: any;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public userService: UserService,
    public sweetAlertHelper: SweetAlertHelper
  ) {
  }

  ngOnInit() {
    $(document).ready(function() {
      $(window).resize(function() {
        setHeightResizeFunc();
      });
      setHeightResizeFunc();
    });

    function setHeightResizeFunc() {
      const rightHeight =
        $('.right-box .ibox-title').height() +
        $('.right-box .ibox-content').height() -
        45;
      $('.docLists').height(rightHeight);
    }

    this.showHippaUpdateBtn = localStorage.getItem('showHippaUpdateBtn');
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.loggedInUserId = localStorage.getItem('delegateId') ? localStorage.getItem('delegateId') : this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.demographicId = localStorage.getItem('MinorDemographicId') ? localStorage.getItem('MinorDemographicId') : this.activatedRoute.snapshot.paramMap.get('demographicId');
    this.isUpdate = localStorage.getItem('isUpdate');

    this.findUserDetails(this.loggedInUserId);
    this.findSignedGroups(this.loggedInUserId);
    this.findTempUserDetails(this.loggedInUserId);
    this.practiceDocCount = sessionStorage.getItem('practiceDocCount')

  }

  findUserDetails(userId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      id: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log('findUserDetails', apiResponse)
          if (apiResponse.commandResult.status == 1) {
            this.docGroupId =
              apiResponse.commandResult.data.userInfo.DocGroupId;
            this.docGroupTitle =
              apiResponse.commandResult.data.userInfo.GroupTitle;
            this.profileObj = apiResponse.commandResult.data.userInfo;

            this.findCompanyDefaultGroup(this.loggedInUserId);
            this.activeGroupId = this.profileObj.DocGroupId;
            this.docSet = this.profileObj.DocGroupId;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findTempUserDetails(userId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      id: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_get_demographic_temp_Api,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log('findUserDetails', apiResponse)
          if (apiResponse.commandResult.success == 1) {
            this.tempProfileObj = apiResponse.commandResult.data.userInfo;
            this.profileObj.FullName = apiResponse.commandResult.data.userInfo.FullName;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }


  findCompanyDefaultGroup(userId) {
    const finalData = {
      company_slug: null,
      loggedInUserId: userId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() +
        ApiHelper.name_getCompanyDefaultGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.companyDefaultGroupTitle =
            apiResponse.commandResult.data.companyDefaultGroup.GroupTitle;
          this.companyDefaultGroupId =
            apiResponse.commandResult.data.companyDefaultGroup.Id;
          // this.fnFindGroupDocs();
          this.fnFindAllTempDocuments(userId);
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  showDemographicForm() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
    };
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_generateDemographicPdfApi,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.success == 1) {
            // console.log(this.resultJsonObj.commandResult.data);

            // this.demographicUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );
          } else {
            // alert("oops! error occured, please try later");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.onSubmit
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  showHippaForm() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
    };

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_generateHippaRealeasPdfApi,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.success == 1) {
            this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );
          } else {
            // alert("oops! error occured, please try later");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.onSubmit
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  showDisclaimerForm() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
    };

    return this.http
      .post(
        this.appConfig.getPhpUrl() +
        ApiHelper.php_generateWaiverDisclaimerPdfApi,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.success == 1) {
            // console.log(apiResponse);
            // this.disclaimerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );
          } else {
            // alert("oops! error occured, please try later");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.onSubmit
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goToDemographicForm() {
    this.router.navigate([
      '/demographicsform/' +
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
    ]);
  }

  async editDemographicForm5() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.documentToubsComp.editDemographicsForm5
    );
    if (alertStatus) {
      this.router.navigate([
        '/edit-demographic-5/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/' +
        this.activatedRoute.snapshot.paramMap.get('demographicId'),
      ]);
    }
  }

  async editSignature() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.documentToubsComp.editSignature
    );
    if (alertStatus) {
      this.router.navigate([
        '/profile/signature/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/' +
        this.activatedRoute.snapshot.paramMap.get('demographicId'),
      ]);
    }
  }

  showDocument(documentId, documentUrl, docTyep) {
    this.selectedDocId = documentId;
    this.docTyep = docTyep;
    // console.log('this.docTyep', this.docTyep);
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
    this.showDeleteButton = true;
  }

  async onReset() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.demographics.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        '/dashboard/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/1',
      ]);
    }
  }

  selectDocGroup(docSet) {
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.showDeleteButton = false;
    if (docSet == 'A') {
      this.fnFindAllDocuments();
    } else if (docSet == 'U') {
      this.fnFindUploadedDocs();
    } else if (docSet == 'DS') {
      this.fnFindDoctorAcknowledgments();
    } else {
      this.activeGroupId = docSet;
      this.fnFindGroupDocs();
    }
  }


  fnFindAllDocuments() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
      email: this.profileObj.Email,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findAllDocumentsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log('All Documents', this.resultJsonObj);
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            // this.documentsList =               this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }


  fnFindGroupDocs() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: null,
      doc_group_id: this.activeGroupId,
      default_group_id: this.companyDefaultGroupId,
      email: this.profileObj.Email,
    };

    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
        ApiHelper.name_findTempDocsBySubGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.showLoader = false;

          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            // this.documentsList =               this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }

  fnFindUploadedDocs() {
    // console.log(this.profileObj.Email);
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
      docType: ConstantHelper.DOC_TYPE_USER_UPLOADED,
      email: this.profileObj.Email,
      physicianUserId: null,
    };
    // console.log(finalData);
    // return;

    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
        ApiHelper.name_getUploadedDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            // this.documentsList = this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  deleteDoc() {
    if (confirm('PLEASE CONFIRM: Are you sure, you want to delete')) {
      const finalData = {
        docId: this.selectedDocId,
      };
      return this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_deleteDocApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
            this.showDeleteButton = false;
            this.fnFindUploadedDocs();
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async clearViewr() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.confirmationComp.clearViewr
    );
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to clear preview?"))
    if (alertStatus) {
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
      this.showDeleteButton = false;
    }
  }

  ////////////////////////////////////////////////////////

  async signOff() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.documentToubsComp.signOff
    );
    if (alertStatus) {
      this.showDocThumbnails = false;
      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      const options = {
        headers: httpHeaders,
      };
      const finalData = {
        loggedInUserId: this.loggedInUserId,
        demographicId: this.demographicId,
        company_slug: ConstantHelper.COMPANY_SLUG,
        isUpdate: this.isUpdate,
      };

      console.log(finalData);
      // return;
      this.showLoader = true;

      this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_sendSignOffMail_updateApi,
          finalData,
          options
        )
        .subscribe(
          (apiResponse) => {
            this.resultJsonObj = apiResponse;
            this.showLoader = false;
            this.goToregisteredDOctorList()
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async goToregisteredDOctorList(){
    if (+this.practiceDocCount > 0){
      const alertStatus = await this.sweetAlertHelper.confirmPopUpYesOrNo(
        this.message.confirmTitle,
        this.message.confirmMessages.documentToubsComp.goToDocList
      );
      if(alertStatus){
        this.router.navigate(['/registered-doctors/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId')]); 
      }else {
        this.router.navigate([
          '/dashboard/' +
          this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
          '/1',
        ]);
      }
    } else {
      this.router.navigate([
        '/dashboard/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/1',
      ]);
    }
  }

  findSignedGroups(userId) {
    const finalData = {
      company_slug: null,
      loggedInUserId: userId,
      showLatest: 1,
    };
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findUserDocumentGroupsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.signedGroups = apiResponse.commandResult.data.DocGroupd;
          this.signedGroupsCount = apiResponse.commandResult.data.DocGroupd.length;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindDoctorAcknowledgments() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
      docType: 7,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
        ApiHelper.name_getAcknowledgmentDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            // this.documentsList = this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }


  fnFindAllTempDocuments(userId) {
    const finalData = {
      loggedInUserId: userId,
      company_slug: ConstantHelper.COMPANY_SLUG,
      email: this.profileObj.Email,
    };

    console.log('finalData', finalData);
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_get_user_docs_temp_Api,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.showLoader = false;
          this.resultJsonObj = apiResponse;
          console.log('All Documents', this.resultJsonObj);
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList = this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

}
