import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UserService } from "../../_services/user.service";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";

@Component({
  selector: "app-registered-sites",
  templateUrl: "./registered-sites.component.html",
  styleUrls: ["./registered-sites.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class RegisteredSitesComponent implements OnInit {
  loggedInUserId: string;
  companiesList: any = [];
  companiesListLength = false;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    private userService: UserService
  ) {}

  ngOnInit() {
    // console.log(localStorage);
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.findUserDoctors();
  }

  //not in use
  findCompanies() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findCompaniesListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.companiesList = apiResponse.commandResult.data.companiesList;
          // console.log(this.companiesList);
          this.companiesListLength = true;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDoctors() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findUserDoctorsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.companiesList = apiResponse.commandResult.data.companiesList;
          // console.log(this.companiesList);
          this.companiesListLength = true;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goToChildSite(companySlug) {
    // console.log(companySlug);
    // console.log(localStorage.getItem('loggedInUserRoleId'));

    if (localStorage.getItem("loggedInUserRoleId") == "2") {
      window.open(
        this.userService.childSiteUrl(companySlug) +
          "/dashboard/" +
          this.loggedInUserId,
        "_self"
      );
    } else {
      window.open(
        this.userService.childSiteUrl(companySlug) +
          "/admin/dashboard/" +
          this.loggedInUserId,
        "_self"
      );
    }
  }

  async logout() {
    // if (confirm('PLEASE CONFIRM: Are you sure, you want to close your session?'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.docHeaderComp.logout
    );
    if (alertStatus) {
      localStorage.clear();
      this.router.navigate(["/"]);
    }
  }

  async goToDashboard() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to return to the dashboard?"
    //   )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }
}
