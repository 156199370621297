import {NgModule} from '@angular/core';
import {Routes, RouterModule, CanActivate} from '@angular/router';

import {from} from 'rxjs';
import {AuthGuard} from './_guards/auth.guard';
import {AuthRoleGuard} from './_guards/auth-role.guard';
import {LoginQaComponent} from './auth/login-qa/login-qa.component';
import {LoginComponent} from './auth/login/login.component';
import {RegisterComponent} from './auth/register/register.component';
import {DemographicsformComponent} from './user/demographicsform/demographicsform.component';
import {HippoReleaseNavigationComponent} from './user/hippo-release-navigation/hippo-release-navigation.component';
import {HippoReleaseFormComponent} from './user/hippo-release-form/hippo-release-form.component';
import {WaiverDisclaimerFormComponent} from './user/waiver-disclaimer-form/waiver-disclaimer-form.component';
import {WaiverDisclaimerNavigationComponent} from './user/waiver-disclaimer-navigation/waiver-disclaimer-navigation.component';
import {SignatureWaiverComponent} from './user/signature-waiver/signature-waiver.component';
import {DocumentThoubsComponent} from './user/document-thoubs/document-thoubs.component';
import {Editform2Component} from './user/editform2/editform2.component';
import {Editform3Component} from './user/editform3/editform3.component';
import {Editform4Component} from './user/editform4/editform4.component';
import {Editform5Component} from './user/editform5/editform5.component';
import {ThankyouComponent} from './user/thankyou/thankyou.component';
import {ForgotpasswordComponent} from './auth/forgotpassword/forgotpassword.component';
import {ChangepasswordComponent} from './auth/changepassword/changepassword.component';
import {ConfirmationformComponent} from './user/confirmationform/confirmationform.component';
import {UsersListComponent} from './admin/users-list/users-list.component';
import {ThumbMobileComponent} from './user/thumb-mobile/thumb-mobile.component';
import {AdminLoginComponent} from './auth/admin-login/admin-login.component';
import {DemographicsComponent} from './admin/demographics/demographics.component';
import {AddAdminUserComponent} from './admin/add-admin-user/add-admin-user.component';
import {AdminForgotpasswordComponent} from './auth/admin-forgotpassword/admin-forgotpassword.component';
import {AdminChangepasswordComponent} from './auth/admin-changepassword/admin-changepassword.component';
import {FaqListComponent} from './admin/faq-list/faq-list.component';
import {DocumentListComponent} from './admin/document-list/document-list.component';
import {AddAdminDocComponent} from './admin/add-admin-doc/add-admin-doc.component';
import {ModifyAdminDocComponent} from './admin/modify-admin-doc/modify-admin-doc.component';
import {ActivityLogComponent} from './admin/activity-log/activity-log.component';
import {PhysiciansListComponent} from './admin/physicians-list/physicians-list.component';
import {ImportPhysicianComponent} from './admin/import-physician/import-physician.component';
import {DashboardComponent} from './user/dashboard/dashboard.component';
import {DoctorSignupComponent} from './auth/doctor-signup/doctor-signup.component';
import {RegisteredSitesComponent} from './common/registered-sites/registered-sites.component';
import {DoctorRegisterComponent} from './auth/doctor-register/doctor-register.component';
import {SenddocumentComponent} from './user/senddocument/senddocument.component';
import {EmailLogComponent} from './user/email-log/email-log.component';
import {ResendDocsComponent} from './user/resend-docs/resend-docs.component';
import {RegisteredDoctorsComponent} from './user/registered-doctors/registered-doctors.component';
import {DocReviewComponent} from './user/doc-review/doc-review.component';
import {DoctorViewDocsComponent} from './auth/doctor-view-docs/doctor-view-docs.component';
import {ViewDocsAcknowledgementComponent} from './admin/view-docs-acknowledgement/view-docs-acknowledgement.component';
import {ViewUserDocsComponent} from './admin/view-user-docs/view-user-docs.component';
import {ShowDocsComponent} from './auth/show-docs/show-docs.component';
import {ChooseDoctorsComponent} from './user/choose-doctors/choose-doctors.component';
import {UshfComponent} from './user/ushf/ushf.component';
import {NjComponent} from './hipp/nj/nj.component';
import {NyComponent} from './hipp/ny/ny.component';
import {FlComponent} from './hipp/fl/fl.component';
import {PaComponent} from './hipp/pa/pa.component';
import {CtComponent} from './hipp/ct/ct.component';
import {OhComponent} from './hipp/oh/oh.component';
import {CoComponent} from './hipp/co/co.component';
import {CaComponent} from './hipp/ca/ca.component';
import {AlComponent} from './hipp/al/al.component';
import {AzComponent} from './hipp/az/az.component';
import {ArComponent} from './hipp/ar/ar.component';
import {DeComponent} from './hipp/de/de.component';
import {GaComponent} from './hipp/ga/ga.component';
import {HiComponent} from './hipp/hi/hi.component';
import {IdComponent} from './hipp/id/id.component';
import {IlComponent} from './hipp/il/il.component';
import {AkComponent} from './hipp/ak/ak.component';
import {TxComponent} from './hipp/tx/tx.component';
import {SdComponent} from './hipp/sd/sd.component';
import {NdComponent} from './hipp/nd/nd.component';
import {VaComponent} from './hipp/va/va.component';
import {VtComponent} from './hipp/vt/vt.component';
import {KyComponent} from './hipp/ky/ky.component';
import {WiComponent} from './hipp/wi/wi.component';
import {WvComponent} from './hipp/wv/wv.component';
import {TnComponent} from './hipp/tn/tn.component';
import {LaComponent} from './hipp/la/la.component';
import {MdComponent} from './hipp/md/md.component';
import {MnComponent} from './hipp/mn/mn.component';
import {MoComponent} from './hipp/mo/mo.component';
import {MsComponent} from './hipp/ms/ms.component';
import {NvComponent} from './hipp/nv/nv.component';
import {OkComponent} from './hipp/ok/ok.component';
import {WaComponent} from './hipp/wa/wa.component';
import {IaComponent} from './hipp/ia/ia.component';
import {NhComponent} from './hipp/nh/nh.component';
import {KsComponent} from './hipp/ks/ks.component';
import {OrComponent} from './hipp/or/or.component';
import {NeComponent} from './hipp/ne/ne.component';
import {MtComponent} from './hipp/mt/mt.component';
import {NcComponent} from './hipp/nc/nc.component';
import {ScComponent} from './hipp/sc/sc.component';
import {InComponent} from './hipp/in/in.component';
import {MeComponent} from './hipp/me/me.component';
import {RiComponent} from './hipp/ri/ri.component';
import {UtComponent} from './hipp/ut/ut.component';
import {NmComponent} from './hipp/nm/nm.component';
import {MaComponent} from './hipp/ma/ma.component';

import {ExpertPhysicianOpinionComponent} from './user/expert-physician-opinion/expert-physician-opinion.component';
import {ExpertPhysicianOpinionLogComponent} from './user/expert-physician-opinion-log/expert-physician-opinion-log.component';
import {HippaAcceptanceComponent} from './user/hippa-acceptance/hippa-acceptance.component';
import {WelcomeComponent} from './auth/welcome/welcome.component';
import {UploadMultipleDocumentsComponent} from './user/upload-multiple-documents/upload-multiple-documents.component';
import {UploadMultipleDocuments2Component} from './user/upload-multiple-documents2/upload-multiple-documents2.component';
import {UserViewDocsComponent} from './user/user-view-docs/user-view-docs.component';
import {UserVideoComponent} from './user/user-video/user-video.component';
import {AuthShowD2dDocumentsComponent} from './auth/auth-show-d2d-documents/auth-show-d2d-documents.component';
import {AdminViewD2dDocsComponent} from './admin/admin-view-d2d-docs/admin-view-d2d-docs.component';
import {ExpertPhysicianOpinion2Component} from './user/expert-physician-opinion2/expert-physician-opinion2.component';
import {UserViewD2dDocsComponent} from './user/user-view-d2d-docs/user-view-d2d-docs.component';
import {UserReplyD2dComponent} from './user/user-reply-d2d/user-reply-d2d.component';
import {SendEmailComponent} from './admin/send-email/send-email.component';
import {PracticesComponent} from './admin/practices/practices.component';
import {AddAdminPracticeComponent} from './admin/add-admin-practice/add-admin-practice.component';
import {UserContactUsComponent} from './user/user-contact-us/user-contact-us.component';
import {UserChangePasswordComponent} from './user/user-change-password/user-change-password.component';
import {UserSecurityQuestions} from './user/user-security-questions/user-security-questions.component';

import {ParentMinorRelationComponent} from './user/parent-minor-relation/parent-minor-relation.component';
import {ParentMinorLoginComponent} from './auth/parent-minor-login/parent-minor-login.component';
import {MinorActivityLogComponent} from './user/minor-activity-log/minor-activity-log.component';
import {ReportsComponent} from './admin/reports/reports.component';

import {PatientDemographicsComponent} from './user/profile/patient-demographics/patient-demographics.component';
import {AcceptHipaaComponent} from './user/profile/accept-hipaa/accept-hipaa.component';
import {SignHipaaComponent} from './user/profile/sign-hipaa/sign-hipaa.component';
import {ReviewHipaaComponent} from './user/profile/review-hipaa/review-hipaa.component';
import { AhsaichatgptComponent } from "./ahsaichatgpt/ahsaichatgpt.component";
import { ShareDocuments } from './user/share-document/share-documents.component';
import { PatientHistory } from './user/patient-history/patient-history.component';


const routes: Routes = [
  {path: '', component: LoginComponent, pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'login-qa', component: LoginQaComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'forget-password', component: ForgotpasswordComponent},

  {
    path: 'registered-doctors/:loggedInUserId',
    component: RegisteredDoctorsComponent,
  },
  {path: 'viewDocs/:pId/:docEmail', component: DoctorViewDocsComponent},
  {
    path: 'doctor/view-docs-acknowledgement/:pId/:loggedInUserId',
    component: ViewDocsAcknowledgementComponent,
  },
  {
    path: 'doctor/user-docs/:pId/:loggedInUserId',
    component: ViewUserDocsComponent,
  },
  {
    path: 'doctor/user-docs/:pId/:logId/:loggedInUserId/:userOptionId',
    component: AdminViewD2dDocsComponent,
  },
  {
    path: 'doctor/view-docs-acknowledgement/:pId/:logId/:loggedInUserId/:userOptionId',
    component: ViewDocsAcknowledgementComponent,
  },
  {path: 'showMail/:pId/:docEmail', component: ShowDocsComponent},
  {
    path: 'showMail/:pId/:logId/:docEmail/:userOptionId',
    component: AuthShowD2dDocumentsComponent,
  },
  {path: 'viewMail/:pId', component: LoginComponent},
  {path: 'view-docs/:pId/:loggedInUserId', component: UserViewDocsComponent},
  {path: 'admin', component: AdminLoginComponent},
  {path: 'admin', component: AdminLoginComponent, pathMatch: 'full'},

  {
    path: 'videos/:loggedInUserId',
    component: UserVideoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user-docs/:pId/:logId/:loggedInUserId/:userOptionId',
    component: UserViewD2dDocsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reply-d2d/:pId/:logId/:loggedInUserId/:userOptionId',
    component: UserReplyD2dComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'hippa-nj/:loggedInUserId',
    component: NjComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ny/:loggedInUserId',
    component: NyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-fl/:loggedInUserId',
    component: FlComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-pa/:loggedInUserId',
    component: PaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ct/:loggedInUserId',
    component: CtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-oh/:loggedInUserId',
    component: OhComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-co/:loggedInUserId',
    component: CoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ca/:loggedInUserId',
    component: CaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-al/:loggedInUserId',
    component: AlComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-az/:loggedInUserId',
    component: AzComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ar/:loggedInUserId',
    component: ArComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-de/:loggedInUserId',
    component: DeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ga/:loggedInUserId',
    component: GaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-hi/:loggedInUserId',
    component: HiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-id/:loggedInUserId',
    component: IdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-il/:loggedInUserId',
    component: IlComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ak/:loggedInUserId',
    component: AkComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-tx/:loggedInUserId',
    component: TxComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-sd/:loggedInUserId',
    component: SdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-nd/:loggedInUserId',
    component: NdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-va/:loggedInUserId',
    component: VaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-vt/:loggedInUserId',
    component: VtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ky/:loggedInUserId',
    component: KyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-wi/:loggedInUserId',
    component: WiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-wv/:loggedInUserId',
    component: WvComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-tn/:loggedInUserId',
    component: TnComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-la/:loggedInUserId',
    component: LaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-md/:loggedInUserId',
    component: MdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-mn/:loggedInUserId',
    component: MnComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-mo/:loggedInUserId',
    component: MoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ms/:loggedInUserId',
    component: MsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-nv/:loggedInUserId',
    component: NvComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ok/:loggedInUserId',
    component: OkComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-wa/:loggedInUserId',
    component: WaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ia/:loggedInUserId',
    component: IaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-nh/:loggedInUserId',
    component: NhComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ks/:loggedInUserId',
    component: KsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-or/:loggedInUserId',
    component: OrComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ne/:loggedInUserId',
    component: NeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-mt/:loggedInUserId',
    component: MtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-nc/:loggedInUserId',
    component: NcComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-sc/:loggedInUserId',
    component: ScComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-in/:loggedInUserId',
    component: InComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-me/:loggedInUserId',
    component: MeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ri/:loggedInUserId',
    component: RiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ut/:loggedInUserId',
    component: UtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-nm/:loggedInUserId',
    component: NmComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ma/:loggedInUserId',
    component: MaComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'hippa-nj/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: NjComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ny/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: NyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-fl/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: FlComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-pa/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: PaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ct/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: CtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-oh/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: OhComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-co/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: CoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ca/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: CaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-al/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: AlComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-az/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: AzComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ar/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: ArComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-de/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: DeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ga/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: GaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-hi/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: HiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-id/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: IdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-il/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: IlComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ak/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: AkComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-tx/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: TxComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-sd/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: SdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-nd/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: NdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-va/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: VaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-vt/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: VtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ky/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: KyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-wi/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: WiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-wv/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: WvComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-tn/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: TnComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-la/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: LaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-md/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: MdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-mn/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: MnComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-mo/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: MoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ms/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: MsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-nv/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: NvComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ok/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: OkComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-wa/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: WaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ia/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: IaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-nh/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: NhComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ks/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: KsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-or/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: OrComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ne/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: NeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-mt/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: MtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-nc/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: NcComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-sc/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: ScComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-in/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: InComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-me/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: MeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ri/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: RiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ut/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: UtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-nm/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: NmComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ma/:loggedInUserId/:isEmail/:isPracticePhy/:physicianId',
    component: MaComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'hippa-nj/:loggedInUserId/:isEmail/:requestId',
    component: NjComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ny/:loggedInUserId/:isEmail/:requestId',
    component: NyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-nv/:loggedInUserId/:isEmail/:requestId',
    component: NvComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'hippa-fl/:loggedInUserId/:isEmail/:requestId',
    component: FlComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-pa/:loggedInUserId/:isEmail/:requestId',
    component: PaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ct/:loggedInUserId/:isEmail/:requestId',
    component: CtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-oh/:loggedInUserId/:isEmail/:requestId',
    component: OhComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-co/:loggedInUserId/:isEmail/:requestId',
    component: CoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ca/:loggedInUserId/:isEmail/:requestId',
    component: CaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-al/:loggedInUserId/:isEmail/:requestId',
    component: AlComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-az/:loggedInUserId/:isEmail/:requestId',
    component: AzComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ar/:loggedInUserId/:isEmail/:requestId',
    component: ArComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-de/:loggedInUserId/:isEmail/:requestId',
    component: DeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ga/:loggedInUserId/:isEmail/:requestId',
    component: GaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-hi/:loggedInUserId/:isEmail/:requestId',
    component: HiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-id/:loggedInUserId/:isEmail/:requestId',
    component: IdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-il/:loggedInUserId/:isEmail/:requestId',
    component: IlComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ak/:loggedInUserId/:isEmail/:requestId',
    component: AkComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-tx/:loggedInUserId/:isEmail/:requestId',
    component: TxComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-sd/:loggedInUserId/:isEmail/:requestId',
    component: SdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-nd/:loggedInUserId/:isEmail/:requestId',
    component: NdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-va/:loggedInUserId/:isEmail/:requestId',
    component: VaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-vt/:loggedInUserId/:isEmail/:requestId',
    component: VtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ky/:loggedInUserId/:isEmail/:requestId',
    component: KyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-wi/:loggedInUserId/:isEmail/:requestId',
    component: WiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-wv/:loggedInUserId/:isEmail/:requestId',
    component: WvComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-tn/:loggedInUserId/:isEmail/:requestId',
    component: TnComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-la/:loggedInUserId/:isEmail/:requestId',
    component: LaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-md/:loggedInUserId/:isEmail/:requestId',
    component: MdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-mn/:loggedInUserId/:isEmail/:requestId',
    component: MnComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-mo/:loggedInUserId/:isEmail/:requestId',
    component: MoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ms/:loggedInUserId/:isEmail/:requestId',
    component: MsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-mv/:loggedInUserId/:isEmail/:requestId',
    component: NvComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ok/:loggedInUserId/:isEmail/:requestId',
    component: OkComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-wa/:loggedInUserId/:isEmail/:requestId',
    component: WaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ia/:loggedInUserId/:isEmail/:requestId',
    component: IaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-nh/:loggedInUserId/:isEmail/:requestId',
    component: NhComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ks/:loggedInUserId/:isEmail/:requestId',
    component: KsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-or/:loggedInUserId/:isEmail/:requestId',
    component: OrComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ne/:loggedInUserId/:isEmail/:requestId',
    component: NeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-mt/:loggedInUserId/:isEmail/:requestId',
    component: MtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-nc/:loggedInUserId/:isEmail/:requestId',
    component: NcComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-sc/:loggedInUserId/:isEmail/:requestId',
    component: ScComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-in/:loggedInUserId/:isEmail/:requestId',
    component: InComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-me/:loggedInUserId/:isEmail/:requestId',
    component: MeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ri/:loggedInUserId/:isEmail/:requestId',
    component: RiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ut/:loggedInUserId/:isEmail/:requestId',
    component: UtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-nm/:loggedInUserId/:isEmail/:requestId',
    component: NmComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-ma/:loggedInUserId/:isEmail/:requestId',
    component: MaComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'hippanj/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: NjComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippany/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: NyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippafl/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: FlComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippapa/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: PaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippact/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: CtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaoh/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: OhComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaco/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: CoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaca/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: CaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaal/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: AlComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaaz/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: AzComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaar/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: ArComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippade/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: DeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaga/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: GaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippahi/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: HiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaid/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: IdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippail/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: IlComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaak/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: AkComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippatx/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: TxComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippasd/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: SdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippand/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: NdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippava/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: VaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippavt/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: VtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaky/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: KyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippawi/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: WiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippawv/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: WvComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippatn/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: TnComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippala/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: LaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippamd/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: MdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippamn/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: MnComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippamo/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: MoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippams/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: MsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippamv/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: NvComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaok/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: OkComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippawa/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: WaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaia/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: IaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippanh/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: NhComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaks/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: KsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaor/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: OrComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippane/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: NeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippamt/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: MtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippanc/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: NcComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippasc/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: ScComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippain/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: InComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippame/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: MeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippari/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: RiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaut/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: UtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippanm/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: NmComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippama/:loggedInUserId/:isEmail/:tempId/:userOptionId',
    component: MaComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'hippanj1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: NjComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippany1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: NyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippafl1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: FlComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippapa1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: PaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippact1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: CtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaoh1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: OhComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaco1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: CoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaca1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: CaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaal1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: AlComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaaz1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: AzComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaar1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: ArComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippade1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: DeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaga1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: GaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippahi1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: HiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaid1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: IdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippail1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: IlComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaak1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: AkComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippatx1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: TxComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippasd1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: SdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippand1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: NdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippava1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: VaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippavt1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: VtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaky1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: KyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippawi1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: WiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippawv1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: WvComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippatn1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: TnComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippala1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: LaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippamd1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: MdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippamn1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: MnComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippamo1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: MoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippams1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: MsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippanv1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: NvComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaok1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: OkComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippawa1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: WaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaia1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: IaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippanh1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: NhComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaks1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: KsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaor1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: OrComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippane1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: NeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippamt1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: MtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippanc1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: NcComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippasc1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: ScComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippain1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: InComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippame1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: MeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippari1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: RiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippaut1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: UtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippanm1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: NmComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippama1/:loggedInUserId/:isEmail/:emailLogId/:isPracticePhy/:physicianId',
    component: MaComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'reset-password/:loggedInUserId',
    component: ChangepasswordComponent,
  },
  {
    path: 'demographics/:loggedInUserId',
    component: DemographicsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/:loggedInUserId',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/:loggedInUserId/:clean',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'registered-sites/:loggedInUserId',
    component: RegisteredSitesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'faqs/:loggedInUserId',
    component: FaqListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admins/:loggedInUserId',
    component: UsersListComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'contact-us/:loggedInUserId',
    component: UserContactUsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'change-password/:loggedInUserId',
    component: UserChangePasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile/security-questions/:loggedInUserId/:demographicId',
    component: UserSecurityQuestions,
    canActivate: [AuthGuard],
  },

  {
    path: 'practices/:loggedInUserId',
    component: PracticesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'practices/add/:loggedInUserId',
    component: AddAdminPracticeComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'physicians/:loggedInUserId',
    component: PhysiciansListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'physicians/import/:loggedInUserId',
    component: ImportPhysicianComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'documents/:loggedInUserId',
    component: DocumentListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admins/add/:loggedInUserId/:companySlug',
    component: AddAdminUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'documents/add/:loggedInUserId/:companySlug',
    component: AddAdminDocComponent,
    canActivate: [AuthGuard],
  },
  // tslint:disable-next-line:max-line-length
  {
    path: 'documents/modify/:loggedInUserId/:docId/:documentName/:companySlug',
    component: ModifyAdminDocComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'logs/:loggedInUserId',
    component: ActivityLogComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'demographicsform/:loggedInUserId',
    component: DemographicsformComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippa-acceptance/:loggedInUserId/:demographicId',
    component: ConfirmationformComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hipporeleasenavigation/:loggedInUserId/:demographicId',
    component: HippoReleaseNavigationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hippoReleaseform/:loggedInUserId/:demographicId',
    component: HippoReleaseFormComponent,
    canActivate: [AuthGuard],
  },
  // tslint:disable-next-line:max-line-length
  {
    path: 'waiverdisclaimernavigation/:loggedInUserId/:demographicId',
    component: WaiverDisclaimerNavigationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'waiverdisclaimerform/:loggedInUserId/:demographicId',
    component: WaiverDisclaimerFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'signaturewaiver/:loggedInUserId/:demographicId',
    component: SignatureWaiverComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'signaturewaiver/:loggedInUserId/:demographicId/:clean',
    component: SignatureWaiverComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'documentthoumbs/:loggedInUserId/:demographicId',
    component: DocumentThoubsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-demographic-2/:loggedInUserId/:demographicId',
    component: Editform2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-demographic-3/:loggedInUserId/:demographicId',
    component: Editform3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-demographic-4/:loggedInUserId/:demographicId',
    component: Editform4Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-demographic-5/:loggedInUserId/:demographicId',
    component: Editform5Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'thankyou/:loggedInUserId',
    component: ThankyouComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'thumb-mobile/:loggedInUserId/:demographicId',
    component: ThumbMobileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/users/:loggedInUserId',
    component: UsersListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/add-admin-user/:loggedInUserId',
    component: AddAdminUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/forget-password',
    component: AdminForgotpasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/reset-password/:loggedInUserId',
    component: AdminChangepasswordComponent,
  },
  {path: 'doctor/register/:companyslug', component: DoctorSignupComponent},
  {path: 'doctor/signup', component: DoctorRegisterComponent},
  {path: 'doctor/signup/:pId/:docEmail', component: DoctorSignupComponent},
  {
    path: 'doctor/signup/:pId/:logId/:docEmail/:userOptionId',
    component: DoctorSignupComponent,
  },
  {
    path: 'upload-doc/:loggedInUserId',
    component: UploadMultipleDocuments2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'choose-doctors/:loggedInUserId',
    component: ChooseDoctorsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'send-doc/:loggedInUserId',
    component: SenddocumentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'minor-activity-log/:loggedInUserId',
    component: MinorActivityLogComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'send-doc/:loggedInUserId/:isHippa/:isPracticePhy/:physicianId/:documentId',
    component: SenddocumentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'email-log/:loggedInUserId',
    component: EmailLogComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'email-log/:loggedInUserId/:regDoctorInfo',
    component: EmailLogComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'expert-physician-opinion/:loggedInUserId/:userOptionId',
    component: ExpertPhysicianOpinion2Component,
    canActivate: [AuthGuard],
  },
  // tslint:disable-next-line:max-line-length
  {
    path: 'expert-physician-opinion/:loggedInUserId/:userOptionId/:isHippa/:tempId/:documentId',
    component: ExpertPhysicianOpinion2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'expert-physician-opinion-log/:loggedInUserId',
    component: ExpertPhysicianOpinionLogComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resend-docs/:loggedInUserId/:logId',
    component: ResendDocsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resend-docs/:loggedInUserId/:logId/:regDoctorInfo',
    component: ResendDocsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'resend-docs/:loggedInUserId/:logId/:isHippa/:documentId/:isPracticePhy/:physicianId',
    component: ResendDocsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ushf/:loggedInUserId',
    component: UshfComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'doc-review/:loggedInUserId/:demographicId',
    component: DocReviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'send-email/:loggedInUserId',
    component: SendEmailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reports/:loggedInUserId',
    component: ReportsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'parent-minor-relation/:loggedInUserId',
    component: ParentMinorRelationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'parent-minor-login/:loggedInUserId/:minorId',
    component: ParentMinorLoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile/demographics/:loggedInUserId/:demographicId',
    component: PatientDemographicsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile/hipaa/:loggedInUserId/:demographicId',
    component: AcceptHipaaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile/signature/:loggedInUserId/:demographicId',
    component: SignHipaaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile/review-doc/:loggedInUserId/:demographicId',
    component: ReviewHipaaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "ahsgpt/:loggedInUserId",
    component: AhsaichatgptComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'share-doc/:loggedInUserId',
    component: ShareDocuments,
    canActivate: [AuthGuard],
  },
  {
    path: 'patient-history/:loggedInUserId',
    component: PatientHistory,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
