import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";

@Component({
  selector: "app-hippa-acceptance",
  templateUrl: "./hippa-acceptance.component.html",
  styleUrls: ["./hippa-acceptance.component.scss"],
  providers: [AppConfig, CommonHelper]
})
export class HippaAcceptanceComponent implements OnInit {
  hippaAcceptanceForm: FormGroup;
  hippaAcceptanceFormSubmitted: boolean = false;
  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};

  editDdemographicsformDataObj: any = {};
  ahsDisclaimerPdf: SafeResourceUrl;
  ahsHippaPdf: SafeResourceUrl;
  errorMsg: boolean = false;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.hippaAcceptanceForm = this.formBuilder.group({
      hipaaAccepted: ["", [Validators.required]],
      disclaimerAccepted: ["", [Validators.required]]
    });

    this.ahsDisclaimerPdf = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.appConfig.getDisclaimerPdf()
    );

    this.ahsHippaPdf = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.appConfig.getHippaPdf()
    );

    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get(
      "loggedInUserId"
    );
    this.demographicId = this.activatedRoute.snapshot.paramMap.get(
      "demographicId"
    );
  }

  get f() {
    return this.hippaAcceptanceForm.controls;
  }

  onSubmit() {
    this.hippaAcceptanceFormSubmitted = true;
    if (this.hippaAcceptanceForm.invalid) {
      this.errorMsg = true;
      return;
    }

    this.router.navigate([
      "/signaturewaiver/" + this.loggedInUserId + "/" + this.demographicId
    ]);
  }

  goToDemographicForm() {
    this.router.navigate(["/demographicsform/" + this.loggedInUserId]);
  }
}
